import { PageBannerVariant } from 'components/Layout/PageBanner';
import { AaasLayout } from 'layouts/AaasLayout';
import { useApp } from 'pages/aaas/AaasDetails/useApp';
import { Outlet, useNavigate } from 'react-router-dom';

export const AaasDetails = () => {
  const navigate = useNavigate();
  const { data: app, isPending } = useApp();

  return (
    <AaasLayout
      pageBannerProps={{
        loading: isPending,
        onClickBack: () => navigate(-1),
        title: app?.name ?? '',
        variant: PageBannerVariant.AAAS,
      }}
    >
      <Outlet />
    </AaasLayout>
  );
};
