import { Button, Grid, Stack, Typography } from '@mui/material';
import { IoAddSharp } from 'react-icons/io5';

export interface IDeploymentGridActionButton {
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const DeploymentGridActionButton: React.FC<IDeploymentGridActionButton> = ({
  label,
  onClick,
  ...props
}) => (
  <Grid container item xs={4} {...props}>
    <Button
      onClick={onClick}
      sx={{
        color: theme => theme.colors.functional.text.primary,
        background: '#fff',
        '&:hover': {
          background: '#fafafa',
        },
        width: '100%',
        minHeight: '260px',
        height: '100%',
      }}
    >
      <Stack alignItems="center" flexDirection="column" justifyContent="center" spacing="24px">
        <IoAddSharp size={24} />
        <Typography mt={3} variant="bodySmallC">
          {label ?? 'New Deployment'}
        </Typography>
      </Stack>
    </Button>
  </Grid>
);
