import { Skeleton, Stack } from '@mui/material';
import { SectionContentRow } from 'pages/raas/DeploymentDetails/components/SectionContentRow';
import { Section } from 'shared/components/Section';
import { enforceLowercasedHyphenCasing } from 'utils/strings';

import { IRollupInfoSection } from './types';

export const DaDetailsSection = ({ loading, rollup }: IRollupInfoSection) => {
  return (
    <Section
      renderGridContent={
        loading
          ? Array.from({ length: 3 }).map((_, idx) => (
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                key={idx.toString()}
                sx={{
                  width: '100%',
                  py: '24px',
                  px: '40px',
                }}
              >
                <Skeleton variant="text" width="35%" />
                <Skeleton variant="text" width="40%" />
                <Skeleton height="20px" variant="rectangular" width="20px" />
              </Stack>
            ))
          : Object.entries(rollup?.dataAvailabilityMetadata ?? {}).map(([key, value]) => (
              <SectionContentRow
                clipboard
                key={key}
                label={enforceLowercasedHyphenCasing(key)}
                loading={loading}
                value={value}
              />
            ))
      }
      sectionContentSx={{ px: 0 }}
      title="DA Details"
    />
  );
};
