import { Button, Grid, Radio, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { deploymentTypeHelperText } from 'pages/aaas/NewAgentDeployment/constants';
import { DeploymentType, FormValues } from 'pages/aaas/NewAgentDeployment/types';
import { useFormContext } from 'react-hook-form';
import { ErrorCaption } from 'shared/components/Error';
import { Option } from 'shared/components/Option';

export const SelectDeploymentType: React.FC = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const form = useFormContext<FormValues>();

  const deploymentType = form.watch('deploymentType');
  const error = form.formState.errors.deploymentType;

  return (
    <Option id="step_deploymentType" optionTitle="Select deployment type">
      <Grid
        container
        sx={{
          border: theme =>
            `1px solid ${
              error ? theme.colors.schema.error : theme.colors.functional.subject.border
            }`,
          '& > .MuiButton-root': {
            width: '100%',
          },
        }}
      >
        {[DeploymentType.TEE, DeploymentType.NON_TEE].map((_deploymentType, idx) => {
          const active = _deploymentType === deploymentType;

          return (
            <Grid
              item
              key={_deploymentType}
              md={6}
              sx={theme => ({
                ...(active && {
                  border: `1px solid ${theme.colors.functional.text.link}`,
                }),
                ...((!active && idx) === 0 && {
                  [xs ? 'borderBottom' : 'borderRight']: theme =>
                    `1px solid ${theme.colors.functional.subject.border}`,
                }),
              })}
              xs={12}
            >
              <Button
                onClick={() => {
                  if (active) {
                    return form.setValue('deploymentType', undefined);
                  }

                  form.setValue('deploymentType', _deploymentType);
                }}
                sx={theme => ({
                  p: 0,
                  height: '100%',
                  justifyContent: 'start',
                  background: theme.colors.gradients.sheet,
                  '&:hover': {
                    background: theme.colors.schema.metalPrimary,
                  },
                  color: theme.colors.functional.text.primary,
                })}
              >
                <Stack
                  alignItems="start"
                  direction="row"
                  justifyContent="start"
                  spacing="40px"
                  sx={{
                    p: '24px',
                    height: '100%',
                  }}
                >
                  <Stack alignItems="start" justifyContent="start" spacing="8px">
                    <Typography variant="bodySmallM">{_deploymentType}</Typography>
                    <Typography textAlign="justify" variant="caption">
                      {deploymentTypeHelperText[_deploymentType]}
                    </Typography>
                  </Stack>

                  <Radio
                    checked={active}
                    sx={theme => ({
                      color: theme.palette.text.primary,
                      '&.Mui-checked': {
                        color: theme.colors.functional.text.link,
                      },
                    })}
                  />
                </Stack>
              </Button>
            </Grid>
          );
        })}
      </Grid>
      <ErrorCaption error={!!error} message={error?.message} />
    </Option>
  );
};
