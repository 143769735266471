import { useMediaQuery, useTheme } from '@mui/material';
import { SectionContentKeyValue } from 'pages/raas/DeploymentDetails/components/SectionContentKeyValue';
import { useMemo } from 'react';
import { Section } from 'shared/components/Section';

import { IRollupInfoSection } from './types';

export const AssetsSection = ({ loading, rollup }: IRollupInfoSection) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const truncateMaxLength = useMemo(() => {
    if (isSmallScreen) return 6;
    if (isMediumScreen) return 20;

    return 40;
  }, [isMediumScreen, isSmallScreen]);

  return (
    <Section
      renderGridContent={
        <>
          <SectionContentKeyValue
            clipboard
            label="WebSocket"
            loading={loading}
            truncate
            truncateMaxLength={truncateMaxLength}
            value={rollup?.rpcWsEndpoint}
            xs={6}
          />
          <SectionContentKeyValue
            clipboard
            hyperlink
            label="HTTP RPC"
            loading={loading}
            truncate
            truncateMaxLength={truncateMaxLength}
            value={rollup?.rpcEndpoint}
            xs={6}
          />
          <SectionContentKeyValue
            clipboard
            hyperlink
            label="Bridge"
            loading={loading}
            truncate
            truncateMaxLength={truncateMaxLength}
            value={rollup?.bridge ?? 'https://bridge.arbitrum.io/'}
            xs={6}
          />
          <SectionContentKeyValue
            clipboard
            hyperlink
            label="Explorer"
            loading={loading}
            truncate
            truncateMaxLength={truncateMaxLength}
            value={rollup?.explorer}
            xs={6}
          />
        </>
      }
      title="Assets"
    />
  );
};
