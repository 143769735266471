import { AAAType } from 'types/protoc-gen/bffaaa';

export const aaaType: Record<AAAType, string> = {
  [AAAType.AAATYPE_BASE_AGENT]: 'Based Agent',
  [AAAType.AAATYPE_PERPLEXICA]: 'Perplexica',
  [AAAType.AAATYPE_CUSTOM]: 'Custom',
  [AAAType.AAATYPE_AGENTKIT]: 'Agentkit',
  [AAAType.AAATYPE_AGENTKIT_ROLLUP]: 'Agentkit Rollup',
  [AAAType.AAATYPE_ELIZA]: 'Eliza',
  [AAAType.AAATYPE_UNSPECIFIED]: 'Unspecified',
  [AAAType.AAATYPE_APP_STORE]: 'Unrecognized',
  [AAAType.UNRECOGNIZED]: 'Unrecognized',
};
