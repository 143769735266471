import { toRegex } from 'utils/strings';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  imageName: yup
    .string()
    .required()
    .noUrl()
    .min(3)
    .max(12)
    .matches(/^[0-9a-z-]+$/, "Please use lowercase alphanumeric characters or '-' only.")
    .matches(/^[^-].*[^-]$/, "Cannot begin or end with '-'.")
    .matches(/^(?!.*-{2}).*$/, "'-' cannot be used consecutively.")
    .matches(/[a-z0-9]([-a-z0-9]*[a-z0-9])/, 'Does not match regex [a-z0-9]([-a-z0-9]*[a-z0-9])')
    .noLeadingDigit(),
  imageDesc: yup.string().required().min(5).max(300),
  agentLogo: yup.string().required().url(),
  livenessProbePath: yup
    .string()
    .required()
    .matches(/^\/.*$/, "Must begin with '/'")
    .noUrl('Only the path is required. E.g. https://example.com/ping -> /ping'),
  imageId: yup
    .string()
    .matches(
      /^(?:[a-z0-9]+(?:[._-][a-z0-9]+)*\/)*[a-z0-9]+(?:[._-][a-z0-9]+)*\/[a-z0-9]+(?:[._-][a-z0-9]+)*:[a-z0-9]+(?:[._-][a-z0-9]+)*$/,
      'Image ID must follow the format "{username}/{repository}:{tag}".',
    ),
  githubUrl: yup.string().url(),
  envVars: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Name is required'),
      regex: yup.string().isRegex().optional(),
      example: yup.string().when('regex', ([regexStrVal], schema) => {
        if (!regexStrVal) return schema.optional();
        const regex = toRegex(regexStrVal);

        return regex
          ? schema
              .required('Example value is required when regex is specified')
              .matches(regex, 'Example value does not match regex')
          : schema.required('Example value is required when regex is specified');
      }),
    }),
  ),
});
