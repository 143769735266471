import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { QUERY_KEYS } from 'types/react-query';

interface DnsQueryResult {
  Status: number;
  TC: boolean;
  RD: boolean;
  RA: boolean;
  AD: boolean;
  CD: boolean;
  Question: Array<{
    name: string;
    type: number;
  }>;
  Answer: Array<{
    name: string;
    type: number;
    TTL: number;
    data: string;
  }>;
  Comment: string;
}

export const useDnsLookup = (hostname: string): UseQueryResult<boolean, Error> => {
  return useQuery({
    queryKey: [QUERY_KEYS.DNS_LOOKUP, { hostname }],
    queryFn: () => resolveDns(hostname),
    retry: 8,
    retryDelay: 30_000,
    enabled: !!hostname,
  });
};

const resolveDns = async (hostname: string): Promise<boolean> => {
  try {
    const res = await axios.get<DnsQueryResult>('https://dns.google/resolve', {
      params: {
        name: hostname,
        type: 'A',
      },
    });

    if (res.data?.Answer?.length === 0) {
      throw new Error('No DNS records found'); // throw error to trigger retry
    }

    return res.data?.Answer?.length > 0;
  } catch (error) {
    throw new Error(`DNS lookup failed: ${(error as Error).message}`);
  }
};
