import Fuse, { Expression, FuseIndex, FuseSearchOptions, IFuseOptions } from 'fuse.js';
import { useCallback, useMemo, useState } from 'react';

interface IUseFuzzySearchOpts<T> {
  list: readonly T[];
  options?: IFuseOptions<T> | undefined;
  index?: FuseIndex<T> | undefined;
  returnEmpty?: boolean;
}

export const useFuzzySearch = <T>({
  index,
  list,
  options,
  returnEmpty,
}: IUseFuzzySearchOpts<T>) => {
  const [result, setResult] = useState<T[]>([...list]);

  const fuse = useMemo(
    () =>
      new Fuse<T>(
        list,
        {
          includeScore: true,
          threshold: 0.2,
          distance: 100,
          ...options,
        },
        index,
      ),
    [index, list, options],
  );

  const search = useCallback(
    (pattern: string | Expression, options?: FuseSearchOptions) => {
      if (!pattern) {
        return setResult(returnEmpty ? [] : [...list]);
      }

      const result = fuse.search(pattern, options);

      setResult(result.map(({ item }) => item));
    },
    [fuse, list, returnEmpty],
  );

  return {
    result,
    search,
  };
};
