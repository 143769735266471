import { Button, Stack, Typography } from '@mui/material';
import { aaaType } from 'labels/aaas';
import { AAA, AAAType, aAATypeFromJSON } from 'types/protoc-gen/bffaaa';

export interface IAgentCard {
  app: AAA;
  startAdornment?: React.ReactNode;
  onClick?: () => void;
}

export const AgentCard: React.FC<IAgentCard> = ({ app, onClick, startAdornment }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        width: '100%',
        height: 'auto',
        px: '40px',
        py: '24px',
        background: theme => theme.colors.gradients.sheet,
        color: theme => theme.colors.functional.text.primary,
        '&:hover': { background: '#fff' },
      }}
    >
      <Stack alignItems="center" direction="row" justifyContent="start" spacing="16px" width="100%">
        {startAdornment}

        <Stack alignItems="start">
          <Typography variant="body2">{app.name}</Typography>

          <Typography variant="caption">
            {aaaType[app.app ? aAATypeFromJSON(app.app) : AAAType.UNRECOGNIZED]}
          </Typography>
        </Stack>
      </Stack>
    </Button>
  );
};
