import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Editor } from '@monaco-editor/react';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useMemo } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { CustomAutocomplete } from 'shared/components/CustomAutocomplete';
import { FormTextField } from 'shared/components/form';
import { Abi, Address, isAddress } from 'viem';
import * as yup from 'yup';

export interface ImportAvsFormValues {
  contractAddress: Address;
  aggregatorHandlerAbi: { value: string; label: string };
  contractAbi: Abi;
}

const validationSchema = yup.object().shape({
  contractAddress: yup.string().address().required(),
  contractAbi: yup.object().required(),
  aggregatorHandlerAbi: yup.object().shape({ value: yup.string().required() }).required(),
});

export function ImportAvsDialog({ onClose, open, ...rest }: DialogProps) {
  const form = useForm<ImportAvsFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema as yup.ObjectSchema<ImportAvsFormValues>),
  });
  const contractAddress = form.watch('contractAddress');
  const aggregatorHandlerAbi = form.watch('aggregatorHandlerAbi');
  const contractAbi = form.watch('contractAbi');

  const onSubmit: SubmitHandler<ImportAvsFormValues> = data => {
    console.log(data);
  };

  const onError: SubmitErrorHandler<ImportAvsFormValues> = formErrors => {
    console.error(formErrors);
  };

  const {
    data: etherscanAbiRes,
    isError,
    isLoading,
    isPending,
  } = useQuery({
    queryFn: async () => {
      const res = await axios.get(
        `https://api-holesky.etherscan.io/api?module=contract&action=getabi&address=${contractAddress}&apikey=${process.env.REACT_APP_ETHERSCAN_APIKEY}`,
      );

      if (res?.status >= 400 || res?.data?.message !== 'OK') {
        throw Error('Failed to fetch ABI');
      }

      const abi: Abi = JSON.parse(res?.data?.result);

      return abi;
    },
    queryKey: ['GET_ABI', contractAddress],
    enabled: isAddress(contractAddress),
  });

  useEffect(() => {
    if (etherscanAbiRes) {
      form.clearErrors('contractAbi');
      form.setValue('contractAbi', etherscanAbiRes);

      return;
    }

    if (isError) {
      form.setValue('contractAbi', []);
    }
  }, [etherscanAbiRes, form, isError]);

  const aggregatorFnOptions = useMemo(() => {
    const abiFns = contractAbi?.filter?.(cur => cur?.type === 'function');

    return abiFns
      ?.filter(cur => cur?.inputs?.length)
      ?.map(cur => ({ label: cur?.name, value: JSON.stringify(cur) }));
  }, [contractAbi]);

  return (
    <Dialog
      disablePortal
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiPaper-root': {
          p: 3,
          background: '#FFFFFF',
          maxWidth: '90vw',
          minWidth: { xs: '60vw', md: '50vw', lg: '40vw' },
        },
      }}
      {...rest}
    >
      <DialogTitle>
        <Typography fontWeight={500} variant="h5">
          Import Existing AVS
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <FormTextField
              fieldConfig={{
                label: 'Contract Address',
                name: 'contractAddress',
                placeholder: 'Service manager contract address',
              }}
              optionProps={{ sx: { '&&': { pl: 0 } } }}
            />
            {!isPending && (
              <>
                <Typography
                  alignItems="center"
                  component="div"
                  flexDirection="row"
                  fontWeight={500}
                  mt={3}
                  variant="bodySmallC"
                >
                  Contract ABI (JSON)
                </Typography>

                <Editor
                  defaultLanguage="json"
                  height="30vh"
                  onChange={(value?: string) => {
                    try {
                      form.clearErrors('contractAbi');
                      const parsedAbi = JSON.parse(value || '[]');

                      form.setValue('contractAbi', parsedAbi, {
                        shouldTouch: true,
                      });
                    } catch (e) {
                      form.setError('contractAbi', { message: 'Unable to parse ABI as JSON' });
                    }
                  }}
                  options={{
                    selectOnLineNumbers: true,
                    scrollBeyondLastLine: false,
                    folding: true,
                    showFoldingControls: 'always',
                  }}
                  theme="vs-dark"
                  value={
                    contractAbi?.length
                      ? JSON.stringify(contractAbi, null, 2)
                      : '"Unable to fetch ABI from etherscan. Please paste your contract ABI here"'
                  }
                />
                {form.formState.errors.contractAbi?.message && (
                  <FormHelperText error>
                    {form.formState.errors.contractAbi?.message}
                  </FormHelperText>
                )}
              </>
            )}
            <Typography
              alignItems="center"
              component="div"
              flexDirection="row"
              fontWeight={500}
              sx={{ mt: 3 }}
              variant="bodySmallC"
            >
              Custom Aggregator Function
            </Typography>
            <CustomAutocomplete
              afterChange={selectedOption => {
                form.setValue(
                  `aggregatorHandlerAbi`,
                  {
                    label: selectedOption?.label,
                    value: JSON.stringify(selectedOption?.value || ''),
                  },
                  {
                    shouldTouch: true,
                    shouldValidate: true,
                  },
                );
              }}
              getOptionKey={option => option?.label}
              getOptionLabel={option => option?.label || ''}
              id={`step_${form.register('aggregatorHandlerAbi').name}`}
              loading={isLoading}
              loadingText="Fetching ABI from etherscan..."
              options={aggregatorFnOptions || []}
              placeholder="Select the aggregator function"
              sx={{ width: '100%' }}
              value={aggregatorHandlerAbi}
            />
            <LoadingButton fullWidth sx={{ mt: 3 }} variant="contained">
              Import
            </LoadingButton>
          </form>
          <DevTool control={form.control} />
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
