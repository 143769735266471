import { hideFeature } from 'helpers/visibility';
import { RollupType } from 'labels/raas';

export const getSideNavigationItems = (
  path: string | undefined,
  rollupType: RollupType,
): {
  id: string;
  label: string;
  items: {
    label: string;
    href?: string;
    hidden?: boolean;
    to: string;
  }[];
}[] =>
  [
    {
      id: 'rollup-management',
      label: 'Rollup Management',
      items: [
        {
          label: 'Rollup Info',
          to: `${path}/info`,
        },
        {
          label: 'Start a Fullnode',
          to: `${path}/start-fullnode`,
          hidden: hideFeature,
        },
        {
          label: 'Customization',
          to: `${path}/customization`,
          hidden: hideFeature,
        },
      ],
    },
    {
      id: 'operational-metrics',
      label: 'Operational Metrics',
      items: [
        {
          label: 'Rollup Statistics',
          to: `${path}/stats`,
        },
        {
          label: 'Bridge Metrics',
          to: `${path}/bridge`,
        },
        {
          label: 'Fee Tracker',
          to: `${path}/fee-tracker`,
        },
      ],
    },
    {
      id: 'technical-metrics',
      label: 'Technical Metrics',
      items: [
        {
          label: 'Rollup Statistics',
          to: `${path}/rollup-statistics`,
        },
        {
          label: 'Blockchain Statistics',
          to: `${path}/blockchain-stats`,
        },
        {
          label: 'Proposer',
          to: `${path}/proposer`,
        },
        {
          label: 'Batcher',
          to: `${path}/batcher`,
        },
      ],
    },
    {
      id: 'plugin-service',
      label: 'Plugin Service',
      items: [
        {
          label: 'Balance Alert',
          to: `${path}/balance`,
        },
        {
          label: 'Auto Withdrawal Fee',
          to: `${path}/withdrawal-fee`,
          hidden: !isOpstack(rollupType),
        },
        {
          label: 'RPC Usage Monitoring',
          to: `${path}/rpc`,
          hidden: !isOpstack(rollupType),
        },
      ],
    },
    {
      id: 'troubleshooting',
      label: 'Troubleshooting',
      items: [
        {
          label: 'FAQ',
          to: `${path}/faq`,
        },
        {
          label: 'Contact us',
          to: `${path}/contact-us`,
        },
      ],
    },
  ].filter(({ id }) => {
    // Show everything for OP Stack
    if (isOpstack(rollupType)) return true;

    // Only show basic panels for other rollup types
    return ['rollup-management', 'troubleshooting', 'plugin-service'].includes(id);
  });

const isOpstack = (rollupType: RollupType) => rollupType === RollupType.OPSTACK;
