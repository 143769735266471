import { Box, CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { axiosAuth } from '../api';
import { PATHS } from '../App';
import { BaseLayout } from '../components/Layout';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header';
import { authUtil } from '../utils/auth';

export enum PERMISSIONS {
  WRITE_PROMO_CODE = 'write:promo_code',
  READ_PROMO_CODE = 'read:promo_code',
  WRITE_ORG_DEPLOYMENTS = 'write:org_deployments',
  WRITE_ORG_USERS = 'write:org_users',
  WRITE_ALL_OPERATORS = 'write:all_operators',
  READ_ALL_DEPLOYMENTS = 'read:all_deployments',
}
export interface User {
  email: string;
  org_id: number;
  org_name: string;
  user_id: number;
  picture: string;
  name: string;
  permissions: PERMISSIONS[];
}
interface Context {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  loginSuccessRedirectPath: string;
  setLoginSuccessRedirectPath: Dispatch<SetStateAction<string>>;
  organization: string;
  setOrganization: Dispatch<SetStateAction<string>>;
  user?: User;
  setUser: Dispatch<SetStateAction<User | undefined>>;
}

export const AuthContext = createContext<Context>({} as Context);

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginSuccessRedirectPath, setLoginSuccessRedirectPath] = useState(
    [PATHS.LOGIN, PATHS.CREATE_ORG, '', '/']?.includes(location.pathname)
      ? PATHS.DEFAULT
      : location.pathname + location.search,
  );

  const [organization, setOrganization] = useState('');
  const [user, setUser] = useState<User>();
  const navigate = useNavigate();

  const {
    data: res,
    error,
    isPending: isPendingRefreshToken,
  } = useQuery<{
    data: { user: User; accessToken: string };
  }>({
    queryKey: ['GET_REFRESH_TOKEN'],
    queryFn: async () => {
      authUtil.loadAccessToken();

      return await axiosAuth.get('/auth/refresh');
    },
    retry: false,
  });

  useEffect(() => {
    if (error) {
      setIsLoggedIn(false);
    } else if (res) {
      const { accessToken, user } = res?.data || {};

      setIsLoggedIn(true);
      authUtil.setAccessToken(accessToken);
      setUser(user);

      if (!user?.org_id) {
        navigate(PATHS.CREATE_ORG);
      } else {
        navigate(loginSuccessRedirectPath || PATHS.DEFAULT);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res, error]);

  const showLoadingScreen = !error && (isPendingRefreshToken || !user);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        loginSuccessRedirectPath,
        setLoginSuccessRedirectPath,
        organization,
        setOrganization,
        user,
        setUser,
      }}
    >
      {showLoadingScreen ? (
        <BaseLayout>
          <Box
            sx={{
              background: "url('/imgs/bgApp.png')",
              position: 'absolute',
              height: '100vh',
              width: '100vw',
              backgroundRepeat: 'repeat',
              zIndex: -1,
              bgcolor: '#fafafa',
              backgroundPositionY: '5rem',
            }}
          />
          <Header />
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              flex: 1,
              position: 'relative',
              minHeight: 'calc(100vh - 200px)',
            }}
          >
            <CircularProgress size={50} />
          </Stack>
          <Footer />
        </BaseLayout>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
