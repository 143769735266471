import { Skeleton, Stack, Typography } from '@mui/material';
import { MetricWidget } from 'shared/components/metric/MetricWidget';
import { StatusLabel } from 'shared/components/StatusLabel';

import { useRpm } from './hooks';

// TODO: remove when rpc usage monitoring finalized
export const RpmCountMetric = () => {
  const { isPending } = useRpm();

  return (
    <Stack sx={{ background: '#fafafa' }}>
      <MetricWidget
        caption={'QPS'}
        label={'Throughput Limit'}
        loading={isPending}
        value={2_000}
        xs={12}
      />

      <Stack
        alignItems="start"
        justifyContent="start"
        spacing="16px"
        sx={{
          borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
          borderRight: theme => `1px solid ${theme.colors.functional.subject.border}`,
          px: '40px',
          py: '24px',
        }}
      >
        <StatusLabel loading={isPending} sx={{ background: theme => theme.colors.gradients.leaf }}>
          Healthy
        </StatusLabel>

        {isPending ? (
          <Stack spacing="8px" width="100%">
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="75%" />
          </Stack>
        ) : (
          <Typography variant="caption">
            Your rollup has stayed under the monthly limit and throughput limit in the past 24
            hours.{' '}
            <a href="#" style={{ textDecoration: 'underline' }} target="_blank">
              Contact Support
            </a>{' '}
            to upgrade.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
