import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  IconButtonProps,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { terminateOperator } from 'api/avs';
import IconLink from 'components/icons/IconLink';
import Web3LoadingButton from 'components/Web3/Web3LoadingButton';
import RegistryCoordinatorAbi from 'contracts/RegistryCoordinatorAbi';
import { useAvsRoles } from 'hooks/useAvsRoles';
import { OperatorState, useOperators } from 'hooks/useOperators';
import { useWriteTx } from 'hooks/useWriteTx';
import { _strategyOptions } from 'pages/avs/NewAVS/form-steps/SelectStrategies';
import { FC, Fragment, MouseEvent, ReactNode, useMemo, useRef, useState } from 'react';
import { IoMdMore } from 'react-icons/io';
import CheckboxCard from 'shared/components/Card/CheckboxCard';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { AVS_QUORUMS } from 'types/avs';
import { AVSDeployment, OperatorStatus, QueryOperatorResponse } from 'types/protoc-gen/avs';
import { QUERY_KEYS } from 'types/react-query';
import { getOnChainQuorums } from 'utils/avs';
import { Address, toHex } from 'viem';

type StatusFilter = {
  whitelisted?: boolean;
  registered?: boolean;
};

function TerminateButton({
  avs,
  disabled,
}: {
  avs: AVSDeployment;
  operator: OperatorState;
  disabled?: boolean;
}) {
  const { isPending, mutate: terminate } = useMutation({
    mutationFn: terminateOperator,
    onSuccess: () => {
      client?.invalidateQueries({ queryKey: [QUERY_KEYS.GET_OPERATOR_STATUS, avs?.deploymentId] });
      setShowConfirmationDialog(false);
    },
  });
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const client = useQueryClient();

  return (
    <>
      <ConfirmationDialog
        handleClose={() => {
          setShowConfirmationDialog(false);
        }}
        isLoading={isPending}
        onClick={e => e?.stopPropagation()}
        onConfirm={() => {
          terminate({ deploymentId: avs?.deploymentId });
        }}
        open={showConfirmationDialog}
      >
        Are you sure you want to terminate the hosted operator?
      </ConfirmationDialog>
      <ListItemButton
        disabled={disabled}
        onClick={() => {
          setShowConfirmationDialog(true);
        }}
      >
        <ListItemText primary="Terminate" />
      </ListItemButton>
    </>
  );
}

function EjectButton({
  avs,
  disabled,
  operator,
}: {
  avs: AVSDeployment;
  operator: OperatorState;
  disabled?: boolean;
}) {
  const [showEjectDialog, setShowEjectDialog] = useState(false);
  const { refetchOperatorStates } = useOperators({ avs });
  const strategyOptions = useMemo(
    () => _strategyOptions?.filter(cur => operator?.registeredQuorums?.includes(cur?.value)),
    [operator?.registeredQuorums],
  );
  const [selectedQuorums, setSelectedQuorums] = useState([] as AVS_QUORUMS[]);

  const handleSelectOption = (value: AVS_QUORUMS) => () => {
    if (selectedQuorums.includes(value)) {
      setSelectedQuorums(selectedQuorums.filter(cur => cur !== value));
    } else {
      setSelectedQuorums([...selectedQuorums, value]?.sort());
    }
  };

  const { isPending, write: eject } = useWriteTx({
    contractAbi: RegistryCoordinatorAbi,
    contractAddress: avs?.registryCoordinatorProxy as Address,
    functionName: 'ejectOperator',
    functionArgs: [
      operator?.address,
      `0x${getOnChainQuorums(selectedQuorums, avs)
        ?.map(num => toHex(num, { size: 1 })?.slice(2))
        ?.sort()
        ?.join('')}`,
    ],
    txKey: `eject_${operator?.address}`,
    onTxConfirmed: () => {
      refetchOperatorStates();
    },
  });

  const { ejector } = useAvsRoles(avs);

  return (
    <>
      <Dialog
        disablePortal
        onClick={e => {
          e?.stopPropagation();
        }}
        onClose={() => setShowEjectDialog(false)}
        open={showEjectDialog}
        sx={{ '& .MuiDialog-container > .MuiPaper-root': { p: 4, minWidth: '30rem' } }}
      >
        <DialogTitle>Eject Operator</DialogTitle>
        <DialogContent>
          <Typography variant="bodySmallC">Select strategies to eject from</Typography>
          {strategyOptions?.map((option, index) => {
            const isNextOptionSelected = selectedQuorums?.includes(
              strategyOptions?.[index + 1]?.value,
            );

            return (
              <Grid item key={option?.value} xs={12}>
                <CheckboxCard
                  content={<Typography px={1}>{option.label}</Typography>}
                  isSelected={selectedQuorums?.includes(option.value)}
                  leftButtonGroup={
                    <Checkbox
                      checked={selectedQuorums?.includes(option.value)}
                      onChange={handleSelectOption(option.value)}
                      size="small"
                      sx={{ ml: 1.5 }}
                    />
                  }
                  onClick={handleSelectOption(option.value)}
                  sx={{ py: 1, svg: {}, ...(isNextOptionSelected && { borderBottom: 'none' }) }}
                />
              </Grid>
            );
          })}
          <Web3LoadingButton
            disabled={!selectedQuorums?.length}
            loading={isPending}
            onClick={eject}
            requiredRole="ejector"
            requiredSender={ejector}
            sx={{ mt: 2 }}
            variant="contained"
          >
            <Typography>Eject</Typography>
          </Web3LoadingButton>
        </DialogContent>
      </Dialog>
      <ListItemButton
        disabled={disabled}
        onClick={() => {
          setShowEjectDialog(true);
        }}
      >
        <ListItemText primary="Eject" />
      </ListItemButton>
    </>
  );
}

const OperatorActionsMenu: FC<
  {
    avs: AVSDeployment;
    operator: OperatorState;
    operatorStatus: QueryOperatorResponse;
    renderMenuItems?: (
      defaultActions: { allowedStatuses?: StatusFilter; component: ReactNode }[],
    ) => ReactNode;
    isAvsOwner: boolean;
  } & IconButtonProps
> = ({ avs, isAvsOwner, operator, operatorStatus, renderMenuItems, sx, ...rest }) => {
  const ref = useRef<any>(null);
  const [showActionMenu, setShowActionMenu] = useState(false);

  const toggleActionMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    setShowActionMenu(state => !state);
  };

  const closeActionMenu = (e: MouseEvent) => {
    e?.stopPropagation();
    setShowActionMenu(false);
  };

  const actions: { allowedStatuses?: StatusFilter; component: ReactNode }[] = useMemo(
    () => [
      {
        component: (
          <ListItemButton
            component={Link}
            href={`https://holesky.eigenlayer.xyz/operator/${operator?.address}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <ListItemText primary="Details" />
            <IconLink />
          </ListItemButton>
        ),
      },
      {
        allowedStatuses: { registered: true },
        component: operator?.registered ? (
          <EjectButton avs={avs} disabled={!isAvsOwner} operator={operator} />
        ) : null,
      },
      {
        component:
          operator?.address === avs?.operators?.[0]?.operator &&
          [OperatorStatus.running, OperatorStatus.launching]?.includes(operatorStatus?.status) ? (
            <TerminateButton avs={avs} disabled={!isAvsOwner} operator={operator} />
          ) : null,
      },
    ],
    [avs, isAvsOwner, operator, operatorStatus?.status],
  );
  const actionsForCurrentStatus = actions.filter(cur =>
    Object.keys(cur?.allowedStatuses || {})?.every(
      key =>
        operator?.[key as 'whitelisted' | 'registered'] ===
        cur?.allowedStatuses?.[key as 'whitelisted' | 'registered'],
    ),
  );

  return (
    <>
      <IconButton
        onClick={toggleActionMenu}
        ref={ref}
        sx={{ background: 'transparent', pointerEvents: 'all', ...sx }}
        {...rest}
      >
        <IoMdMore />
      </IconButton>
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={closeActionMenu}
        open={showActionMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List
          component="nav"
          onClick={e => {
            e?.stopPropagation();
          }}
          sx={{ p: 0 }}
        >
          {renderMenuItems ? (
            renderMenuItems?.(actionsForCurrentStatus)
          ) : actionsForCurrentStatus?.length > 0 ? (
            actionsForCurrentStatus.map((cur, index) => (
              <Fragment key={index}>{cur.component}</Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No Actions Available" />
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};

export default OperatorActionsMenu;
