import { useQuery } from '@tanstack/react-query';
import { bff } from 'api';
import { ListAAAReply } from 'types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'types/react-query';

export const useListApps = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_LIST_AAA],
    queryFn: async () => {
      const res = await bff.get<ListAAAReply>('/bff/aaas');

      return res.data;
    },
  });
};
