import { Grid } from '@mui/material';
import { SectionButtonTimeFilter } from 'pages/raas/DeploymentDetails/components/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { BridgeTVLMetric } from 'pages/raas/DeploymentDetails/views/OverviewView/BridgeTVLMetric';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { TokenPairInfoMetric } from './TokenPairInfoMetric';

export const GeneralSection = () => {
  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <BridgeTVLMetric />
          </MetricGridItem>
          <Grid item xs={6}>
            <TokenPairInfoMetric />
          </Grid>
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter variant={TimeFilterVariant.BRIDGE_METRICS_GENERAL_SECTION} />
      }
      sectionContentSx={{ p: '0px' }}
      title="General"
    />
  );
};
