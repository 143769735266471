import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import IconCheckedCircle from 'components/icons/IconCheckedCircle';
import IconNay from 'components/icons/IconNay';
import IconUncheckedCircle from 'components/icons/IconUncheckedCircle';
import { useMemo } from 'react';
import { FieldName, useFormContext, useFormState } from 'react-hook-form';

import { FormValues } from './types';

export function Checked({ valid }: { valid?: boolean }) {
  return valid ? (
    <IconCheckedCircle />
  ) : valid === undefined ? (
    <IconUncheckedCircle />
  ) : (
    <IconNay />
  );
}

const labelMap: Record<keyof FormValues, string> = {
  // Order of the keys of this map is also the order of appearance on the summary panel.
  imageName: 'Name:',
  imageDesc: 'Description:',
  imageId: 'Image ID:',
  agentLogo: 'Logo:',
  livenessProbePath: 'Liveness:',
  githubUrl: 'Github:',
  envVars: 'Env vars:',
};

function getDisplayValue(fieldName: keyof FormValues, value: FormValues[keyof FormValues]) {
  switch (fieldName) {
    case 'imageDesc':
      return value?.length && value?.length > 10
        ? `${value?.slice(0, 4)}...${value?.slice(-4)}`
        : value;
    case 'agentLogo':
      return value && <Box component="img" src={String(value)} sx={{ height: 40, width: 40 }} />;
    case 'envVars':
      return Array.isArray(value) && value?.length ? value?.map(cur => cur?.name).join(', ') : '';
    default:
      return value;
  }
}

export function PublishAgentSummaryPanel({
  isSubmitDisabled,
  isSubmitting,
}: {
  isSubmitting?: boolean;
  isSubmitDisabled?: boolean;
}) {
  const form = useFormContext<FormValues>();
  const formValues = form?.watch();
  const formState = useFormState({ control: form.control });

  const summaryItems = useMemo(
    () =>
      Object.entries(formValues)
        ?.map(([name, value]) => {
          const fieldName = name as keyof FormValues;

          let isError = false;
          const fieldErrors = (formState?.errors as any)?.[name];

          if (fieldErrors?.message || fieldErrors?.root?.messge) {
            isError = true;
          } else if (Array.isArray(fieldErrors)) {
            isError = fieldErrors?.some?.((cur: any) =>
              Object.values(cur || {})?.some?.(val => Boolean(val)),
            );
          } else if (Object.values(fieldErrors || {})?.length) {
            isError = true;
          }

          return {
            isError,
            fieldName,
            value: getDisplayValue(fieldName, value),
            touched: Boolean(
              (formState?.touchedFields as Record<string, boolean | undefined>)?.[fieldName],
            ),
            valid: !form.getFieldState(fieldName as any)?.invalid,
            dirty: form.getFieldState(fieldName as any)?.isDirty,
          };
        })
        ?.sort(
          (a, b) =>
            Object.keys(labelMap || {})?.findIndex(cur => cur === a.fieldName) -
            Object.keys(labelMap || {})?.findIndex(cur => cur === b.fieldName),
        ),
    [formValues, formState?.errors, formState?.touchedFields, form],
  );

  const scrollToStep = (fieldName: FieldName<FormValues>) => () => {
    const el = document.getElementById(`step_${fieldName}`);

    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.08)',
        background: 'linear-gradient(118.58deg, #F6F6F6 0%, #E7E7E7 100%)',
        m: { md: 5 },
        position: 'sticky',
        top: 40,
      }}
    >
      <Stack alignItems="center" direction="row" gap={1} sx={{ px: 5, py: 2 }}>
        <Typography pt={5} variant="captionC">
          Summary
        </Typography>
      </Stack>
      {summaryItems?.map(cur => (
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          key={cur.fieldName}
          onClick={scrollToStep(cur.fieldName)}
          sx={{
            px: 5,
            py: 2,
            borderTop: '1px solid rgba(32, 39, 35, 0.08)',
            cursor: 'pointer',
          }}
        >
          <Checked
            valid={
              cur.valid && (cur.dirty || formState?.isSubmitted)
                ? true
                : cur.isError
                ? false
                : undefined
            }
          />
          <Typography variant="caption">
            {labelMap?.[cur?.fieldName] ?? `${cur?.fieldName}`}
          </Typography>
          {labelMap?.[cur?.fieldName] && (
            <Typography variant="captionM">
              {Array.isArray(cur?.value) ? String(cur?.value) : cur?.value}
            </Typography>
          )}
        </Stack>
      ))}
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        sx={{ px: 5, py: 2, borderTop: '1px solid rgba(32, 39, 35, 0.08)' }}
      >
        <LoadingButton
          disabled={isSubmitting || isSubmitDisabled}
          fullWidth
          loading={isSubmitting}
          type="submit"
          variant="contained"
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </LoadingButton>
      </Stack>
    </Box>
  );
}
