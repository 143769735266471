import { RequireLoginGuard } from 'guards';
import { Outlet, RouteObject } from 'react-router-dom';

import { routes as detailsRoutes } from './AaasDetails/routes';
import { AaasDeployments } from './AaasDeployments';
import { AaasDetails } from './AaasDetails';
import { NewAgentDeployment } from './NewAgentDeployment';
import { PublishCommunityAgent } from './PublishCommunityAgent';

export enum AAAS_PATHS {
  AAAS_DEPLOYMENTS = '/autonome',
  AAAS_DETAILS = '/autonome/:appId/details',
  AAAS_DETAILS_CHAT = '/autonome/:appId/chat',
  AAAS_NEW_DEPLOYMENT = '/autonome/new',
  AAAS_PUBLISH_AGENT = '/autonome/publish',
}

export const routes: RouteObject[] = [
  {
    path: AAAS_PATHS.AAAS_DEPLOYMENTS,
    element: (
      <RequireLoginGuard>
        <Outlet />
      </RequireLoginGuard>
    ),
    children: [
      {
        path: '',
        element: <AaasDeployments />,
      },
      {
        path: ':appId',
        element: <AaasDetails />,
        children: detailsRoutes,
      },
      {
        path: 'new',
        element: <NewAgentDeployment />,
      },
      {
        path: 'publish',
        element: <PublishCommunityAgent />,
      },
    ],
  },
];
