export enum QUERY_KEYS {
  GET_BFF_PROMO_CODES = 'GET_BFF_PROMO_CODES',
  GET_AVS_PROMO_CODES = 'GET_AVS_PROMO_CODES',
  VERIFY_PROMO_CODE = 'VERIFY_PROMO_CODE',
  GET_DEPLOYMENTS = 'GET_ROLLUP_DEPLOYMENTS',
  GET_DEPLOYMENT = 'GET_ROLLUP_DEPLOYMENT',
  GET_AVS_DEPLOYMENTS = 'GET_AVS_DEPLOYMENTS',
  GET_AGGREGATORS_STATUS = 'GET_AGGREGATORS_STATUS',
  GET_ROLLUP_KEYS = 'GET_ROLLUP_KEYS',
  CREATE_AVS = 'CREATE_AVS',
  GET_AVS_OPERATORS = 'GET_AVS_OPERATORS',
  GET_AVS_METADATA = 'GET_AVS_METADATA',
  GET_OPERATOR_STATUS = 'GET_OPERATOR_STATUS',
  GET_GLOBAL_AVS = 'GET_GLOBAL_AVS',
  GET_AAA_DEPLOYED_APPS = 'GET_AAA_DEPLOYED_APPS',
  PREVIEW_METADATA = 'PREVIEW_METADATA',
  GET_RAAS_METRICS = 'GET_RAAS_METRICS',
  GET_BLOCKSCOUT_STATS = 'GET_BLOCKSCOUT_STATS',
  VIEM_BATCH_BALANCES = 'VIEM_BATCH_BALANCES',
  GET_ROLLUP_STATUS = 'GET_ROLLUP_STATUS',
  GET_LIST_AAA = 'GET_LIST_AAA',
  GET_LIST_AAA_TYPES = 'GET_LIST_AAA_TYPES',
  AAAS_HEALTHZ = 'AAAS_HEALTHZ',
  AAAS_PENDING_AGENT_REQUESTS = 'AAAS_PENDING_AGENT_REQUESTS',
  RAAS_WITHDRAW_FEE_STATUS = 'RAAS_WITHDRAW_FEE_STATUS',
  RAAS_WITHDRAW_FEE_HISTORY = 'RAAS_WITHDRAW_FEE_HISTORY',
  DNS_LOOKUP = 'DNS_LOOKUP',
  RAAS_BALANCE_ALERT_LIST_ACCOUNTS = 'RAAS_BALANCE_ALERT_LIST_ACCOUNTS',
  RAAS_WITHDRAW_FEE_REGISTERED = 'RAAS_WITHDRAW_FEE_REGISTERED',
}

export enum MUTATION_KEYS {
  AAAS_DEPLOY_APPS = 'AAAS_DEPLOY_APPS',
  AAAS_VERIFY_PROMO_CODE = 'AAAS_VERIFY_PROMO_CODE',
  AAAS_AI_AGENT_PROMPT = 'AAAS_AI_AGENT_PROMPT',
  PROMOTE_TEAM_MEMBER = 'PROMOTE_TEAM_MEMBER',
  KICK_TEAM_MEMBER = 'KICK_TEAM_MEMBER',
  ADD_NETWORK_TO_WALLET = 'ADD_NETWORK_TO_WALLET',
  CREATE_AAA = 'CREATE_AAA',
  TERMINATE_AAA = 'TERMINATE_AAA',
  RAAS_WITHDRAW_FEE_REGISTER = 'RAAS_WITHDRAW_FEE_REGISTER',
  RAAS_WITHDRAW_FEE_TRIGGER = 'RAAS_WITHDRAW_FEE_TRIGGER',
  RAAS_BALANCE_ALERT_DELETE_ACCOUNT = 'RAAS_BALANCE_ALERT_DELETE_ACCOUNT',
  RAAS_BALANCE_ALERT_CREATE_ACCOUNT = 'RAAS_BALANCE_ALERT_CREATE_ACCOUNT',
  RAAS_BALANCE_ALERT_UPDATE_ACCOUNT = 'RAAS_BALANCE_ALERT_UPDATE_ACCOUNT',
}
