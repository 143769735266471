import { Button, Grid, Stack, Typography } from '@mui/material';
import { SubHeader } from 'components/Layout/SubHeader';
import { useListApps } from 'hooks/aaas';
import { useDisclosure } from 'hooks/ui';
import { AaasLayout } from 'layouts/AaasLayout';
import { IoAddSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';

import { AgentCard } from './components/AgentCard';
import AgentCreationModeDialog from './components/AgentCreationModeDialog';
import AgentFrameworksCarousel from './components/AgentFrameworksCarousel';
import UserAgentStore from './components/UserAgentStore';

export const AaasDeployments = () => {
  const navigate = useNavigate();
  const { data, isPending: getAppsPending } = useListApps();
  const { onClose, onOpen, open } = useDisclosure();

  return (
    <>
      <AgentCreationModeDialog onClose={onClose} open={open} />
      <AaasLayout>
        <AgentFrameworksCarousel />
        <UserAgentStore />
        <SubHeader isLoading={getAppsPending}>Your deployed agents</SubHeader>
        <Grid
          container
          sx={{
            background: '#fafafa',
            pb: '50px',
            '& > *': theme => ({
              borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
              borderRight: `1px solid ${theme.colors.functional.subject.border}`,
            }),
          }}
        >
          {data?.aaas?.map((app, index) => (
            <Grid item key={app.id} xs={3}>
              <AgentCard
                app={app}
                onClick={() => navigate(PATHS.AAAS_DETAILS.replace(':appId', app.id))}
                startAdornment={
                  <Typography
                    alignItems="center"
                    bgcolor={
                      [
                        'green',
                        'gainsboro',
                        'wheat',
                        'violet',
                        'turquoise',
                        'tomato',
                        'teal',
                        'silver',
                        'coral',
                        'crimson',
                        'lemonchiffon',
                        'oldlace',
                      ][index] ?? 'blanchedalmond' // TODO: remove when backend integration is ready
                    }
                    borderRadius={index % 2 === 0 ? '50%' : '0'}
                    color="white"
                    display="flex"
                    height="48px"
                    justifyContent="center"
                    variant="body2"
                    width="48px"
                  >
                    {app.name.slice(0, 2).toUpperCase()}
                  </Typography>
                }
              />
            </Grid>
          ))}

          <Grid item xs={3}>
            <Button
              onClick={onOpen}
              sx={theme => ({
                minHeight: '96px',
                width: '100%',
                background: '#fff',
                color: theme.colors.functional.text.primary,
                '&:hover': { background: '#fafafa' },
              })}
            >
              <Stack alignItems="center" height="100%" justifyContent="center">
                <IoAddSharp size={24} />
              </Stack>
            </Button>
          </Grid>
        </Grid>
      </AaasLayout>
    </>
  );
};
