import { Metric } from 'shared/components/metric';
import { minuteFormatter } from 'utils/recharts';

import { useRpmByMethods } from './hooks';

export const RpmByMethodsMetric = () => {
  const { data, isPending, isRefetching, refetch } = useRpmByMethods();

  return (
    <Metric
      chartType="line"
      data={data}
      description={'Requests per minute for each method'}
      hideLegend
      id="requests-by-methods"
      isRefetching={isRefetching}
      loading={isPending}
      moreMenuProps={{
        onClickRefetch: () => refetch(),
      }}
      responsiveContainerSx={{ minHeight: '250px' }}
      title="Requests by methods"
      xAxisProps={{
        tickFormatter: minuteFormatter,
      }}
    />
  );
};
