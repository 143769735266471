import { useBlockscoutMetrics } from 'hooks';
import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { Metric } from 'shared/components/metric';

export const NewContractsMetric = () => {
  const { selectedTimeFilter } = useTimeFilterStore();
  const {
    end: to,
    start: from,
    timeframe,
  } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.ROLLUP_STATS_CONTRACTS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'newContracts',
    l2: true,
    timeframe,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Number of new contracts in the network'}
      id={'new-contracts'}
      loading={isPending}
      title={'New contracts'}
    />
  );
};
