import { Box, Button, Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { AAAType, aAATypeToJSON } from 'types/protoc-gen/bffaaa';

export interface AgentCarouselCardProps {
  tagNames: string[];
  iconUrl: string;
  title: string;
  description: string;
  aaaType: AAAType;
}

const tagMap: Record<string, ReactNode> = {
  featured: (
    <Box
      component="span"
      sx={{
        p: 0.5,
        background: theme => theme.colors.gradients.cream,
        border: '1px solid rgba(32, 39, 35, 0.08)',
      }}
    >
      Featured
    </Box>
  ),
  community: <Box>Community</Box>,
};

export const AgentCarouselCard: React.FC<{ card: AgentCarouselCardProps }> = ({ card }) => {
  const navigate = useNavigate();

  return (
    <Grid
      alignItems="flex-start"
      container
      item
      justifyContent="space-between"
      spacing={1}
      sx={{
        px: '40px',
        py: '24px',
        background: theme => theme.colors.gradients.sheet,
        border: '1px solid rgba(32, 39, 35, 0.08)',
        color: theme => theme.colors.functional.text.primary,
        height: '100%',
        '&:hover': { background: '#fff' },
      }}
      xs={12}
    >
      <Grid item xl={3} xs={6}>
        <Box component="img" src={card?.iconUrl} />
      </Grid>
      <Grid item xl={5} xs={6}>
        <Typography variant="h5">{card?.title}</Typography>
        <Box>{card?.tagNames?.map(cur => tagMap?.[cur])}</Box>
      </Grid>
      <Grid item order={{ xs: 3, xl: 0 }} xl={4} xs={12}>
        <Button
          fullWidth
          onClick={() =>
            navigate(`${PATHS.AAAS_NEW_DEPLOYMENT}?template=${aAATypeToJSON(card?.aaaType)}`)
          }
        >
          Deploy
        </Button>
      </Grid>
      <Grid item xs={12}>
        {card?.description}
      </Grid>
    </Grid>
  );
};
