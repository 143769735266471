import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import CopyableText from 'shared/components/Copyable/CopyableText';
import { AVSDeployment } from 'types/protoc-gen/avs';
import { Address } from 'viem';

function ContractItem({
  contract: { address, name },
}: {
  contract: { name: string; address: Address };
}) {
  return (
    <Stack gap={1} mb={2} mr={2}>
      <Typography>{name}:</Typography>
      <CopyableText
        href={`https://holesky.etherscan.io/address/${address}`}
        linkProps={{ sx: { '&:hover': { textDecoration: 'underline' } } }}
        placement="right"
        showTooltip={false}
        text={address}
      >
        <Typography>{address}</Typography>
      </CopyableText>
    </Stack>
  );
}

export default function ContractAddressesDialog({
  avs,
  onClose,
  open,
  ...rest
}: DialogProps & { avs?: AVSDeployment }) {
  const contractAddresses = Object.entries((avs || {}) as Record<string, any>)?.reduce(
    (acc, [key, value]) => {
      if (typeof value === 'string' && value?.startsWith('0x')) {
        return {
          ...acc,
          [key]: value as Address,
        };
      }

      return acc;
    },
    {} as Record<string, Address>,
  );

  return (
    <Dialog
      disablePortal
      onClose={onClose}
      open={open}
      sx={{ '& .MuiPaper-root': { p: 3, background: '#FFFFFF' } }}
      {...rest}
    >
      <DialogTitle>
        <Typography fontWeight={500} variant="h5">
          Contract Addresses
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ bgcolor: '#FAFAFA', border: '1px solid rgba(32, 39, 35, 0.08)', p: 3 }}>
          {Object.entries(contractAddresses)?.map(([name, address]) => (
            <ContractItem contract={{ name, address }} key={address} />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
