import { CircularProgress, Modal, Stack } from '@mui/material';
import { CredentialResponse } from '@react-oauth/google';
import { AxiosError, AxiosResponse } from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { axiosAuth } from '../../api';
import { PATHS } from '../../App';
import { useAuthContext, User } from '../../contexts/AuthContext';
import { authUtil } from '../../utils/auth';
import { AutonomeLogin } from './AutonomeLogin';
import { WizardLogin } from './WizardLogin';

const LoginComponent =
  window.location.origin === process.env.REACT_APP_AUTONOME_ORIGIN ? AutonomeLogin : WizardLogin;

export function Login() {
  const { loginSuccessRedirectPath, setIsLoggedIn, setUser } = useAuthContext();
  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleInitializeMava = useCallback(async (user: User) => {
    if (!window.Mava) return console.debug('Mava not initialized');

    await window.Mava.initialize();
    await window.Mava.identify({
      emailAddress: user.email,
      customAttributes: [
        {
          label: 'User ID',
          value: String(user.user_id),
        },
        {
          label: 'Name',
          value: user.name,
        },
        {
          label: 'Organization ID',
          value: String(user.org_id),
        },
        {
          label: 'Organization Name',
          value: user.org_name,
        },
      ],
    });

    console.debug('Mava initialized and identified');
  }, []);

  const handleGoogleLoginSuccess = useCallback(
    async (credentialResponse: CredentialResponse) => {
      const idToken = credentialResponse?.credential;

      console.debug('id token: ', idToken);

      let res: AxiosResponse<{ accessToken: string; user: User }>;

      try {
        setIsLoggingIn(true);
        res = await axiosAuth.get<{ accessToken: string; user: User }>('/auth/login', {
          headers: { Authorization: `Bearer ${idToken}` },
        });

        await handleInitializeMava(res.data.user);
      } catch (_err) {
        const err = _err as AxiosError<{ message: string }>;

        addAlert({
          title: `Login failed (${err?.response?.statusText})`,
          severity: ALERT_SEVERITY.ERROR,
          desc: `${err?.response?.data?.message}`,
        });

        return;
      } finally {
        setIsLoggingIn(false);
      }

      const { accessToken, user } = res?.data || {};

      authUtil.setAccessToken(accessToken);
      setUser(user);
      setIsLoggedIn(true);

      if (!user?.org_id) {
        navigate(PATHS.CREATE_ORG);
      } else {
        navigate(loginSuccessRedirectPath || PATHS.DEFAULT);
      }
    },
    [addAlert, handleInitializeMava, loginSuccessRedirectPath, navigate, setIsLoggedIn, setUser],
  );

  return (
    <>
      <Modal open={isLoggingIn}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            flex: 1,
            position: 'relative',
            width: '100vw',
            height: '100vh',
          }}
        >
          <CircularProgress size={50} sx={{ color: '#EAEAEAAA' }} />
        </Stack>
      </Modal>
      <LoginComponent handleGoogleLoginSuccess={handleGoogleLoginSuccess} />
    </>
  );
}
