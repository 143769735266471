import { Box } from '@mui/material';
import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';

export const MarkdownRenderer: FC<{ markdown: string }> = ({ markdown }) => {
  const components: Components = {
    a: ({ children, href }) => (
      <a href={href} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    ),
    img: ({ alt, src }) => (
      <Box alt={alt} component="img" src={src} sx={{ height: 'auto', maxWidth: '100%' }} />
    ),
  };

  return <ReactMarkdown components={components}>{markdown}</ReactMarkdown>;
};
