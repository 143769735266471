import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useRoutes } from 'react-router-dom';
import { PATHS, routes } from 'routes';

import Layout from './components/Layout';
import { AuthContextProvider } from './contexts/AuthContext';
import { inputGlobalStyles } from './theme/globalStyle';

export { PATHS }; // TODO: migrate all that imports PATHS from App

const isAutonomeSubDomain = window.location.origin === process.env.REACT_APP_AUTONOME_ORIGIN;

const App: React.FC = () => {
  const content = useRoutes(routes);

  return (
    <>
      {isAutonomeSubDomain && (
        <HelmetProvider>
          <Helmet>
            <title>{isAutonomeSubDomain ? 'Autonome' : 'AltLayer Wizard'}</title>
            <link href="/imgs/autonome.svg" rel="icon" />
          </Helmet>
        </HelmetProvider>
      )}
      <CssBaseline />
      {inputGlobalStyles}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}>
        <AuthContextProvider>
          <Layout>{content}</Layout>
        </AuthContextProvider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
