export function countLeadingZeroes(value: number): number {
  // Convert the number to a string in fixed-point notation to avoid scientific notation
  const valueString = value.toFixed(20).replace(/0+$/, ''); // Remove trailing zeroes

  // Extract the fractional part after the decimal
  const fractionalPart = valueString.split('.')[1];

  // Match leading zeroes in the fractional part
  const leadingZeroMatches = fractionalPart.match(/^0+/);

  // Return the count of leading zeroes or 0 if none are found
  return leadingZeroMatches ? leadingZeroMatches[0].length : 0;
}
