import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  FeeVaultsSection,
  OverviewSection,
  VaultBalanceSection,
  WithdrawHistorySection,
} from './components';
import { useWFRegister, useWFRegistered } from './hooks';

export const AutoWithdrawalView = () => {
  const { rollupId } = useParams();
  const { data: registered, isSuccess: registeredCallSuccess } = useWFRegistered({
    params: { opstackId: rollupId ?? '' },
  });
  const { mutate: register } = useWFRegister({
    onSuccess: () => console.debug('rollup withdrawal fee registered'),
  });

  useEffect(() => {
    if (registeredCallSuccess && !registered) {
      register({ opstackId: rollupId ?? '' });
      console.debug('rollup withdrawal fee registering...');
    }
  }, [register, registered, registeredCallSuccess, rollupId]);

  return (
    <>
      <OverviewSection />
      <VaultBalanceSection />
      <WithdrawHistorySection />
      <FeeVaultsSection />
    </>
  );
};
