import { Grid } from '@mui/material';
import { BASE_FEE_ADDR, L1_FEE_ADDR, SEQUENCER_FEE_ADDR } from 'constants/raas';
import { useRaasDeployment } from 'hooks';
import { getTokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import { useWFStatus } from 'pages/raas/DeploymentDetails/views/AutoWithdrawalView/hooks';
import { useMemo } from 'react';
import { MetricWidget } from 'shared/components/metric/MetricWidget';
import { Section } from 'shared/components/Section';
import { FeeVault } from 'types/protoc-gen/raas-rollup';
import { countLeadingZeroes } from 'utils/number';
import { commify, formatWithPrecision } from 'utils/strings';
import { Address } from 'viem';

type AccountMap = {
  label: string;
  account: Address;
  balance: number;
  vault: FeeVault;
};

const accounts: Omit<AccountMap, 'balance'>[] = [
  { label: 'Base fee vault balance', account: BASE_FEE_ADDR, vault: FeeVault.BASE_FEE_VAULT },
  { label: 'L1 fee vault balance', account: L1_FEE_ADDR, vault: FeeVault.L1_FEE_VAULT },
  {
    label: 'Sequencer fee vault balance',
    account: SEQUENCER_FEE_ADDR,
    vault: FeeVault.SEQUENCER_FEE_VAULT,
  },
];

export const OverviewSection = () => {
  const { data: rollup } = useRaasDeployment();
  const { data: withdrawStatuses, isPending: getBalancesPending } = useWFStatus({
    params: { rollupId: rollup?.id ?? '' },
    enabled: Boolean(rollup?.id),
  });

  const balances = useMemo<AccountMap[]>(() => {
    const uniqueBalances = new Map<Address, AccountMap>();

    withdrawStatuses?.forEach(({ vault, vaultBalance }) => {
      const account = accounts.find(v => v.vault === vault);

      if (account) {
        uniqueBalances.set(account.account, {
          vault,
          label: account.label,
          account: account.account,
          balance: +vaultBalance,
        });
      }
    });

    return Array.from(uniqueBalances.values());
  }, [withdrawStatuses]);

  const totalBalance = useMemo(
    () => balances?.reduce((acc, { balance }) => acc + balance, 0),
    [balances],
  );

  return (
    <Section
      renderContent={
        <Grid container>
          {!balances || getBalancesPending ? (
            Array.from({ length: 4 }).map((_, idx) => (
              <MetricWidget key={idx.toString()} loading tooltip="..." />
            ))
          ) : (
            <>
              {balances?.map(({ account, balance, label }) => (
                <MetricWidget
                  key={account}
                  label={label}
                  tooltip={`Balance in ${getTokenSymbol(rollup)} for account ${account}`}
                  value={commify(
                    formatWithPrecision(balance, countLeadingZeroes(balance) >= 6 ? 2 : 4),
                  )}
                  // value={formatNumberWithPrecisionOrEth(balance)}
                  valueSuffix={getTokenSymbol(rollup)}
                />
              ))}
              <MetricWidget
                label={'Total vault balance'}
                tooltip={`The total balance of the 3 vaults in ${getTokenSymbol(rollup)}`}
                value={commify(
                  formatWithPrecision(totalBalance, countLeadingZeroes(totalBalance) >= 6 ? 2 : 4),
                )}
                valueSuffix={getTokenSymbol(rollup)}
              />
            </>
          )}
        </Grid>
      }
      title="Overview"
    />
  );
};
