import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { FC, useState } from 'react';

export const PasswordTextField: FC<TextFieldProps> = ({ InputProps, ...props }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const onToggle = () => setVisible(!visible);

  return (
    <TextField
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={onToggle} size="small">
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={visible ? 'text' : 'password'}
      {...props}
    />
  );
};
