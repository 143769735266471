import { Link as MuiLink, LinkProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

export default function HeaderLink({
  children,
  external,
  href,
  ...props
}: PropsWithChildren<LinkProps & { external?: boolean }>) {
  return (
    <MuiLink
      {...(external
        ? { href, rel: 'noopener noreferrer', target: '_blank' }
        : { component: Link, to: href })}
      sx={{
        background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        px: 6,
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
      {...props}
    >
      {children}
    </MuiLink>
  );
}
