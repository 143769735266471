import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { bff } from 'api';
import { TerminateAAAReply, TerminateAAARequest } from 'types/protoc-gen/bffaaa';
import { MUTATION_KEYS } from 'types/react-query';

export interface IUseTerminateApp
  extends Omit<
    UseMutationOptions<TerminateAAAReply, Error, TerminateAAARequest, unknown>,
    'mutationFn' | 'mutationKey'
  > {}

export const useTerminateApp = (
  opts?: IUseTerminateApp,
): UseMutationResult<TerminateAAAReply, Error, TerminateAAARequest, unknown> =>
  useMutation({
    mutationKey: [MUTATION_KEYS.TERMINATE_AAA],
    mutationFn: async (args: TerminateAAARequest) => {
      const res = await bff.post<TerminateAAAReply>('/bff/aaas/terminate', args);

      return res.data;
    },
    ...opts,
  });
