import { AdminGuard, ProductionEnvGuard } from 'guards';
import { RaasAdminDashboard } from 'pages/admin/RaasAdmin/views/RaasAdminDashboard';
import { RaasAdminDetails } from 'pages/admin/RaasAdmin/views/RaasAdminDetails';
import { Outlet, RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
  {
    path: '',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <RaasAdminDashboard />,
      },
      {
        path: ':rollupId',
        element: <RaasAdminDetails />,
      },
    ],
  },
].map(route => ({
  ...route,
  element: (
    <ProductionEnvGuard>
      <AdminGuard>{route.element}</AdminGuard>
    </ProductionEnvGuard>
  ),
}));
