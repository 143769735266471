import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormHelperText,
  Grid,
  Link,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import IconLink from 'components/icons/IconLink';
import { ConditionalConnectButton } from 'components/Web3/ConditionalConnectButton';
import Web3LoadingButton from 'components/Web3/Web3LoadingButton';
import RewardsCoordinatorAbi from 'contracts/RewardsCoordinatorAbi';
import GenericTemplate from 'contracts/templates/GenericTemplate';
import dayjs, { Dayjs } from 'dayjs';
import { useAmount } from 'hooks/useAmount';
import { useAvsRoles } from 'hooks/useAvsRoles';
import { useWriteTx } from 'hooks/useWriteTx';
import { avsStrategyLabels } from 'labels/deployments';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import CheckboxCard from 'shared/components/Card/CheckboxCard';
import { CustomAutocomplete } from 'shared/components/CustomAutocomplete';
import CustomStepIcon from 'shared/components/CustomStepIcon';
import MaxButton from 'shared/components/MaxButton';
import MaximizableAmount from 'shared/components/MaximizableAmount';
import { AVS_QUORUMS, strategiesByQuorums } from 'types/avs';
import { AVSDeployment } from 'types/protoc-gen/avs';
import { getQuorumsByStrategies } from 'utils/avs';
import { commify, truncate } from 'utils/strings';
import { Address, erc20Abi, formatUnits, parseEther, sha256, stringToBytes } from 'viem';
import { readContract } from 'viem/actions';
import { useAccount, useClient, useReadContract, useReadContracts } from 'wagmi';
import { string } from 'yup';

interface TokenDetails {
  address: Address;
  symbol: string;
  decimals: number;
  logoURI: string;
}

interface TokenOption extends TokenDetails {
  label: string;
  value: Address;
  balance: bigint;
  isCreatedOption?: boolean;
}

const strategyOptions = [
  {
    label: avsStrategyLabels[AVS_QUORUMS.ETH_LST],
    value: AVS_QUORUMS.ETH_LST,
  },
  { label: avsStrategyLabels[AVS_QUORUMS.EIGEN], value: AVS_QUORUMS.EIGEN },
  { label: avsStrategyLabels[AVS_QUORUMS.REALT], value: AVS_QUORUMS.REALT },
];

const REWARDS_COORDINATOR_PROXY = '0xAcc1fb458a1317E886dB376Fc8141540537E68fE';

export default function AddRewardsModal({
  avs,
  onClose,
  open,
  ...rest
}: DialogProps & { avs: AVSDeployment }) {
  const handleClose = () => {
    setShowWizard(false);
    setActiveStep(0);
    resetApprove();
    resetAddRewards();
    onClose?.({}, 'backdropClick');
  };

  const [showWizard, setShowWizard] = useState(false);
  const { address, chain } = useAccount();
  const { data: tokenList, isLoading: isLoadingTokenList } = useQuery({
    queryKey: ['LOAD_TOKEN_LIST'],
    queryFn: async () =>
      (
        await axios.get<TokenDetails[]>(
          `${process.env.PUBLIC_URL}/tokens/${chain?.name?.toLowerCase()}.json`,
          {
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          },
        )
      )?.data,
    enabled: Boolean(chain?.name),
  });

  const { data: strategies } = useReadContract({
    abi: GenericTemplate.abi,
    address: avs?.serviceManagerProxy as Address,
    functionName: 'getRestakeableStrategies',
    query: {
      enabled: Boolean(avs?.serviceManagerProxy && open),
    },
  });
  const quorums = useMemo(() => getQuorumsByStrategies(strategies || []), [strategies]);

  useEffect(() => {
    setSelectedStrategies(quorums);
  }, [quorums]);

  const { data: rewardsDateCriteria } = useReadContracts({
    contracts: [
      {
        abi: RewardsCoordinatorAbi,
        address: REWARDS_COORDINATOR_PROXY,
        functionName: 'CALCULATION_INTERVAL_SECONDS',
      },
      {
        abi: RewardsCoordinatorAbi,
        address: REWARDS_COORDINATOR_PROXY,
        functionName: 'MAX_RETROACTIVE_LENGTH',
      },
      {
        abi: RewardsCoordinatorAbi,
        address: REWARDS_COORDINATOR_PROXY,
        functionName: 'MAX_FUTURE_LENGTH',
      },
    ],
    query: {
      enabled: open,
    },
  });
  const [intervalSeconds, maxRetroactiveLength, maxFutureLength] =
    rewardsDateCriteria?.map(cur => cur.result) || [];
  const rewardDurationOptions = useMemo(() => {
    if (!intervalSeconds) {
      return [];
    }

    const intervalDays = intervalSeconds / 60 / 60 / 24;

    const numOptions = Math.floor(31 / intervalDays); // max 31 days

    return new Array(numOptions).fill(null).map((_, index) => (index + 1) * intervalDays);
  }, [intervalSeconds]);

  const client = useClient();
  const [isLoadingBalances, setIsLoadingBalances] = useState(false);
  const [tokenBalances, setTokenBalances] = useState<TokenOption[]>();
  const [selectedToken, setSelectedToken] = useState<TokenOption>();
  const [selectedTokenError, setSelectedTokenError] = useState<string>();

  const { amountBN, amountRaw, setAmountRaw } = useAmount(selectedToken?.decimals || 18);

  const [selectedStrategies, setSelectedStrategies] = useState<AVS_QUORUMS[]>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [startDateError, setStartDateError] = useState('');
  const [duration, setDuration] = useState<number>();

  const { data: allowance, refetch: refetchAllowance } = useReadContract({
    abi: erc20Abi,
    address: selectedToken?.address,
    functionName: 'allowance',
    args:
      address && avs?.serviceManagerProxy
        ? [address, avs.serviceManagerProxy as Address]
        : undefined,
    query: {
      enabled: Boolean(selectedToken?.address && address && avs?.serviceManagerProxy),
    },
  });

  const {
    isPending: isApproving,
    reset: resetApprove,
    txHash: approveTxHash,
    write: approve,
  } = useWriteTx({
    contractAbi: erc20Abi,
    contractAddress: selectedToken?.address as Address,
    functionName: 'approve',
    functionArgs: [avs?.serviceManagerProxy, amountBN],
    txKey: `approve_${amountRaw}_${address}`,
    onTxConfirmed: () => {
      refetchAllowance();
    },
  });

  const handleSelectStrat = (value: AVS_QUORUMS) => () => {
    if (selectedStrategies?.includes(value)) {
      setSelectedStrategies(selectedStrategies.filter(cur => cur !== value));
    } else {
      setSelectedStrategies([...selectedStrategies, value]?.flat());
    }
  };

  const handleSelectDuration: (e: SelectChangeEvent<number>) => void = e => {
    const value = e?.target?.value;

    setDuration(Number(value));
  };

  useEffect(() => {
    const fetchBalances = async () => {
      if (tokenList && client && address && open) {
        setIsLoadingBalances(true);

        const res = await Promise.all(
          tokenList.map(cur => {
            return readContract(client, {
              abi: erc20Abi,
              functionName: 'balanceOf',
              args: [address],
              address: cur.address,
            });
          }),
        );

        const balances = tokenList?.map((cur, index) => ({
          ...cur,
          balance: res?.[index] || 0n,
          label: cur.symbol,
          value: cur.address,
        }));

        setTokenBalances(balances);
      }
    };

    fetchBalances();
  }, [tokenList, client, address, open]);

  useEffect(() => {
    const fetchCustomToken = async () => {
      setSelectedTokenError('');
      const schema = string().address();

      if (!selectedToken) {
        setSelectedToken(undefined);
      }

      if (selectedToken?.isCreatedOption) {
        try {
          await schema.validate(selectedToken?.value);
        } catch (err) {
          setSelectedTokenError('Please enter a valid address');
        }
      }

      if (
        selectedToken &&
        selectedToken?.isCreatedOption &&
        !selectedToken.symbol &&
        client &&
        address
      ) {
        try {
          const res = await Promise.all([
            readContract(client, {
              abi: erc20Abi,
              functionName: 'balanceOf',
              args: [address],
              address: selectedToken?.value,
            }),
            readContract(client, {
              abi: erc20Abi,
              functionName: 'symbol',
              address: selectedToken?.value,
            }),
            readContract(client, {
              abi: erc20Abi,
              functionName: 'decimals',
              address: selectedToken?.value,
            }),
          ]);
          const [balance, symbol, decimals] = res || [];

          const newOption = {
            ...selectedToken,
            address: selectedToken?.value,
            label: symbol,
            balance,
            symbol,
            decimals,
          };

          setTokenBalances(prev => {
            const prevCreatedOptionIndex = prev?.findIndex(cur => cur?.isCreatedOption);

            if (prevCreatedOptionIndex) {
              const cloned = prev?.slice();

              cloned?.splice(prevCreatedOptionIndex, 1, newOption);

              return cloned;
            }

            return prev ? [...prev, newOption] : [newOption];
          });
          setSelectedToken(newOption);
        } catch (err) {
          setSelectedTokenError('Please enter a valid erc20 token address');
        }
      }
    };

    fetchCustomToken();
  }, [address, client, selectedToken]);

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (activeStep === 0 && allowance && amountBN <= allowance) {
      setActiveStep(1);
    }
  }, [activeStep, allowance, amountBN]);

  const createRewardsArgs = useMemo(() => {
    const strategiesAndMultipliers = selectedStrategies
      ?.map(cur => strategiesByQuorums?.[cur])
      ?.flat()
      ?.sort((a, b) => (a?.toLowerCase() > b?.toLowerCase() ? 1 : -1)) // Reverts if sorted by checksummed address
      ?.map(cur => ({ strategy: cur, multiplier: parseEther('1') }));

    return [
      [
        {
          strategiesAndMultipliers,
          token: selectedToken?.value,
          amount: amountBN,
          startTimestamp: startDate
            ?.subtract(startDate?.unix() % (intervalSeconds || 0), 'seconds')
            ?.unix(),
          duration: (duration || 0) * 60 * 60 * 24,
        },
      ],
    ];
  }, [amountBN, duration, intervalSeconds, selectedStrategies, selectedToken?.value, startDate]);

  const {
    isPending: isAddingRewards,
    reset: resetAddRewards,
    txHash: addRewardsTxHash,
    write: addRewards,
  } = useWriteTx({
    contractAbi: GenericTemplate.abi,
    contractAddress: avs?.serviceManagerProxy as Address,
    functionName: 'createAVSRewardsSubmission',
    functionArgs: createRewardsArgs,
    txKey: `create_rewards_${sha256(stringToBytes(JSON.stringify(createRewardsArgs)))}`,
    onTxConfirmed: () => {
      refetchAllowance();
      setActiveStep(2);
    },
  });

  const { rewardsInitiator } = useAvsRoles(avs);

  const wizardSteps: {
    title: ReactNode;
    description?: ReactNode;
    content?: ReactNode;
    actionBar: ReactNode;
  }[] = useMemo(
    () => [
      {
        title: (
          <Typography>
            Approve {amountRaw} {selectedToken?.symbol}
          </Typography>
        ),
        description: (
          <Stack>
            <Typography fontSize={12} variant="caption">
              Current allowance: {formatUnits(allowance || 0n, selectedToken?.decimals || 18)}{' '}
              {selectedToken?.symbol}
            </Typography>
            {approveTxHash ? (
              <Link
                href={`${chain?.blockExplorers?.default?.url}/tx/${approveTxHash}`}
                rel="noopener noreferrer"
                sx={{
                  textDecoration: 'underline',
                  display: 'inline-flex',
                  alignItems: 'center',
                  width: 'max-content',
                }}
                target="_blank"
              >
                <Typography fontSize={12} variant="caption">
                  Txn hash: {truncate(approveTxHash, undefined, 5, 3)}
                </Typography>
                <IconLink sx={{ ml: 1, width: '14px', height: '14px' }} />
              </Link>
            ) : null}
          </Stack>
        ),
        actionBar: (
          <Web3LoadingButton fullWidth loading={isApproving} onClick={approve} variant="contained">
            Approve
          </Web3LoadingButton>
        ),
      },
      {
        title: <Typography>Add {selectedToken?.symbol} rewards to quorum(s)</Typography>,
        description: addRewardsTxHash ? (
          <Link
            href={`${chain?.blockExplorers?.default?.url}/tx/${addRewardsTxHash}`}
            rel="noopener noreferrer"
            sx={{
              textDecoration: 'underline',
              display: 'inline-flex',
              width: 'max-content',
              alignItems: 'center',
            }}
            target="_blank"
          >
            <Typography fontSize={12} variant="caption">
              Txn hash: {truncate(addRewardsTxHash, undefined, 5, 3)}
            </Typography>
            <IconLink sx={{ ml: 1, width: '14px', height: '14px' }} />
          </Link>
        ) : null,
        content: (
          <Stack>
            <Typography variant="captionC">Selected Quorums:</Typography>
            {selectedStrategies?.map(cur => (
              <Typography key={cur} variant="caption">
                - {avsStrategyLabels?.[cur]}
              </Typography>
            ))}
          </Stack>
        ),
        actionBar: (
          <Web3LoadingButton
            fullWidth
            loading={isAddingRewards}
            onClick={addRewards}
            requiredRole="rewardsInitiator"
            requiredSender={rewardsInitiator}
            variant="contained"
          >
            Add
          </Web3LoadingButton>
        ),
      },
    ],
    [
      addRewards,
      addRewardsTxHash,
      allowance,
      amountRaw,
      approve,
      approveTxHash,
      chain?.blockExplorers?.default?.url,
      isAddingRewards,
      isApproving,
      rewardsInitiator,
      selectedStrategies,
      selectedToken?.decimals,
      selectedToken?.symbol,
    ],
  );

  const isFormInvalid =
    !selectedToken ||
    !!selectedTokenError ||
    !amountBN ||
    Boolean(amountBN > (selectedToken?.balance || 0n)) ||
    !selectedStrategies?.length ||
    !startDate ||
    !!startDateError ||
    !duration;

  return (
    <Dialog
      disablePortal
      onClose={handleClose}
      open={open}
      sx={{ '& .MuiPaper-root': { p: 3, background: '#FFFFFF' } }}
      {...rest}
    >
      <DialogTitle>
        <Typography fontWeight={500} variant="h5">
          Add Rewards
        </Typography>
      </DialogTitle>

      <DialogContent>
        <ConditionalConnectButton sx={{ minWidth: '20rem' }}>
          <Collapse in={!showWizard}>
            <Typography alignItems="center" fontWeight={500} variant="bodySmallC">
              Reward Token
            </Typography>
            <CustomAutocomplete
              creatable
              errorMsg={selectedTokenError}
              fullWidth
              loading={isLoadingTokenList || isLoadingBalances}
              options={tokenBalances || ([] as TokenOption[])}
              placeholder="Select or enter an erc20 token address"
              renderOption={(props, option: TokenOption) => {
                return (
                  <ListItem
                    {...props}
                    key={option.address}
                    onClick={() => setSelectedToken(option)}
                    sx={{
                      p: 2,
                      cursor: 'pointer',
                    }}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Stack alignItems="center" direction="row" gap={1}>
                        {option.logoURI && (
                          <Box
                            component="img"
                            src={option.logoURI}
                            sx={{ height: 20, width: 20 }}
                          />
                        )}
                        <Typography>
                          {option.isCreatedOption
                            ? `Use ERC20 token: ${option.label}`
                            : option.label}
                        </Typography>
                      </Stack>
                      {!option.isCreatedOption && (
                        <Typography>
                          {Number(formatUnits(option?.balance || 0n, option.decimals))?.toFixed(2)}
                          &nbsp;
                          {option.symbol}
                        </Typography>
                      )}
                    </Stack>
                  </ListItem>
                );
              }}
              sx={{ background: theme => theme.colors.gradients.sheet }}
              value={selectedToken || null}
            />
            <Box py={2}>
              <Typography alignItems="center" fontWeight={500} variant="bodySmallC">
                Amount
              </Typography>
              <MaximizableAmount
                error={Boolean(amountBN > (selectedToken?.balance || 0n))}
                fullWidth
                helperText={
                  Boolean(amountBN > (selectedToken?.balance || 0n)) && 'Exceeded maximum balance'
                }
                inputProps={{
                  onWheel: event => event.currentTarget.blur(),
                  sx: { '&&': { fontSize: { xs: 14, md: 20, lg: 24 } } },
                }}
                setAmountRaw={setAmountRaw}
                tokenSymbol={selectedToken?.symbol}
                value={amountRaw}
              />
              <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
                <Typography variant="subtitle1">
                  BALANCE:&nbsp;
                  <Typography component="span" variant="subtitle1">
                    {commify(
                      formatUnits(selectedToken?.balance || 0n, selectedToken?.decimals || 18),
                    ) || '-'}
                  </Typography>
                  <Typography component="span" variant="subtitle1">
                    &nbsp;{selectedToken?.symbol}
                  </Typography>
                </Typography>
                <MaxButton
                  onClick={() => {
                    setAmountRaw(
                      formatUnits(selectedToken?.balance || 0n, selectedToken?.decimals || 18),
                    );
                  }}
                />
              </Stack>
            </Box>
            <Box>
              <Typography alignItems="center" fontWeight={500} variant="bodySmallC">
                Rewarded Strategies
              </Typography>
              <Stack direction="row">
                {strategyOptions
                  ?.filter(strat => quorums.includes(strat?.value))
                  ?.map(strat => (
                    <CheckboxCard
                      content={<Typography px={1}>{strat.label}</Typography>}
                      isSelected={selectedStrategies?.includes(strat.value)}
                      key={strat?.value}
                      onClick={handleSelectStrat(strat.value)}
                      sx={{ py: 1, svg: {}, flex: 1 }}
                    />
                  ))}
              </Stack>
              {!selectedStrategies?.length && (
                <FormHelperText error>At least 1 strategy must be selected</FormHelperText>
              )}
            </Box>
            <Grid container mt={2} spacing={2}>
              <Grid item xs={6}>
                <Typography alignItems="center" fontWeight={500} variant="bodySmallC">
                  Reward Start Date (UTC{-new Date().getTimezoneOffset() / 60 > 0 ? '+' : '-'}
                  {-new Date().getTimezoneOffset() / 60})
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    maxDate={dayjs().add(maxFutureLength || 0, 'seconds')}
                    minDate={dayjs().subtract(maxRetroactiveLength || 0, 'seconds')}
                    onChange={selected => {
                      if (
                        intervalSeconds &&
                        selected &&
                        selected?.unix() % intervalSeconds < 60 * 60 * 24
                      ) {
                        setStartDateError('');
                        setStartDate(selected);
                      }
                    }}
                    onError={err => {
                      if (err) {
                        switch (err) {
                          case 'shouldDisableDate':
                            setStartDateError('Invalid date selected');
                            break;
                          case 'minDate':
                            setStartDateError(
                              `Cannot select date before ${dayjs()
                                .subtract(maxRetroactiveLength || 0, 'seconds')
                                .format('DD MMM')}`,
                            );
                            break;
                          case 'maxDate':
                            setStartDateError(
                              `Cannot select date after ${dayjs()
                                .add(maxFutureLength || 0, 'seconds')
                                .format('DD MMM')}`,
                            );
                            break;
                          default:
                            setStartDateError(err);
                        }
                      }
                    }}
                    shouldDisableDate={date =>
                      intervalSeconds ? date.unix() % intervalSeconds > 60 * 60 * 24 : true
                    }
                    value={startDate}
                  />
                </LocalizationProvider>
                <FormHelperText error={Boolean(startDateError)}>{startDateError}</FormHelperText>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  <Typography alignItems="center" fontWeight={500} variant="bodySmallC">
                    Reward Duration
                  </Typography>
                  <Select<number>
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectDuration}
                    renderValue={val =>
                      val ? `${val}D` : <Typography color="#AAA">Select a duration</Typography>
                    }
                    sx={{
                      p: 0.8,
                      mt: 0.5,
                    }}
                    value={duration || ''}
                  >
                    {rewardDurationOptions?.map(numDays => (
                      <MenuItem key={numDays} value={numDays}>
                        <Typography>{numDays}D</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            </Grid>
            <Button
              disabled={isFormInvalid}
              fullWidth
              onClick={() => {
                setActiveStep(0);
                setShowWizard(true);
              }}
              sx={{ mt: 2 }}
              variant="contained"
            >
              Proceed
            </Button>
          </Collapse>
          <Collapse in={showWizard}>
            <Stepper activeStep={activeStep} connector={null} orientation="vertical">
              {wizardSteps.map((step, index) => (
                <Step
                  key={index}
                  sx={
                    index !== wizardSteps?.length - 1
                      ? {
                          position: 'relative',
                          '& .MuiStepLabel-iconContainer:after': {
                            content: "' '",
                            height: 'calc(100% - 24px)',
                            borderRight: '1px solid black',
                            position: 'absolute',
                            left: '11px',
                            top: '32px',
                            zIndex: 0,
                          },
                        }
                      : {}
                  }
                >
                  <StepLabel StepIconComponent={CustomStepIcon} sx={{ alignItems: 'flex-start' }}>
                    <Typography mt={0.25}>{step.title}</Typography> {step.description}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.content}</Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            <Stack alignItems="center" justifyContent="center" mt={2} width="100%">
              {activeStep >= wizardSteps?.length ? (
                <Stack gap={2} width="100%">
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#66B489',
                      fontWeight: 500,
                    }}
                  >
                    🎉&nbsp;Successfully added rewards
                  </Typography>
                  <Button fullWidth onClick={handleClose} variant="contained">
                    Close
                  </Button>
                </Stack>
              ) : (
                wizardSteps?.[activeStep]?.actionBar
              )}
            </Stack>
            {activeStep < wizardSteps?.length && (
              <Button
                fullWidth
                onClick={() => setShowWizard(false)}
                sx={{ mt: 2 }}
                variant="outlined"
              >
                Back
              </Button>
            )}
          </Collapse>
        </ConditionalConnectButton>
      </DialogContent>
    </Dialog>
  );
}
