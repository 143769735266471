import { ArrowBackSharp, ArrowForwardSharp } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AAAType } from 'types/protoc-gen/bffaaa';

import { AgentCarouselCardProps } from './AgentCarouselCard';
import AgentCarouselItem from './AgentCarouselItem';

const cards: AgentCarouselCardProps[] = [
  {
    tagNames: ['featured'],
    iconUrl: '/imgs/agentKit.svg',
    title: 'Agentkit',
    aaaType: AAAType.AAATYPE_AGENTKIT,
    description:
      'AgentKit is a LangChain / FastAPI / Next.js14 toolkit developed by BCG X to build Agents. Developers can use AgentKit to rapidly build high quality Agent applications that can scale into production-grade apps.',
  },
  {
    tagNames: ['featured'],
    iconUrl: '/imgs/basedAgent.svg',
    title: 'Based Agent',
    aaaType: AAAType.AAATYPE_BASE_AGENT,
    description:
      'The Coinbase Developer Platform (CDP) Agentkit simplifies bringing your AI Agents onchain. Every AI Agent deserves a crypto wallet!',
  },
  {
    tagNames: ['featured'],
    iconUrl: '/imgs/eliza.svg',
    title: 'Eliza',
    aaaType: AAAType.AAATYPE_ELIZA,
    description:
      'Full-featured Discord, Twitter and Telegram connectors | Support for every model (Llama, Grok, OpenAI, Anthropic, etc.) | Multi-agent and room support | Easily ingest and interact with your documents | Retrievable memory and document store | Highly extensible',
  },
];

const AgentFrameworksCarousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const carouselGroupSize = isMobile ? 1 : isTablet ? 2 : 3;

  const groupedCards = useMemo(
    () =>
      cards?.reduce((acc, card, index) => {
        const groupIndex = Math.floor(index / carouselGroupSize);

        if (!acc[groupIndex]) {
          acc[groupIndex] = [];
        }

        acc[groupIndex].push(card);

        return acc;
      }, [] as AgentCarouselCardProps[][]),
    [carouselGroupSize],
  );

  const goToPrevious = () => {
    const newIndex = currentIndex === 0 ? groupedCards.length - 1 : currentIndex - 1;

    scrollToIndex(newIndex);
  };

  const goToNext = () => {
    const newIndex = currentIndex === groupedCards.length - 1 ? 0 : currentIndex + 1;

    scrollToIndex(newIndex);
  };

  const scrollToIndex = (index: number) => {
    if (carouselRef.current) {
      const itemWidth = carouselRef.current.children[index].getBoundingClientRect().width;

      carouselRef.current.scrollTo({
        left: itemWidth * index,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute('data-index'));

          // Element has scrolled into view
          setCurrentIndex(index);
        }
      });
    };

    // Set up the IntersectionObserver with the callback
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.5, // Trigger when X% of the element is visible
    };
    const observer = new IntersectionObserver(callback, options);

    const elements = document.querySelectorAll(`.carousel-item`);

    setTimeout(() => {
      // Workaround for a bug where current index is non-deterministically set to 1 on load. Start observing after a delay.
      elements?.forEach(element => observer.observe(element));
    }, 500);

    return () => elements?.forEach(element => observer.unobserve(element));
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        px: 2,
        pt: 4,
        pb: 8,
        width: '100%',
        margin: 'auto',
        bgcolor: '#fafafa',
      }}
    >
      <Box
        aria-label="Image carousel"
        ref={carouselRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {groupedCards.map((group, index) => (
          <AgentCarouselItem
            group={group}
            groupSize={carouselGroupSize}
            index={index}
            key={index}
          />
        ))}
      </Box>
      {groupedCards?.length > 1 && (
        <Stack
          alignItems="center"
          direction="row"
          gap={{ xs: 1, md: 3 }}
          justifyContent="space-between"
          sx={{
            zIndex: 1,
            position: 'absolute',
            bottom: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            width: { xs: 120, md: 210 },
          }}
        >
          <IconButton
            onClick={goToPrevious}
            sx={{ visibility: currentIndex === 0 ? 'hidden' : 'visible' }}
          >
            <ArrowBackSharp sx={{ width: { xs: 20, md: 40 }, height: { xs: 20, md: 40 } }} />
          </IconButton>
          <Typography variant="bodySmallC">
            {currentIndex + 1}&nbsp;/&nbsp;{groupedCards?.length}
          </Typography>
          <IconButton
            onClick={goToNext}
            sx={{ visibility: currentIndex >= groupedCards?.length - 1 ? 'hidden' : 'visible' }}
          >
            <ArrowForwardSharp sx={{ width: { xs: 20, md: 40 }, height: { xs: 20, md: 40 } }} />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
};

export default AgentFrameworksCarousel;
