import OperatorAllowlistAbi from 'contracts/OperatorAllowlistAbi';
import { GetOperatorStateAbi } from 'contracts/OperatorStateRetrieverAbi';
import GenericTemplate from 'contracts/templates/GenericTemplate';
import { OperatorList } from 'pages/avs/AvsDetails/SelectOperators';
import { useCallback, useMemo, useState } from 'react';
import { AVS_QUORUMS } from 'types/avs';
import { AVSDeployment } from 'types/protoc-gen/avs';
import { getOffChainQuorum, getOnChainQuorums, getQuorumsByStrategies } from 'utils/avs';
import { Address, getAddress, numberToHex, WriteContractReturnType } from 'viem';
import { useAccount, useBlockNumber, useReadContract, useReadContracts } from 'wagmi';

import { useWriteTx } from './useWriteTx';

const HARDCODED_OPERATORS = (
  [
    { address: '0xb1d24e87b33f7d83d02040cec9af4cdec1399cbf', name: 'Black Sand' },
    { address: '0xb8feb0c0c8e767d402f411d9f56c1efa618db1a4', name: 'BlockPI' },
    { address: '0x1392d82c6e22cc6e7d48be93050f6f3ebee5986d', name: '01node' },
    { address: '0x73bf91bb1327415ec7b9d436bfced67c3ee843fa', name: 'A41 [all AVS]' },
    { address: '0x08b9c489b6b0bebf21e350e6067829b881854a24', name: 'Allnodes' },
    { address: '0x5d0eb7efbae980582be4a121750addcfb7f6a186', name: 'BwareLabs' },
    { address: '0x6c76a22052d5e8bbad9801bf1e413477843af698', name: 'CoinSummer Labs' },
    { address: '0xbe4b4fa92b6767fda2c8d1db53a286834db19638', name: 'Coinbase' },
    { address: '0xc78b2bc952e491eed3e6c14f0c00c009fb29a281', name: 'Cosmostation' },
    { address: '0x7e26f2102e455b5755526dc5c421898470a23924', name: 'DAIC' },
    { address: '0x33503f021b5f1c00ba842ced26b44ca2fab157bd', name: 'DSRV' },
    { address: '0xb9b1ad6d07b4a5768a4c18bc440dd6a710e44af7', name: 'Finoa Consensus Services' },
    { address: '0xcd853ca18dffba261aed77d15a41fcf8a9ad523e', name: 'Forbole' },
    { address: '0x2385bf3ab1e88e2055368a73a4943faff99a644d', name: 'HashKey Cloud' },
    { address: '0xc421e1216f99c838b0a426b1cd10c8e40030ca5c', name: 'InfStones' },
    { address: '0xa4e245c3a1cb2f0512a71b9cd908dca2f1641781', name: 'Kiln' },
    { address: '0xbb5614cda99012c473b0aaa22904a270d50a149a', name: 'Luganodes' },
    { address: '0x57b6fdef3a23b81547df68f44e5524b987755c99', name: 'Nethermind' },
    { address: '0xe3b520f525b57be060f2c7b9ca0ea98a2dc4500b', name: 'Nodes.Guru' },
    { address: '0x3e31908e30b3051dfe056b1d0902b164d78cd8b8', name: 'Ebunker' },
    { address: '0x63a27fd29a5385561991108e0cefb288c627cc03', name: 'Everstake' },
    { address: '0x4a3d6e0ff640f2f0c1091878e6ed3be7ed3f6665', name: 'Pier Two' },
    { address: '0x758e016468e5e90cdb42e743881c2e921d8e7bf8', name: 'OKX' },
    { address: '0x82da30e2ab471c8d2e6af2c7c6e25f19d80436b2', name: '0Y' },
  ] as OperatorList
)
  ?.map(cur => ({ ...cur, address: getAddress(cur.address) }))
  ?.sort((a, b) => (a.name < b.name ? -1 : 1));

interface UseOperatorsArgs {
  selectedOperators?: Address[];
  avs: AVSDeployment;
  onWhitelistOperatorsConfirmed?: (txHash: WriteContractReturnType) => void;
}

export interface OperatorState {
  address: `0x${string}`;
  whitelisted?: boolean;
  registered?: boolean;
  registeredQuorums?: number[];
  name: string;
}

export const useOperators = ({
  avs,
  onWhitelistOperatorsConfirmed,
  selectedOperators,
}: UseOperatorsArgs) => {
  const { address } = useAccount();
  const {
    operatorStateRetriever,
    operators: managedOperators,
    registryCoordinatorProxy,
    serviceManagerProxy,
  } = avs || {};
  const svcMngrAddr = serviceManagerProxy as Address;
  const operatorStateRetrieverAddr = operatorStateRetriever as Address;
  const registryCoordinatorAddr = registryCoordinatorProxy as Address;

  const { data: isAllowlistEnabled, refetch: refetchIsAllowlistEnabled } = useReadContract({
    abi: OperatorAllowlistAbi,
    functionName: 'allowlistEnabled',
    address: svcMngrAddr,
    query: {
      enabled: Boolean(svcMngrAddr),
    },
  });
  const { isPending: isEnablingAllowlist, write: enableAllowlist } = useWriteTx({
    contractAbi: OperatorAllowlistAbi,
    contractAddress: svcMngrAddr,
    txKey: `enableAllowlist_${svcMngrAddr}`,
    functionName: 'enableAllowlist',
    onTxConfirmed: () => {
      refetchIsAllowlistEnabled();
    },
  });
  const { isPending: isDisablingAllowlist, write: disableAllowlist } = useWriteTx({
    contractAbi: OperatorAllowlistAbi,
    contractAddress: svcMngrAddr,
    txKey: `disableAllowlist_${svcMngrAddr}`,
    functionName: 'disableAllowlist',
    onTxConfirmed: () => {
      refetchIsAllowlistEnabled();
    },
  });

  const {
    data: allowlistSize,
    isLoading: isLoadingAllowlistSize,
    refetch: refetchAllowlistSize,
  } = useReadContract({
    abi: OperatorAllowlistAbi,
    functionName: 'getAllowlistSize',
    address: svcMngrAddr,
    query: {
      enabled: Boolean(svcMngrAddr),
    },
  });

  const {
    data: _allowlist,
    isLoading: isLoadingAllowlist,
    refetch: refetchAllowlist,
  } = useReadContract({
    abi: OperatorAllowlistAbi,
    address: svcMngrAddr,
    functionName: 'queryOperators',
    args: [0n, allowlistSize as bigint],
    query: {
      enabled: Boolean(allowlistSize && svcMngrAddr),
      retry: 2,
    },
  });

  const triggerRefetchAllowlist = useCallback(() => {
    refetchAllowlistSize();
    refetchAllowlist();
  }, [refetchAllowlist, refetchAllowlistSize]);

  const allowlist = useMemo(
    () => _allowlist || (allowlistSize !== undefined ? ([] as Address[]) : undefined),
    [_allowlist, allowlistSize],
  );

  const { data: currentBlock, refetch: refetchCurrentBlock } = useBlockNumber();

  const { data: strategies } = useReadContract({
    abi: GenericTemplate.abi,
    address: avs?.serviceManagerProxy as Address,
    functionName: 'getRestakeableStrategies',
    query: {
      enabled: Boolean(avs?.serviceManagerProxy),
    },
  });
  const offChainQuorums = useMemo(() => {
    return getQuorumsByStrategies(strategies || []);
  }, [strategies]);
  const onChainQuorums = useMemo(() => {
    return getOnChainQuorums(offChainQuorums, avs);
  }, [avs, offChainQuorums]);

  const { data: operatorStatesByQuorum } = useReadContracts({
    contracts: onChainQuorums?.map(cur => ({
      abi: GetOperatorStateAbi,
      address: operatorStateRetrieverAddr,
      functionName: 'getOperatorState',
      args: [registryCoordinatorAddr, numberToHex(cur, { size: 1 }), Number(currentBlock)],
    })),
    query: {
      enabled: Boolean(
        currentBlock &&
          registryCoordinatorAddr &&
          operatorStateRetrieverAddr &&
          onChainQuorums?.length,
      ),
      select: res => {
        type Result = Exclude<(typeof res)[0]['result'], undefined>[0];

        return res?.reduce((acc, cur, quorumIndex) => {
          if (!cur?.result?.flat()?.length) {
            return acc;
          }

          return {
            ...acc,
            [onChainQuorums?.[quorumIndex]]: cur?.result?.flat(),
          };
        }, {} as Record<number, Result | undefined>);
      },
    },
  });

  const [customOperators, setCustomOperators] = useState<Address[]>([]);

  const operatorList: OperatorState[] = useMemo(() => {
    const operators = [
      ...(managedOperators?.map(cur => ({
        name: 'Hosted Operator Service',
        address: cur.operator as Address,
      })) || []),
    ]
      ?.concat(customOperators?.map(addr => ({ name: `${addr} (Custom)`, address: addr })))
      ?.concat(
        allowlist
          ?.filter(
            allowlistAddr =>
              !HARDCODED_OPERATORS?.find(cur => cur?.address === allowlistAddr) &&
              !customOperators?.includes(allowlistAddr) &&
              !managedOperators?.find(cur => cur?.operator === allowlistAddr),
          )
          ?.map(cur => ({ name: `${cur} (Custom)`, address: cur })) || [],
      )
      ?.concat(HARDCODED_OPERATORS)
      ?.filter(cur => Boolean(cur));

    return operators
      ?.map(cur => {
        const registeredQuorums = Object.entries(operatorStatesByQuorum || {})
          ?.map(([quorumNumber, operatorStates]) =>
            operatorStates?.some(operatorState => operatorState?.operator === cur.address)
              ? Number(getOffChainQuorum(Number(quorumNumber) as AVS_QUORUMS, avs))
              : NaN,
          )
          ?.filter(cur => !isNaN(cur));

        return {
          ...cur,
          address: cur.address,
          whitelisted: allowlist?.includes(cur.address),
          registered: registeredQuorums?.length > 0,
          registeredQuorums,
        };
      })
      ?.sort((a, b) =>
        // Sort by managed operator first, then registered operators, then whitelisted operators, then alphabetically
        a?.address === managedOperators?.[0]?.operator
          ? -1
          : b?.address === managedOperators?.[0]?.operator
          ? 1
          : a?.registered && !b?.registered
          ? -1
          : !a?.registered && b?.registered
          ? 1
          : a?.whitelisted && !b?.whitelisted
          ? -1
          : !a?.whitelisted && b?.whitelisted
          ? 1
          : a?.name < b?.name
          ? -1
          : 1,
      );
  }, [allowlist, avs, customOperators, managedOperators, operatorStatesByQuorum]);

  const operatorChangeset = useMemo(() => {
    const addedOperators = [] as Address[];
    const removedOperators = [] as Address[];
    const originalEnabledOperators = operatorList
      ?.filter(cur => cur.whitelisted)
      ?.map(cur => cur.address);

    selectedOperators?.forEach(cur => {
      if (!originalEnabledOperators?.includes(cur)) {
        addedOperators.push(cur);
      }
    });
    originalEnabledOperators?.forEach(cur => {
      if (!selectedOperators?.includes(cur)) {
        removedOperators.push(cur);
      }
    });
    const changeset = {
      addedOperators,
      removedOperators,
    };

    console.debug('changeset: ', changeset);

    return changeset;
  }, [operatorList, selectedOperators]);

  const updateOperatorsPayload = useMemo(() => {
    const { addedOperators, removedOperators } = operatorChangeset || {};

    return [
      [addedOperators, removedOperators].flat(),
      [addedOperators?.map(_ => true), removedOperators.map(_ => false)].flat(),
    ];
  }, [operatorChangeset]);

  const { isPending: isUpdatingOperators, write: updateOperators } = useWriteTx({
    contractAbi: OperatorAllowlistAbi,
    contractAddress: svcMngrAddr,
    txKey: `editOperators_${address}`,
    functionName: 'setAllowlist',
    functionArgs: updateOperatorsPayload,
    onTxConfirmed: txReceipt => {
      triggerRefetchAllowlist();
      onWhitelistOperatorsConfirmed?.(txReceipt);
    },
  });

  const { isPending: isWhitelistingHostedOperator, write: whitelistHostedOperator } = useWriteTx({
    contractAbi: OperatorAllowlistAbi,
    contractAddress: svcMngrAddr,
    txKey: `whitelistHostedOperator_${address}`,
    functionName: 'setAllowlist',
    functionArgs: [[managedOperators?.[0]?.operator], [true]],
    onTxConfirmed: txReceipt => {
      console.log(txReceipt);
      triggerRefetchAllowlist();
      onWhitelistOperatorsConfirmed?.(txReceipt);
    },
  });

  return useMemo(
    () => ({
      operatorList,
      allowlist,
      isLoadingAllowlist: isLoadingAllowlistSize || isLoadingAllowlist,
      updateOperators,
      isUpdatingOperators,
      operatorChangeset,
      whitelistHostedOperator,
      isWhitelistingHostedOperator,
      operatorStatesByQuorum,
      refetchOperatorStates: refetchCurrentBlock,
      offChainQuorums,
      setCustomOperators,
      isAllowlistEnabled,
      enableAllowlist,
      disableAllowlist,
      isTogglingAllowlist: isEnablingAllowlist || isDisablingAllowlist,
    }),
    [
      operatorList,
      allowlist,
      isLoadingAllowlistSize,
      isLoadingAllowlist,
      updateOperators,
      isUpdatingOperators,
      operatorChangeset,
      whitelistHostedOperator,
      isWhitelistingHostedOperator,
      operatorStatesByQuorum,
      refetchCurrentBlock,
      offChainQuorums,
      isAllowlistEnabled,
      enableAllowlist,
      disableAllowlist,
      isEnablingAllowlist,
      isDisablingAllowlist,
    ],
  );
};
