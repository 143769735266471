import { Grid, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Section } from 'shared/components/Section';

export const CurrentPlanSection = () => {
  const currentPlan = useMemo(
    () => [
      { label: 'Contract Period', value: '1 Year' },
      {
        label: 'Start Date',
        value: 'Oct 4, 2024',
      },
      {
        label: 'End Date',
        value: 'Oct 4, 2025',
      },
      { label: 'Billing', value: 'By Month' },
      { label: 'Payment Status', value: 'Awaiting 2024.8 payment' },
    ],
    [],
  );

  return (
    <Section
      renderGridContent={
        <>
          {' '}
          {currentPlan?.map(item => (
            <Grid container flexDirection="column" item key={item.label} mb="24px" xs={3}>
              <Stack spacing="8px">
                <Typography variant="caption">{item.label}</Typography>
                <Typography variant="bodySmallM">{item.value}</Typography>
              </Stack>
            </Grid>
          ))}
        </>
      }
      title="Current plan"
    />
  );
};
