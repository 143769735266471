import { Box, Link, Stack, useMediaQuery, useTheme } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import Carousel from 'components/Carousel';
import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export function AutonomeLogin({
  handleGoogleLoginSuccess,
}: PropsWithChildren & {
  handleGoogleLoginSuccess: (credentialResponse: CredentialResponse) => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack>
      <Carousel
        imageUrls={
          isMobile
            ? [
                '/autonome-carousel/mobile-welcome.png',
                '/autonome-carousel/mobile-agent-kit.png',
                '/autonome-carousel/mobile-based-agent.png',
                '/autonome-carousel/mobile-eliza.png',
              ]
            : [
                '/autonome-carousel/welcome.png',
                '/autonome-carousel/agent-kit.png',
                '/autonome-carousel/based-agent.png',
                '/autonome-carousel/eliza.png',
              ]
        }
      />
      <Stack alignItems="center" justifyContent="center">
        <Box
          sx={{
            width: { xs: '100%', md: 'max-content' },
            p: { xs: 2, md: 0 },
            mt: 8,
            "& div[role='button']": {
              px: 8,
              py: 4,
              borderRadius: 0,
            },
            "& div[role='button'] > div:nth-of-type(2) > div": {
              background: '#202124',
            },
            "& div[role='button']:hover > div:nth-of-type(2) > div , & div[role='button']:focus > div:nth-of-type(2) > div":
              {
                background: 'rgba(32, 33, 36, 0.02)',
              },
          }}
        >
          <GoogleLogin
            onError={() => {
              console.error('Login Failed');
            }}
            onSuccess={handleGoogleLoginSuccess}
            shape="pill"
            theme="filled_black"
          />
        </Box>
        <Link component={RouterLink} sx={{ my: 8 }} to="/disclaimer">
          TERMS OF SERVICE
        </Link>
      </Stack>
    </Stack>
  );
}
