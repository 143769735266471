import { Box, Button, Stack, StackProps, Typography } from '@mui/material';
import { IoArrowForwardSharp } from 'react-icons/io5';

export interface IDeploymentCard extends StackProps {
  onClickHeader?: React.MouseEventHandler<HTMLButtonElement>;
  titleStartAdornment?: React.ReactNode;
  titleEndAdornment?: React.ReactNode;
  title?: string;
}

export const DeploymentCard: React.FC<IDeploymentCard> = ({
  children,
  onClickHeader,
  sx,
  title,
  titleEndAdornment,
  titleStartAdornment,
  ...props
}) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      {...props}
      sx={{
        background: theme => theme.colors.gradients.sheet,
        width: '100%',
        minHeight: '260px',
        ...sx,
      }}
    >
      <Button
        onClick={onClickHeader}
        sx={theme => ({
          px: 5,
          py: 3,
          width: '100%',
          minHeight: '72px',
          '&, &:hover': {
            color: theme.colors.functional.text.primary,
            background: theme.colors.gradients.metal,
            borderColor: theme.colors.functional.subject.border,
            borderStyle: 'solid',
            borderWidth: '0px 1px 1px 0px',
          },
          '&:hover': {
            filter: 'invert(0.02)',
          },
        })}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack alignItems="center" direction="row" justifyContent="center" spacing="16px">
            {titleStartAdornment}

            <Typography
              alignItems="center"
              display="flex"
              gap={2}
              justifyContent="space-between"
              variant="bodyBold"
            >
              {title}
            </Typography>
          </Stack>

          {titleEndAdornment ?? <Box component={IoArrowForwardSharp} size={24} />}
        </Stack>
      </Button>
      <Stack height="100%" minHeight="195px" px={5} width="100%">
        {children}
      </Stack>
    </Stack>
  );
};
