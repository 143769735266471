import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { AAAAppStatus, aAAAppStatusFromJSON, APP } from 'types/protoc-gen/bffaaa';

import StoreCardActionsMenu from './StoreCardActionsMenu';

const LabelPrivate = <Chip color="primary" label="Private" />;
const LabelPublic = <Chip color="primary" label="Public" />;
const visibilityLabels: Record<AAAAppStatus, ReactNode> = {
  [AAAAppStatus.UNRECOGNIZED]: LabelPrivate,
  [AAAAppStatus.AAA_APP_STATUS_UNSPECIFIED]: LabelPrivate,
  [AAAAppStatus.AAA_APP_STATUS_SUBMITTED]: LabelPrivate,
  [AAAAppStatus.AAA_APP_STATUS_PENDING]: LabelPrivate,
  [AAAAppStatus.AAA_APP_STATUS_PUBLISHED]: LabelPublic,
  [AAAAppStatus.AAA_APP_STATUS_DELETED]: '',
  [AAAAppStatus.AAA_APP_STATUS_REJECTED]: LabelPrivate,
};

const statusLabels: Record<AAAAppStatus, ReactNode> = {
  [AAAAppStatus.UNRECOGNIZED]: <Chip color="error" label="Unrecognized" sx={{ color: 'white' }} />,
  [AAAAppStatus.AAA_APP_STATUS_UNSPECIFIED]: '',
  [AAAAppStatus.AAA_APP_STATUS_SUBMITTED]: '',
  [AAAAppStatus.AAA_APP_STATUS_PENDING]: (
    <Chip color="warning" label="Pending Approval" sx={{ color: 'white' }} />
  ),
  [AAAAppStatus.AAA_APP_STATUS_PUBLISHED]: (
    <Chip color="secondary" label="Published" sx={{ color: 'white' }} />
  ),
  [AAAAppStatus.AAA_APP_STATUS_DELETED]: (
    <Chip color="error" label="Deleted" sx={{ color: 'white' }} />
  ),
  [AAAAppStatus.AAA_APP_STATUS_REJECTED]: (
    <Chip color="error" label="Rejected" sx={{ color: 'white' }} />
  ),
};

export default function AgentStoreCard({ app }: PropsWithChildren<{ app: APP }>) {
  return (
    <Grid container item key={app.appStoreID} spacing={2} sx={{ px: '40px', py: '24px' }} xs={3}>
      <Grid alignItems="center" container direction="row" gap={2} item xs={12}>
        <Box
          component="img"
          src={app?.iconLink || '/imgs/autonome.svg'}
          sx={{ borderRadius: '100rem', height: 40, width: 40 }}
        />
        <Typography variant="h6">{app?.name}</Typography>
        <Stack direction="row" flex={1} justifyContent="flex-end">
          <StoreCardActionsMenu app={app} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1}>
          {visibilityLabels[aAAAppStatusFromJSON(app?.status)]}
          {statusLabels[aAAAppStatusFromJSON(app?.status)]}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography>{app?.description}</Typography>
      </Grid>
    </Grid>
  );
}
