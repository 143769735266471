import { Stack, Typography } from '@mui/material';
import IconInfo from 'components/icons/IconInfo';
import { FC } from 'react';

export const CustomTokenAlert: FC = () => (
  <Stack p={3} px={5} sx={{ width: '100%', background: theme => theme.colors.gradients.cream }}>
    <Stack direction="row" spacing={1}>
      <IconInfo height={'20px'} width={'20px'} />
      <Typography variant="bodySmallM">
        This feature is not currently supported for chains with custom gas tokens.
      </Typography>
    </Stack>
  </Stack>
);
