import { ArrowBackSharp, ArrowForwardSharp } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import CarouselItem from './CarouselItem';

interface CarouselProps {
  imageUrls: string[];
}

const Carousel: React.FC<CarouselProps> = ({ imageUrls }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const goToPrevious = () => {
    const newIndex = currentIndex === 0 ? imageUrls.length - 1 : currentIndex - 1;

    scrollToIndex(newIndex);
  };

  const goToNext = () => {
    const newIndex = currentIndex === imageUrls.length - 1 ? 0 : currentIndex + 1;

    scrollToIndex(newIndex);
  };

  const scrollToIndex = (index: number) => {
    if (carouselRef.current) {
      const itemWidth = carouselRef.current.children[index].getBoundingClientRect().width;

      carouselRef.current.scrollTo({
        left: itemWidth * index,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute('data-index'));

          // Element has scrolled into view
          setCurrentIndex(index);
        }
      });
    };

    // Set up the IntersectionObserver with the callback
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.5, // Trigger when X% of the element is visible
    };
    const observer = new IntersectionObserver(callback, options);

    const elements = document.querySelectorAll(`.carousel-item`);

    setTimeout(() => {
      // Workaround for a bug where current index is non-deterministically set to 1 on load. Start observing after a delay.
      elements?.forEach(element => observer.observe(element));
    }, 500);
  }, []);

  return (
    <Box sx={{ position: 'relative', p: { xs: 2, md: 0 }, width: '100%', margin: 'auto' }}>
      <Box
        aria-label="Image carousel"
        ref={carouselRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {imageUrls.map((url, index) => (
          <CarouselItem index={index} key={url} url={url} />
        ))}
      </Box>
      <Stack
        alignItems="center"
        direction="row"
        gap={{ xs: 1, md: 3 }}
        justifyContent="space-between"
        sx={{
          zIndex: 1,
          position: 'absolute',
          bottom: { xs: 30, lg: 50 },
          right: { md: 40, xs: 30 },
          width: { xs: 120, md: 210 },
        }}
      >
        <IconButton
          onClick={goToPrevious}
          sx={{ visibility: currentIndex === 0 ? 'hidden' : 'visible' }}
        >
          <ArrowBackSharp sx={{ width: { xs: 20, md: 40 }, height: { xs: 20, md: 40 } }} />
        </IconButton>
        <Typography variant="bodySmallC">
          {currentIndex + 1}&nbsp;/&nbsp;{imageUrls?.length}
        </Typography>
        <IconButton
          onClick={goToNext}
          sx={{ visibility: currentIndex >= imageUrls?.length - 1 ? 'hidden' : 'visible' }}
        >
          <ArrowForwardSharp sx={{ width: { xs: 20, md: 40 }, height: { xs: 20, md: 40 } }} />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default Carousel;
