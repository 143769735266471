import { LoadingButton } from '@mui/lab';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import IconCheckedCircle from 'components/icons/IconCheckedCircle';
import IconNay from 'components/icons/IconNay';
import IconUncheckedCircle from 'components/icons/IconUncheckedCircle';
import { useCreateApp } from 'hooks/aaas';
import { FC, useMemo } from 'react';
import { FieldName, useFormContext, useFormState, useWatch } from 'react-hook-form';

import { FormValues } from './types';

export function Checked({ valid }: { valid?: boolean }) {
  return valid ? (
    <IconCheckedCircle />
  ) : valid === undefined ? (
    <IconUncheckedCircle />
  ) : (
    <IconNay />
  );
}

const labelMap: Record<FieldName<FormValues>, string> = {
  agentPrefixName: 'Agent prefix name:',
  deploymentType: 'Deployment type:',
  template: 'Template:',
  imageId: 'Image ID:',
  config: 'Config:',
};

function getDisplayValue(fieldName: FieldName<FormValues>, value: string | number | null) {
  switch (fieldName) {
    // case 'template':
    //   return value ? getTemplateName(String(value)) : undefined;
    default:
      return value;
  }
}

export const SummaryPanel: FC<
  BoxProps & {
    isSubmitting?: boolean;
    isSubmitDisabled?: boolean;
  }
> = ({ isSubmitDisabled, isSubmitting, sx, ...props }) => {
  const form = useFormContext<FormValues>();
  const formValues = useWatch<FormValues>({ control: form.control });
  const formState = useFormState({ control: form.control });

  const { isPending: deployAgentPending } = useCreateApp();

  const summaryItems = useMemo(
    () =>
      Object.entries(formValues)?.map(([name, value]) => {
        const fieldName = name as FieldName<FormValues>;

        return {
          isError: (formState?.errors as any)?.[fieldName]?.message,
          fieldName,
          value: getDisplayValue(fieldName, value),
        };
      }),
    [formValues, formState],
  );

  const scrollToStep = (fieldName: FieldName<FormValues>) => () => {
    const el = document.getElementById(`step_${fieldName}`);

    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        m: { md: 5 },
        top: 40,
        border: theme => `1px solid ${theme.colors.functional.subject.border}`,
        background: theme => theme.colors.gradients.metal,
        ...sx,
      }}
      {...props}
    >
      <Stack alignItems="center" direction="row" gap={1} sx={{ px: 5, py: 2 }}>
        <Typography pt={5} variant="captionC">
          Summary
        </Typography>
      </Stack>
      {summaryItems?.map(cur => (
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          key={cur.fieldName}
          onClick={scrollToStep(cur.fieldName)}
          sx={{
            px: 5,
            py: 2,
            borderTop: '1px solid rgba(32, 39, 35, 0.08)',
            cursor: 'pointer',
          }}
        >
          <Checked valid={cur.isError ? false : cur.value === undefined ? undefined : true} />
          <Typography variant="caption">
            {labelMap?.[cur?.fieldName] ?? `${cur?.fieldName}`}
          </Typography>
          {labelMap?.[cur?.fieldName] && (
            <Typography variant="captionM">
              {cur?.fieldName === 'config'
                ? form.formState.errors.config
                  ? 'Invalid JSON'
                  : 'Valid JSON'
                : String(cur.value ?? '')}
            </Typography>
          )}
        </Stack>
      ))}
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        sx={{ px: 5, py: 2, borderTop: '1px solid rgba(32, 39, 35, 0.08)' }}
      >
        <LoadingButton
          disabled={isSubmitting || isSubmitDisabled}
          fullWidth
          loading={deployAgentPending}
          type="submit"
          variant="contained"
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </LoadingButton>
      </Stack>
    </Box>
  );
};
