import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { raas } from 'api';
import { AxiosError } from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useMemo } from 'react';
import { OperateAAAAppRequest } from 'types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'types/react-query';

export type IUseSubmitAaaRequest = Omit<
  UseMutationOptions<void, Error, OperateAAAAppRequest>,
  'mutationFn'
>;

export default function useSubmitAaaRequest(props?: IUseSubmitAaaRequest) {
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();

  const {
    isPending: isSubmittingRequest,
    mutate: submitRequest,
    ...mutationReturn
  } = useMutation({
    mutationFn: async (reqBody: OperateAAAAppRequest) => {
      await raas.post('/bff/apps/operate', reqBody);
    },
    onSuccess: (...args) => {
      queryClient?.invalidateQueries({ queryKey: [QUERY_KEYS.AAAS_PENDING_AGENT_REQUESTS] });
      queryClient?.invalidateQueries({ queryKey: [QUERY_KEYS.GET_LIST_AAA_TYPES] });
      props?.onSuccess?.(...args);
    },
    onError: err => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title:
          (err as AxiosError<{ message: string }>)?.response?.data?.message ||
          'Failed to submit request.',
      });
    },
  });

  return useMemo(
    () => ({
      submitRequest,
      isSubmittingRequest,
      ...mutationReturn,
    }),
    [submitRequest, isSubmittingRequest, mutationReturn],
  );
}
