import { Box } from '@mui/material';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { truncate } from 'utils/strings';

interface TruncatableTextProps {
  backChars?: number;
  frontChars?: number;
  isTruncate?: boolean;
  separator?: string;
  text: string;
}

export const TruncatableText: React.FC<TruncatableTextProps> = ({
  backChars = 6,
  frontChars = 8,
  isTruncate = true,
  separator = '...',
  text,
}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [shouldTruncate, setShouldTruncate] = useState(isTruncate);

  //  Used useLayoutEffect to perform
  //  DOM measurement before browser paints the screen
  useLayoutEffect(() => {
    //  Local function that measures parent element
    //  width to be compared to the text length/width
    const checkWidth = () => {
      if (!textRef.current) return;

      //  Selects 2nd parent element as the first parent grows with the te
      const parentWidth = textRef.current.parentElement?.parentElement?.offsetWidth || 0;
      const textWidth = text.length * 8;

      //  Truncate when text is larger than parent width
      setShouldTruncate(isTruncate && textWidth > parentWidth);
    };

    checkWidth();

    window.addEventListener('resize', checkWidth);

    //  Cleanup function to clean event listener
    return () => window.removeEventListener('resize', checkWidth);
  }, [text, isTruncate, frontChars, backChars, separator]);

  return (
    <Box component="span" ref={textRef} sx={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
      {shouldTruncate ? truncate(text, separator, frontChars, backChars) : text}
    </Box>
  );
};
