import { useRaasMetrics } from 'hooks/raas';
import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'types/protoc-gen/raas-metrics';

import { useYAxisMinValue } from '../../useYAxisMinValue';

export const L2SafeMetric = () => {
  const { rollupId } = useParams();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step, timeframe } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.L2_NODES_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_L2_SAFE,
    timeframe,
    rollupId,
    start,
    end,
    step,
  });
  const { data: yAxisMinValue = 0 } = useYAxisMinValue({
    panel: Panel.PANEL_OPSTACK_L2_SAFE,
    timeframe,
    result,
    rollupId,
  });

  return (
    <Metric
      chartType="line"
      curveType="step"
      data={result}
      description={'Block height of L2 safe'}
      id={'l2-safe'}
      legendDirection={'column'}
      legendSpacing={'4px'}
      loading={isPending}
      title={'L2 safe'}
      yAxisProps={{
        domain: [yAxisMinValue],
      }}
    />
  );
};
