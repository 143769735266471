import { useRaasDeployment } from 'hooks';
import { SectionButtonTimeFilter } from 'pages/raas/DeploymentDetails/components/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { ContractsGrowthMetric } from './ContractsGrowthMetric';
import { NewContractsMetric } from './NewContractsMetric';

export const ContractsSection = () => {
  const { isPending } = useRaasDeployment();

  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <ContractsGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <NewContractsMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter
          loading={isPending}
          variant={TimeFilterVariant.ROLLUP_STATS_CONTRACTS_SECTION}
        />
      }
      sectionContentSx={{ p: '0px' }}
      title="Contracts"
    />
  );
};
