import {
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useDisclosure } from 'hooks/ui';
import { memo, useMemo, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { IoCloseCircleSharp } from 'react-icons/io5';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import CopyableText from 'shared/components/Copyable/CopyableText';
import TRow from 'shared/components/Table/TRow';
import { APP, OperateAction } from 'types/protoc-gen/bffaaa';
import { ColumnConfig } from 'types/table';

import useManageAgentRequests from './useManageAgentRequests';

export default memo(function RequestList() {
  const theme = useTheme();

  const {
    onClose: onApproveDialogClose,
    onOpen: openApproveDialog,
    open: isApproveDialogOpen,
  } = useDisclosure();
  const {
    onClose: onRejectDialogClose,
    onOpen: openRejectDialog,
    open: isRejectDialogOpen,
  } = useDisclosure();

  const [selectedRow, setSelectedRow] = useState<APP>();

  const { isLoadingRequests, isSubmittingReview, pendingRequests, reviewRequest } =
    useManageAgentRequests({
      onReviewSuccess: () => {
        onApproveDialogClose();
        onRejectDialogClose();
      },
    });

  const columnConfig: ColumnConfig<APP>[] = useMemo(
    () => [
      {
        id: 'appStoreID',
        Header: 'ID',
        Cell: row => (
          <Stack direction="row">
            <CopyableText
              anchorGridProps={{ sx: { width: 'unset', ml: 0.5 } }}
              text={row?.appStoreID}
            />
          </Stack>
        ),
      },
      {
        id: 'name',
        Header: 'Name',
        Cell: row => (
          <Stack direction="row">
            <CopyableText anchorGridProps={{ sx: { width: 'unset', ml: 0.5 } }} text={row?.name} />
          </Stack>
        ),
      },
      {
        id: 'description',
        Header: 'Description',
        Cell: row => (
          <Stack direction="row">
            <Typography variant="captionC">{row?.description}</Typography>
          </Stack>
        ),
      },
      {
        id: 'actions',
        Header: 'Approve/Reject',
        Cell: row => (
          <Stack alignItems="center" direction="row" justifyContent="center" spacing={1}>
            <IconButton
              onClick={() => {
                setSelectedRow(row);
                openApproveDialog();
              }}
            >
              <FaCheckCircle color="white" fill={theme.colors.schema.success} size={20} />
            </IconButton>
            <IconButton
              onClick={() => {
                setSelectedRow(row);
                openRejectDialog();
              }}
            >
              <IoCloseCircleSharp color="white" fill={theme.colors.schema.failure} size={24} />
            </IconButton>
          </Stack>
        ),
      },
    ],
    [openApproveDialog, openRejectDialog, setSelectedRow, theme.colors.schema],
  );

  return (
    <>
      <ConfirmationDialog
        confirmButtonText="Approve"
        handleClose={onApproveDialogClose}
        isLoading={isSubmittingReview}
        onConfirm={() => {
          if (selectedRow?.appStoreID) {
            reviewRequest({
              id: selectedRow?.appStoreID,
              OperateAction: OperateAction.OPERATE_ACTION_APPROVE,
            });
          }
        }}
        open={isApproveDialogOpen}
        title="Confirm request approval"
      >
        This will make the app public on the app store
      </ConfirmationDialog>
      <ConfirmationDialog
        confirmButtonText="Reject"
        handleClose={onRejectDialogClose}
        isLoading={isSubmittingReview}
        onConfirm={() => {
          if (selectedRow?.appStoreID) {
            reviewRequest({
              id: selectedRow?.appStoreID,
              OperateAction: OperateAction.OPERATE_ACTION_REJECT,
            });
          }
        }}
        open={isRejectDialogOpen}
        title="Confirm request rejection"
      />
      <Grid alignItems="center" container justifyContent="center" spacing={1}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columnConfig.map(col => (
                  <TableCell align={col.align} key={col.id} sx={{ width: col.width }}>
                    <Stack
                      alignItems="center"
                      direction="row"
                      sx={{ cursor: col?.sortable ? 'pointer' : 'auto', display: 'inline-flex' }}
                    >
                      {col.Header}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingRequests?.map(req => (
                <TRow<APP> columnConfig={columnConfig} key={req?.appStoreID} rowData={req} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoadingRequests && (
          <Grid alignItems="center" container justifyContent="center" sx={{ height: 120 }}>
            <CircularProgress />
          </Grid>
        )}
        {!isLoadingRequests && !pendingRequests?.length && (
          <Stack alignItems="center" justifyContent="center" width="100%">
            No pending requests
          </Stack>
        )}
      </Grid>
    </>
  );
});
