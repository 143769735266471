import { Box, BoxProps, Collapse, Grid, Typography } from '@mui/material';
import { AnimatedIconChevronDown } from 'components/icons/AnimatedIconChevronDown';
import { ReactNode } from 'react';

export interface IAccordionSection extends BoxProps {
  title?: string;
  renderTitle?: ReactNode;
  onClickHeader: () => void;
  open: boolean;
}

export const AccordionSection = ({
  children,
  onClickHeader,
  open,
  renderTitle,
  sx,
  title,
  ...props
}: IAccordionSection) => {
  return (
    <Box {...props} sx={{ mb: '164px', ...sx }}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        onClick={onClickHeader}
        sx={{
          background: '#fafafa',
          px: 5,
          py: 4,
          border: '1px solid rgba(32, 39, 35, 0.08)',
          cursor: 'pointer',
        }}
      >
        {renderTitle || <Typography variant="h5">{title}</Typography>}
        <AnimatedIconChevronDown open={open} />
      </Grid>
      <Collapse in={open} mountOnEnter>
        {children}
      </Collapse>
    </Box>
  );
};
