import { Grid, GridProps, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { truncate as truncateString } from 'utils/strings';

export interface ISectionContentCompactRow extends Omit<GridProps, 'children'> {
  label: string;
  labelUnderline?: boolean;
  truncate?: boolean;
  gridItem?: boolean;
  renderContent?: ReactNode;
  renderContentEnd?: ReactNode;
  renderStart?: ReactNode;
  renderEnd?: ReactNode;
  onClick?: () => void;
}

export const SectionContentCompactRow = ({
  gridItem,
  label,
  labelUnderline,
  onClick,
  renderContent,
  renderContentEnd,
  renderEnd,
  renderStart,
  sx,
  truncate,
  ...props
}: ISectionContentCompactRow) => {
  return (
    label && (
      <Grid
        container
        flexDirection="column"
        item={gridItem}
        sx={{ width: '100%', ...sx }}
        {...props}
        onClick={onClick}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
            {renderStart}
            {renderContent ? (
              <Stack alignItems="center" direction="row" spacing="8px">
                {renderContent}

                {renderContentEnd}
              </Stack>
            ) : (
              <Stack alignItems="center" direction="row" spacing="8px">
                <Typography
                  sx={{ textDecoration: labelUnderline ? 'underline' : 'none' }}
                  variant="bodySmall"
                >
                  {truncate ? truncateString(label, '...', 20) : label}
                </Typography>

                {renderContentEnd}
              </Stack>
            )}
          </Stack>

          {renderEnd}
        </Stack>
      </Grid>
    )
  );
};
