import { Box, Drawer, Popover, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AutonomeLogo from 'components/icons/AutonomeLogo';
import IconLink from 'components/icons/IconLink';
import { useDisclosure } from 'hooks/ui';
import PromoCodeList from 'pages/admin/ManagePromoCodes/PromoCodeList';
import { TeamSettings } from 'pages/TeamSettings';
import React, { useState } from 'react';
import { FaPowerOff, FaWpforms } from 'react-icons/fa';
import { GrUserSettings } from 'react-icons/gr';
import { ImTicket } from 'react-icons/im';
import { Link, useNavigate } from 'react-router-dom';
import { authUtil } from 'utils/auth';

import { axiosAuth } from '../../api';
import { PATHS } from '../../App';
import { PERMISSIONS, useAuthContext, User } from '../../contexts/AuthContext';
import { px } from '../../theme/margins';
import IconLogo from '../icons/IconLogo';
import HeaderLink from './HeaderLink';

const isAutonome = window.location.origin === process.env.REACT_APP_AUTONOME_ORIGIN;

const AppLogo = isAutonome ? AutonomeLogo : IconLogo;

const Header: React.FC = () => {
  const { setIsLoggedIn, setLoginSuccessRedirectPath, setUser, user } = useAuthContext();
  const showOrgInfo = user?.org_name && !user?.org_name?.startsWith('user:');
  const navigate = useNavigate();
  const [showSettingsDrawer, setShowSettingsDrawer] = useState(false);
  const {
    onClose: closePromoDrawer,
    onOpen: openPromoDrawer,
    open: isPromoDrawerOpen,
  } = useDisclosure();

  const client = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async () => {
      await axiosAuth.post('/auth/logout');
    },
    onSuccess: () => {
      setIsLoggedIn(false);
      setUser({} as User);
      client?.clear();
      authUtil.setAccessToken('');
      setLoginSuccessRedirectPath('');
      navigate(PATHS.LOGIN);
    },
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        onClose={() => setShowSettingsDrawer(false)}
        open={showSettingsDrawer}
      >
        <TeamSettings />
      </Drawer>
      <Drawer
        anchor="bottom"
        onClose={closePromoDrawer}
        open={isPromoDrawerOpen}
        sx={{ '.MuiPaper-root': { px: 4, pb: 12, minHeight: '40vh' } }}
      >
        <Typography sx={{ py: 4 }} variant="h3">
          My Promo Codes
        </Typography>
        <PromoCodeList />
      </Drawer>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
          borderBottom: '1px solid rgba(32, 39, 35, 0.03)',
        }}
      >
        <Stack px={px} py={{ md: 2.5, xs: 1.5 }}>
          <Link to={PATHS.DEFAULT}>
            <AppLogo sx={{ height: { xs: '30px', md: '40px' }, width: 'auto' }} />
          </Link>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          sx={{ '&>a': { display: { xs: 'none', sm: 'flex' } } }}
        >
          <HeaderLink
            external
            href={
              isAutonome
                ? 'https://docs.altlayer.io/altlayer-documentation/autonome'
                : 'https://docs.altlayer.io/altlayer-documentation/wizard/introduction'
            }
          >
            <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
              <Typography variant="bodySmallC">User guide</Typography>
              <IconLink />
            </Stack>
          </HeaderLink>

          {user?.email && (
            <Stack
              alignItems="center"
              direction="row"
              gap={2}
              px={6}
              sx={{ height: '100%', background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)' }}
            >
              <Box
                alt="profile picture"
                component="img"
                referrerPolicy="no-referrer"
                src={user?.picture}
                sx={{ width: 30, height: 30, borderRadius: '50%' }}
              />
              <Stack>
                <Typography>{user?.email}</Typography>
                {showOrgInfo && <Typography fontSize={12}>{user?.org_name}</Typography>}
              </Stack>
              <Box
                component="img"
                onClick={handleClick}
                src={`/imgs/chevron_down.svg`}
                sx={{
                  height: '18px',
                  width: '18px',
                  cursor: 'pointer',
                  transform: anchorEl ? 'rotate(180deg)' : 'none',
                  transition: 'all 0.2s ease-in-out',
                }}
              />
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                onClose={handleClose}
                open={Boolean(anchorEl)}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Stack
                  sx={{
                    '&>*': {
                      py: 2,
                      px: 3,
                      display: 'flex',
                      gap: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: '#ccc4',
                      },
                    },
                  }}
                >
                  {showOrgInfo && (
                    <Typography
                      onClick={() => {
                        setShowSettingsDrawer(true);
                        handleClose();
                      }}
                    >
                      <GrUserSettings size={20} />
                      Team Settings
                    </Typography>
                  )}
                  <Typography
                    onClick={() => {
                      if (user?.permissions?.includes(PERMISSIONS.WRITE_PROMO_CODE)) {
                        navigate(PATHS.MANAGE_PROMO_CODES);
                      } else {
                        openPromoDrawer();
                      }

                      handleClose();
                    }}
                  >
                    <ImTicket size={20} />
                    {user?.permissions?.includes(PERMISSIONS.WRITE_PROMO_CODE) ? 'Manage ' : 'My '}
                    Promo Codes
                  </Typography>
                  {user?.permissions?.includes(PERMISSIONS.WRITE_PROMO_CODE) && (
                    <Typography
                      onClick={() => {
                        navigate(PATHS.MANAGE_AGENT_REQUESTS);
                        handleClose();
                      }}
                    >
                      <FaWpforms size={20} />
                      Manage Agent Requests
                    </Typography>
                  )}

                  <Typography
                    onClick={() => {
                      mutate();
                      handleClose();
                    }}
                  >
                    <FaPowerOff size={20} />
                    Logout
                  </Typography>
                </Stack>
              </Popover>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Header;
