import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const IconAta: FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.0516 12.5161C16.0283 12.4566 16.0713 12.4474 15.9932 12.4279C15.9949 12.4447 15.9962 12.4615 15.9976 12.4783C16.0008 12.4985 16.0025 12.5188 16.0026 12.5393C16.0026 12.5411 16.0026 12.543 16.0026 12.5446C16.0384 13.1815 15.8065 13.6786 15.2269 14.063C14.9493 14.2477 14.6423 14.3286 14.3095 14.3291C12.4206 14.3321 10.5318 14.3364 8.64306 14.3421C8.64087 14.3773 8.64138 14.4139 8.64121 14.4499C8.64121 14.4139 8.64121 14.3773 8.64306 14.3421C7.68195 14.3347 6.72084 14.3253 5.75973 14.3228C5.65695 14.3228 5.56056 14.327 5.47424 14.2625C5.0376 14.2843 4.63791 14.1441 4.23418 14.0007C3.39147 13.7014 2.68546 13.1794 2.1101 12.5305C1.48235 11.8228 1.11927 10.8527 0.956707 9.90247C0.589931 10.7732 0.448695 11.7913 0.516374 12.7307C0.655426 14.6588 1.51359 16.2181 2.98943 17.4202C4.37189 18.5462 5.18841 18.8541 6.96183 18.8309H7.93587C9.71249 18.8361 11.3213 18.8514 13.0979 18.8401C13.5751 18.8369 14.0577 18.7948 14.5204 18.6467L14.5953 18.5963C15.0361 18.1076 15.5134 17.6503 15.8212 17.0533C16.5846 15.5738 16.6614 14.0614 16.0516 12.5161ZM8.02572 17.2131C8.01732 17.288 8.01178 17.3633 8.00892 17.4387C8.01178 17.3633 8.01732 17.2874 8.02572 17.2131Z"
      fill="url(#paint0_linear_4286_2909)"
    />
    <path
      d="M7.73633 17.9318C7.73633 17.9373 7.73728 17.9431 7.73792 17.9488C7.73728 17.9431 7.73665 17.9373 7.73633 17.9318Z"
      fill="url(#paint1_linear_4286_2909)"
    />
    <path
      d="M13.66 1.82824C12.1155 0.771575 10.421 0.480035 8.61429 0.857727C7.61791 1.06614 6.72952 1.50294 5.92023 2.1705C5.56673 2.46325 5.23382 2.78 4.92385 3.11851C4.91898 3.12388 4.91445 3.12959 4.91008 3.1353C4.89071 3.16139 4.87056 3.18602 4.84962 3.20919C4.84095 3.21879 4.83313 3.22913 4.82628 3.24009C4.80502 3.27433 4.78187 3.30736 4.75692 3.33901C4.75334 3.3436 4.74998 3.3483 4.74684 3.35312C4.72028 3.3945 4.69135 3.43431 4.66019 3.47235C4.65405 3.48003 4.64844 3.48811 4.6434 3.49654C4.62139 3.53302 4.59733 3.56823 4.57135 3.602C4.56289 3.61293 4.55547 3.62463 4.54918 3.63693C4.53552 3.66347 4.52097 3.68938 4.50552 3.71469C4.50266 3.71939 4.49998 3.72409 4.49695 3.72879C4.48811 3.7429 4.47887 3.75684 4.46924 3.77061L4.46118 3.7822C4.45228 3.79463 4.44321 3.80689 4.43398 3.81881L4.42558 3.83056C4.40996 3.85424 4.39384 3.87725 4.37688 3.89908C4.36935 3.90874 4.36266 3.91902 4.35689 3.92982C4.32848 3.9827 4.29591 4.03324 4.25949 4.08096C4.25179 4.09107 4.24493 4.1018 4.239 4.11304L4.19047 4.20926L4.18459 4.22001C3.41829 5.52052 2.64225 6.81533 1.88384 8.12004C1.59079 8.62385 1.25071 9.10449 1.03004 9.64676C0.937642 9.8734 0.918555 10.1233 0.975462 10.3613C1.16758 11.173 1.54326 11.9096 2.09309 12.5296C2.66861 13.1785 3.37462 13.7004 4.21717 13.9997C4.62022 14.1431 5.02075 14.2834 5.45739 14.2615C5.12571 14.0024 4.77086 13.779 4.61031 13.3449C4.42088 12.8319 4.46253 12.3578 4.73677 11.9007C5.73029 10.2448 6.72762 8.59116 7.72875 6.93977C7.74004 6.92066 7.74976 6.90066 7.7578 6.87998L8.25339 6.04533L8.2507 6.04701C8.48279 5.66176 8.71567 5.27696 8.94932 4.8926C9.01129 4.79184 9.08955 4.69981 9.16076 4.60409C9.16328 4.60056 9.1658 4.59703 9.16815 4.59334C9.42812 4.1918 9.73712 3.84685 10.1333 3.56136C11.2672 2.74401 12.5012 2.32097 13.9089 2.49042C14.1299 2.51712 14.3517 2.53593 14.5734 2.55759C14.3054 2.26925 13.9846 2.05042 13.66 1.82824Z"
      fill="url(#paint2_linear_4286_2909)"
    />
    <path
      d="M19.4306 11.0766C19.3798 10.7829 19.3032 10.4942 19.2015 10.214C19.1923 10.1887 19.1545 10.1035 19.1151 9.99186C18.9602 9.55405 18.7335 9.14159 18.468 8.75063C17.8533 7.69732 17.2385 6.64396 16.6235 5.59054C16.3058 5.04693 15.9885 4.50281 15.6673 3.96154C15.3605 3.4443 15.0217 2.9514 14.5572 2.55859C14.3357 2.53609 14.1137 2.51728 13.8927 2.49142C12.4844 2.32197 11.251 2.745 10.1173 3.56236C9.71926 3.84919 9.40908 4.19599 9.14844 4.59988C9.33922 4.51121 9.5226 4.39835 9.72178 4.3384C10.4731 4.11336 11.1506 4.38508 11.581 5.09412C12.6125 6.79113 13.6336 8.49436 14.6605 10.1947L14.6627 10.1981L14.9146 10.6179C15.0783 10.958 15.3029 11.2633 15.4951 11.5871C15.544 11.6696 15.5969 11.7498 15.6485 11.8308L15.9256 12.2924C15.9598 12.3421 15.9834 12.3983 15.9949 12.4575C16.0037 12.4768 16.0107 12.4968 16.0183 12.5158C16.6245 14.0622 16.5478 15.5746 15.788 17.053C15.4795 17.651 14.9651 18.1553 14.523 18.6449C14.6355 18.6408 14.7781 18.5683 14.887 18.5352C16.5261 18.0381 17.7289 16.9972 18.6144 15.559C18.9793 14.962 19.2306 14.3027 19.3559 13.6143C19.4706 13.0305 19.4798 12.5197 19.4842 12.209C19.4896 11.8308 19.4717 11.4526 19.4306 11.0766Z"
      fill="url(#paint3_linear_4286_2909)"
    />
    <g opacity="0.33">
      <mask
        height="17"
        id="mask0_4286_2909"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="11"
        x="9"
        y="2"
      >
        <path
          d="M18.0293 16.4155C17.1114 17.5652 15.8755 18.2041 14.5099 18.6541C15.8656 17.4601 16.5896 15.6649 16.3657 13.8629C16.2403 12.8537 15.8013 12.1202 15.2909 11.2709C14.335 9.6824 13.3963 8.0843 12.4378 6.49729C12.124 5.97769 11.8477 5.4072 11.4514 4.9447C10.8108 4.19721 9.98611 4.21031 9.13281 4.59086C9.72966 3.73723 10.5831 3.10881 11.5592 2.73867C12.5287 2.37105 13.5372 2.452 14.55 2.53714C14.8037 2.80904 15.0698 3.05691 15.2861 3.36189C15.6194 3.83211 15.8962 4.34567 16.1887 4.84176C16.7837 5.85106 17.3839 6.85701 17.9689 7.8727C18.5464 8.87479 19.2452 9.84295 19.4178 11.012C19.7117 12.9908 19.1903 14.8035 18.0293 16.4155Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4286_2909)">
        <path
          d="M11.495 3.54996C11.5477 3.58136 11.5402 3.64266 11.4886 3.6786C11.4371 3.71454 11.3623 3.71067 11.3077 3.68028C11.2532 3.64988 11.2406 3.6042 11.294 3.56725C11.3474 3.53031 11.4428 3.51889 11.495 3.54996Z"
          fill="white"
        />
        <path
          d="M11.9221 3.26684C11.9701 3.30043 11.9661 3.3582 11.9069 3.39733C11.8478 3.43646 11.7558 3.44183 11.7054 3.40925C11.655 3.37667 11.6666 3.31756 11.7281 3.27726C11.7896 3.23695 11.8739 3.23309 11.9221 3.26684Z"
          fill="white"
        />
        <path
          d="M12.251 3.09521C12.2724 3.11082 12.2678 3.134 12.2431 3.14643C12.2297 3.1522 12.215 3.1546 12.2003 3.15343C12.1857 3.15226 12.1716 3.14755 12.1592 3.13971C12.1375 3.12392 12.14 3.10209 12.1657 3.08933C12.1794 3.08342 12.1943 3.08086 12.2091 3.08189C12.2239 3.08291 12.2383 3.08748 12.251 3.09521Z"
          fill="white"
        />
        <path
          d="M12.576 3.37798C12.5807 3.38195 12.5842 3.38705 12.5864 3.39279C12.5885 3.39853 12.5892 3.40473 12.5882 3.41078C12.5873 3.41684 12.5849 3.42256 12.5811 3.4274C12.5773 3.43224 12.5724 3.43604 12.5668 3.43844C12.5535 3.44451 12.5386 3.44642 12.5242 3.44393C12.5098 3.44145 12.4965 3.43467 12.486 3.4245C12.4812 3.42076 12.4775 3.41583 12.4752 3.41021C12.4729 3.40459 12.4722 3.39847 12.473 3.39246C12.4738 3.38645 12.4762 3.38076 12.4799 3.37595C12.4836 3.37114 12.4884 3.36738 12.494 3.36505C12.5075 3.35893 12.5224 3.3569 12.537 3.3592C12.5515 3.36149 12.5651 3.36803 12.576 3.37798Z"
          fill="white"
        />
        <path
          d="M12.8778 3.721C12.8822 3.72585 12.8855 3.73163 12.8873 3.73791C12.8892 3.74419 12.8896 3.7508 12.8885 3.75726C12.8874 3.76372 12.8849 3.76985 12.8812 3.7752C12.8774 3.78055 12.8725 3.78499 12.8667 3.78817C12.8538 3.7944 12.839 3.79599 12.825 3.79269C12.811 3.78939 12.7985 3.7814 12.7897 3.77004C12.785 3.76536 12.7815 3.75968 12.7795 3.75343C12.7774 3.74718 12.7768 3.74053 12.7778 3.73402C12.7787 3.72751 12.7812 3.7213 12.7849 3.71589C12.7887 3.71049 12.7936 3.70603 12.7994 3.70286C12.8126 3.69663 12.8275 3.69504 12.8418 3.69833C12.856 3.70162 12.8687 3.7096 12.8778 3.721Z"
          fill="white"
        />
        <path
          d="M13.1578 4.10001C13.1618 4.10544 13.1648 4.11165 13.1663 4.11826C13.1679 4.12487 13.168 4.13173 13.1668 4.13841C13.1656 4.14509 13.163 4.15144 13.1592 4.15706C13.1553 4.16267 13.1504 4.16744 13.1447 4.17105C13.1322 4.17771 13.1177 4.17942 13.104 4.17584C13.0903 4.17226 13.0785 4.16365 13.0709 4.15174C13.0667 4.14645 13.0637 4.14034 13.0621 4.13381C13.0604 4.12728 13.0601 4.12047 13.0612 4.11382C13.0623 4.10717 13.0649 4.10084 13.0686 4.09522C13.0723 4.0896 13.0772 4.08483 13.0829 4.0812C13.0954 4.07451 13.1101 4.07272 13.1239 4.0762C13.1378 4.07967 13.1498 4.08816 13.1578 4.10001Z"
          fill="white"
        />
        <path
          d="M13.4098 4.49043C13.4172 4.50203 13.4197 4.51607 13.4167 4.52951C13.4138 4.54294 13.4057 4.55467 13.3941 4.56214C13.3823 4.56843 13.3686 4.57 13.3556 4.56653C13.3427 4.56307 13.3315 4.55482 13.3244 4.54347C13.3173 4.53211 13.3148 4.51848 13.3174 4.50533C13.3199 4.49219 13.3274 4.4805 13.3382 4.47263C13.3501 4.46562 13.3643 4.46356 13.3776 4.46689C13.391 4.47022 13.4026 4.47867 13.4098 4.49043Z"
          fill="white"
        />
        <path
          d="M13.6416 4.86651C13.648 4.87813 13.6496 4.89177 13.6462 4.90456C13.6427 4.91736 13.6345 4.92834 13.6231 4.9352C13.6178 4.93876 13.6118 4.94121 13.6055 4.9424C13.5991 4.94359 13.5927 4.94349 13.5864 4.94211C13.5801 4.94073 13.5742 4.9381 13.569 4.93437C13.5637 4.93064 13.5593 4.9259 13.5559 4.92042C13.5492 4.90906 13.5471 4.89548 13.5503 4.88263C13.5534 4.86978 13.5615 4.85868 13.5727 4.85173C13.5782 4.84806 13.5843 4.84552 13.5908 4.84427C13.5972 4.84302 13.6039 4.84309 13.6103 4.84447C13.6167 4.84585 13.6228 4.84851 13.6282 4.8523C13.6336 4.85609 13.6381 4.86092 13.6416 4.86651Z"
          fill="white"
        />
        <path
          d="M13.8464 5.21255C13.8525 5.22409 13.8539 5.23754 13.8502 5.25007C13.8466 5.2626 13.8383 5.27323 13.8269 5.27972C13.8218 5.28348 13.8159 5.28613 13.8096 5.28752C13.8034 5.28891 13.7969 5.28902 13.7906 5.28782C13.7843 5.28663 13.7783 5.28416 13.773 5.28057C13.7677 5.27699 13.7632 5.27235 13.7598 5.26696C13.7537 5.25563 13.7523 5.24238 13.7558 5.23002C13.7594 5.21767 13.7676 5.20718 13.7787 5.20079C13.7839 5.19703 13.7898 5.19434 13.796 5.19289C13.8023 5.19144 13.8088 5.19126 13.8151 5.19236C13.8214 5.19345 13.8274 5.1958 13.8328 5.19927C13.8382 5.20274 13.8428 5.20726 13.8464 5.21255Z"
          fill="white"
        />
        <path
          d="M14.0535 5.55413C14.0596 5.56565 14.061 5.57909 14.0574 5.59162C14.0538 5.60414 14.0454 5.61478 14.0342 5.62131C14.029 5.62505 14.0231 5.62769 14.0168 5.62908C14.0106 5.63047 14.0041 5.63058 13.9979 5.62941C13.9916 5.62823 13.9856 5.62579 13.9803 5.62223C13.975 5.61867 13.9705 5.61407 13.967 5.60871C13.9608 5.59747 13.9593 5.58424 13.9628 5.57189C13.9663 5.55955 13.9745 5.54906 13.9856 5.54271C13.9908 5.53893 13.9967 5.53622 14.0029 5.53474C14.0092 5.53326 14.0156 5.53305 14.022 5.53411C14.0283 5.53518 14.0343 5.5375 14.0397 5.54094C14.0451 5.54437 14.0498 5.54886 14.0535 5.55413Z"
          fill="white"
        />
        <path
          d="M14.2603 5.89434C14.2664 5.90584 14.2678 5.91926 14.2643 5.93179C14.2607 5.94431 14.2524 5.95497 14.2412 5.96152C14.236 5.96521 14.2301 5.96781 14.2239 5.96918C14.2176 5.97054 14.2112 5.97064 14.2049 5.96947C14.1986 5.96829 14.1927 5.96587 14.1874 5.96234C14.1821 5.95881 14.1775 5.95425 14.174 5.94892C14.1678 5.9377 14.1663 5.9245 14.1698 5.91218C14.1733 5.89986 14.1815 5.88941 14.1926 5.88309C14.1978 5.8793 14.2036 5.87658 14.2099 5.87509C14.2161 5.8736 14.2225 5.87338 14.2289 5.87443C14.2352 5.87548 14.2412 5.87778 14.2466 5.8812C14.252 5.88462 14.2567 5.88909 14.2603 5.89434Z"
          fill="white"
        />
        <path
          d="M14.4692 6.2342C14.4753 6.24556 14.4768 6.25887 14.4732 6.27127C14.4696 6.28367 14.4613 6.29417 14.4501 6.30053C14.4449 6.30422 14.439 6.30683 14.4328 6.3082C14.4265 6.30956 14.4201 6.30966 14.4138 6.30848C14.4076 6.30731 14.4016 6.30488 14.3963 6.30135C14.391 6.29783 14.3864 6.29326 14.3829 6.28794C14.3768 6.27673 14.3753 6.26358 14.3788 6.25129C14.3823 6.23901 14.3905 6.22859 14.4016 6.22227C14.4068 6.21854 14.4126 6.21588 14.4189 6.21446C14.4251 6.21304 14.4316 6.21287 14.4379 6.21399C14.4442 6.2151 14.4502 6.21746 14.4556 6.22093C14.461 6.2244 14.4656 6.22891 14.4692 6.2342Z"
          fill="white"
        />
        <path
          d="M14.6764 6.57368C14.6825 6.58518 14.684 6.5986 14.6804 6.61112C14.6768 6.62364 14.6685 6.6343 14.6573 6.64085C14.6521 6.64454 14.6462 6.64715 14.64 6.64851C14.6337 6.64988 14.6273 6.64998 14.621 6.6488C14.6148 6.64763 14.6088 6.6452 14.6035 6.64167C14.5982 6.63814 14.5936 6.63358 14.5901 6.62826C14.5839 6.61707 14.5823 6.60387 14.5858 6.59153C14.5892 6.5792 14.5974 6.56874 14.6086 6.56242C14.6138 6.55863 14.6196 6.55591 14.6259 6.55442C14.6321 6.55293 14.6386 6.5527 14.6449 6.55375C14.6512 6.5548 14.6573 6.5571 14.6627 6.56052C14.6681 6.56394 14.6728 6.56842 14.6764 6.57368Z"
          fill="white"
        />
        <path
          d="M14.8846 6.91355C14.8907 6.92507 14.8921 6.9385 14.8885 6.95103C14.8849 6.96356 14.8766 6.9742 14.8653 6.98073C14.8602 6.98433 14.8543 6.98686 14.8482 6.98818C14.842 6.9895 14.8357 6.98957 14.8295 6.9884C14.8233 6.98722 14.8174 6.98482 14.8122 6.98134C14.807 6.97786 14.8025 6.97337 14.799 6.96813C14.7928 6.95691 14.7913 6.94371 14.7948 6.93139C14.7983 6.91907 14.8065 6.90862 14.8176 6.9023C14.8228 6.89861 14.8286 6.89598 14.8347 6.89455C14.8409 6.89312 14.8473 6.89293 14.8535 6.89397C14.8597 6.89502 14.8657 6.89729 14.871 6.90065C14.8764 6.90401 14.881 6.90839 14.8846 6.91355Z"
          fill="white"
        />
        <path
          d="M15.0906 7.25435C15.0967 7.26589 15.0981 7.27934 15.0944 7.29187C15.0908 7.30439 15.0825 7.31503 15.0712 7.32152C15.066 7.32527 15.0601 7.32793 15.0538 7.32932C15.0476 7.33071 15.0411 7.33082 15.0348 7.32962C15.0285 7.32843 15.0225 7.32596 15.0172 7.32237C15.0119 7.31878 15.0074 7.31415 15.004 7.30876C14.9979 7.29751 14.9964 7.28431 14.9999 7.272C15.0034 7.25969 15.0117 7.24925 15.0228 7.24293C15.0279 7.2391 15.0338 7.23635 15.0401 7.23484C15.0463 7.23334 15.0528 7.23312 15.0591 7.23418C15.0655 7.23525 15.0715 7.23758 15.0769 7.24105C15.0824 7.24451 15.087 7.24904 15.0906 7.25435Z"
          fill="white"
        />
        <path
          d="M15.2947 7.59622C15.3008 7.60777 15.3021 7.62122 15.2985 7.63374C15.2949 7.64627 15.2865 7.6569 15.2752 7.6634C15.27 7.667 15.2642 7.66953 15.2581 7.67084C15.2519 7.67214 15.2456 7.67219 15.2394 7.67098C15.2332 7.66977 15.2273 7.66734 15.2221 7.66381C15.2169 7.66029 15.2125 7.65575 15.209 7.65047C15.2029 7.63915 15.2015 7.62588 15.205 7.61351C15.2086 7.60115 15.2168 7.59066 15.228 7.5843C15.2332 7.58066 15.239 7.57808 15.2451 7.5767C15.2513 7.57533 15.2576 7.57519 15.2638 7.5763C15.2701 7.57741 15.276 7.57975 15.2813 7.58317C15.2866 7.58659 15.2911 7.59103 15.2947 7.59622Z"
          fill="white"
        />
        <path
          d="M15.5005 7.9397C15.5065 7.95125 15.5079 7.96469 15.5043 7.97722C15.5006 7.98975 15.4923 8.00038 15.481 8.00688C15.4758 8.01061 15.4698 8.01323 15.4636 8.01459C15.4573 8.01594 15.4508 8.016 15.4445 8.01476C15.4382 8.01351 15.4323 8.011 15.427 8.00736C15.4217 8.00372 15.4172 7.99905 15.4138 7.99361C15.4079 7.98222 15.4066 7.96901 15.4101 7.95669C15.4137 7.94436 15.4218 7.93384 15.4328 7.92728C15.438 7.9235 15.4439 7.92081 15.4502 7.91939C15.4564 7.91797 15.4629 7.91783 15.4693 7.91899C15.4756 7.92016 15.4816 7.92259 15.487 7.92615C15.4923 7.92971 15.4969 7.93432 15.5005 7.9397Z"
          fill="white"
        />
        <path
          d="M15.7051 8.28573C15.7112 8.29729 15.7126 8.31075 15.7089 8.32328C15.7052 8.33581 15.6968 8.34643 15.6855 8.3529C15.6803 8.35643 15.6744 8.35888 15.6682 8.36011C15.662 8.36134 15.6556 8.36132 15.6495 8.36005C15.6433 8.35879 15.6374 8.3563 15.6322 8.35273C15.627 8.34917 15.6226 8.3446 15.6192 8.3393C15.6131 8.32796 15.6116 8.31468 15.6152 8.30231C15.6188 8.28993 15.6271 8.27946 15.6383 8.27313C15.6435 8.26949 15.6494 8.26693 15.6556 8.26559C15.6617 8.26426 15.6681 8.26417 15.6744 8.26535C15.6806 8.26652 15.6865 8.26893 15.6918 8.27243C15.6971 8.27593 15.7016 8.28045 15.7051 8.28573Z"
          fill="white"
        />
        <path
          d="M15.9113 8.63196C15.9171 8.64346 15.9182 8.65674 15.9146 8.66907C15.9109 8.68141 15.9026 8.69187 15.8915 8.6983C15.8862 8.70193 15.8802 8.70445 15.8739 8.70571C15.8676 8.70698 15.8612 8.70696 15.8549 8.70565C15.8486 8.70435 15.8427 8.70179 15.8374 8.69813C15.8322 8.69448 15.8277 8.68979 15.8243 8.68436C15.8181 8.67305 15.8167 8.65978 15.8203 8.64743C15.8239 8.63507 15.8322 8.62462 15.8434 8.61836C15.8487 8.6147 15.8547 8.61215 15.8611 8.61085C15.8674 8.60955 15.8739 8.60954 15.8802 8.6108C15.8866 8.61207 15.8926 8.6146 15.8979 8.61823C15.9032 8.62187 15.9078 8.62654 15.9113 8.63196Z"
          fill="white"
        />
        <path
          d="M16.1153 8.97943C16.1213 8.99087 16.1225 9.0042 16.1187 9.01655C16.115 9.0289 16.1065 9.03927 16.0951 9.04543C16.0899 9.04915 16.0839 9.05175 16.0776 9.05305C16.0713 9.05435 16.0647 9.05433 16.0584 9.05298C16.0521 9.05163 16.0461 9.04899 16.0409 9.04522C16.0357 9.04145 16.0313 9.03664 16.028 9.03108C16.0247 9.02553 16.0226 9.01935 16.0218 9.01294C16.021 9.00654 16.0216 9.00004 16.0235 8.99386C16.0253 8.98768 16.0285 8.98195 16.0326 8.97704C16.0368 8.97213 16.042 8.96814 16.0478 8.96532C16.0586 8.95826 16.0718 8.95579 16.0845 8.95843C16.0971 8.96107 16.1082 8.96862 16.1153 8.97943Z"
          fill="white"
        />
        <path
          d="M16.3215 9.32707C16.3276 9.33859 16.3289 9.35198 16.3253 9.36446C16.3217 9.37694 16.3134 9.38754 16.3021 9.39404C16.2909 9.40055 16.2775 9.40245 16.2649 9.39936C16.2523 9.39627 16.2413 9.38842 16.2344 9.37745C16.2313 9.37189 16.2294 9.36578 16.2288 9.35948C16.2281 9.35318 16.2287 9.34681 16.2305 9.34073C16.2323 9.33466 16.2353 9.32901 16.2393 9.3241C16.2433 9.31919 16.2483 9.31512 16.2539 9.31213C16.2648 9.30523 16.2781 9.30293 16.2908 9.30573C16.3034 9.30852 16.3145 9.31619 16.3215 9.32707Z"
          fill="white"
        />
        <path
          d="M16.5292 9.67378C16.5321 9.67941 16.5339 9.68557 16.5345 9.6919C16.535 9.69823 16.5343 9.7046 16.5324 9.71066C16.5305 9.71671 16.5274 9.72233 16.5233 9.72718C16.5192 9.73204 16.5142 9.73603 16.5085 9.73894C16.4976 9.7458 16.4844 9.74805 16.4718 9.74522C16.4593 9.74239 16.4483 9.7347 16.4414 9.72383C16.4383 9.71829 16.4364 9.7122 16.4358 9.70591C16.4351 9.69963 16.4358 9.69328 16.4376 9.68724C16.4395 9.6812 16.4425 9.67559 16.4465 9.67074C16.4506 9.66589 16.4556 9.6619 16.4612 9.659C16.4722 9.65224 16.4855 9.65001 16.4982 9.65276C16.5108 9.65551 16.5219 9.66304 16.5292 9.67378Z"
          fill="white"
        />
        <path
          d="M16.7325 10.0192C16.7354 10.0248 16.7372 10.0309 16.7378 10.0372C16.7383 10.0435 16.7376 10.0499 16.7356 10.0559C16.7337 10.0619 16.7306 10.0675 16.7265 10.0723C16.7223 10.0771 16.7173 10.081 16.7116 10.0838C16.7007 10.0906 16.6875 10.0928 16.675 10.09C16.6624 10.0872 16.6515 10.0795 16.6445 10.0687C16.6414 10.0632 16.6396 10.0572 16.6389 10.0509C16.6383 10.0447 16.6389 10.0384 16.6407 10.0324C16.6426 10.0264 16.6456 10.0208 16.6497 10.016C16.6537 10.0112 16.6587 10.0073 16.6643 10.0044C16.6754 9.9976 16.6886 9.99534 16.7013 9.99809C16.714 10.0008 16.7252 10.0084 16.7325 10.0192Z"
          fill="white"
        />
        <path
          d="M16.9375 10.3627C16.9404 10.3683 16.9422 10.3745 16.9427 10.3808C16.9433 10.3871 16.9426 10.3934 16.9406 10.3994C16.9387 10.4054 16.9356 10.411 16.9315 10.4158C16.9273 10.4206 16.9223 10.4246 16.9167 10.4274C16.9057 10.4341 16.8925 10.4363 16.88 10.4334C16.8674 10.4306 16.8565 10.4229 16.8495 10.4121C16.8465 10.4066 16.8446 10.4005 16.844 10.3943C16.8434 10.388 16.844 10.3817 16.8459 10.3757C16.8477 10.3697 16.8508 10.3642 16.8548 10.3594C16.8589 10.3546 16.8639 10.3506 16.8695 10.3478C16.8806 10.3411 16.8938 10.3388 16.9065 10.3416C16.9192 10.3444 16.9303 10.352 16.9375 10.3627Z"
          fill="white"
        />
        <path
          d="M17.1337 10.7171C17.1392 10.7288 17.14 10.7422 17.1359 10.7545C17.1319 10.7668 17.1232 10.777 17.1118 10.7831C17.1064 10.7863 17.1003 10.7884 17.094 10.7892C17.0878 10.79 17.0814 10.7896 17.0753 10.7879C17.0692 10.7862 17.0635 10.7834 17.0585 10.7794C17.0535 10.7755 17.0494 10.7707 17.0463 10.7651C17.0405 10.7537 17.0394 10.7403 17.0433 10.7281C17.0473 10.7158 17.0559 10.7056 17.0673 10.6997C17.0728 10.6964 17.0789 10.6942 17.0852 10.6933C17.0915 10.6924 17.098 10.6928 17.1042 10.6945C17.1103 10.6961 17.1161 10.6989 17.1212 10.7028C17.1263 10.7067 17.1305 10.7116 17.1337 10.7171Z"
          fill="white"
        />
        <path
          d="M17.3213 11.0874C17.3268 11.0994 17.3274 11.1131 17.3232 11.1256C17.3189 11.1381 17.31 11.1485 17.2983 11.1546C17.2928 11.1576 17.2868 11.1594 17.2806 11.1599C17.2744 11.1605 17.2682 11.1598 17.2623 11.1578C17.2564 11.1558 17.2509 11.1527 17.2463 11.1486C17.2416 11.1444 17.2379 11.1394 17.2353 11.1338C17.2298 11.122 17.229 11.1087 17.2331 11.0964C17.2372 11.0841 17.2458 11.0738 17.2573 11.0678C17.2627 11.0647 17.2687 11.0627 17.275 11.062C17.2812 11.0614 17.2875 11.062 17.2935 11.0638C17.2995 11.0656 17.305 11.0687 17.3098 11.0727C17.3146 11.0768 17.3185 11.0818 17.3213 11.0874Z"
          fill="white"
        />
        <path
          d="M17.4886 11.471C17.4933 11.4831 17.4935 11.4965 17.489 11.5087C17.4845 11.5209 17.4757 11.531 17.4643 11.5371C17.4588 11.5399 17.4529 11.5415 17.4468 11.5418C17.4407 11.5421 17.4347 11.5411 17.429 11.5389C17.4233 11.5367 17.4182 11.5333 17.4139 11.5289C17.4097 11.5246 17.4064 11.5193 17.4043 11.5136C17.3994 11.5017 17.3991 11.4884 17.4034 11.4762C17.4078 11.464 17.4165 11.4539 17.4278 11.4478C17.4333 11.445 17.4393 11.4433 17.4454 11.4429C17.4515 11.4426 17.4577 11.4435 17.4634 11.4457C17.4692 11.4479 17.4744 11.4513 17.4787 11.4556C17.4831 11.46 17.4864 11.4652 17.4886 11.471Z"
          fill="white"
        />
        <path
          d="M17.6303 11.8645C17.6343 11.8769 17.6339 11.8903 17.6291 11.9023C17.6243 11.9144 17.6154 11.9244 17.604 11.9305C17.5987 11.9331 17.5928 11.9345 17.5869 11.9345C17.581 11.9346 17.5752 11.9333 17.5698 11.9309C17.5644 11.9284 17.5597 11.9248 17.5559 11.9202C17.5521 11.9157 17.5494 11.9104 17.5479 11.9047C17.5437 11.8925 17.544 11.8792 17.5486 11.8672C17.5533 11.8552 17.5621 11.8452 17.5734 11.839C17.5788 11.8364 17.5846 11.835 17.5906 11.8348C17.5965 11.8347 17.6024 11.8359 17.6079 11.8384C17.6133 11.8408 17.6182 11.8444 17.622 11.849C17.6259 11.8535 17.6288 11.8588 17.6303 11.8645Z"
          fill="white"
        />
        <path
          d="M17.7486 12.2659C17.7517 12.2786 17.7506 12.2919 17.7454 12.3038C17.7402 12.3157 17.7312 12.3256 17.7199 12.3319C17.7148 12.3345 17.7091 12.3358 17.7034 12.3358C17.6977 12.3357 17.6921 12.3343 17.687 12.3317C17.6819 12.329 17.6775 12.3252 17.6742 12.3206C17.6709 12.3159 17.6687 12.3106 17.6678 12.3049C17.6646 12.2923 17.6657 12.2791 17.6708 12.2671C17.6759 12.2552 17.6848 12.2453 17.696 12.2389C17.7012 12.2364 17.7069 12.2351 17.7126 12.2351C17.7184 12.2352 17.724 12.2366 17.7291 12.2392C17.7342 12.2418 17.7387 12.2456 17.7421 12.2502C17.7454 12.2549 17.7477 12.2603 17.7486 12.2659Z"
          fill="white"
        />
        <path
          d="M17.8343 12.6702C17.8362 12.6833 17.8341 12.6967 17.8284 12.7087C17.8227 12.7206 17.8135 12.7306 17.8021 12.7374C17.7803 12.7486 17.7591 12.7364 17.7547 12.7104C17.7526 12.6973 17.7545 12.6839 17.7602 12.6719C17.7658 12.6599 17.7749 12.6499 17.7863 12.6432C17.7911 12.6405 17.7966 12.639 17.8021 12.639C17.8077 12.639 17.8131 12.6405 17.8179 12.6432C17.8228 12.6459 17.8268 12.6498 17.8297 12.6545C17.8326 12.6593 17.8342 12.6647 17.8343 12.6702Z"
          fill="white"
        />
        <path
          d="M17.8837 13.0762C17.8841 13.0899 17.8809 13.1035 17.8746 13.1157C17.8682 13.1278 17.8589 13.1382 17.8474 13.1458C17.8256 13.1582 17.8066 13.1469 17.8048 13.1207C17.8041 13.1071 17.8071 13.0935 17.8133 13.0813C17.8196 13.0691 17.8289 13.0588 17.8404 13.0514C17.8446 13.0483 17.8497 13.0465 17.855 13.0462C17.8603 13.0459 17.8655 13.0471 17.8701 13.0498C17.8747 13.0524 17.8784 13.0563 17.8808 13.061C17.8832 13.0657 17.8842 13.071 17.8837 13.0762Z"
          fill="white"
        />
        <path
          d="M17.8936 13.4888C17.893 13.5038 17.8891 13.5186 17.8821 13.5319C17.8751 13.5453 17.8653 13.5569 17.8533 13.566C17.8314 13.5795 17.8141 13.566 17.8145 13.5376C17.815 13.5229 17.8189 13.5085 17.8259 13.4954C17.8328 13.4824 17.8427 13.4711 17.8546 13.4624C17.8768 13.4488 17.8944 13.4601 17.8936 13.4888Z"
          fill="white"
        />
        <path
          d="M17.8766 13.9957C17.8744 14.0313 17.8551 14.0711 17.8337 14.0839C17.8124 14.0967 17.7971 14.0775 17.799 14.0422C17.8008 14.007 17.8195 13.9685 17.841 13.9556C17.8625 13.9427 17.8779 13.9598 17.8766 13.9957Z"
          fill="white"
        />
        <path
          d="M17.824 14.5915C17.8193 14.6317 17.798 14.6745 17.7775 14.6863C17.757 14.698 17.7439 14.6745 17.7493 14.6345C17.7546 14.5946 17.7738 14.5532 17.7944 14.5413C17.8151 14.5294 17.8287 14.5514 17.824 14.5915Z"
          fill="white"
        />
        <path
          d="M17.7219 15.2437C17.7133 15.2863 17.6895 15.3301 17.6697 15.3411C17.6499 15.352 17.6413 15.3259 17.6497 15.2835C17.6581 15.241 17.6801 15.1981 17.7001 15.1871C17.7201 15.176 17.7303 15.2012 17.7219 15.2437Z"
          fill="white"
        />
        <path
          d="M17.5538 15.9176C17.5405 15.9606 17.5128 16.0042 17.493 16.0146C17.4732 16.025 17.469 15.9978 17.4823 15.9557C17.4955 15.9135 17.5209 15.8699 17.5405 15.8593C17.5602 15.8487 17.5669 15.8747 17.5538 15.9176Z"
          fill="white"
        />
        <path
          d="M17.3051 16.5788C17.2863 16.6197 17.2531 16.6627 17.2324 16.673C17.2117 16.6832 17.2119 16.659 17.2311 16.6179C17.2502 16.5767 17.2814 16.5348 17.3009 16.524C17.3204 16.5133 17.3239 16.5378 17.3051 16.5788Z"
          fill="white"
        />
        <path
          d="M11.8567 3.8176C11.8672 3.82681 11.8754 3.83837 11.8806 3.85134C11.8858 3.8643 11.8879 3.87831 11.8867 3.89224C11.8855 3.90616 11.8811 3.91961 11.8737 3.9315C11.8664 3.94339 11.8564 3.95338 11.8444 3.96068C11.7941 3.99427 11.7257 3.98251 11.6782 3.94153C11.6307 3.90056 11.6226 3.84598 11.6728 3.81088C11.723 3.77578 11.8098 3.77561 11.8567 3.8176Z"
          fill="white"
        />
        <path
          d="M12.2581 3.54994C12.3022 3.59411 12.2969 3.65826 12.2413 3.69521C12.1857 3.73216 12.1007 3.72762 12.0552 3.68446C12.0097 3.6413 12.0216 3.5753 12.0789 3.53735C12.1059 3.51917 12.1382 3.51053 12.1706 3.51281C12.2031 3.51509 12.2339 3.52816 12.2581 3.54994Z"
          fill="white"
        />
        <path
          d="M13.0193 3.16724C13.0613 3.20838 13.0529 3.25994 12.9976 3.28362C12.9679 3.29481 12.9357 3.29759 12.9045 3.29167C12.8733 3.28574 12.8443 3.27134 12.8208 3.25003C12.7783 3.20754 12.7894 3.15447 12.8448 3.13247C12.8743 3.12207 12.9061 3.1198 12.9368 3.12592C12.9675 3.13204 12.996 3.14631 13.0193 3.16724Z"
          fill="white"
        />
        <path
          d="M14.1225 3.19269C14.1582 3.23216 14.1475 3.28035 14.0949 3.30051C14.0668 3.3099 14.0366 3.31123 14.0078 3.30435C13.979 3.29746 13.9527 3.28263 13.9319 3.26155C13.8954 3.2199 13.9112 3.1717 13.9644 3.15373C13.9918 3.1453 14.021 3.14447 14.0488 3.15133C14.0767 3.15819 14.1021 3.17249 14.1225 3.19269Z"
          fill="white"
        />
        <path
          d="M14.3707 3.4947C14.3789 3.50407 14.3846 3.51526 14.3875 3.52731C14.3904 3.53936 14.3904 3.55193 14.3874 3.56397C14.3844 3.57601 14.3786 3.58716 14.3705 3.5965C14.3623 3.60583 14.3521 3.61308 14.3405 3.61763C14.3137 3.62909 14.284 3.63153 14.2557 3.6246C14.2274 3.61766 14.2021 3.60172 14.1837 3.57917C14.1757 3.56942 14.1702 3.55789 14.1676 3.54556C14.1651 3.53323 14.1655 3.52047 14.169 3.50835C14.1724 3.49623 14.1787 3.48513 14.1873 3.47596C14.196 3.4668 14.2067 3.45986 14.2186 3.45573C14.2448 3.44521 14.2737 3.44331 14.301 3.45032C14.3284 3.45733 14.3528 3.47287 14.3707 3.4947Z"
          fill="white"
        />
        <path
          d="M14.6083 3.82174C14.6158 3.8324 14.621 3.84457 14.6234 3.85742C14.6258 3.87027 14.6254 3.88349 14.6222 3.89617C14.6191 3.90884 14.6131 3.92068 14.6049 3.93085C14.5967 3.94102 14.5864 3.94929 14.5747 3.95509C14.5494 3.96849 14.5201 3.97222 14.4923 3.96557C14.4645 3.95892 14.44 3.94236 14.4235 3.91898C14.4162 3.90793 14.4114 3.89543 14.4093 3.88233C14.4073 3.86924 14.4081 3.85585 14.4117 3.84309C14.4153 3.83033 14.4216 3.8185 14.4302 3.80841C14.4388 3.79832 14.4495 3.79021 14.4615 3.78463C14.4864 3.77226 14.5148 3.7692 14.5418 3.77601C14.5687 3.78282 14.5923 3.79904 14.6083 3.82174Z"
          fill="white"
        />
        <path
          d="M15.032 4.50473C15.0388 4.51624 15.0432 4.52901 15.0449 4.54227C15.0467 4.55553 15.0457 4.56901 15.0421 4.58188C15.0384 4.59475 15.0322 4.60675 15.0238 4.61716C15.0154 4.62757 15.005 4.63617 14.9932 4.64244C14.9428 4.67166 14.8757 4.65772 14.8469 4.61053C14.8182 4.56334 14.8392 4.5007 14.8906 4.47147C14.9138 4.45728 14.9417 4.45282 14.9681 4.45904C14.9946 4.46527 15.0176 4.48168 15.032 4.50473Z"
          fill="white"
        />
        <path
          d="M13.9678 3.67576C14.0024 3.72346 13.9846 3.78375 13.9289 3.81011C13.9008 3.82196 13.8696 3.82401 13.8403 3.81593C13.8109 3.80786 13.7852 3.79013 13.7671 3.76561C13.7319 3.71523 13.7465 3.6578 13.8024 3.63395C13.8309 3.62245 13.8622 3.6203 13.892 3.62782C13.9217 3.63534 13.9483 3.65213 13.9678 3.67576Z"
          fill="white"
        />
        <path
          d="M14.2091 4.02831C14.2165 4.04013 14.2212 4.05337 14.2231 4.06715C14.2249 4.08093 14.2238 4.09495 14.2199 4.10828C14.2159 4.12161 14.2091 4.13395 14.2001 4.14448C14.191 4.15501 14.1798 4.1635 14.1672 4.16938C14.1411 4.18307 14.1108 4.18654 14.0823 4.17912C14.0538 4.1717 14.0291 4.15391 14.013 4.12924C14.0051 4.11786 13.9998 4.10489 13.9975 4.09124C13.9951 4.0776 13.9958 4.06361 13.9994 4.05024C14.003 4.03687 14.0095 4.02446 14.0184 4.01385C14.0273 4.00325 14.0384 3.99472 14.0509 3.98885C14.0777 3.97564 14.1083 3.97235 14.1373 3.97958C14.1662 3.98681 14.1917 4.00408 14.2091 4.02831Z"
          fill="white"
        />
        <path
          d="M13.3318 5.04462C13.3386 5.05694 13.3429 5.0705 13.3445 5.08451C13.3461 5.09853 13.3448 5.11271 13.3408 5.12623C13.3368 5.13976 13.3302 5.15235 13.3213 5.16328C13.3124 5.17421 13.3014 5.18325 13.2889 5.18988C13.2355 5.22112 13.1677 5.20802 13.1378 5.15814C13.1242 5.13229 13.1213 5.10215 13.1297 5.07418C13.1381 5.04621 13.1571 5.02264 13.1826 5.00851C13.1948 5.00098 13.2083 4.99593 13.2224 4.99367C13.2365 4.99141 13.2509 4.99197 13.2648 4.99533C13.2786 4.99869 13.2917 5.00478 13.3032 5.01324C13.3147 5.0217 13.3244 5.03237 13.3318 5.04462Z"
          fill="white"
        />
        <path
          d="M12.9262 5.28388C12.9329 5.29606 12.9372 5.30945 12.9387 5.32328C12.9403 5.33711 12.9391 5.35111 12.9352 5.36448C12.9313 5.37785 12.9249 5.39033 12.9162 5.40119C12.9075 5.41206 12.8967 5.4211 12.8845 5.4278C12.8729 5.43504 12.86 5.43988 12.8465 5.44203C12.833 5.44418 12.8192 5.4436 12.806 5.44033C12.7927 5.43706 12.7802 5.43115 12.7693 5.42297C12.7583 5.41479 12.7492 5.4045 12.7423 5.3927C12.735 5.38107 12.7301 5.3681 12.7279 5.35455C12.7257 5.341 12.7262 5.32715 12.7294 5.31381C12.7327 5.30046 12.7385 5.2879 12.7467 5.27685C12.7548 5.2658 12.7651 5.25648 12.7769 5.24945C12.8014 5.23478 12.8306 5.23017 12.8584 5.23659C12.8863 5.24301 12.9105 5.25996 12.9262 5.28388Z"
          fill="white"
        />
        <path
          d="M13.7446 5.73327C13.7731 5.78029 13.7548 5.84276 13.7014 5.87366C13.648 5.90456 13.5803 5.89247 13.5518 5.84562C13.5232 5.79876 13.5445 5.73478 13.5981 5.70354C13.6517 5.67231 13.7157 5.68658 13.7446 5.73327Z"
          fill="white"
        />
        <path
          d="M14.6901 5.61324C14.7037 5.63718 14.7072 5.66553 14.6998 5.69204C14.6925 5.71856 14.6749 5.74107 14.651 5.75464C14.6006 5.78672 14.5334 5.77597 14.5052 5.72895C14.477 5.68193 14.4962 5.61677 14.5474 5.58469C14.5585 5.5769 14.5711 5.57145 14.5845 5.56866C14.5978 5.56588 14.6115 5.56582 14.6249 5.56849C14.6382 5.57115 14.6509 5.57649 14.6621 5.58419C14.6733 5.59188 14.6828 5.60176 14.6901 5.61324Z"
          fill="white"
        />
        <path
          d="M14.8992 5.95294C14.9059 5.96478 14.9102 5.97783 14.9118 5.99134C14.9135 6.00484 14.9125 6.01855 14.9088 6.03166C14.9052 6.04478 14.899 6.05705 14.8907 6.06778C14.8823 6.0785 14.8719 6.08747 14.86 6.09418C14.8096 6.12625 14.7425 6.1155 14.7143 6.06865C14.686 6.02179 14.705 5.95647 14.7562 5.92439C14.7674 5.91669 14.7801 5.91131 14.7934 5.90857C14.8067 5.90584 14.8204 5.9058 14.8337 5.90846C14.8471 5.91112 14.8597 5.91643 14.8709 5.92407C14.8822 5.93171 14.8918 5.94153 14.8992 5.95294Z"
          fill="white"
        />
        <path
          d="M13.9776 6.97308C14.0064 7.0196 13.9857 7.08207 13.934 7.11179C13.9108 7.12623 13.8828 7.13103 13.8561 7.12517C13.8294 7.1193 13.8061 7.10323 13.7911 7.08039C13.7843 7.06891 13.7799 7.05617 13.7782 7.04294C13.7764 7.02971 13.7775 7.01627 13.7811 7.00345C13.7848 6.99062 13.791 6.97867 13.7995 6.96834C13.8079 6.95801 13.8183 6.94951 13.8302 6.94335C13.8817 6.91363 13.9489 6.92639 13.9776 6.97308Z"
          fill="white"
        />
        <path
          d="M16.055 6.21345C16.0618 6.22495 16.0662 6.23773 16.0679 6.251C16.0696 6.26427 16.0686 6.27775 16.0649 6.29061C16.0612 6.30347 16.055 6.31545 16.0465 6.3258C16.038 6.33615 16.0275 6.34467 16.0157 6.35082C15.9639 6.38021 15.8969 6.36644 15.8684 6.31942C15.8398 6.2724 15.8605 6.21009 15.9122 6.18087C15.9355 6.16655 15.9635 6.16194 15.9902 6.16803C16.0169 6.17412 16.0402 6.19043 16.055 6.21345Z"
          fill="white"
        />
        <path
          d="M16.2598 6.554C16.2666 6.56556 16.2709 6.57838 16.2726 6.59169C16.2743 6.605 16.2733 6.61851 16.2696 6.63141C16.2659 6.6443 16.2596 6.65632 16.2512 6.66671C16.2427 6.67711 16.2322 6.68567 16.2203 6.69188C16.1687 6.7211 16.1017 6.70716 16.0733 6.66014C16.045 6.61311 16.0656 6.55047 16.1173 6.52125C16.1406 6.50696 16.1686 6.50239 16.1952 6.50851C16.2219 6.51464 16.245 6.53097 16.2598 6.554Z"
          fill="white"
        />
        <path
          d="M16.4672 6.89596C16.474 6.90757 16.4783 6.92042 16.4799 6.93374C16.4816 6.94707 16.4805 6.96059 16.4768 6.97349C16.4731 6.98639 16.4668 6.9984 16.4583 7.0088C16.4498 7.0192 16.4393 7.02778 16.4274 7.03401C16.3757 7.0634 16.3099 7.04929 16.2806 7.00193C16.2514 6.95457 16.2734 6.8921 16.325 6.86271C16.3483 6.84843 16.3763 6.84392 16.4029 6.85014C16.4295 6.85637 16.4526 6.87283 16.4672 6.89596Z"
          fill="white"
        />
        <path
          d="M16.0843 7.11026C16.1125 7.15746 16.0909 7.22043 16.0375 7.25016C16.0137 7.26476 15.9851 7.26954 15.9579 7.26349C15.9306 7.25745 15.9068 7.24104 15.8914 7.21775C15.8848 7.20582 15.8807 7.1927 15.8794 7.17916C15.8781 7.16563 15.8795 7.15196 15.8835 7.13898C15.8876 7.126 15.8943 7.11398 15.9031 7.10365C15.912 7.09332 15.9228 7.08489 15.935 7.07886C15.9886 7.04947 16.056 7.06341 16.0843 7.11026Z"
          fill="white"
        />
        <path
          d="M14.7816 7.43238C14.7882 7.44429 14.7922 7.4574 14.7936 7.47093C14.795 7.48446 14.7936 7.49813 14.7895 7.51111C14.7854 7.52408 14.7788 7.5361 14.7699 7.54644C14.7611 7.55678 14.7503 7.56522 14.7381 7.57126C14.6847 7.60116 14.6172 7.58806 14.5888 7.54053C14.5604 7.493 14.5816 7.4307 14.6352 7.40081C14.6589 7.38611 14.6874 7.38118 14.7147 7.38707C14.742 7.39296 14.766 7.4092 14.7816 7.43238Z"
          fill="white"
        />
        <path
          d="M14.3916 7.6497C14.42 7.69672 14.3991 7.75919 14.3476 7.78858C14.3243 7.80285 14.2963 7.80743 14.2696 7.80135C14.243 7.79526 14.2198 7.77898 14.205 7.756C14.1982 7.74448 14.1939 7.73169 14.1922 7.71843C14.1905 7.70516 14.1915 7.6917 14.1952 7.67885C14.1989 7.666 14.2052 7.65403 14.2137 7.64368C14.2221 7.63333 14.2326 7.62481 14.2445 7.61863C14.296 7.58924 14.363 7.60284 14.3916 7.6497Z"
          fill="white"
        />
        <path
          d="M16.2877 7.45494C16.3161 7.50263 16.2947 7.56611 16.2413 7.59617C16.2174 7.6108 16.1887 7.61548 16.1613 7.6092C16.134 7.60293 16.1102 7.58619 16.095 7.56259C16.0883 7.55063 16.0841 7.53741 16.0826 7.52375C16.0811 7.5101 16.0825 7.49628 16.0866 7.48317C16.0906 7.47005 16.0974 7.45791 16.1063 7.44748C16.1152 7.43705 16.1262 7.42856 16.1385 7.42253C16.1919 7.39314 16.2593 7.40724 16.2877 7.45494Z"
          fill="white"
        />
        <path
          d="M16.4939 7.8018C16.5223 7.84983 16.5013 7.91398 16.4481 7.94438C16.4242 7.95922 16.3955 7.96402 16.3681 7.95773C16.3407 7.95143 16.317 7.93456 16.302 7.91079C16.2953 7.89875 16.2911 7.88549 16.2896 7.87179C16.2882 7.85808 16.2895 7.84423 16.2935 7.83105C16.2975 7.81787 16.3041 7.80563 16.313 7.79507C16.3218 7.7845 16.3327 7.77583 16.345 7.76956C16.3991 7.73967 16.4662 7.75377 16.4939 7.8018Z"
          fill="white"
        />
        <path
          d="M16.1253 8.01708C16.1389 8.0415 16.1423 8.07029 16.1348 8.0972C16.1273 8.12412 16.1095 8.14698 16.0852 8.16083C16.0335 8.19257 15.9676 8.18032 15.9389 8.13228C15.9102 8.08425 15.9304 8.01826 15.9819 7.98618C15.9933 7.9785 16.0061 7.9732 16.0195 7.9706C16.033 7.968 16.0468 7.96814 16.0602 7.97103C16.0736 7.97392 16.0863 7.97948 16.0975 7.9874C16.1087 7.99531 16.1181 8.00541 16.1253 8.01708Z"
          fill="white"
        />
        <path
          d="M15.1937 8.11782C15.2003 8.12978 15.2045 8.14297 15.2059 8.15659C15.2072 8.17021 15.2059 8.18396 15.2017 8.19702C15.1976 8.21008 15.1909 8.22217 15.182 8.23255C15.1731 8.24292 15.1621 8.25137 15.1498 8.25738C15.0964 8.28727 15.0289 8.27316 15.0007 8.22564C14.9725 8.17811 14.9941 8.11513 15.0476 8.08524C15.0714 8.07061 15.0999 8.06583 15.1272 8.07191C15.1545 8.07799 15.1783 8.09446 15.1937 8.11782Z"
          fill="white"
        />
        <path
          d="M15.3966 8.46253C15.4032 8.47454 15.4074 8.48777 15.4088 8.50142C15.4101 8.51507 15.4088 8.52886 15.4047 8.54195C15.4006 8.55505 15.3939 8.56719 15.385 8.57764C15.3761 8.58809 15.3652 8.59663 15.353 8.60276C15.2995 8.63282 15.232 8.61871 15.2038 8.57102C15.1756 8.52332 15.1973 8.45984 15.2507 8.42978C15.2745 8.41509 15.303 8.41029 15.3303 8.41641C15.3576 8.42253 15.3814 8.43909 15.3966 8.46253Z"
          fill="white"
        />
        <path
          d="M15.0066 8.68078C15.035 8.72864 15.014 8.79213 14.9623 8.82151C14.9507 8.82865 14.9379 8.83345 14.9245 8.83562C14.9111 8.8378 14.8975 8.83731 14.8843 8.83419C14.8711 8.83107 14.8586 8.82538 14.8476 8.81744C14.8366 8.8095 14.8273 8.79947 14.8202 8.78793C14.8134 8.77622 14.8091 8.76327 14.8074 8.74984C14.8057 8.73642 14.8068 8.72279 14.8105 8.70979C14.8142 8.69678 14.8205 8.68465 14.829 8.67413C14.8375 8.66361 14.8481 8.6549 14.86 8.64854C14.9117 8.61848 14.9784 8.63275 15.0066 8.68078Z"
          fill="white"
        />
        <path
          d="M15.6017 8.8083C15.6083 8.82035 15.6124 8.83362 15.6138 8.8473C15.6152 8.86098 15.6138 8.8748 15.6097 8.88793C15.6056 8.90106 15.599 8.91324 15.5901 8.92374C15.5812 8.93423 15.5703 8.94284 15.558 8.94903C15.5046 8.97926 15.4371 8.96583 15.4089 8.91763C15.3807 8.86943 15.4023 8.80595 15.4558 8.77572C15.4795 8.76088 15.5081 8.75598 15.5354 8.76208C15.5627 8.76818 15.5865 8.78478 15.6017 8.8083Z"
          fill="white"
        />
        <path
          d="M15.21 9.02845C15.2383 9.07648 15.2173 9.14013 15.1656 9.16985C15.1541 9.17702 15.1413 9.18183 15.1279 9.18402C15.1145 9.1862 15.1008 9.18572 15.0876 9.1826C15.0744 9.17948 15.0619 9.17378 15.0509 9.16583C15.04 9.15788 15.0306 9.14783 15.0235 9.13627C15.0167 9.12454 15.0123 9.11154 15.0106 9.09806C15.0089 9.08459 15.0099 9.0709 15.0136 9.05783C15.0173 9.04476 15.0237 9.03258 15.0322 9.02202C15.0407 9.01146 15.0513 9.00274 15.0633 8.99637C15.0872 8.98163 15.1158 8.97673 15.1431 8.98272C15.1705 8.9887 15.1945 9.00511 15.21 9.02845Z"
          fill="white"
        />
        <path
          d="M17.0947 7.92152C17.1015 7.93316 17.106 7.94605 17.1077 7.95942C17.1094 7.9728 17.1085 7.98639 17.1049 7.99939C17.1013 8.01239 17.0951 8.02453 17.0867 8.0351C17.0783 8.04567 17.0679 8.05444 17.0561 8.06091C17.0325 8.07567 17.0041 8.08061 16.9769 8.07468C16.9497 8.06875 16.9259 8.05241 16.9107 8.02917C16.8977 8.00468 16.8949 7.97604 16.903 7.94952C16.911 7.923 16.9292 7.90074 16.9536 7.8876C16.9651 7.8805 16.9779 7.87574 16.9912 7.8736C17.0045 7.87147 17.0181 7.87199 17.0313 7.87514C17.0444 7.8783 17.0567 7.88402 17.0676 7.89198C17.0785 7.89994 17.0877 7.90998 17.0947 7.92152Z"
          fill="white"
        />
        <path
          d="M16.7166 8.142C16.7451 8.1902 16.7243 8.25485 16.6711 8.28592C16.6593 8.29341 16.6462 8.29851 16.6325 8.30093C16.6188 8.30334 16.6047 8.30303 16.5911 8.3C16.5775 8.29698 16.5646 8.2913 16.5532 8.28329C16.5418 8.27528 16.5321 8.26511 16.5246 8.25334C16.5179 8.24118 16.5137 8.22781 16.5123 8.214C16.5108 8.2002 16.5122 8.18625 16.5162 8.17296C16.5202 8.15968 16.5268 8.14733 16.5357 8.13665C16.5446 8.12597 16.5555 8.11717 16.5678 8.11076C16.621 8.08003 16.6882 8.09363 16.7166 8.142Z"
          fill="white"
        />
        <path
          d="M16.9211 8.49173C16.9497 8.53993 16.929 8.60525 16.8763 8.63683C16.8647 8.64467 16.8517 8.65006 16.838 8.65267C16.8243 8.65528 16.8102 8.65506 16.7966 8.65201C16.783 8.64896 16.7701 8.64315 16.7588 8.63494C16.7476 8.62673 16.7381 8.6163 16.731 8.60428C16.7239 8.59227 16.7193 8.57893 16.7176 8.56508C16.7159 8.55123 16.717 8.53718 16.7209 8.52378C16.7248 8.51039 16.7314 8.49794 16.7403 8.48719C16.7492 8.47645 16.7602 8.46765 16.7727 8.46133C16.8257 8.4301 16.8926 8.44336 16.9211 8.49173Z"
          fill="white"
        />
        <path
          d="M16.5518 8.71093C16.5586 8.72291 16.5629 8.73612 16.5646 8.74979C16.5662 8.76346 16.5652 8.77733 16.5614 8.79058C16.5577 8.80384 16.5514 8.81622 16.5428 8.82703C16.5343 8.83783 16.5237 8.84683 16.5117 8.85351C16.4598 8.88407 16.3931 8.8703 16.3645 8.82227C16.336 8.77424 16.3563 8.70858 16.408 8.67768C16.4196 8.67026 16.4326 8.66523 16.4461 8.66289C16.4596 8.66054 16.4735 8.66093 16.4869 8.66403C16.5003 8.66713 16.513 8.67287 16.5241 8.68093C16.5352 8.68898 16.5447 8.69918 16.5518 8.71093Z"
          fill="white"
        />
        <path
          d="M17.1124 8.85048C17.141 8.89868 17.1207 8.96434 17.0683 8.99625C17.0568 9.00398 17.0439 9.00933 17.0303 9.01196C17.0167 9.0146 17.0028 9.01447 16.9892 9.01158C16.9757 9.0087 16.9629 9.00312 16.9516 8.99517C16.9402 8.98723 16.9306 8.97709 16.9233 8.96535C16.9098 8.94069 16.9065 8.91165 16.9143 8.88461C16.9221 8.85757 16.9404 8.83472 16.965 8.82109C17.0174 8.78969 17.084 8.80211 17.1124 8.85048Z"
          fill="white"
        />
        <path
          d="M17.4915 8.62176C17.4983 8.63355 17.5026 8.64656 17.5043 8.66003C17.506 8.6735 17.505 8.68718 17.5014 8.70027C17.4978 8.71336 17.4917 8.72561 17.4833 8.73632C17.4749 8.74703 17.4646 8.75598 17.4527 8.76266C17.4024 8.79373 17.336 8.78097 17.3078 8.7331C17.2796 8.68524 17.2994 8.62025 17.3505 8.58952C17.3618 8.58216 17.3745 8.57716 17.3878 8.5748C17.401 8.57243 17.4147 8.57277 17.4278 8.57578C17.441 8.57878 17.4534 8.5844 17.4644 8.5923C17.4753 8.6002 17.4845 8.61022 17.4915 8.62176Z"
          fill="white"
        />
        <path
          d="M16.745 9.0712C16.7517 9.08318 16.756 9.09639 16.7576 9.11007C16.7592 9.12374 16.7581 9.13759 16.7543 9.15081C16.7504 9.16404 16.744 9.17636 16.7353 9.18705C16.7267 9.19775 16.7159 9.20661 16.7038 9.21311C16.6514 9.2435 16.5844 9.2299 16.5559 9.1812C16.5273 9.1325 16.548 9.06834 16.6004 9.03761C16.612 9.03028 16.6251 9.02533 16.6387 9.02305C16.6523 9.02077 16.6662 9.0212 16.6796 9.02432C16.6931 9.02744 16.7058 9.03319 16.717 9.04124C16.7282 9.04928 16.7377 9.05946 16.745 9.0712Z"
          fill="white"
        />
        <path
          d="M16.9527 9.42192C16.9595 9.43385 16.9637 9.447 16.9653 9.46061C16.9668 9.47421 16.9656 9.48799 16.9618 9.50112C16.9579 9.51425 16.9514 9.52646 16.9427 9.53703C16.934 9.5476 16.9232 9.55632 16.9111 9.56266C16.8585 9.59272 16.7914 9.57945 16.7628 9.53041C16.7343 9.48137 16.7552 9.41823 16.8078 9.38817C16.8315 9.37345 16.8601 9.36873 16.8873 9.37506C16.9144 9.38139 16.938 9.39824 16.9527 9.42192Z"
          fill="white"
        />
        <path
          d="M16.0099 9.50095C16.0165 9.51308 16.0206 9.5264 16.0219 9.54013C16.0233 9.55386 16.0219 9.56773 16.0178 9.58092C16.0138 9.59411 16.0071 9.60635 15.9983 9.61693C15.9894 9.62751 15.9785 9.63621 15.9662 9.64252C15.9127 9.67342 15.8453 9.65931 15.8171 9.61212C15.7889 9.56493 15.8106 9.49994 15.864 9.46921C15.8875 9.45412 15.9161 9.44898 15.9435 9.45493C15.9708 9.46088 15.9947 9.47743 16.0099 9.50095Z"
          fill="white"
        />
        <path
          d="M15.8048 9.15462C15.8114 9.16672 15.8155 9.18002 15.8168 9.19373C15.8182 9.20744 15.8168 9.22128 15.8127 9.23444C15.8087 9.2476 15.802 9.25981 15.7932 9.27036C15.7843 9.28091 15.7734 9.28958 15.7612 9.29586C15.7077 9.32642 15.6402 9.31265 15.612 9.26479C15.5838 9.21693 15.6055 9.15294 15.6589 9.12238C15.6825 9.10742 15.7112 9.10242 15.7385 9.10846C15.7658 9.1145 15.7897 9.13109 15.8048 9.15462Z"
          fill="white"
        />
        <path
          d="M15.6184 9.72517C15.6466 9.7732 15.6256 9.83752 15.5741 9.86759C15.5626 9.8743 15.55 9.8787 15.5368 9.88052C15.5237 9.88234 15.5103 9.88156 15.4975 9.87822C15.4846 9.87487 15.4726 9.86903 15.462 9.86103C15.4514 9.85302 15.4425 9.84301 15.4358 9.83156C15.4291 9.82012 15.4247 9.80746 15.4228 9.79432C15.421 9.78117 15.4218 9.7678 15.4251 9.75495C15.4285 9.74211 15.4343 9.73005 15.4423 9.71947C15.4503 9.70889 15.4603 9.69998 15.4718 9.69327C15.5242 9.66304 15.5902 9.67714 15.6184 9.72517Z"
          fill="white"
        />
        <path
          d="M16.4279 10.2046C16.4561 10.2522 16.4375 10.315 16.3841 10.3462C16.3307 10.3775 16.2633 10.3649 16.2351 10.3173C16.2069 10.2698 16.2284 10.2053 16.2818 10.1741C16.3352 10.1428 16.3999 10.1571 16.4279 10.2046Z"
          fill="white"
        />
        <path
          d="M17.9194 9.3172C17.9329 9.34088 17.9365 9.36894 17.9295 9.39527C17.9224 9.42161 17.9052 9.44407 17.8816 9.45777C17.8312 9.48901 17.7661 9.47725 17.7377 9.42972C17.7093 9.3822 17.7289 9.31687 17.7793 9.28563C17.7905 9.27825 17.8031 9.2732 17.8163 9.27079C17.8295 9.26838 17.843 9.26865 17.8561 9.2716C17.8692 9.27455 17.8815 9.28011 17.8924 9.28795C17.9033 9.29578 17.9124 9.30573 17.9194 9.3172Z"
          fill="white"
        />
        <path
          d="M16.0365 10.4322C16.0647 10.4799 16.0437 10.5441 15.992 10.5745C15.9806 10.5827 15.9677 10.5885 15.954 10.5914C15.9403 10.5944 15.9261 10.5944 15.9124 10.5916C15.8986 10.5887 15.8856 10.5831 15.8742 10.5749C15.8628 10.5668 15.8532 10.5563 15.8461 10.5443C15.839 10.5322 15.8344 10.5188 15.8327 10.5049C15.8311 10.4909 15.8323 10.4768 15.8364 10.4634C15.8405 10.45 15.8474 10.4376 15.8566 10.427C15.8657 10.4164 15.877 10.4078 15.8897 10.4018C15.9414 10.3714 16.0082 10.385 16.0365 10.4322Z"
          fill="white"
        />
        <path
          d="M18.3148 10.0156C18.3272 10.0418 18.3296 10.0716 18.3216 10.0994C18.3135 10.1273 18.2955 10.1512 18.271 10.1667C18.2595 10.1741 18.2465 10.1789 18.2329 10.1808C18.2193 10.1828 18.2055 10.1818 18.1924 10.178C18.1792 10.1742 18.167 10.1676 18.1566 10.1587C18.1462 10.1498 18.1378 10.1388 18.132 10.1264C18.1198 10.0998 18.1178 10.0697 18.1262 10.0417C18.1345 10.0137 18.1527 9.98969 18.1775 9.97409C18.1889 9.96676 18.2019 9.96199 18.2154 9.96012C18.2289 9.95825 18.2426 9.95933 18.2557 9.96326C18.2687 9.9672 18.2808 9.97392 18.291 9.98295C18.3012 9.99198 18.3093 10.0031 18.3148 10.0156Z"
          fill="white"
        />
        <path
          d="M17.941 10.2452C17.953 10.2723 17.9547 10.3028 17.9459 10.3311C17.9371 10.3594 17.9184 10.3836 17.8932 10.3992C17.8818 10.4067 17.8689 10.4118 17.8554 10.414C17.8419 10.4163 17.8281 10.4156 17.8148 10.412C17.8016 10.4084 17.7893 10.4021 17.7788 10.3934C17.7682 10.3847 17.7596 10.3738 17.7536 10.3615C17.7411 10.3352 17.7388 10.3052 17.7471 10.2772C17.7555 10.2493 17.774 10.2255 17.7989 10.2104C17.8508 10.1781 17.915 10.1928 17.941 10.2452Z"
          fill="white"
        />
        <path
          d="M18.4926 10.4161C18.5038 10.4439 18.5048 10.4748 18.4956 10.5033C18.4864 10.5318 18.4675 10.5562 18.4423 10.5723C18.4305 10.5794 18.4174 10.5838 18.4038 10.5851C18.3902 10.5865 18.3764 10.5848 18.3635 10.5801C18.3507 10.5755 18.339 10.568 18.3294 10.5583C18.3197 10.5486 18.3124 10.5368 18.3079 10.5239C18.2969 10.4959 18.2959 10.465 18.3051 10.4364C18.3142 10.4078 18.333 10.3832 18.3583 10.3669C18.4097 10.3367 18.4693 10.359 18.4926 10.4161Z"
          fill="white"
        />
        <path
          d="M18.1195 10.637C18.1303 10.6651 18.1311 10.6961 18.1215 10.7247C18.112 10.7532 18.0928 10.7776 18.0673 10.7936C18.0558 10.8007 18.0429 10.8053 18.0294 10.8068C18.0159 10.8084 18.0023 10.807 17.9895 10.8027C17.9766 10.7983 17.9649 10.7912 17.9551 10.7819C17.9454 10.7725 17.9378 10.761 17.9329 10.7484C17.9219 10.7209 17.9209 10.6903 17.9301 10.6621C17.9393 10.6339 17.9581 10.6099 17.9833 10.5942C17.9947 10.5867 18.0077 10.5819 18.0212 10.58C18.0348 10.5781 18.0485 10.5792 18.0616 10.5833C18.0746 10.5874 18.0866 10.5944 18.0966 10.6037C18.1066 10.613 18.1144 10.6244 18.1195 10.637Z"
          fill="white"
        />
        <path
          d="M17.0107 11.2361C17.0165 11.2481 17.0198 11.2611 17.0204 11.2744C17.0209 11.2876 17.0187 11.3009 17.0138 11.3133C17.0089 11.3256 17.0016 11.3369 16.9921 11.3462C16.9827 11.3556 16.9714 11.3629 16.959 11.3676C16.9051 11.391 16.8414 11.3718 16.8164 11.3248C16.7914 11.2778 16.8164 11.2183 16.8698 11.1926C16.8943 11.1799 16.9228 11.1773 16.9492 11.1855C16.9755 11.1936 16.9976 11.2118 17.0107 11.2361Z"
          fill="white"
        />
        <path
          d="M16.6168 11.4153C16.6412 11.4598 16.6168 11.5151 16.5639 11.5376C16.511 11.5601 16.4524 11.5431 16.4282 11.5013C16.404 11.4595 16.4237 11.4064 16.4759 11.3812C16.5281 11.356 16.5916 11.371 16.6168 11.4153Z"
          fill="white"
        />
        <path
          d="M18.7764 11.2657C18.7836 11.2952 18.7812 11.3261 18.7695 11.3541C18.7579 11.382 18.7376 11.4055 18.7116 11.4211C18.6612 11.4478 18.6082 11.4223 18.5924 11.3638C18.5848 11.3345 18.5868 11.3036 18.5981 11.2755C18.6094 11.2474 18.6293 11.2236 18.655 11.2076C18.7059 11.1803 18.7608 11.2065 18.7764 11.2657Z"
          fill="white"
        />
        <path
          d="M18.1413 12.0671C18.1475 12.0946 18.1446 12.1233 18.1331 12.149C18.1216 12.1747 18.1021 12.196 18.0775 12.2097C18.0293 12.233 17.9797 12.2073 17.9663 12.1516C17.9599 12.124 17.9626 12.0951 17.974 12.0691C17.9854 12.0432 18.0049 12.0217 18.0296 12.0078C18.0782 11.9845 18.1284 12.0113 18.1413 12.0671Z"
          fill="white"
        />
        <path
          d="M18.4092 11.4629C18.4167 11.4918 18.4146 11.5223 18.4033 11.5499C18.392 11.5774 18.372 11.6006 18.3464 11.6159C18.296 11.6428 18.2423 11.6186 18.2255 11.5618C18.2177 11.5331 18.2194 11.5027 18.2305 11.4751C18.2415 11.4475 18.2612 11.4242 18.2866 11.4088C18.3375 11.3811 18.3929 11.4053 18.4092 11.4629Z"
          fill="white"
        />
        <path
          d="M18.0319 11.6613C18.0402 11.6884 18.0389 11.7175 18.0283 11.7438C18.0177 11.7701 17.9984 11.7919 17.9736 11.8056C17.9244 11.8295 17.8707 11.8039 17.8532 11.7483C17.8448 11.7211 17.846 11.6918 17.8566 11.6654C17.8673 11.639 17.8867 11.617 17.9116 11.6032C17.961 11.5784 18.0151 11.6048 18.0319 11.6613Z"
          fill="white"
        />
        <path
          d="M17.3269 11.9794C17.3315 11.9915 17.3335 12.0045 17.3328 12.0174C17.332 12.0304 17.3285 12.043 17.3224 12.0545C17.3163 12.066 17.3079 12.076 17.2976 12.0839C17.2874 12.0919 17.2755 12.0975 17.2629 12.1005C17.2363 12.107 17.2082 12.1037 17.1838 12.0911C17.1595 12.0785 17.1405 12.0576 17.1304 12.0321C17.126 12.0199 17.1243 12.007 17.1253 11.994C17.1262 11.9811 17.1299 11.9686 17.136 11.9572C17.1421 11.9458 17.1506 11.9358 17.1608 11.9278C17.171 11.9199 17.1828 11.9142 17.1954 11.911C17.2219 11.9037 17.2502 11.9067 17.2745 11.9194C17.2989 11.932 17.3176 11.9535 17.3269 11.9794Z"
          fill="white"
        />
        <path
          d="M17.5362 12.7844C17.5412 12.8123 17.5359 12.8411 17.5214 12.8655C17.5069 12.89 17.4842 12.9084 17.4573 12.9175C17.4036 12.933 17.3519 12.8955 17.3416 12.8354C17.3314 12.7753 17.3668 12.7163 17.4209 12.7031C17.4338 12.7001 17.4473 12.6999 17.4604 12.7025C17.4734 12.705 17.4858 12.7103 17.4967 12.718C17.5076 12.7257 17.5167 12.7355 17.5235 12.747C17.5303 12.7584 17.5347 12.7711 17.5362 12.7844Z"
          fill="white"
        />
        <path
          d="M17.203 13.3718C17.2089 13.4434 17.1694 13.523 17.119 13.5473C17.0686 13.5717 17.0241 13.5242 17.0183 13.4466C17.0124 13.369 17.0455 13.2954 17.0974 13.2776C17.1492 13.2598 17.1971 13.3001 17.203 13.3718Z"
          fill="white"
        />
        <path
          d="M17.2265 13.8918C17.2265 13.9546 17.1843 14.034 17.1343 14.0636C17.0842 14.0931 17.0459 14.0607 17.0449 13.9977C17.0451 13.9657 17.0531 13.9341 17.0682 13.9059C17.0834 13.8776 17.1053 13.8535 17.1319 13.8357C17.1823 13.8065 17.2258 13.8288 17.2265 13.8918Z"
          fill="white"
        />
        <path
          d="M17.6024 13.674C17.6024 13.736 17.5621 13.8141 17.5106 13.8443C17.459 13.8745 17.4165 13.8443 17.416 13.7825C17.4173 13.7498 17.4265 13.7178 17.4427 13.6893C17.459 13.6609 17.4819 13.6368 17.5096 13.6191C17.5613 13.5892 17.6023 13.6121 17.6024 13.674Z"
          fill="white"
        />
        <path
          d="M17.594 14.1785C17.5908 14.2551 17.5475 14.3454 17.4959 14.3775C17.4443 14.4096 17.405 14.3703 17.4084 14.2927C17.4118 14.2151 17.4563 14.1296 17.5077 14.0985C17.559 14.0675 17.5973 14.1019 17.594 14.1785Z"
          fill="white"
        />
        <path
          d="M17.5487 14.7718C17.5395 14.8583 17.4904 14.9577 17.4382 14.9925C17.386 15.0273 17.3514 14.9826 17.361 14.8948C17.3705 14.8069 17.4202 14.71 17.4721 14.6764C17.524 14.6428 17.5578 14.6852 17.5487 14.7718Z"
          fill="white"
        />
        <path
          d="M18.2086 14.373C18.1968 14.4618 18.1505 14.5577 18.1051 14.5848C18.0598 14.6118 18.0324 14.5619 18.0435 14.4738C18.0546 14.3856 18.1002 14.2929 18.1463 14.2647C18.1923 14.2365 18.2203 14.2843 18.2086 14.373Z"
          fill="white"
        />
        <path
          d="M18.7456 14.362C18.7282 14.4488 18.6899 14.53 18.6342 14.5987C18.5865 14.6509 18.5618 14.6249 18.5765 14.5426C18.5911 14.4603 18.6379 14.3565 18.6838 14.309C18.7296 14.2614 18.7583 14.2844 18.7456 14.362Z"
          fill="white"
        />
        <path
          d="M18.0464 15.9942C18.0052 16.0872 17.9513 16.174 17.8862 16.252C17.8344 16.3101 17.8257 16.2873 17.8616 16.2016C17.8976 16.116 17.9636 16.0028 18.0138 15.946C18.064 15.8893 18.0806 15.9111 18.0464 15.9942Z"
          fill="white"
        />
        <path
          d="M16.6088 17.0367C16.5639 17.1257 16.4744 17.2259 16.4195 17.2609C16.3646 17.2958 16.3643 17.2523 16.4103 17.1638C16.4563 17.0753 16.53 16.9751 16.5839 16.9398C16.6378 16.9045 16.6538 16.9477 16.6088 17.0367Z"
          fill="white"
        />
        <path
          d="M16.8885 16.3437C16.8579 16.4381 16.7839 16.5442 16.7303 16.5796C16.6767 16.6151 16.666 16.5657 16.6967 16.471C16.7274 16.3763 16.7892 16.2723 16.842 16.2374C16.8947 16.2024 16.9189 16.2493 16.8885 16.3437Z"
          fill="white"
        />
        <path
          d="M17.066 15.6583C17.0472 15.7524 16.9845 15.8585 16.9326 15.8934C16.8807 15.9283 16.8609 15.8766 16.8804 15.7814C16.8999 15.6862 16.9516 15.5844 17.0028 15.551C17.0541 15.5176 17.0858 15.5638 17.066 15.6583Z"
          fill="white"
        />
        <path
          d="M18.5819 12.2803C18.5845 12.3084 18.579 12.3367 18.5661 12.3618C18.5532 12.3869 18.5334 12.4079 18.509 12.4222C18.462 12.4469 18.4175 12.4242 18.4091 12.3701C18.406 12.3417 18.4111 12.3131 18.4239 12.2876C18.4367 12.2621 18.4565 12.2408 18.4812 12.2263C18.5294 12.2013 18.5749 12.226 18.5819 12.2803Z"
          fill="white"
        />
        <path
          d="M18.8683 13.3042C18.8626 13.3714 18.8219 13.4553 18.7798 13.4889C18.7376 13.5225 18.7097 13.4903 18.7156 13.4199C18.7215 13.3495 18.7588 13.2708 18.8008 13.2404C18.8428 13.21 18.8742 13.2374 18.8683 13.3042Z"
          fill="white"
        />
        <path
          d="M16.9294 12.0866C16.9336 12.0984 16.9352 12.111 16.9342 12.1234C16.9331 12.1359 16.9294 12.1481 16.9233 12.159C16.9172 12.1699 16.9088 12.1794 16.8987 12.1868C16.8886 12.1942 16.877 12.1994 16.8647 12.202C16.8394 12.208 16.8127 12.2048 16.7896 12.1929C16.7664 12.181 16.7483 12.1612 16.7384 12.1371C16.7339 12.126 16.7319 12.114 16.7325 12.102C16.7332 12.09 16.7364 12.0782 16.7421 12.0676C16.7477 12.057 16.7556 12.0478 16.7652 12.0405C16.7749 12.0333 16.7859 12.0283 16.7977 12.0258C16.8504 12.0108 16.9109 12.037 16.9294 12.0866Z"
          fill="white"
        />
        <path
          d="M16.7842 11.7418C16.8059 11.7878 16.7783 11.8404 16.7256 11.8577C16.7014 11.8662 16.675 11.8659 16.651 11.857C16.627 11.848 16.6069 11.8308 16.5943 11.8085C16.5726 11.7651 16.5943 11.7154 16.6472 11.6956C16.7001 11.6758 16.7624 11.6958 16.7842 11.7418Z"
          fill="white"
        />
        <path
          d="M16.2418 10.7759C16.27 10.8228 16.2485 10.8854 16.1968 10.9156C16.1857 10.9239 16.173 10.9296 16.1595 10.9327C16.146 10.9357 16.132 10.9358 16.1185 10.9331C16.1049 10.9304 16.0921 10.9248 16.0808 10.9168C16.0696 10.9089 16.0601 10.8986 16.053 10.8867C16.0459 10.8749 16.0414 10.8616 16.0398 10.8479C16.0381 10.8342 16.0394 10.8203 16.0434 10.8071C16.0475 10.7939 16.0542 10.7817 16.0633 10.7712C16.0724 10.7608 16.0835 10.7524 16.096 10.7465C16.1476 10.7161 16.2137 10.7291 16.2418 10.7759Z"
          fill="white"
        />
        <path
          d="M16.4318 11.0937C16.4583 11.1384 16.4355 11.1975 16.3834 11.2249C16.3314 11.2522 16.2709 11.2388 16.2445 11.1958C16.2182 11.1528 16.2358 11.0951 16.2877 11.066C16.3396 11.0369 16.4044 11.0489 16.4318 11.0937Z"
          fill="white"
        />
        <path
          d="M15.2552 5.74856C15.2839 5.79542 15.2626 5.85789 15.2092 5.88761C15.1854 5.90231 15.1568 5.90724 15.1295 5.90139C15.1021 5.89554 15.0781 5.87935 15.0624 5.85621C15.0337 5.80935 15.0518 5.74772 15.1054 5.71783C15.1589 5.68794 15.2265 5.70171 15.2552 5.74856Z"
          fill="white"
        />
        <path
          d="M13.3395 5.96837C13.368 6.01522 13.3475 6.07837 13.2963 6.10843C13.2733 6.12301 13.2455 6.1279 13.2189 6.12205C13.1924 6.1162 13.1692 6.10007 13.1544 6.07719C13.1476 6.06567 13.1432 6.05289 13.1415 6.03962C13.1397 6.02636 13.1407 6.01287 13.1443 5.99998C13.1479 5.98709 13.154 5.97506 13.1624 5.96461C13.1707 5.95415 13.1811 5.94549 13.1929 5.93915C13.2443 5.90942 13.3109 5.92202 13.3395 5.96837Z"
          fill="white"
        />
        <path
          d="M13.8319 4.22642C13.8398 4.23821 13.8452 4.25149 13.8478 4.26544C13.8504 4.27939 13.8501 4.29371 13.8469 4.30755C13.8438 4.32138 13.8378 4.33443 13.8295 4.34589C13.8211 4.35735 13.8105 4.36699 13.7983 4.37421C13.7728 4.38919 13.7424 4.39364 13.7136 4.38662C13.6848 4.37959 13.6599 4.36165 13.6441 4.33659C13.6363 4.32443 13.631 4.31082 13.6286 4.29658C13.6261 4.28233 13.6265 4.26774 13.6297 4.25365C13.633 4.23957 13.639 4.22628 13.6475 4.21456C13.656 4.20285 13.6667 4.19295 13.6791 4.18545C13.7049 4.17139 13.7352 4.1678 13.7637 4.17543C13.7921 4.18305 13.8165 4.20131 13.8319 4.22642Z"
          fill="white"
        />
      </g>
    </g>
    <g opacity="0.33">
      <mask
        height="15"
        id="mask1_4286_2909"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="15"
        x="0"
        y="0"
      >
        <path
          d="M5.41916 14.2602C4.67537 14.1536 4.0545 14.0194 3.40391 13.6078C3.06585 13.3942 2.70058 13.1846 2.41408 12.9024C1.66978 12.1692 1.15169 11.1861 0.925816 10.176C0.919938 9.30606 1.66894 8.49374 2.07955 7.78084C2.66733 6.75978 3.28047 5.7577 3.89328 4.75175C4.17877 4.28152 4.45016 3.79366 4.76656 3.34392C5.16709 2.77529 5.72179 2.32253 6.28068 1.91729C6.79558 1.5438 7.3219 1.18575 7.93252 0.989603C9.14554 0.600155 10.4445 0.672872 11.6809 0.889512C12.7171 1.13067 13.8627 1.77219 14.5788 2.57208C12.6185 2.21135 10.73 2.76068 9.42012 4.28051C8.69798 5.11769 8.16545 6.15689 7.60639 7.10624C7.00634 8.12596 6.38447 9.13207 5.78594 10.153C5.4588 10.7112 5.07052 11.2446 4.77831 11.8219C4.27819 12.8089 4.54303 13.6076 5.41916 14.2602Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_4286_2909)">
        <path
          d="M10.7962 1.01411C10.7049 1.02318 10.5735 1.01411 10.5037 0.993957C10.4338 0.973804 10.4516 0.949957 10.5423 0.940385C10.633 0.930812 10.7631 0.940385 10.8337 0.960369C10.9042 0.980354 10.8878 1.00504 10.7962 1.01411Z"
          fill="white"
        />
        <path
          d="M11.3077 1.19052C11.2145 1.19841 11.0813 1.18733 11.008 1.16163C10.9346 1.13594 10.9483 1.10537 11.0405 1.09681C11.1426 1.09007 11.245 1.1019 11.3428 1.13174C11.4181 1.15828 11.4013 1.18212 11.3077 1.19052Z"
          fill="white"
        />
        <path
          d="M11.638 1.35006C11.5943 1.35342 11.5416 1.34653 11.5204 1.33427C11.4993 1.32201 11.5181 1.30908 11.5614 1.30555C11.6047 1.30203 11.6568 1.30958 11.678 1.32235C11.6991 1.33511 11.6815 1.34636 11.638 1.35006Z"
          fill="white"
        />
        <path
          d="M10.9685 1.4508C10.9269 1.46004 10.8756 1.45769 10.8546 1.44543C10.8336 1.43317 10.8509 1.41587 10.8928 1.40663C10.9346 1.3974 10.9848 1.40008 11.0058 1.41234C11.0268 1.4246 11.0102 1.44157 10.9685 1.4508Z"
          fill="white"
        />
        <path
          d="M10.3346 1.62682C10.2958 1.63975 10.2466 1.64009 10.2249 1.62682C10.2032 1.61355 10.2178 1.59323 10.2568 1.58081C10.2958 1.56838 10.3446 1.56754 10.3661 1.58081C10.3876 1.59407 10.3735 1.61389 10.3346 1.62682Z"
          fill="white"
        />
        <path
          d="M9.75305 1.84698C9.71795 1.86159 9.6711 1.86277 9.64859 1.84967C9.62609 1.83657 9.6365 1.81407 9.67194 1.79929C9.70737 1.78451 9.75406 1.78249 9.77639 1.79626C9.79873 1.81004 9.78832 1.8322 9.75305 1.84698Z"
          fill="white"
        />
        <path
          d="M9.23494 2.07973C9.21968 2.08692 9.20306 2.09075 9.18619 2.09095C9.16933 2.09116 9.15262 2.08773 9.1372 2.0809C9.11369 2.06713 9.1204 2.04463 9.15046 2.03052C9.16574 2.02334 9.18236 2.01947 9.19924 2.01918C9.21611 2.01889 9.23286 2.02219 9.24837 2.02884C9.27121 2.04194 9.26517 2.06512 9.23494 2.07973Z"
          fill="white"
        />
        <path
          d="M8.79557 2.29438C8.78143 2.30036 8.76615 2.30313 8.75082 2.30249C8.73548 2.30185 8.72048 2.29782 8.70689 2.29069C8.68288 2.27658 8.68372 2.25542 8.70908 2.24316C8.72319 2.23702 8.73849 2.23408 8.75388 2.23454C8.76927 2.23501 8.78436 2.23887 8.79808 2.24585C8.82193 2.25995 8.82076 2.28195 8.79557 2.29438Z"
          fill="white"
        />
        <path
          d="M8.44697 2.46985C8.434 2.4755 8.41987 2.47796 8.40576 2.47703C8.39164 2.47609 8.37796 2.47179 8.36585 2.46448C8.34268 2.44987 8.34167 2.42854 8.36266 2.41695C8.37551 2.41148 8.38947 2.40914 8.4034 2.41013C8.41733 2.41113 8.43083 2.41542 8.44277 2.42266C8.46611 2.4371 8.46829 2.45843 8.44697 2.46985Z"
          fill="white"
        />
        <path
          d="M8.11015 2.67132C8.09771 2.67817 8.08368 2.68159 8.06948 2.68124C8.05529 2.68089 8.04145 2.67677 8.02937 2.6693C8.00653 2.65402 8.00586 2.63051 8.02735 2.61657C8.03967 2.60981 8.05357 2.60646 8.06762 2.60684C8.08166 2.60722 8.09536 2.61134 8.10729 2.61875C8.13063 2.63387 8.13215 2.65738 8.11015 2.67132Z"
          fill="white"
        />
        <path
          d="M7.77567 2.90723C7.76368 2.91513 7.74967 2.91941 7.73532 2.91956C7.72097 2.91971 7.70687 2.91572 7.69472 2.90807C7.68977 2.90525 7.68561 2.90122 7.68265 2.89636C7.67968 2.89149 7.678 2.88595 7.67776 2.88026C7.67753 2.87456 7.67875 2.8689 7.6813 2.86381C7.68386 2.85872 7.68767 2.85436 7.69237 2.85114C7.70425 2.84345 7.7181 2.83936 7.73226 2.83936C7.74641 2.83936 7.76026 2.84345 7.77214 2.85114C7.77699 2.8539 7.78108 2.85782 7.78403 2.86255C7.78699 2.86728 7.78872 2.87267 7.78907 2.87823C7.78942 2.8838 7.78837 2.88936 7.78604 2.89443C7.7837 2.89949 7.78013 2.90389 7.77567 2.90723Z"
          fill="white"
        />
        <path
          d="M7.44378 3.17232C7.43221 3.18098 7.41829 3.18594 7.40385 3.18657C7.38941 3.1872 7.37512 3.18346 7.36284 3.17584C7.35748 3.173 7.35292 3.16884 7.3496 3.16376C7.34628 3.15867 7.3443 3.15283 7.34385 3.14678C7.3434 3.14073 7.34449 3.13466 7.34701 3.12914C7.34954 3.12362 7.35342 3.11883 7.3583 3.11522C7.36964 3.10653 7.38336 3.10153 7.39763 3.1009C7.4119 3.10027 7.42602 3.10403 7.43807 3.11169C7.44347 3.11446 7.44809 3.11854 7.4515 3.12357C7.4549 3.12859 7.45698 3.1344 7.45755 3.14044C7.45812 3.14648 7.45716 3.15257 7.45475 3.15814C7.45234 3.16371 7.44857 3.16859 7.44378 3.17232Z"
          fill="white"
        />
        <path
          d="M7.12499 3.46177C7.11389 3.47114 7.10011 3.47675 7.08562 3.47777C7.07113 3.4788 7.05669 3.47519 7.04438 3.46748C7.03881 3.46459 7.03402 3.46039 7.03043 3.45525C7.02683 3.4501 7.02454 3.44416 7.02375 3.43794C7.02296 3.43171 7.02369 3.42538 7.02587 3.4195C7.02806 3.41362 7.03165 3.40836 7.03632 3.40417C7.04721 3.3948 7.06083 3.38918 7.07515 3.38812C7.08948 3.38706 7.10377 3.39063 7.11592 3.39829C7.12151 3.40116 7.12632 3.40532 7.12997 3.41044C7.13361 3.41555 7.13598 3.42147 7.13686 3.42768C7.13775 3.4339 7.13714 3.44024 7.13507 3.44617C7.13301 3.4521 7.12955 3.45745 7.12499 3.46177Z"
          fill="white"
        />
        <path
          d="M6.82715 3.77084C6.81664 3.78078 6.80311 3.78691 6.78871 3.78825C6.77431 3.78959 6.75988 3.78606 6.74772 3.77823C6.74195 3.77536 6.73692 3.7712 6.73304 3.76606C6.72915 3.76092 6.72651 3.75495 6.72532 3.74862C6.72414 3.74229 6.72443 3.73577 6.72619 3.72957C6.72795 3.72337 6.73112 3.71767 6.73546 3.7129C6.74571 3.70292 6.75905 3.6967 6.77329 3.69526C6.78754 3.69383 6.80184 3.69727 6.81388 3.70501C6.81964 3.70794 6.82466 3.71214 6.82857 3.71728C6.83247 3.72242 6.83517 3.72839 6.83644 3.73472C6.83772 3.74105 6.83755 3.74759 6.83594 3.75385C6.83433 3.7601 6.83132 3.76591 6.82715 3.77084Z"
          fill="white"
        />
        <path
          d="M6.55775 4.09426C6.54797 4.1046 6.53486 4.11115 6.52072 4.11274C6.50658 4.11434 6.49233 4.11089 6.4805 4.103C6.47456 4.10011 6.46933 4.09596 6.46517 4.09083C6.46101 4.08571 6.45803 4.07973 6.45643 4.07333C6.45483 4.06692 6.45465 4.06025 6.45591 4.05377C6.45716 4.04729 6.45982 4.04116 6.4637 4.03582C6.47319 4.02539 6.48611 4.01872 6.50011 4.01703C6.5141 4.01533 6.52824 4.01872 6.53995 4.02658C6.54592 4.0295 6.55119 4.03366 6.5554 4.0388C6.55962 4.04393 6.56267 4.04992 6.56436 4.05634C6.56605 4.06277 6.56633 4.06948 6.56519 4.07603C6.56405 4.08257 6.56151 4.08879 6.55775 4.09426Z"
          fill="white"
        />
        <path
          d="M6.31991 4.42748C6.30597 4.44897 6.27272 4.453 6.24635 4.43688C6.24042 4.434 6.23513 4.42995 6.2308 4.42497C6.22647 4.42 6.22319 4.41421 6.22115 4.40793C6.21911 4.40166 6.21836 4.39505 6.21894 4.38848C6.21952 4.38191 6.22141 4.37552 6.22452 4.36971C6.23297 4.35905 6.24519 4.35204 6.25866 4.35013C6.27213 4.34823 6.28582 4.35157 6.2969 4.35946C6.32326 4.37542 6.33384 4.40648 6.31991 4.42748Z"
          fill="white"
        />
        <path
          d="M6.1102 4.76475C6.09693 4.78624 6.06418 4.79027 6.03798 4.77415C6.03157 4.77174 6.02576 4.76795 6.02098 4.76303C6.0162 4.75812 6.01257 4.75221 6.01034 4.74573C6.00811 4.73925 6.00734 4.73235 6.00809 4.72554C6.00883 4.71873 6.01108 4.71216 6.01465 4.70632C6.01823 4.70047 6.02306 4.69549 6.02879 4.69172C6.03451 4.68796 6.041 4.68551 6.04779 4.68454C6.05458 4.68358 6.06149 4.68413 6.06804 4.68615C6.07459 4.68817 6.08061 4.69161 6.08568 4.69623C6.11154 4.71235 6.12346 4.74325 6.1102 4.76475Z"
          fill="white"
        />
        <path
          d="M5.9044 5.10259C5.89164 5.12425 5.85922 5.12812 5.83303 5.11199C5.82104 5.10616 5.81185 5.09582 5.80748 5.08323C5.8031 5.07064 5.8039 5.05683 5.80968 5.04482C5.82245 5.02332 5.85335 5.01845 5.87971 5.03457C5.90608 5.0507 5.91733 5.08109 5.9044 5.10259Z"
          fill="white"
        />
        <path
          d="M5.70415 5.44066C5.69139 5.46232 5.65931 5.46619 5.63328 5.4499C5.6216 5.44375 5.61269 5.43339 5.60835 5.42092C5.60402 5.40844 5.60459 5.39479 5.60994 5.38272C5.6227 5.36106 5.65327 5.35636 5.6793 5.37265C5.70533 5.38894 5.71675 5.41916 5.70415 5.44066Z"
          fill="white"
        />
        <path
          d="M5.50621 5.77884C5.49362 5.80033 5.46171 5.8042 5.43601 5.78791C5.4242 5.78163 5.41523 5.77107 5.41095 5.75839C5.40668 5.74571 5.40741 5.73188 5.41301 5.71972C5.4256 5.69823 5.456 5.69352 5.48169 5.70981C5.50739 5.7261 5.51881 5.75734 5.50621 5.77884Z"
          fill="white"
        />
        <path
          d="M5.30643 6.11709C5.29367 6.13859 5.26193 6.14228 5.2364 6.12582C5.22466 6.11943 5.2158 6.1088 5.21162 6.0961C5.20744 6.0834 5.20825 6.06959 5.2139 6.05747C5.22649 6.03598 5.25689 6.03144 5.28242 6.0479C5.30794 6.06436 5.31903 6.09559 5.30643 6.11709Z"
          fill="white"
        />
        <path
          d="M5.10671 6.45542C5.09378 6.47691 5.06204 6.48044 5.03685 6.46398C5.02515 6.4575 5.01637 6.44681 5.01228 6.43408C5.0082 6.42135 5.00911 6.40754 5.01485 6.39546C5.01862 6.39021 5.02343 6.38578 5.02897 6.38244C5.03451 6.37911 5.04068 6.37693 5.04709 6.37605C5.0535 6.37517 5.06002 6.37561 5.06625 6.37733C5.07249 6.37905 5.07831 6.38202 5.08336 6.38606C5.10872 6.40235 5.11947 6.43375 5.10671 6.45542Z"
          fill="white"
        />
        <path
          d="M4.89943 6.79289C4.89118 6.80322 4.87919 6.8099 4.86605 6.81147C4.85292 6.81304 4.83969 6.80938 4.82923 6.80129C4.8176 6.79476 4.80892 6.78399 4.80501 6.77123C4.8011 6.75847 4.80226 6.7447 4.80824 6.73277C4.81205 6.7275 4.81688 6.72307 4.82246 6.71973C4.82803 6.71639 4.83422 6.71421 4.84066 6.71333C4.84709 6.71245 4.85364 6.71289 4.8599 6.71461C4.86617 6.71634 4.87202 6.71931 4.8771 6.72336C4.90229 6.74083 4.9127 6.77139 4.89943 6.79289Z"
          fill="white"
        />
        <path
          d="M4.69257 7.13043C4.68428 7.14083 4.67221 7.14754 4.659 7.14908C4.64578 7.15062 4.63249 7.14687 4.62203 7.13865C4.61036 7.13216 4.60168 7.12136 4.59785 7.10856C4.59402 7.09576 4.59535 7.08197 4.60154 7.07014C4.60541 7.06488 4.6103 7.06045 4.61592 7.05711C4.62153 7.05378 4.62776 7.05161 4.63423 7.05073C4.6407 7.04986 4.64728 7.05029 4.65358 7.05201C4.65988 7.05372 4.66577 7.05669 4.6709 7.06073C4.68268 7.06743 4.69147 7.07836 4.6955 7.0913C4.69952 7.10424 4.69847 7.11823 4.69257 7.13043Z"
          fill="white"
        />
        <path
          d="M4.48127 7.46764C4.46817 7.48913 4.4366 7.49266 4.41141 7.47603C4.39979 7.46953 4.3911 7.45882 4.38713 7.44611C4.38316 7.43339 4.38422 7.41964 4.39008 7.40768C4.39387 7.40243 4.39869 7.39799 4.40425 7.39465C4.40981 7.39131 4.41599 7.38914 4.42241 7.38826C4.42883 7.38738 4.43537 7.38781 4.44162 7.38954C4.44787 7.39126 4.4537 7.39423 4.45877 7.39828C4.48396 7.4149 4.49437 7.44614 4.48127 7.46764Z"
          fill="white"
        />
        <path
          d="M4.28162 7.80481C4.26886 7.82631 4.23762 7.82983 4.21243 7.81338C4.20081 7.80687 4.19209 7.79619 4.18804 7.7835C4.18399 7.77082 4.1849 7.75706 4.1906 7.74502C4.19432 7.73977 4.19907 7.73534 4.20456 7.73199C4.21005 7.72864 4.21617 7.72645 4.22254 7.72556C4.22891 7.72466 4.2354 7.72508 4.2416 7.72678C4.2478 7.72848 4.25359 7.73143 4.25861 7.73545C4.2838 7.75208 4.29438 7.78331 4.28162 7.80481Z"
          fill="white"
        />
        <path
          d="M4.08032 8.14148C4.06772 8.16297 4.03649 8.16667 4.01146 8.15021C3.99982 8.14376 3.99106 8.13314 3.98695 8.12048C3.98284 8.10783 3.98367 8.09409 3.9893 8.08203C3.99298 8.07686 3.99767 8.0725 4.0031 8.0692C4.00852 8.0659 4.01455 8.06374 4.02083 8.06285C4.02711 8.06195 4.03351 8.06234 4.03964 8.06399C4.04576 8.06564 4.05149 8.06852 4.05647 8.07245C4.08216 8.08891 4.09291 8.12015 4.08032 8.14148Z"
          fill="white"
        />
        <path
          d="M3.88316 8.47821C3.87056 8.49971 3.83933 8.5034 3.81414 8.48711C3.80254 8.48067 3.79381 8.47007 3.7897 8.45746C3.78559 8.44484 3.7864 8.43114 3.79197 8.4191C3.79565 8.41394 3.80035 8.40958 3.80576 8.40628C3.81118 8.40298 3.8172 8.40081 3.82348 8.3999C3.82975 8.39899 3.83615 8.39936 3.84228 8.40098C3.84841 8.40261 3.85414 8.40546 3.85914 8.40936C3.87101 8.41577 3.88006 8.42639 3.8845 8.43913C3.88895 8.45187 3.88847 8.46581 3.88316 8.47821Z"
          fill="white"
        />
        <path
          d="M3.6877 8.8148C3.67511 8.8363 3.64387 8.84016 3.61868 8.82387C3.60715 8.81758 3.59845 8.80713 3.59436 8.79465C3.59027 8.78216 3.5911 8.76859 3.59668 8.7567C3.60037 8.75155 3.60506 8.7472 3.61046 8.74389C3.61587 8.74059 3.62188 8.7384 3.62814 8.73746C3.6344 8.73652 3.64079 8.73685 3.64693 8.73842C3.65306 8.74 3.65882 8.74278 3.66386 8.74662C3.68972 8.76241 3.70047 8.79331 3.6877 8.8148Z"
          fill="white"
        />
        <path
          d="M3.48327 9.15171C3.47515 9.16203 3.46332 9.16877 3.4503 9.17049C3.43729 9.17222 3.42411 9.16879 3.41358 9.16095C3.40192 9.15479 3.39313 9.14431 3.38912 9.13175C3.3851 9.11919 3.38616 9.10555 3.39208 9.09377C3.39583 9.08852 3.40059 9.08407 3.40609 9.0807C3.41158 9.07733 3.4177 9.0751 3.42408 9.07414C3.43046 9.07318 3.43697 9.07352 3.44321 9.07513C3.44946 9.07675 3.45532 9.0796 3.46043 9.08353C3.4721 9.08995 3.48093 9.10053 3.48516 9.11316C3.48939 9.12579 3.48872 9.13955 3.48327 9.15171Z"
          fill="white"
        />
        <path
          d="M3.28093 9.48876C3.27226 9.49837 3.26045 9.5046 3.24762 9.50633C3.23478 9.50806 3.22175 9.50519 3.21084 9.49822C3.19992 9.49125 3.19183 9.48064 3.188 9.46827C3.18417 9.4559 3.18484 9.44257 3.18991 9.43065C3.19372 9.4254 3.19853 9.42096 3.20407 9.4176C3.20961 9.41423 3.21577 9.41201 3.22219 9.41105C3.2286 9.4101 3.23514 9.41043 3.24142 9.41204C3.2477 9.41365 3.2536 9.41649 3.25877 9.42041C3.27054 9.42677 3.27941 9.43742 3.28353 9.45015C3.28766 9.46287 3.28673 9.4767 3.28093 9.48876Z"
          fill="white"
        />
        <path
          d="M3.06547 9.83095C3.05712 9.84148 3.04515 9.84853 3.03189 9.85073C3.01863 9.85294 3.00502 9.85014 2.99371 9.84288C2.98239 9.83562 2.97418 9.82443 2.97065 9.81146C2.96712 9.79849 2.96854 9.78467 2.97462 9.77268C2.97843 9.76722 2.98328 9.76256 2.98889 9.75899C2.9945 9.75541 3.00077 9.75298 3.00733 9.75184C3.01389 9.7507 3.02061 9.75087 3.0271 9.75235C3.03359 9.75382 3.03973 9.75656 3.04515 9.76042C3.05707 9.7672 3.06587 9.77838 3.06966 9.79155C3.07346 9.80473 3.07196 9.81887 3.06547 9.83095Z"
          fill="white"
        />
        <path
          d="M2.83348 10.2099C2.82998 10.216 2.82525 10.2212 2.81961 10.2253C2.81397 10.2294 2.80754 10.2323 2.80071 10.2338C2.79389 10.2353 2.78683 10.2354 2.77998 10.234C2.77313 10.2327 2.76665 10.2299 2.76093 10.2259C2.74911 10.2178 2.7408 10.2055 2.73768 10.1916C2.73457 10.1776 2.73687 10.163 2.74414 10.1506C2.74758 10.1447 2.7522 10.1395 2.75771 10.1354C2.76322 10.1313 2.76952 10.1284 2.7762 10.1268C2.78288 10.1253 2.78982 10.1251 2.79658 10.1263C2.80334 10.1275 2.80978 10.13 2.81551 10.1338C2.82768 10.1418 2.83633 10.1541 2.83968 10.1683C2.84302 10.1824 2.8408 10.1973 2.83348 10.2099Z"
          fill="white"
        />
        <path
          d="M2.60029 10.6202C2.59729 10.6264 2.59297 10.6319 2.58763 10.6363C2.5823 10.6406 2.57607 10.6438 2.56939 10.6455C2.56271 10.6473 2.55574 10.6475 2.54895 10.6463C2.54216 10.645 2.53573 10.6423 2.53009 10.6383C2.51841 10.6294 2.5103 10.6165 2.50723 10.6021C2.50415 10.5877 2.5063 10.5727 2.5133 10.5597C2.51621 10.5536 2.52043 10.5481 2.52567 10.5437C2.53091 10.5393 2.53705 10.5361 2.54365 10.5343C2.55026 10.5326 2.55717 10.5322 2.5639 10.5334C2.57064 10.5346 2.57704 10.5372 2.58266 10.5411C2.59452 10.55 2.60281 10.5629 2.60604 10.5774C2.60927 10.5919 2.60723 10.6071 2.60029 10.6202Z"
          fill="white"
        />
        <path
          d="M2.38985 11.0487C2.3876 11.0549 2.3839 11.0605 2.37906 11.065C2.37423 11.0695 2.36839 11.0728 2.36203 11.0746C2.35567 11.0765 2.34897 11.0767 2.34248 11.0755C2.33599 11.0742 2.32989 11.0714 2.32469 11.0673C2.31299 11.058 2.30466 11.045 2.30096 11.0305C2.29726 11.016 2.2984 11.0007 2.3042 10.9869C2.30636 10.9807 2.30996 10.975 2.31472 10.9705C2.31948 10.966 2.32526 10.9626 2.33156 10.9608C2.33787 10.9589 2.34453 10.9586 2.35098 10.9598C2.35744 10.9611 2.36351 10.9638 2.36869 10.9679C2.38058 10.9772 2.38909 10.9901 2.39291 11.0047C2.39673 11.0193 2.39566 11.0347 2.38985 11.0487Z"
          fill="white"
        />
        <path
          d="M2.22036 11.4796C2.21921 11.4857 2.21639 11.4913 2.21223 11.4959C2.20807 11.5005 2.20273 11.5038 2.19678 11.5056C2.19084 11.5073 2.18453 11.5074 2.17856 11.5057C2.17258 11.5041 2.16717 11.5009 2.16292 11.4964C2.15145 11.4867 2.14296 11.4739 2.13844 11.4596C2.13393 11.4453 2.13356 11.43 2.1374 11.4154C2.13863 11.4095 2.14146 11.404 2.14557 11.3995C2.14968 11.395 2.15493 11.3918 2.16075 11.3901C2.16658 11.3883 2.17276 11.3882 2.17864 11.3897C2.18453 11.3912 2.18989 11.3943 2.19416 11.3986C2.20567 11.4083 2.21423 11.421 2.21887 11.4354C2.2235 11.4497 2.22402 11.465 2.22036 11.4796Z"
          fill="white"
        />
        <path
          d="M2.12338 11.8988C2.11952 11.9248 2.09869 11.9303 2.07669 11.9115C2.06543 11.9015 2.05656 11.8891 2.05075 11.8751C2.04494 11.8612 2.04232 11.8462 2.0431 11.8311C2.04663 11.8052 2.06746 11.799 2.08962 11.8177C2.10101 11.8278 2.10996 11.8403 2.11581 11.8543C2.12165 11.8684 2.12424 11.8836 2.12338 11.8988Z"
          fill="white"
        />
        <path
          d="M10.157 1.11953C10.073 1.13885 9.94974 1.13633 9.88358 1.11651C9.81741 1.09669 9.83152 1.06478 9.91397 1.04581C9.99643 1.02683 10.1179 1.02683 10.186 1.04715C10.2542 1.06747 10.2416 1.10022 10.157 1.11953Z"
          fill="white"
        />
        <path
          d="M10.6476 1.29198C10.5585 1.31129 10.4322 1.30877 10.363 1.28392C10.2938 1.25906 10.3061 1.22027 10.3929 1.19995C10.4797 1.17963 10.6099 1.18735 10.6814 1.21271C10.7529 1.23807 10.7368 1.2725 10.6476 1.29198Z"
          fill="white"
        />
        <path
          d="M11.3525 1.67641C11.2626 1.69606 11.1585 1.6932 11.1137 1.66465C11.0688 1.6361 11.1017 1.59446 11.1919 1.57481C11.2821 1.55516 11.3951 1.56557 11.4396 1.59378C11.4841 1.622 11.4423 1.65676 11.3525 1.67641Z"
          fill="white"
        />
        <path
          d="M11.1797 2.33984C11.1081 2.3657 11.0225 2.35781 10.9904 2.32305C10.9583 2.28828 10.9934 2.23908 11.0677 2.21271C11.1419 2.18634 11.2249 2.19205 11.2541 2.22749C11.2833 2.26292 11.2506 2.31398 11.1797 2.33984Z"
          fill="white"
        />
        <path
          d="M10.6935 2.54316C10.6305 2.57289 10.5478 2.57003 10.5102 2.53796C10.4726 2.50588 10.4958 2.45567 10.5615 2.42477C10.6271 2.39387 10.7079 2.39488 10.7427 2.42796C10.7774 2.46104 10.7564 2.51344 10.6935 2.54316Z"
          fill="white"
        />
        <path
          d="M10.2639 2.76269C10.208 2.79292 10.1275 2.79192 10.0854 2.76185C10.0432 2.73179 10.056 2.68309 10.1141 2.65202C10.1722 2.62095 10.2513 2.61944 10.2914 2.65034C10.3316 2.68124 10.3198 2.7323 10.2639 2.76269Z"
          fill="white"
        />
        <path
          d="M9.53505 3.16465C9.50975 3.17861 9.48128 3.1858 9.45239 3.18554C9.4235 3.18527 9.39517 3.17756 9.37013 3.16314C9.32445 3.13342 9.32462 3.08522 9.37013 3.05751C9.39563 3.0441 9.42414 3.03748 9.45293 3.03827C9.48173 3.03907 9.50983 3.04725 9.53455 3.06204C9.58006 3.0916 9.58039 3.13678 9.53505 3.16465Z"
          fill="white"
        />
        <path
          d="M10.4341 2.30551C10.3651 2.33725 10.283 2.3391 10.2481 2.30836C10.2131 2.27763 10.238 2.2254 10.31 2.19282C10.3821 2.16024 10.4711 2.16108 10.5037 2.19282C10.5362 2.22456 10.5032 2.2736 10.4341 2.30551Z"
          fill="white"
        />
        <path
          d="M9.96305 2.53758C9.90242 2.56932 9.82299 2.57116 9.78251 2.54144C9.74204 2.51171 9.75631 2.46251 9.81946 2.43026C9.8826 2.39802 9.96842 2.39668 10.0067 2.42623C10.045 2.45579 10.0235 2.506 9.96305 2.53758Z"
          fill="white"
        />
        <path
          d="M8.50081 2.10676C8.47055 2.11911 8.43784 2.12425 8.40525 2.12178C8.37266 2.1193 8.3411 2.10927 8.31306 2.09249C8.26268 2.0589 8.26184 2.01288 8.31658 1.98887C8.3482 1.97712 8.38196 1.97224 8.4156 1.97456C8.44925 1.97688 8.48202 1.98633 8.51173 2.0023C8.56295 2.03421 8.5559 2.08107 8.50081 2.10676Z"
          fill="white"
        />
        <path
          d="M8.11669 1.85897C8.06211 1.88114 7.97798 1.86988 7.9286 1.83814C7.87923 1.8064 7.88427 1.76811 7.93885 1.74796C7.99343 1.72781 8.07689 1.73217 8.12626 1.7619C8.17564 1.79162 8.17127 1.83714 8.11669 1.85897Z"
          fill="white"
        />
        <path
          d="M7.82535 2.4638C7.779 2.49319 7.70141 2.48798 7.65053 2.44885C7.59964 2.40972 7.59511 2.35313 7.64162 2.32475C7.68814 2.29637 7.76758 2.30644 7.81846 2.34456C7.86935 2.38269 7.8717 2.43458 7.82535 2.4638Z"
          fill="white"
        />
        <path
          d="M8.20563 3.18657C8.1814 3.20418 8.15243 3.2141 8.12249 3.21506C8.09255 3.21602 8.06301 3.20797 8.0377 3.19195C7.98732 3.15836 7.98144 3.10076 8.02695 3.06566C8.07246 3.03056 8.1519 3.03073 8.20127 3.06398C8.25064 3.09723 8.25064 3.15114 8.20563 3.18657Z"
          fill="white"
        />
        <path
          d="M7.88922 3.45641C7.8663 3.47587 7.83771 3.48742 7.8077 3.48936C7.77769 3.49129 7.74786 3.48351 7.72263 3.46716C7.67107 3.43357 7.66183 3.37227 7.70583 3.33281C7.74983 3.29334 7.82876 3.292 7.87931 3.32592C7.92986 3.35985 7.93204 3.41745 7.88922 3.45641Z"
          fill="white"
        />
        <path
          d="M6.4478 2.95671C6.42403 2.97723 6.39401 2.98909 6.36263 2.99037C6.33125 2.99165 6.30036 2.98228 6.27499 2.96376C6.26335 2.956 6.25365 2.94567 6.24664 2.93355C6.23963 2.92144 6.2355 2.90788 6.23457 2.89391C6.23365 2.87995 6.23595 2.86596 6.2413 2.85303C6.24665 2.8401 6.2549 2.82857 6.26542 2.81934C6.28827 2.7984 6.31766 2.78602 6.34861 2.78427C6.37955 2.78253 6.41014 2.79153 6.43521 2.80977C6.48576 2.84806 6.49197 2.91439 6.4478 2.95671Z"
          fill="white"
        />
        <path
          d="M8.07938 4.54994C8.06086 4.5725 8.03462 4.58737 8.00576 4.59165C7.97689 4.59594 7.94746 4.58933 7.92319 4.57312C7.86895 4.53953 7.84997 4.47236 7.88474 4.42718C7.9195 4.38201 7.9944 4.37495 8.04814 4.40871C8.10188 4.44246 8.11364 4.50443 8.07938 4.54994Z"
          fill="white"
        />
        <path
          d="M7.83794 4.88395C7.80586 4.93047 7.74037 4.94289 7.68528 4.90813C7.6302 4.87337 7.60753 4.80535 7.63994 4.759C7.67235 4.71265 7.74641 4.70526 7.80133 4.73986C7.85624 4.77445 7.86985 4.83726 7.83794 4.88395Z"
          fill="white"
        />
        <path
          d="M7.61563 5.22195C7.58607 5.26864 7.52276 5.2814 7.46751 5.24647C7.41225 5.21154 7.38706 5.14302 7.41712 5.09633C7.44719 5.04965 7.5199 5.04243 7.57515 5.07719C7.63041 5.11195 7.64535 5.17527 7.61563 5.22195Z"
          fill="white"
        />
        <path
          d="M7.196 4.9575C7.16594 5.00402 7.09238 5.01023 7.0336 4.97329C6.97482 4.93634 6.95434 4.87068 6.98456 4.82416C7.01479 4.77764 7.08432 4.76823 7.14276 4.80518C7.2012 4.84213 7.22606 4.91031 7.196 4.9575Z"
          fill="white"
        />
        <path
          d="M6.24528 3.88449C6.22631 3.9071 6.19941 3.92161 6.1701 3.92504C6.14079 3.92848 6.11127 3.92058 6.08758 3.90296C6.03468 3.86602 6.01873 3.79683 6.054 3.75014C6.08926 3.70345 6.16416 3.69758 6.2169 3.73519C6.26963 3.77281 6.28105 3.83848 6.24528 3.88449Z"
          fill="white"
        />
        <path
          d="M5.85988 3.61163C5.84091 3.63441 5.81403 3.64917 5.78464 3.65297C5.75524 3.65677 5.72549 3.64932 5.70135 3.63212C5.68944 3.62442 5.67925 3.61434 5.67142 3.60252C5.66359 3.5907 5.65828 3.57738 5.65584 3.56341C5.65339 3.54944 5.65386 3.53512 5.65721 3.52134C5.66057 3.50756 5.66673 3.49462 5.67532 3.48333C5.69317 3.45977 5.71948 3.44408 5.74869 3.43957C5.77789 3.43506 5.80771 3.44208 5.83184 3.45915C5.84389 3.46715 5.8542 3.4775 5.86217 3.48958C5.87013 3.50166 5.87559 3.51521 5.8782 3.52944C5.88082 3.54367 5.88055 3.55828 5.8774 3.5724C5.87425 3.58652 5.8683 3.59986 5.85988 3.61163Z"
          fill="white"
        />
        <path
          d="M6.98451 5.2965C6.95563 5.34319 6.88224 5.34957 6.82329 5.31245C6.76435 5.27534 6.74369 5.20951 6.77291 5.16282C6.80213 5.11613 6.86931 5.10589 6.92809 5.143C6.98687 5.18012 7.0134 5.2488 6.98451 5.2965Z"
          fill="white"
        />
        <path
          d="M6.77567 5.6356C6.74729 5.68262 6.67407 5.689 6.61512 5.65155C6.55618 5.6141 6.53485 5.54827 6.56323 5.50125C6.59161 5.45423 6.65896 5.44415 6.7179 5.48143C6.77685 5.51872 6.80405 5.58858 6.77567 5.6356Z"
          fill="white"
        />
        <path
          d="M6.38206 5.38687C6.35351 5.4339 6.28835 5.44565 6.23091 5.41055C6.17348 5.37545 6.14695 5.30727 6.17583 5.26042C6.20472 5.21356 6.27659 5.20567 6.33369 5.24077C6.39079 5.27587 6.41044 5.34002 6.38206 5.38687Z"
          fill="white"
        />
        <path
          d="M5.79676 4.56007C5.76838 4.60676 5.70507 4.61801 5.65301 4.58291C5.60095 4.54781 5.58012 4.4803 5.60834 4.43344C5.63655 4.38659 5.70322 4.37836 5.75562 4.41363C5.80802 4.44889 5.82515 4.51338 5.79676 4.56007Z"
          fill="white"
        />
        <path
          d="M5.59311 4.89799C5.5654 4.94468 5.5026 4.95593 5.45037 4.921C5.39814 4.88607 5.37698 4.81889 5.40502 4.77221C5.43307 4.72552 5.49907 4.71712 5.5513 4.75205C5.60353 4.78699 5.62099 4.85147 5.59311 4.89799Z"
          fill="white"
        />
        <path
          d="M5.21244 4.64155C5.18439 4.68824 5.11873 4.69731 5.06835 4.66405C5.04491 4.64984 5.02793 4.62706 5.021 4.60054C5.01407 4.57402 5.01774 4.54584 5.03123 4.52198C5.03817 4.51062 5.04738 4.50082 5.0583 4.49321C5.06922 4.4856 5.0816 4.48034 5.09466 4.47777C5.10772 4.47519 5.12117 4.47536 5.13416 4.47826C5.14715 4.48116 5.15939 4.48672 5.17012 4.49461C5.22117 4.52819 5.24048 4.59503 5.21244 4.64155Z"
          fill="white"
        />
        <path
          d="M5.39328 5.23558C5.36574 5.2821 5.30293 5.29352 5.2502 5.25859C5.19746 5.22366 5.1758 5.15648 5.20351 5.1098C5.23122 5.06311 5.29772 5.05505 5.35012 5.08981C5.40252 5.12458 5.42065 5.18906 5.39328 5.23558Z"
          fill="white"
        />
        <path
          d="M5.01036 4.97966C4.98231 5.02618 4.91615 5.03508 4.86543 5.00183C4.84193 4.9878 4.82487 4.96511 4.8179 4.93865C4.81093 4.91218 4.81461 4.88404 4.82815 4.86026C4.83521 4.84896 4.84452 4.83924 4.8555 4.8317C4.86649 4.82416 4.87891 4.81897 4.89199 4.81645C4.90507 4.81393 4.91853 4.81413 4.93153 4.81704C4.94452 4.81996 4.95678 4.82552 4.96754 4.83339C5.01808 4.86647 5.03841 4.93314 5.01036 4.97966Z"
          fill="white"
        />
        <path
          d="M7.00897 6.25386C6.98126 6.30139 6.92013 6.31533 6.86538 6.28073C6.81064 6.24614 6.7841 6.17661 6.81198 6.12959C6.83986 6.08256 6.9114 6.07484 6.96615 6.10943C7.0209 6.14403 7.03685 6.20583 7.00897 6.25386Z"
          fill="white"
        />
        <path
          d="M6.58869 5.98784C6.56064 6.03503 6.48793 6.04108 6.42747 6.00313C6.36701 5.96517 6.34535 5.8985 6.37356 5.85198C6.40177 5.80546 6.46945 5.79522 6.52907 5.833C6.58869 5.87079 6.61657 5.94065 6.58869 5.98784Z"
          fill="white"
        />
        <path
          d="M6.38837 6.32972C6.3605 6.37691 6.28761 6.38279 6.22682 6.3445C6.16602 6.30621 6.14419 6.23954 6.17224 6.19235C6.20028 6.14516 6.26813 6.13575 6.32808 6.17387C6.38804 6.21199 6.41625 6.28253 6.38837 6.32972Z"
          fill="white"
        />
        <path
          d="M5.98704 6.07638C5.959 6.1234 5.89518 6.13549 5.83976 6.10023C5.78434 6.06496 5.75898 5.99644 5.78669 5.94908C5.8144 5.90172 5.88527 5.89467 5.94103 5.92977C5.99678 5.96487 6.01509 6.02936 5.98704 6.07638Z"
          fill="white"
        />
        <path
          d="M6.16683 6.66067C6.13895 6.70786 6.06506 6.71357 6.0051 6.67528C5.94515 6.63699 5.92348 6.57066 5.9517 6.52414C5.97991 6.47762 6.04709 6.46721 6.10687 6.50533C6.16666 6.54345 6.19487 6.61348 6.16683 6.66067Z"
          fill="white"
        />
        <path
          d="M6.59542 6.93214C6.56771 6.9795 6.50524 6.99276 6.44914 6.95699C6.39305 6.92122 6.36601 6.85136 6.39389 6.804C6.42177 6.75664 6.49466 6.74993 6.54991 6.78553C6.60516 6.82113 6.62313 6.88461 6.59542 6.93214Z"
          fill="white"
        />
        <path
          d="M5.77267 6.4078C5.74445 6.45482 5.68013 6.46624 5.62455 6.4303C5.56896 6.39436 5.5441 6.32567 5.57215 6.27916C5.6002 6.23264 5.67039 6.22491 5.72615 6.26068C5.7819 6.29645 5.80071 6.36077 5.77267 6.4078Z"
          fill="white"
        />
        <path
          d="M5.56604 6.74792C5.53766 6.79477 5.47334 6.80619 5.41809 6.77008C5.36284 6.73398 5.33899 6.66496 5.36771 6.6181C5.39642 6.57125 5.46595 6.56419 5.52137 6.6003C5.57679 6.63641 5.59459 6.70106 5.56604 6.74792Z"
          fill="white"
        />
        <path
          d="M4.99805 5.90987C4.97051 5.95639 4.90653 5.96748 4.85228 5.93221C4.79804 5.89694 4.77503 5.8296 4.80291 5.78325C4.83079 5.7369 4.89863 5.72884 4.95254 5.76393C5.00645 5.79903 5.0256 5.86335 4.99805 5.90987Z"
          fill="white"
        />
        <path
          d="M5.19443 5.57277C5.16706 5.61929 5.10375 5.63054 5.05034 5.59544C4.99694 5.56034 4.97444 5.49316 5.00214 5.44664C5.02985 5.40013 5.09686 5.39206 5.14993 5.427C5.203 5.46193 5.22164 5.52625 5.19443 5.57277Z"
          fill="white"
        />
        <path
          d="M4.59955 5.65206C4.57117 5.69842 4.50332 5.70681 4.45126 5.67339C4.3992 5.63997 4.38408 5.578 4.4128 5.53165C4.4202 5.52037 4.42981 5.51072 4.44106 5.50327C4.4523 5.49582 4.46494 5.49073 4.47821 5.48831C4.49148 5.48589 4.5051 5.48619 4.51825 5.4892C4.5314 5.4922 4.54381 5.49785 4.55471 5.50579C4.60643 5.53904 4.62793 5.60571 4.59955 5.65206Z"
          fill="white"
        />
        <path
          d="M4.58367 6.59701C4.55512 6.64353 4.48929 6.65428 4.4337 6.61884C4.37811 6.58341 4.35477 6.51556 4.38332 6.46904C4.41187 6.42252 4.4819 6.4148 4.53732 6.45023C4.59274 6.48567 4.61239 6.55049 4.58367 6.59701Z"
          fill="white"
        />
        <path
          d="M6.19165 7.63798C6.16294 7.68483 6.10063 7.69826 6.04521 7.663C5.98979 7.62773 5.96343 7.55787 5.99231 7.51085C6.0212 7.46382 6.09391 7.45761 6.14933 7.49288C6.20475 7.52814 6.22037 7.59095 6.19165 7.63798Z"
          fill="white"
        />
        <path
          d="M4.17758 6.33675C4.14852 6.38327 4.079 6.3915 4.02643 6.35775C3.97387 6.32399 3.95792 6.26152 3.98714 6.21517C4.01636 6.16882 4.07917 6.15588 4.13207 6.18964C4.18497 6.22339 4.20663 6.29023 4.17758 6.33675Z"
          fill="white"
        />
        <path
          d="M5.77927 8.29806C5.75072 8.34408 5.68892 8.35651 5.6335 8.32124C5.57808 8.28597 5.55138 8.21712 5.57993 8.1711C5.60848 8.12509 5.68153 8.11955 5.73695 8.15431C5.79237 8.18907 5.80833 8.25222 5.77927 8.29806Z"
          fill="white"
        />
        <path
          d="M5.35594 8.02727C5.32739 8.07345 5.25316 8.07765 5.19237 8.03936C5.13158 8.00107 5.1084 7.93423 5.13746 7.88821C5.16651 7.8422 5.23452 7.8333 5.29532 7.87142C5.35611 7.90954 5.38365 7.98108 5.35594 8.02727Z"
          fill="white"
        />
        <path
          d="M5.57325 8.6292C5.5452 8.67471 5.4839 8.68697 5.42865 8.65187C5.3734 8.61677 5.34653 8.54876 5.37441 8.50308C5.40229 8.4574 5.47517 8.45169 5.52992 8.48629C5.58467 8.52088 5.60129 8.58369 5.57325 8.6292Z"
          fill="white"
        />
        <path
          d="M5.14955 8.36082C5.12184 8.40683 5.04878 8.4112 4.98766 8.37341C4.92653 8.33563 4.90369 8.26912 4.9319 8.22227C4.96011 8.17541 5.02762 8.16752 5.08808 8.20547C5.14854 8.24343 5.17743 8.31497 5.14955 8.36082Z"
          fill="white"
        />
        <path
          d="M3.97086 7.59627C3.94332 7.64279 3.87867 7.65354 3.82325 7.6181C3.76783 7.58267 3.74364 7.51465 3.77119 7.46814C3.79873 7.42162 3.86775 7.41372 3.92334 7.44933C3.97892 7.48493 3.99857 7.54959 3.97086 7.59627Z"
          fill="white"
        />
        <path
          d="M3.56305 7.33573C3.53567 7.38225 3.46783 7.39064 3.41493 7.35689C3.36203 7.32313 3.3449 7.26049 3.37227 7.21397C3.39964 7.16745 3.46111 7.15436 3.51401 7.18811C3.56691 7.22187 3.59092 7.28904 3.56305 7.33573Z"
          fill="white"
        />
        <path
          d="M5.17333 9.28594C5.14579 9.33112 5.08466 9.34354 5.02974 9.30928C4.97483 9.27503 4.94829 9.20852 4.97583 9.16251C5.00337 9.11649 5.07492 9.11078 5.12983 9.14571C5.18475 9.18064 5.20104 9.2406 5.17333 9.28594Z"
          fill="white"
        />
        <path
          d="M4.14239 9.10071C4.11484 9.14689 4.05136 9.15814 3.99662 9.12288C3.94187 9.08761 3.91752 9.01993 3.94489 8.97358C3.97226 8.92723 4.04112 8.92001 4.09603 8.95544C4.15095 8.99088 4.16976 9.05453 4.14239 9.10071Z"
          fill="white"
        />
        <path
          d="M4.75254 9.02488C4.72517 9.07056 4.65178 9.07643 4.59233 9.03932C4.53288 9.00221 4.50937 8.93688 4.53674 8.8907C4.56412 8.84451 4.63113 8.83561 4.69125 8.87306C4.75137 8.91051 4.77992 8.97869 4.75254 9.02488Z"
          fill="white"
        />
        <path
          d="M4.33948 8.76611C4.31228 8.81229 4.24896 8.82337 4.19422 8.78794C4.13947 8.7525 4.11478 8.68449 4.14199 8.63814C4.16919 8.59179 4.23805 8.5849 4.29313 8.6205C4.34821 8.65611 4.36669 8.71992 4.33948 8.76611Z"
          fill="white"
        />
        <path
          d="M3.57633 8.27103C3.54913 8.31772 3.48514 8.32847 3.43023 8.29286C3.37531 8.25726 3.3513 8.18958 3.37833 8.14306C3.40537 8.09654 3.47355 8.08865 3.52847 8.12425C3.58339 8.15986 3.60287 8.22451 3.57633 8.27103Z"
          fill="white"
        />
        <path
          d="M3.17683 8.94593C3.14895 8.99245 3.08446 9.0032 3.03005 8.96776C2.97564 8.93233 2.9528 8.86448 2.98067 8.8178C3.00855 8.77111 3.07657 8.76338 3.13182 8.79899C3.18707 8.83459 3.20571 8.89925 3.17683 8.94593Z"
          fill="white"
        />
        <path
          d="M2.5744 9.02282C2.54568 9.06951 2.47767 9.07791 2.42561 9.04398C2.40156 9.03013 2.38398 9.00731 2.37671 8.98053C2.36944 8.95375 2.37308 8.92518 2.38682 8.90107C2.41537 8.85455 2.47717 8.84145 2.52923 8.87537C2.58129 8.9093 2.60295 8.9763 2.5744 9.02282Z"
          fill="white"
        />
        <path
          d="M2.35372 9.37516C2.32383 9.42285 2.25531 9.43192 2.20375 9.39733C2.17982 9.38275 2.16256 9.35938 2.15565 9.33223C2.14874 9.30507 2.15275 9.2763 2.16681 9.25206C2.17429 9.24057 2.18404 9.23073 2.19546 9.22315C2.20687 9.21556 2.21972 9.21038 2.23321 9.20792C2.2467 9.20547 2.26054 9.2058 2.2739 9.20888C2.28726 9.21197 2.29985 9.21774 2.3109 9.22586C2.36296 9.25995 2.38345 9.32713 2.35372 9.37516Z"
          fill="white"
        />
        <path
          d="M2.75057 9.63805C2.74278 9.65017 2.73257 9.66055 2.72058 9.66855C2.70859 9.67654 2.69508 9.68198 2.6809 9.68452C2.66671 9.68705 2.65216 9.68664 2.63814 9.6833C2.62412 9.67995 2.61094 9.67376 2.59943 9.66509C2.54552 9.62899 2.52487 9.55778 2.55476 9.50925C2.58465 9.46071 2.65435 9.45215 2.70859 9.48775C2.76283 9.52335 2.78097 9.58885 2.75057 9.63805Z"
          fill="white"
        />
        <path
          d="M2.5237 10.0122C2.51698 10.025 2.5075 10.0361 2.49596 10.0447C2.48442 10.0533 2.47111 10.0593 2.45698 10.0622C2.44285 10.065 2.42826 10.0647 2.41427 10.0612C2.40028 10.0578 2.38723 10.0512 2.37608 10.0421C2.32419 10.0028 2.30437 9.9264 2.33443 9.87417C2.36449 9.82194 2.43385 9.81253 2.48692 9.84998C2.51223 9.86752 2.53005 9.89391 2.53686 9.92395C2.54367 9.95398 2.53897 9.98547 2.5237 10.0122Z"
          fill="white"
        />
        <path
          d="M2.30485 10.4131C2.27546 10.4697 2.21601 10.4833 2.16714 10.4391C2.11827 10.3949 2.09811 10.3128 2.12582 10.2589C2.15353 10.205 2.21953 10.1973 2.27008 10.2387C2.29436 10.2598 2.31095 10.2884 2.31724 10.32C2.32353 10.3515 2.31917 10.3843 2.30485 10.4131Z"
          fill="white"
        />
        <path
          d="M3.05456 10.9225C3.02282 10.9806 2.95615 11.0049 2.90174 10.9718C2.87505 10.9541 2.85586 10.9272 2.84786 10.8962C2.83986 10.8652 2.84362 10.8323 2.85841 10.8039C2.8657 10.7904 2.87567 10.7785 2.88771 10.7689C2.89976 10.7594 2.91364 10.7524 2.92848 10.7484C2.94332 10.7444 2.95883 10.7435 2.97404 10.7457C2.98925 10.7479 3.00385 10.7532 3.01695 10.7612C3.04284 10.778 3.06122 10.8042 3.06823 10.8343C3.07525 10.8643 3.07035 10.8959 3.05456 10.9225Z"
          fill="white"
        />
        <path
          d="M3.66334 11.8232C3.63731 11.884 3.58441 11.9166 3.53067 11.8862C3.50266 11.8686 3.48151 11.842 3.47076 11.8107C3.46 11.7794 3.46028 11.7454 3.47155 11.7144C3.47778 11.7005 3.48684 11.6882 3.49815 11.6781C3.50945 11.668 3.52276 11.6603 3.53719 11.6557C3.55163 11.6511 3.56688 11.6495 3.58196 11.6511C3.59703 11.6528 3.6116 11.6575 3.62471 11.6652C3.67829 11.6951 3.68937 11.7622 3.66334 11.8232Z"
          fill="white"
        />
        <path
          d="M2.96916 12.4738C2.96228 12.5346 2.89947 12.5454 2.83146 12.5019C2.76344 12.4584 2.72078 12.3813 2.72969 12.3222C2.73859 12.2631 2.79451 12.2436 2.86034 12.2854C2.92617 12.3272 2.97555 12.4132 2.96916 12.4738Z"
          fill="white"
        />
        <path
          d="M1.55745 11.198C1.55157 11.2472 1.51362 11.2336 1.47516 11.1725C1.4367 11.1113 1.41336 11.0289 1.41873 10.982C1.42411 10.9352 1.45669 10.9402 1.49565 10.9988C1.53461 11.0574 1.56333 11.1488 1.55745 11.198Z"
          fill="white"
        />
        <path
          d="M1.6377 10.8217C1.62259 10.8721 1.57607 10.8652 1.53694 10.8095C1.49781 10.7537 1.479 10.6751 1.49277 10.6268C1.50654 10.5784 1.54752 10.5787 1.58883 10.6315C1.63014 10.6842 1.65265 10.7714 1.6377 10.8217Z"
          fill="white"
        />
        <path
          d="M1.77104 10.4523C1.74937 10.5037 1.69496 10.5026 1.65214 10.4523C1.60931 10.4019 1.5942 10.3275 1.61418 10.2795C1.63417 10.2314 1.68304 10.2261 1.72754 10.2722C1.77204 10.3184 1.7927 10.4004 1.77104 10.4523Z"
          fill="white"
        />
        <path
          d="M4.35116 9.68877C4.32311 9.73479 4.2504 9.74167 4.19044 9.70557C4.13049 9.66946 4.10916 9.6048 4.13721 9.55829C4.16525 9.51177 4.23243 9.50219 4.29205 9.53847C4.35166 9.57474 4.37937 9.64276 4.35116 9.68877Z"
          fill="white"
        />
        <path
          d="M4.11252 10.9877C4.07893 11.0416 4.01629 11.0653 3.96255 11.0336C3.94917 11.026 3.93745 11.0158 3.92808 11.0036C3.91871 10.9914 3.91189 10.9774 3.90801 10.9625C3.90413 10.9476 3.90328 10.9321 3.90551 10.9169C3.90773 10.9017 3.91299 10.887 3.92097 10.8739C3.92894 10.8607 3.93948 10.8493 3.95195 10.8403C3.96442 10.8313 3.97857 10.8249 3.99356 10.8214C4.00856 10.818 4.02409 10.8176 4.03925 10.8202C4.0544 10.8229 4.06887 10.8286 4.08179 10.8369C4.13603 10.8693 4.14611 10.934 4.11252 10.9877Z"
          fill="white"
        />
        <path
          d="M3.17253 8.01046C3.14566 8.05715 3.07865 8.06555 3.02609 8.03179C2.97353 7.99804 2.9564 7.9354 2.98327 7.88871C3.01014 7.84202 3.07076 7.82926 3.12333 7.86302C3.17589 7.89677 3.19974 7.96395 3.17253 8.01046Z"
          fill="white"
        />
        <path
          d="M3.36851 7.67314C3.34164 7.71982 3.2743 7.72822 3.22156 7.6943C3.16883 7.66037 3.1517 7.59807 3.17857 7.55138C3.20544 7.50469 3.2664 7.49176 3.31914 7.52569C3.37187 7.55961 3.39555 7.62662 3.36851 7.67314Z"
          fill="white"
        />
        <path
          d="M3.9649 6.6735C3.93568 6.72002 3.86615 6.72841 3.81375 6.69466C3.76136 6.6609 3.7449 6.59826 3.77412 6.55174C3.80334 6.50522 3.86649 6.49212 3.91972 6.52588C3.97296 6.55963 3.99496 6.62698 3.9649 6.6735Z"
          fill="white"
        />
        <path
          d="M3.76525 6.9982C3.7372 7.04472 3.66868 7.05312 3.61562 7.01936C3.56255 6.98561 3.54592 6.92297 3.57413 6.87645C3.60235 6.82993 3.66449 6.81683 3.71755 6.85059C3.77062 6.88434 3.79363 6.95152 3.76525 6.9982Z"
          fill="white"
        />
        <path
          d="M8.23378 3.68065C8.1928 3.72028 8.11622 3.72532 8.06416 3.69173C8.0121 3.65814 8.00438 3.5997 8.04737 3.56057C8.0708 3.5413 8.09966 3.52985 8.12993 3.5278C8.16019 3.52575 8.19034 3.53322 8.21615 3.54915C8.2667 3.5819 8.27493 3.64085 8.23378 3.68065Z"
          fill="white"
        />
        <path
          d="M7.44067 2.17108C7.39415 2.19862 7.31455 2.19039 7.26551 2.15311C7.21647 2.11583 7.21664 2.06478 7.26332 2.03824C7.31001 2.01171 7.38491 2.0191 7.43395 2.05504C7.48299 2.09097 7.48719 2.14371 7.44067 2.17108Z"
          fill="white"
        />
        <path
          d="M9.65951 2.32137C9.59503 2.35395 9.50619 2.35496 9.45513 2.32523C9.40408 2.29551 9.41197 2.24412 9.47764 2.21204C9.5433 2.17996 9.64104 2.17845 9.69092 2.20818C9.7408 2.2379 9.72417 2.28896 9.65951 2.32137Z"
          fill="white"
        />
      </g>
    </g>
    <g opacity="0.33">
      <mask
        height="10"
        id="mask2_4286_2909"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="17"
        x="0"
        y="9"
      >
        <path
          d="M0.937774 14.6429C0.673776 13.8227 0.452434 12.9713 0.508861 12.1024C0.559242 11.326 0.743974 10.6326 0.937774 9.8844C1.22847 11.6257 2.31487 13.2306 3.98954 13.8921C4.93235 14.2644 5.86104 14.3049 6.8591 14.3348C8.76822 14.3917 10.6799 14.3131 12.5895 14.3136C13.2297 14.3136 13.894 14.3655 14.5287 14.2724C15.5648 14.1213 16.0008 13.4291 16.0107 12.414C16.4218 13.3572 16.5909 14.4461 16.3924 15.469C16.2037 16.4422 15.6715 17.2715 15.1065 18.0673C14.6172 19.0546 12.9254 18.8489 12.0281 18.8528C10.8189 18.8582 9.60975 18.8192 8.4006 18.8204C7.2462 18.8204 5.94518 18.9588 4.84032 18.5673C3.07764 17.9422 1.71079 16.2704 0.937774 14.6429Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_4286_2909)">
        <path
          d="M15.6808 15.0626C15.625 15.0962 15.5726 15.0598 15.5669 14.9965C15.5612 14.9331 15.6044 14.8688 15.6601 14.8341C15.7159 14.7993 15.7626 14.808 15.7684 14.8729C15.7743 14.9377 15.7365 15.0294 15.6808 15.0626Z"
          fill="white"
        />
        <path
          d="M15.7258 15.5834C15.6699 15.6129 15.6195 15.5834 15.6138 15.5115C15.6081 15.4396 15.6497 15.3521 15.7055 15.3207C15.7613 15.2893 15.8105 15.3269 15.8162 15.4013C15.8219 15.4757 15.7816 15.5538 15.7258 15.5834Z"
          fill="white"
        />
        <path
          d="M15.7111 15.9523C15.6857 15.9654 15.6662 15.9523 15.6681 15.9239C15.6701 15.9085 15.6757 15.8938 15.6845 15.8809C15.6932 15.8681 15.7049 15.8575 15.7185 15.85C15.7442 15.8369 15.7631 15.8483 15.7613 15.8765C15.7593 15.8922 15.7537 15.9071 15.745 15.9202C15.7363 15.9334 15.7247 15.9443 15.7111 15.9523Z"
          fill="white"
        />
        <path
          d="M15.2828 16.1299C15.254 16.1396 15.2314 16.1238 15.2325 16.0963C15.2343 16.0811 15.2403 16.0668 15.2499 16.0549C15.2595 16.043 15.2723 16.0341 15.2867 16.0291C15.3158 16.0196 15.3381 16.0335 15.3371 16.0615C15.3352 16.0768 15.3291 16.0913 15.3195 16.1033C15.31 16.1154 15.2973 16.1246 15.2828 16.1299Z"
          fill="white"
        />
        <path
          d="M14.8143 16.2583C14.8081 16.2605 14.8014 16.2611 14.7949 16.2601C14.7884 16.2591 14.7822 16.2565 14.7768 16.2526C14.7715 16.2488 14.7671 16.2437 14.7642 16.2378C14.7612 16.2318 14.7597 16.2253 14.7598 16.2187C14.7612 16.2037 14.7675 16.1895 14.7776 16.1782C14.7877 16.167 14.8012 16.1594 14.816 16.1564C14.8469 16.1497 14.8713 16.1661 14.8708 16.1944C14.8693 16.2096 14.8631 16.224 14.8529 16.2355C14.8428 16.247 14.8293 16.255 14.8143 16.2583Z"
          fill="white"
        />
        <path
          d="M14.3295 16.3444C14.3229 16.3458 14.3159 16.3458 14.3093 16.3442C14.3026 16.3427 14.2964 16.3397 14.291 16.3354C14.2857 16.3312 14.2813 16.3258 14.2783 16.3197C14.2752 16.3136 14.2736 16.3069 14.2734 16.3C14.2745 16.2853 14.2806 16.2714 14.2909 16.2607C14.3011 16.2501 14.3148 16.2434 14.3295 16.2418C14.3361 16.2402 14.343 16.2401 14.3496 16.2414C14.3562 16.2428 14.3625 16.2457 14.3679 16.2498C14.3733 16.2539 14.3777 16.2591 14.3808 16.2652C14.3839 16.2712 14.3856 16.2778 14.3858 16.2846C14.3847 16.2995 14.3785 16.3136 14.3683 16.3245C14.358 16.3354 14.3444 16.3424 14.3295 16.3444Z"
          fill="white"
        />
        <path
          d="M13.8511 16.3959C13.8443 16.3966 13.8374 16.3959 13.8309 16.3938C13.8244 16.3917 13.8184 16.3883 13.8133 16.3838C13.8082 16.3792 13.8041 16.3736 13.8012 16.3674C13.7984 16.3612 13.7969 16.3544 13.7969 16.3475C13.7972 16.3333 13.8029 16.3197 13.8128 16.3094C13.8226 16.2991 13.836 16.293 13.8503 16.2921C13.857 16.2913 13.8638 16.2919 13.8703 16.2938C13.8768 16.2958 13.8828 16.2991 13.888 16.3035C13.8931 16.308 13.8972 16.3134 13.9001 16.3196C13.903 16.3257 13.9046 16.3324 13.9047 16.3392C13.9043 16.3536 13.8987 16.3673 13.8888 16.3778C13.8789 16.3883 13.8655 16.3947 13.8511 16.3959Z"
          fill="white"
        />
        <path
          d="M13.4046 16.4205C13.398 16.4203 13.3914 16.4188 13.3854 16.4161C13.3793 16.4134 13.3738 16.4095 13.3693 16.4047C13.3647 16.3999 13.3612 16.3942 13.3588 16.388C13.3565 16.3818 13.3554 16.3752 13.3556 16.3686C13.3552 16.3619 13.3561 16.3552 13.3583 16.3489C13.3606 16.3426 13.364 16.3368 13.3685 16.3318C13.373 16.3269 13.3784 16.3229 13.3845 16.32C13.3905 16.3172 13.3971 16.3156 13.4038 16.3153C13.4104 16.3151 13.4171 16.3163 13.4232 16.3188C13.4294 16.3213 13.4351 16.325 13.4398 16.3297C13.4445 16.3344 13.4482 16.34 13.4507 16.3462C13.4531 16.3524 13.4543 16.359 13.4541 16.3657C13.4546 16.3795 13.4497 16.3929 13.4404 16.4031C13.4312 16.4133 13.4183 16.4195 13.4046 16.4205Z"
          fill="white"
        />
        <path
          d="M12.9703 16.4292C12.9564 16.4289 12.9432 16.4232 12.9334 16.4133C12.9236 16.4034 12.9181 16.3901 12.9181 16.3762C12.9177 16.3695 12.9186 16.3627 12.9208 16.3564C12.923 16.3501 12.9265 16.3443 12.931 16.3393C12.9355 16.3343 12.941 16.3303 12.9471 16.3274C12.9531 16.3246 12.9597 16.323 12.9664 16.3228C12.9802 16.3227 12.9935 16.3281 13.0033 16.3378C13.0131 16.3475 13.0187 16.3607 13.0188 16.3745C13.0193 16.3813 13.0183 16.3881 13.0161 16.3945C13.0139 16.4009 13.0105 16.4069 13.006 16.412C13.0014 16.417 12.996 16.4212 12.9898 16.4242C12.9837 16.4271 12.9771 16.4288 12.9703 16.4292Z"
          fill="white"
        />
        <path
          d="M12.5468 16.4366C12.5331 16.4358 12.5203 16.4297 12.511 16.4197C12.5016 16.4098 12.4964 16.3966 12.4964 16.3829C12.4957 16.3763 12.4962 16.3697 12.4981 16.3634C12.5 16.3571 12.5032 16.3512 12.5074 16.3462C12.5116 16.3411 12.5168 16.337 12.5227 16.3341C12.5286 16.3311 12.535 16.3294 12.5416 16.329C12.5551 16.3296 12.5679 16.3354 12.5773 16.3451C12.5866 16.3549 12.5919 16.3678 12.592 16.3814C12.5927 16.388 12.592 16.3946 12.5901 16.401C12.5882 16.4073 12.5851 16.4132 12.5809 16.4184C12.5767 16.4235 12.5715 16.4278 12.5657 16.4309C12.5598 16.434 12.5534 16.436 12.5468 16.4366Z"
          fill="white"
        />
        <path
          d="M12.1382 16.4431C12.1248 16.442 12.1123 16.4358 12.1033 16.4257C12.0944 16.4156 12.0897 16.4025 12.0901 16.389C12.0894 16.3826 12.09 16.376 12.0919 16.3698C12.0937 16.3635 12.0968 16.3577 12.1009 16.3527C12.1051 16.3476 12.1102 16.3435 12.1159 16.3404C12.1217 16.3374 12.128 16.3355 12.1345 16.335C12.1478 16.3358 12.1602 16.3417 12.1692 16.3516C12.1782 16.3614 12.183 16.3744 12.1827 16.3877C12.1841 16.4009 12.1802 16.4142 12.1719 16.4246C12.1635 16.435 12.1514 16.4416 12.1382 16.4431Z"
          fill="white"
        />
        <path
          d="M11.7392 16.4488C11.7264 16.4466 11.7147 16.44 11.7064 16.4301C11.698 16.4202 11.6934 16.4076 11.6934 16.3946C11.6934 16.3816 11.698 16.3691 11.7064 16.3591C11.7147 16.3492 11.7264 16.3426 11.7392 16.3405C11.7457 16.3409 11.752 16.3426 11.7578 16.3455C11.7637 16.3484 11.7689 16.3525 11.7731 16.3574C11.7774 16.3623 11.7806 16.368 11.7827 16.3742C11.7847 16.3803 11.7855 16.3869 11.785 16.3934C11.7862 16.4068 11.782 16.4201 11.7734 16.4304C11.7649 16.4408 11.7526 16.4474 11.7392 16.4488Z"
          fill="white"
        />
        <path
          d="M11.3401 16.4539C11.3335 16.4534 11.3271 16.4516 11.3213 16.4485C11.3154 16.4455 11.3103 16.4413 11.3061 16.4363C11.3019 16.4312 11.2988 16.4253 11.2969 16.419C11.2951 16.4127 11.2945 16.406 11.2953 16.3995C11.2949 16.3861 11.2996 16.373 11.3085 16.363C11.3175 16.353 11.3299 16.3467 11.3433 16.3456C11.3498 16.346 11.3561 16.3476 11.3619 16.3505C11.3677 16.3534 11.3728 16.3575 11.377 16.3624C11.3812 16.3674 11.3843 16.3731 11.3862 16.3793C11.3881 16.3855 11.3887 16.392 11.388 16.3985C11.3884 16.412 11.3838 16.4253 11.3749 16.4356C11.366 16.4458 11.3536 16.4524 11.3401 16.4539Z"
          fill="white"
        />
        <path
          d="M10.9285 16.4585C10.9219 16.4581 10.9154 16.4564 10.9095 16.4534C10.9036 16.4505 10.8983 16.4463 10.8941 16.4412C10.8898 16.4361 10.8867 16.4302 10.8848 16.4239C10.8829 16.4175 10.8824 16.4108 10.8832 16.4042C10.8834 16.3906 10.8887 16.3776 10.898 16.3676C10.9073 16.3577 10.92 16.3515 10.9336 16.3503C10.9401 16.3505 10.9465 16.3521 10.9524 16.355C10.9583 16.3578 10.9636 16.3618 10.9678 16.3668C10.972 16.3718 10.9752 16.3776 10.977 16.3839C10.9789 16.3902 10.9794 16.3968 10.9786 16.4032C10.9785 16.417 10.9733 16.4302 10.9641 16.4404C10.9549 16.4506 10.9422 16.457 10.9285 16.4585Z"
          fill="white"
        />
        <path
          d="M10.5013 16.4627C10.4946 16.4624 10.4879 16.4608 10.4818 16.4579C10.4757 16.455 10.4703 16.4508 10.4658 16.4458C10.4613 16.4407 10.4579 16.4348 10.4557 16.4284C10.4536 16.422 10.4528 16.4152 10.4533 16.4085C10.4539 16.3944 10.4598 16.3811 10.4698 16.3711C10.4798 16.3612 10.4931 16.3553 10.5072 16.3547C10.5139 16.3547 10.5205 16.3562 10.5266 16.3589C10.5328 16.3616 10.5382 16.3656 10.5427 16.3706C10.5472 16.3756 10.5506 16.3815 10.5526 16.3879C10.5547 16.3942 10.5554 16.401 10.5547 16.4076C10.5542 16.4218 10.5485 16.4353 10.5386 16.4454C10.5287 16.4556 10.5154 16.4618 10.5013 16.4627Z"
          fill="white"
        />
        <path
          d="M10.0497 16.4666C10.0426 16.4667 10.0356 16.4653 10.029 16.4626C10.0224 16.4599 10.0165 16.4559 10.0115 16.4508C10.0065 16.4458 10.0026 16.4398 9.99994 16.4332C9.99729 16.4267 9.99599 16.4196 9.9961 16.4125C9.99662 16.3981 10.0028 16.3844 10.0132 16.3743C10.0236 16.3643 10.0376 16.3587 10.052 16.3588C10.059 16.3586 10.066 16.3598 10.0725 16.3623C10.079 16.3649 10.085 16.3688 10.0899 16.3737C10.0949 16.3786 10.0988 16.3845 10.1014 16.391C10.104 16.3976 10.1053 16.4045 10.1051 16.4115C10.1047 16.4261 10.0987 16.4399 10.0884 16.4501C10.0781 16.4604 10.0642 16.4663 10.0497 16.4666Z"
          fill="white"
        />
        <path
          d="M9.63977 16.4696C9.62649 16.4685 9.6142 16.4621 9.6056 16.4519C9.59701 16.4417 9.59281 16.4285 9.59393 16.4152C9.59352 16.4086 9.59442 16.402 9.59658 16.3957C9.59874 16.3894 9.60212 16.3836 9.60652 16.3787C9.61092 16.3737 9.61625 16.3697 9.62222 16.3668C9.62819 16.3639 9.63467 16.3622 9.64129 16.3618C9.64781 16.3622 9.65419 16.3638 9.66005 16.3667C9.66592 16.3696 9.67114 16.3736 9.67542 16.3785C9.6797 16.3835 9.68294 16.3892 9.68495 16.3954C9.68696 16.4017 9.68771 16.4082 9.68713 16.4147C9.6878 16.4282 9.68326 16.4414 9.67444 16.4516C9.66562 16.4619 9.65321 16.4683 9.63977 16.4696Z"
          fill="white"
        />
        <path
          d="M9.23404 16.4719C9.22099 16.47 9.20908 16.4634 9.20052 16.4533C9.19196 16.4433 9.18734 16.4305 9.1875 16.4173C9.18767 16.4041 9.19261 16.3914 9.20141 16.3815C9.21022 16.3717 9.22229 16.3654 9.23538 16.3638C9.24194 16.3641 9.24836 16.3658 9.25426 16.3687C9.26016 16.3716 9.26543 16.3756 9.26975 16.3805C9.27407 16.3855 9.27735 16.3912 9.27941 16.3975C9.28147 16.4037 9.28226 16.4103 9.28174 16.4168C9.2824 16.4304 9.27781 16.4437 9.26893 16.4539C9.26005 16.4642 9.24755 16.4706 9.23404 16.4719Z"
          fill="white"
        />
        <path
          d="M8.82273 16.4731C8.80954 16.4713 8.79745 16.4648 8.78873 16.4547C8.78001 16.4447 8.77527 16.4318 8.77539 16.4185C8.77552 16.4052 8.7805 16.3923 8.7894 16.3825C8.7983 16.3726 8.81052 16.3663 8.82374 16.3647C8.83702 16.3657 8.8494 16.3718 8.85818 16.3818C8.86696 16.3918 8.87142 16.4049 8.87059 16.4182C8.87129 16.4317 8.86669 16.445 8.85776 16.4553C8.84883 16.4655 8.83627 16.4719 8.82273 16.4731Z"
          fill="white"
        />
        <path
          d="M8.41255 16.4733C8.39901 16.4721 8.38649 16.4657 8.37767 16.4553C8.36884 16.445 8.36442 16.4316 8.36536 16.4181C8.36493 16.4114 8.36583 16.4047 8.36801 16.3984C8.37019 16.3921 8.37361 16.3862 8.37806 16.3813C8.38252 16.3763 8.38792 16.3722 8.39396 16.3693C8.39999 16.3665 8.40654 16.3648 8.41322 16.3645C8.41984 16.3649 8.42631 16.3667 8.43226 16.3696C8.43821 16.3725 8.44353 16.3766 8.4479 16.3816C8.45226 16.3866 8.4556 16.3924 8.45772 16.3987C8.45984 16.405 8.4607 16.4116 8.46024 16.4182C8.46104 16.4318 8.45649 16.4452 8.44759 16.4555C8.43868 16.4658 8.42611 16.4722 8.41255 16.4733Z"
          fill="white"
        />
        <path
          d="M8.0026 16.4723C7.98904 16.4711 7.97649 16.4646 7.96766 16.4542C7.95883 16.4439 7.95444 16.4305 7.95541 16.4169C7.95462 16.4102 7.95524 16.4034 7.95726 16.397C7.95928 16.3905 7.96263 16.3846 7.96711 16.3796C7.97158 16.3745 7.97707 16.3705 7.98322 16.3677C7.98937 16.365 7.99603 16.3635 8.00277 16.3635C8.00951 16.3635 8.01618 16.365 8.02232 16.3677C8.02847 16.3705 8.03396 16.3745 8.03844 16.3796C8.04291 16.3846 8.04627 16.3905 8.04829 16.397C8.0503 16.4034 8.05093 16.4102 8.05013 16.4169C8.05111 16.4305 8.04666 16.444 8.03776 16.4544C8.02886 16.4647 8.01623 16.4712 8.0026 16.4723Z"
          fill="white"
        />
        <path
          d="M7.59207 16.4698C7.57852 16.4684 7.56604 16.4619 7.55726 16.4514C7.54849 16.441 7.54411 16.4276 7.54505 16.414C7.54461 16.4074 7.54549 16.4008 7.54763 16.3945C7.54977 16.3882 7.55313 16.3824 7.55752 16.3775C7.5619 16.3725 7.56722 16.3684 7.57318 16.3655C7.57914 16.3626 7.58562 16.3609 7.59224 16.3605C7.5989 16.3609 7.60542 16.3626 7.61141 16.3656C7.61739 16.3686 7.62273 16.3727 7.6271 16.3778C7.63148 16.3828 7.63481 16.3887 7.6369 16.395C7.63898 16.4014 7.63979 16.4081 7.63926 16.4147C7.64019 16.4283 7.63577 16.4416 7.62694 16.4519C7.61811 16.4622 7.60559 16.4686 7.59207 16.4698Z"
          fill="white"
        />
        <path
          d="M7.18353 16.4656C7.17002 16.464 7.15762 16.4574 7.14892 16.4469C7.14022 16.4365 7.1359 16.423 7.13685 16.4095C7.13602 16.3962 7.14046 16.3832 7.1492 16.3732C7.15794 16.3632 7.17028 16.357 7.18353 16.3561C7.19658 16.3581 7.20847 16.3648 7.21705 16.3748C7.22564 16.3848 7.23035 16.3976 7.23035 16.4108C7.23035 16.424 7.22564 16.4368 7.21705 16.4468C7.20847 16.4569 7.19658 16.4635 7.18353 16.4656Z"
          fill="white"
        />
        <path
          d="M6.78511 16.4596C6.77159 16.4579 6.75923 16.4511 6.75056 16.4406C6.74189 16.4301 6.73755 16.4166 6.73843 16.403C6.73792 16.3965 6.73874 16.3899 6.74081 16.3837C6.74289 16.3774 6.74619 16.3717 6.75053 16.3667C6.75486 16.3618 6.76014 16.3578 6.76605 16.3549C6.77196 16.352 6.77838 16.3503 6.78494 16.35C6.79158 16.3505 6.79805 16.3523 6.80398 16.3554C6.8099 16.3584 6.81516 16.3626 6.81946 16.3677C6.82375 16.3728 6.827 16.3787 6.829 16.385C6.831 16.3914 6.83173 16.3981 6.83113 16.4047C6.83113 16.4351 6.8103 16.4601 6.78511 16.4596Z"
          fill="white"
        />
        <path
          d="M6.37396 16.4437C6.36019 16.4419 6.34762 16.4349 6.33878 16.4242C6.32993 16.4135 6.32546 16.3998 6.32626 16.386C6.32596 16.3794 6.32701 16.3728 6.32934 16.3667C6.33167 16.3605 6.33524 16.3549 6.33982 16.3502C6.34439 16.3455 6.34989 16.3418 6.35596 16.3392C6.36204 16.3367 6.36856 16.3355 6.37513 16.3356C6.3887 16.3371 6.40117 16.3438 6.40998 16.3542C6.41879 16.3646 6.42326 16.378 6.42249 16.3917C6.42288 16.3983 6.42191 16.405 6.41966 16.4112C6.4174 16.4175 6.4139 16.4232 6.40936 16.4281C6.40482 16.4329 6.39935 16.4368 6.39326 16.4395C6.38718 16.4422 6.38061 16.4436 6.37396 16.4437Z"
          fill="white"
        />
        <path
          d="M5.9498 16.4095C5.93599 16.4072 5.92352 16.3999 5.91477 16.389C5.90602 16.3781 5.90163 16.3643 5.90244 16.3504C5.90242 16.3438 5.90376 16.3373 5.90637 16.3313C5.90899 16.3253 5.91283 16.3199 5.91765 16.3154C5.92246 16.311 5.92815 16.3076 5.93435 16.3054C5.94055 16.3033 5.94712 16.3025 5.95366 16.303C5.96731 16.3049 5.97975 16.3118 5.9885 16.3225C5.99724 16.3331 6.00165 16.3467 6.00085 16.3604C6.001 16.3671 5.99977 16.3737 5.99723 16.3799C5.99469 16.386 5.9909 16.3916 5.9861 16.3962C5.9813 16.4008 5.9756 16.4044 5.96935 16.4067C5.9631 16.4089 5.95644 16.4099 5.9498 16.4095Z"
          fill="white"
        />
        <path
          d="M5.52059 16.3536C5.50695 16.3508 5.49479 16.3432 5.48636 16.3321C5.47793 16.321 5.4738 16.3072 5.47474 16.2933C5.47499 16.2869 5.47661 16.2805 5.4795 16.2747C5.48239 16.2689 5.48647 16.2638 5.49149 16.2597C5.49651 16.2555 5.50234 16.2525 5.5086 16.2509C5.51486 16.2492 5.52141 16.2488 5.52781 16.2498C5.54131 16.2523 5.55342 16.2597 5.56185 16.2705C5.57027 16.2814 5.57441 16.2949 5.57349 16.3086C5.57336 16.3152 5.57185 16.3217 5.56903 16.3276C5.56622 16.3335 5.56218 16.3388 5.55718 16.343C5.55218 16.3473 5.54633 16.3504 5.54002 16.3523C5.53371 16.3541 5.52709 16.3546 5.52059 16.3536Z"
          fill="white"
        />
        <path
          d="M5.09286 16.2732C5.07944 16.2696 5.06769 16.2615 5.05968 16.2501C5.05167 16.2388 5.04789 16.225 5.04902 16.2112C5.04945 16.2049 5.05126 16.1987 5.05432 16.1932C5.05738 16.1876 5.06163 16.1828 5.06675 16.1791C5.07188 16.1753 5.07776 16.1728 5.08398 16.1716C5.0902 16.1704 5.09661 16.1705 5.10276 16.1721C5.11608 16.1752 5.12782 16.1831 5.13583 16.1942C5.14384 16.2053 5.14761 16.2189 5.14643 16.2325C5.14612 16.2389 5.14442 16.2452 5.14143 16.2509C5.13844 16.2565 5.13425 16.2615 5.12914 16.2654C5.12403 16.2692 5.11813 16.2719 5.11186 16.2733C5.10559 16.2746 5.09911 16.2746 5.09286 16.2732Z"
          fill="white"
        />
        <path
          d="M4.67875 16.1649C4.66563 16.1604 4.65441 16.1516 4.64684 16.1399C4.63927 16.1283 4.63579 16.1145 4.63693 16.1006C4.63734 16.0945 4.63915 16.0886 4.64222 16.0833C4.6453 16.078 4.64956 16.0735 4.65467 16.0701C4.65977 16.0668 4.66559 16.0646 4.67166 16.0639C4.67773 16.0631 4.6839 16.0638 4.68967 16.0659C4.70272 16.0701 4.71396 16.0786 4.72155 16.0901C4.72914 16.1015 4.73264 16.1152 4.73148 16.1288C4.73114 16.135 4.72939 16.141 4.72636 16.1464C4.72333 16.1518 4.7191 16.1564 4.71401 16.1599C4.70891 16.1634 4.70308 16.1656 4.69697 16.1665C4.69085 16.1674 4.68462 16.1669 4.67875 16.1649Z"
          fill="white"
        />
        <path
          d="M4.27657 16.0259C4.26395 16.02 4.25339 16.0104 4.24624 15.9985C4.23909 15.9865 4.23568 15.9727 4.23643 15.9587C4.23619 15.9527 4.23757 15.9468 4.24042 15.9415C4.24328 15.9362 4.24749 15.9318 4.25264 15.9287C4.25779 15.9256 4.26367 15.9239 4.26968 15.9238C4.27569 15.9238 4.28161 15.9253 4.28681 15.9283C4.29954 15.9339 4.31025 15.9432 4.31747 15.955C4.32468 15.9669 4.32805 15.9807 4.32712 15.9945C4.3271 16.0005 4.32557 16.0063 4.32267 16.0115C4.31977 16.0167 4.3156 16.0211 4.31054 16.0242C4.30549 16.0274 4.29972 16.0292 4.29377 16.0295C4.28783 16.0298 4.28191 16.0285 4.27657 16.0259Z"
          fill="white"
        />
        <path
          d="M3.90275 15.8531C3.89058 15.8455 3.88056 15.8349 3.87365 15.8223C3.86674 15.8097 3.86317 15.7955 3.86328 15.7812C3.86446 15.7538 3.88428 15.7424 3.90762 15.7547C3.91988 15.7619 3.93001 15.7722 3.93697 15.7847C3.94393 15.7971 3.94748 15.8111 3.94725 15.8254C3.94574 15.8526 3.92576 15.8653 3.90275 15.8531Z"
          fill="white"
        />
        <path
          d="M3.54875 15.6388C3.53605 15.6296 3.52547 15.6178 3.51773 15.6042C3.50999 15.5906 3.50528 15.5755 3.50391 15.5598C3.50391 15.5326 3.52389 15.5244 3.54807 15.5402C3.56058 15.549 3.57097 15.5604 3.57846 15.5737C3.58596 15.5871 3.59038 15.6019 3.5914 15.6171C3.59157 15.6445 3.57293 15.6547 3.54875 15.6388Z"
          fill="white"
        />
        <path
          d="M3.1226 15.3469C3.09288 15.3257 3.06836 15.2868 3.06836 15.2611C3.06836 15.2354 3.09305 15.2325 3.12277 15.2532C3.1525 15.2738 3.17601 15.3109 3.17601 15.3371C3.17601 15.3633 3.1525 15.368 3.1226 15.3469Z"
          fill="white"
        />
        <path
          d="M2.6286 14.9765C2.59585 14.9504 2.56965 14.9093 2.57033 14.8844C2.571 14.8596 2.59803 14.8626 2.63061 14.8883C2.66319 14.914 2.68906 14.9534 2.68872 14.978C2.68839 15.0025 2.66219 15.0033 2.6286 14.9765Z"
          fill="white"
        />
        <path
          d="M2.10631 14.5331C2.07273 14.5025 2.04754 14.458 2.04888 14.4348C2.05022 14.4117 2.0781 14.4189 2.11102 14.4491C2.14393 14.4793 2.16996 14.5215 2.16879 14.5448C2.16761 14.5682 2.1399 14.5636 2.10631 14.5331Z"
          fill="white"
        />
        <path
          d="M1.6007 14.0232C1.56963 13.9886 1.54679 13.9409 1.54897 13.9181C1.55116 13.8953 1.57735 13.9063 1.60825 13.9409C1.63916 13.9755 1.663 14.0204 1.66099 14.0432C1.65897 14.066 1.63177 14.0576 1.6007 14.0232Z"
          fill="white"
        />
        <path
          d="M1.14755 13.4524C1.12118 13.4143 1.10305 13.3626 1.10573 13.3389C1.10842 13.3152 1.13109 13.3287 1.15729 13.3673C1.18349 13.4059 1.2033 13.4544 1.20062 13.4778C1.19793 13.5011 1.17375 13.4907 1.14755 13.4524Z"
          fill="white"
        />
        <path
          d="M15.2538 15.2643C15.2403 15.2695 15.2258 15.2714 15.2114 15.2698C15.197 15.2683 15.1833 15.2633 15.1712 15.2552C15.1592 15.2472 15.1493 15.2364 15.1423 15.2237C15.1354 15.2111 15.1315 15.1969 15.1312 15.1825C15.1263 15.1205 15.174 15.0649 15.2353 15.0421C15.2966 15.0192 15.3483 15.0367 15.3539 15.099C15.3594 15.1613 15.3156 15.2414 15.2538 15.2643Z"
          fill="white"
        />
        <path
          d="M15.2929 15.766C15.2301 15.7872 15.1753 15.7526 15.1703 15.682C15.1653 15.6115 15.2123 15.5342 15.2746 15.5122C15.3369 15.4902 15.3921 15.5332 15.3968 15.6044C15.3991 15.639 15.3903 15.6733 15.3716 15.7024C15.3528 15.7315 15.3253 15.7538 15.2929 15.766Z"
          fill="white"
        />
        <path
          d="M15.2497 16.6217C15.1899 16.6431 15.1466 16.6145 15.153 16.5546C15.1593 16.4946 15.2139 16.4284 15.2749 16.4074C15.3359 16.3865 15.3792 16.4185 15.3716 16.4778C15.3641 16.5371 15.3093 16.6004 15.2497 16.6217Z"
          fill="white"
        />
        <path
          d="M14.6202 17.704C14.5658 17.7232 14.53 17.6928 14.5411 17.6301C14.5522 17.5675 14.6083 17.499 14.6648 17.4808C14.7214 17.4627 14.7562 17.5011 14.7426 17.5624C14.7345 17.5939 14.7193 17.623 14.698 17.6476C14.6768 17.6722 14.6501 17.6915 14.6202 17.704Z"
          fill="white"
        />
        <path
          d="M14.2186 17.8221C14.1622 17.8355 14.1212 17.7989 14.1276 17.7346C14.134 17.6703 14.1869 17.6041 14.2452 17.5917C14.3034 17.5792 14.3442 17.6241 14.3357 17.6876C14.3309 17.7188 14.3174 17.748 14.2967 17.7718C14.2759 17.7956 14.2489 17.8131 14.2186 17.8221Z"
          fill="white"
        />
        <path
          d="M13.8073 17.8973C13.7504 17.9047 13.7066 17.8638 13.7091 17.7976C13.7116 17.7314 13.7615 17.6695 13.8201 17.6632C13.8787 17.657 13.923 17.7069 13.9185 17.7719C13.9167 17.8022 13.9047 17.8311 13.8846 17.8538C13.8644 17.8766 13.8372 17.8919 13.8073 17.8973Z"
          fill="white"
        />
        <path
          d="M12.9935 17.9277C12.9796 17.9273 12.966 17.9242 12.9533 17.9185C12.9407 17.9128 12.9294 17.9046 12.92 17.8944C12.9106 17.8842 12.9033 17.8722 12.8986 17.8591C12.894 17.846 12.892 17.8322 12.8927 17.8183C12.8927 17.7549 12.9399 17.6979 12.9967 17.6978C13.0535 17.6976 13.0975 17.7549 13.0975 17.8192C13.0975 17.8835 13.0498 17.9282 12.9935 17.9277Z"
          fill="white"
        />
        <path
          d="M14.2734 17.3131C14.2121 17.3246 14.1678 17.2733 14.1745 17.2028C14.178 17.1687 14.1922 17.1366 14.215 17.111C14.2378 17.0854 14.268 17.0677 14.3015 17.0602C14.3651 17.0495 14.4091 17.093 14.4002 17.1627C14.3913 17.2324 14.3347 17.3015 14.2734 17.3131Z"
          fill="white"
        />
        <path
          d="M13.8319 17.3784C13.7716 17.3849 13.7256 17.329 13.7287 17.2578C13.7317 17.1866 13.7836 17.1287 13.8462 17.1223C13.9089 17.1159 13.9554 17.1634 13.9507 17.2345C13.946 17.3055 13.8922 17.3713 13.8319 17.3784Z"
          fill="white"
        />
        <path
          d="M13.4143 16.0519C13.3559 16.0529 13.3105 16.0003 13.3105 15.9293C13.3105 15.8582 13.3559 15.7971 13.4142 15.7963C13.4724 15.7954 13.5216 15.8552 13.5218 15.9263C13.522 15.9973 13.4728 16.0509 13.4143 16.0519Z"
          fill="white"
        />
        <path
          d="M13.4117 15.5086C13.3538 15.5086 13.3083 15.4515 13.3086 15.3862C13.3089 15.3209 13.3549 15.275 13.4125 15.2745C13.4267 15.2744 13.4407 15.2771 13.4538 15.2825C13.4668 15.288 13.4786 15.296 13.4885 15.3061C13.4983 15.3163 13.506 15.3284 13.511 15.3416C13.516 15.3548 13.5183 15.3689 13.5177 15.383C13.5175 15.4479 13.4696 15.5071 13.4117 15.5086Z"
          fill="white"
        />
        <path
          d="M12.5515 16.0612C12.4944 16.0612 12.4519 16.0092 12.4553 15.9375C12.4586 15.8657 12.5067 15.8031 12.5632 15.8031C12.6198 15.8031 12.664 15.8636 12.661 15.9353C12.658 16.007 12.6084 16.0612 12.5515 16.0612Z"
          fill="white"
        />
        <path
          d="M12.1676 16.9956C12.1101 16.9968 12.0625 16.9491 12.0606 16.8844C12.0588 16.8198 12.1029 16.7642 12.1595 16.7632C12.2161 16.7622 12.2646 16.8161 12.2673 16.8808C12.27 16.9454 12.2248 16.9944 12.1676 16.9956Z"
          fill="white"
        />
        <path
          d="M11.7501 17.0026C11.6925 17.0034 11.6462 16.9556 11.6465 16.8909C11.6468 16.8263 11.6925 16.7705 11.7491 16.7697C11.8057 16.7688 11.8524 16.8232 11.8532 16.8872C11.8542 16.9016 11.8523 16.916 11.8476 16.9297C11.8429 16.9433 11.8356 16.9559 11.826 16.9666C11.8163 16.9774 11.8047 16.9861 11.7917 16.9923C11.7786 16.9984 11.7645 17.002 11.7501 17.0026Z"
          fill="white"
        />
        <path
          d="M11.374 15.5344C11.318 15.5344 11.2733 15.4731 11.2755 15.4047C11.2777 15.3364 11.3258 15.289 11.3828 15.2892C11.4397 15.2894 11.4822 15.337 11.4797 15.4051C11.4772 15.4731 11.4301 15.5344 11.374 15.5344Z"
          fill="white"
        />
        <path
          d="M10.8688 17.9253C10.8548 17.9257 10.841 17.9231 10.8281 17.9178C10.8152 17.9124 10.8037 17.9044 10.7941 17.8942C10.7846 17.884 10.7774 17.8719 10.7729 17.8587C10.7684 17.8455 10.7667 17.8315 10.768 17.8177C10.7709 17.7545 10.8202 17.6984 10.8789 17.6978C10.9375 17.6971 10.983 17.752 10.9796 17.8153C10.9786 17.8443 10.9666 17.8718 10.946 17.8923C10.9254 17.9127 10.8978 17.9245 10.8688 17.9253Z"
          fill="white"
        />
        <path
          d="M10.4475 17.9309C10.39 17.9318 10.3459 17.8862 10.3477 17.8228C10.3496 17.7593 10.3981 17.7032 10.4555 17.7025C10.513 17.7018 10.5588 17.7568 10.5563 17.8201C10.5559 17.849 10.5443 17.8766 10.5241 17.8973C10.5038 17.9179 10.4764 17.93 10.4475 17.9309Z"
          fill="white"
        />
        <path
          d="M10.0384 17.9367C10.0246 17.9364 10.0109 17.9334 9.99829 17.9277C9.98564 17.9221 9.97428 17.9139 9.96486 17.9038C9.95545 17.8936 9.94819 17.8816 9.94352 17.8686C9.93885 17.8555 9.93686 17.8417 9.93768 17.8279C9.93768 17.764 9.98403 17.708 10.041 17.7073C10.0979 17.7066 10.1436 17.7617 10.1426 17.8248C10.1435 17.8535 10.1331 17.8813 10.1136 17.9023C10.0941 17.9232 10.0671 17.9356 10.0384 17.9367Z"
          fill="white"
        />
        <path
          d="M10.0398 17.4565C9.98173 17.4565 9.93572 17.4007 9.93555 17.3335C9.93538 17.2664 9.98106 17.215 10.0405 17.2145C10.1 17.214 10.147 17.2649 10.1463 17.332C10.1456 17.3992 10.0979 17.4551 10.0398 17.4565Z"
          fill="white"
        />
        <path
          d="M10.5256 16.085C10.4644 16.085 10.4141 16.0315 10.4141 15.9569C10.4141 15.8823 10.4644 15.8178 10.5261 15.8175C10.5877 15.8172 10.6364 15.8807 10.6362 15.955C10.6361 16.0294 10.5877 16.0844 10.5256 16.085Z"
          fill="white"
        />
        <path
          d="M10.527 15.5359C10.4652 15.5359 10.414 15.4741 10.4141 15.405C10.4136 15.3897 10.4162 15.3745 10.4217 15.3602C10.4272 15.3459 10.4356 15.3329 10.4463 15.3219C10.4569 15.3109 10.4697 15.3022 10.4839 15.2963C10.498 15.2904 10.5132 15.2874 10.5285 15.2875C10.5437 15.2873 10.5587 15.2902 10.5727 15.2961C10.5867 15.302 10.5993 15.3107 10.6097 15.3217C10.6202 15.3328 10.6282 15.3458 10.6333 15.3601C10.6384 15.3744 10.6405 15.3896 10.6395 15.4047C10.6382 15.4736 10.5891 15.5357 10.527 15.5359Z"
          fill="white"
        />
        <path
          d="M9.63201 17.4605C9.57575 17.4605 9.53125 17.4046 9.53125 17.3374C9.53125 17.2703 9.57878 17.2199 9.6347 17.2185C9.69062 17.2172 9.73546 17.2689 9.73445 17.3361C9.73345 17.4033 9.6881 17.46 9.63201 17.4605Z"
          fill="white"
        />
        <path
          d="M9.22266 17.463C9.1664 17.463 9.12189 17.4071 9.12307 17.3402C9.12425 17.2734 9.17144 17.2227 9.22753 17.2227C9.28362 17.2227 9.32829 17.273 9.32644 17.3402C9.32459 17.4074 9.27875 17.4626 9.22266 17.463Z"
          fill="white"
        />
        <path
          d="M9.23358 17.0092C9.17748 17.0092 9.13281 16.9612 9.13281 16.8962C9.13281 16.8312 9.17984 16.7753 9.23593 16.7748C9.29202 16.7743 9.33669 16.8297 9.33669 16.8947C9.33669 16.9597 9.28967 17.0087 9.23358 17.0092Z"
          fill="white"
        />
        <path
          d="M9.65154 16.0924C9.59579 16.0924 9.55078 16.0382 9.55078 15.9633C9.55078 15.8884 9.59562 15.8237 9.65154 15.8234C9.70747 15.823 9.75231 15.8874 9.75231 15.9621C9.75231 16.0368 9.7073 16.0921 9.65154 16.0924Z"
          fill="white"
        />
        <path
          d="M9.24681 16.0949C9.19038 16.0949 9.1447 16.0405 9.14453 15.9657C9.14436 15.891 9.18971 15.8262 9.24681 15.8262C9.30391 15.8262 9.34908 15.8903 9.34925 15.9651C9.34942 16.0398 9.3029 16.0949 9.24681 16.0949Z"
          fill="white"
        />
        <path
          d="M9.24095 15.5425C9.18469 15.5425 9.13867 15.4806 9.13867 15.4114C9.13867 15.3422 9.18519 15.2938 9.24145 15.2938C9.29771 15.2938 9.34221 15.341 9.34221 15.4102C9.34221 15.4794 9.2972 15.5422 9.24095 15.5425Z"
          fill="white"
        />
        <path
          d="M8.83699 16.0948C8.78039 16.0948 8.73454 16.0401 8.73438 15.9655C8.73421 15.8909 8.77989 15.8265 8.83665 15.8263C8.89341 15.8261 8.9396 15.8904 8.93976 15.9652C8.93993 16.0399 8.89425 16.0948 8.83699 16.0948Z"
          fill="white"
        />
        <path
          d="M8.83308 15.544C8.77648 15.544 8.7303 15.482 8.73047 15.413C8.73064 15.3439 8.77716 15.2954 8.83375 15.2954C8.89035 15.2954 8.93569 15.3426 8.93569 15.4118C8.93569 15.481 8.88984 15.5438 8.83308 15.544Z"
          fill="white"
        />
        <path
          d="M8.80643 17.9557C8.75067 17.9557 8.70751 17.9094 8.70902 17.8467C8.71053 17.7841 8.75655 17.7292 8.81247 17.7304C8.8684 17.7315 8.91324 17.7854 8.91122 17.8479C8.91142 17.8619 8.90887 17.8757 8.90371 17.8887C8.89855 17.9017 8.89089 17.9135 8.88116 17.9235C8.87142 17.9335 8.85982 17.9415 8.84699 17.9471C8.83417 17.9526 8.82039 17.9555 8.80643 17.9557Z"
          fill="white"
        />
        <path
          d="M8.81561 17.4832C8.75952 17.4832 8.71484 17.4273 8.71484 17.3605C8.71484 17.2936 8.76271 17.2429 8.81897 17.2421C8.87522 17.2412 8.91973 17.2925 8.91822 17.3596C8.91671 17.4268 8.8717 17.4832 8.81561 17.4832Z"
          fill="white"
        />
        <path
          d="M8.40936 17.4827C8.35343 17.4827 8.30859 17.4269 8.30859 17.3606C8.30859 17.2943 8.35545 17.243 8.41171 17.243C8.46797 17.243 8.51247 17.2934 8.51247 17.3606C8.51247 17.4278 8.46528 17.4829 8.40936 17.4827Z"
          fill="white"
        />
        <path
          d="M8.41719 17.031C8.36076 17.031 8.31542 16.9832 8.31642 16.9182C8.31743 16.8532 8.36294 16.7976 8.41954 16.7976C8.47613 16.7976 8.52181 16.8529 8.52131 16.9177C8.5208 16.9825 8.47361 17.0309 8.41719 17.031Z"
          fill="white"
        />
        <path
          d="M8.00411 17.4621C7.94819 17.4621 7.90251 17.4065 7.90234 17.3412C7.90218 17.2759 7.94802 17.2263 8.00428 17.2263C8.06054 17.2263 8.10505 17.2767 8.10505 17.342C8.10505 17.4074 8.06004 17.4624 8.00411 17.4621Z"
          fill="white"
        />
        <path
          d="M8.00338 17.9305C7.94813 17.9305 7.90413 17.883 7.9043 17.8205C7.90447 17.758 7.9493 17.7049 8.00506 17.7054C8.06082 17.706 8.10582 17.7607 8.10582 17.823C8.10643 17.8369 8.10424 17.8507 8.09937 17.8637C8.0945 17.8767 8.08706 17.8885 8.0775 17.8986C8.06793 17.9086 8.05643 17.9166 8.0437 17.9221C8.03096 17.9276 8.01725 17.9304 8.00338 17.9305Z"
          fill="white"
        />
        <path
          d="M8.00898 17.0149C7.95255 17.0149 7.90737 16.9658 7.90821 16.9002C7.90905 16.8345 7.95406 16.7784 8.01066 16.7786C8.06725 16.7787 8.11293 16.8347 8.1126 16.9003C8.11226 16.966 8.0654 17.0149 8.00898 17.0149Z"
          fill="white"
        />
        <path
          d="M7.59881 17.0139C7.54255 17.0139 7.49805 16.9636 7.49805 16.8984C7.49805 16.8332 7.54339 16.7766 7.59881 16.777C7.65423 16.7773 7.70092 16.8337 7.70075 16.8994C7.70058 16.9651 7.6549 17.0141 7.59881 17.0139Z"
          fill="white"
        />
        <path
          d="M8.0126 16.0935C7.95583 16.0935 7.91016 16.0384 7.91016 15.964C7.91016 15.8897 7.95567 15.826 8.01243 15.8262C8.06919 15.8263 8.11538 15.8907 8.11554 15.9651C8.11571 16.0395 8.06953 16.0939 8.0126 16.0935Z"
          fill="white"
        />
        <path
          d="M8.42309 16.095C8.36633 16.095 8.32048 16.0403 8.32031 15.9657C8.32015 15.8911 8.36599 15.8271 8.42275 15.8271C8.47952 15.8271 8.52604 15.8915 8.52604 15.966C8.52604 16.0406 8.48002 16.095 8.42309 16.095Z"
          fill="white"
        />
        <path
          d="M8.01081 15.5447C7.95405 15.5447 7.90804 15.4824 7.9082 15.4136C7.90837 15.3447 7.95506 15.2974 8.01165 15.2974C8.06825 15.2974 8.11376 15.3451 8.11342 15.4139C8.11309 15.4828 8.06741 15.5449 8.01081 15.5447Z"
          fill="white"
        />
        <path
          d="M7.18084 16.0862C7.12492 16.0862 7.08008 16.0304 7.08008 15.9564C7.08008 15.8823 7.12492 15.8192 7.18084 15.8198C7.23676 15.8205 7.2816 15.8848 7.2816 15.9589C7.2816 16.0329 7.2366 16.0869 7.18084 16.0862Z"
          fill="white"
        />
        <path
          d="M7.18481 17.9328C7.15713 17.9318 7.13097 17.9199 7.11207 17.8997C7.09317 17.8794 7.08309 17.8525 7.08405 17.8248C7.08405 17.7637 7.12821 17.7115 7.18397 17.7123C7.23972 17.7131 7.28473 17.7669 7.28574 17.828C7.28628 17.8416 7.28407 17.8551 7.27924 17.8678C7.27441 17.8805 7.26706 17.8921 7.25763 17.9019C7.2482 17.9117 7.23689 17.9195 7.22438 17.9248C7.21186 17.9301 7.1984 17.9328 7.18481 17.9328Z"
          fill="white"
        />
        <path
          d="M7.18084 15.5394C7.12509 15.5394 7.08008 15.4764 7.08008 15.4077C7.08008 15.339 7.12593 15.2922 7.18168 15.2929C7.23744 15.2935 7.28244 15.3414 7.28244 15.4104C7.28244 15.4794 7.2366 15.5407 7.18084 15.5394Z"
          fill="white"
        />
        <path
          d="M6.34826 17.906C6.31867 17.9048 6.29075 17.892 6.27054 17.8703C6.25033 17.8487 6.23946 17.8199 6.24028 17.7903C6.24081 17.7757 6.24427 17.7613 6.25044 17.7481C6.25662 17.7348 6.26539 17.7229 6.27624 17.7131C6.28709 17.7033 6.29979 17.6958 6.31361 17.6909C6.32742 17.6861 6.34206 17.6841 6.35666 17.685C6.38672 17.6879 6.41458 17.702 6.43467 17.7246C6.45476 17.7471 6.46558 17.7764 6.46498 17.8066C6.46436 17.821 6.46078 17.8351 6.45447 17.848C6.44816 17.861 6.43925 17.8724 6.42829 17.8818C6.41734 17.8911 6.40458 17.8981 6.39081 17.9023C6.37704 17.9064 6.36255 17.9077 6.34826 17.906Z"
          fill="white"
        />
        <path
          d="M6.36291 17.4463C6.30211 17.4416 6.25375 17.383 6.25593 17.3182C6.25587 17.3036 6.25882 17.2892 6.26459 17.2758C6.27036 17.2624 6.27884 17.2504 6.28948 17.2404C6.30012 17.2305 6.3127 17.2228 6.32644 17.218C6.34017 17.2131 6.35476 17.2111 6.36929 17.2122C6.39919 17.2153 6.42676 17.2297 6.44634 17.2525C6.46593 17.2753 6.47603 17.3048 6.47459 17.3348C6.47391 17.3996 6.42353 17.4508 6.36291 17.4463Z"
          fill="white"
        />
        <path
          d="M5.89207 17.8621C5.86199 17.8594 5.83412 17.8452 5.81423 17.8224C5.79435 17.7997 5.78397 17.7702 5.78526 17.74C5.78604 17.7254 5.78983 17.7111 5.79639 17.6981C5.80295 17.685 5.81214 17.6734 5.82338 17.6641C5.83462 17.6547 5.84768 17.6478 5.86172 17.6437C5.87577 17.6396 5.8905 17.6385 5.905 17.6404C5.93557 17.6448 5.96347 17.6602 5.98338 17.6838C6.00329 17.7074 6.01383 17.7375 6.01299 17.7684C6.00963 17.8297 5.95522 17.87 5.89207 17.8621Z"
          fill="white"
        />
        <path
          d="M5.91785 17.4012C5.88669 17.3957 5.8586 17.379 5.83887 17.3543C5.81914 17.3295 5.80913 17.2984 5.81071 17.2668C5.8109 17.2522 5.81421 17.2377 5.82042 17.2245C5.82662 17.2112 5.83559 17.1994 5.84671 17.1899C5.85783 17.1803 5.87086 17.1733 5.88492 17.1692C5.89898 17.1651 5.91376 17.164 5.92827 17.1661C5.9588 17.1706 5.98657 17.1863 6.00619 17.2101C6.0258 17.2339 6.03586 17.2642 6.0344 17.295C6.03453 17.3099 6.03151 17.3246 6.02556 17.3383C6.0196 17.3519 6.01084 17.3641 5.99984 17.3741C5.98884 17.3842 5.97586 17.3917 5.96173 17.3964C5.9476 17.4011 5.93265 17.4027 5.91785 17.4012Z"
          fill="white"
        />
        <path
          d="M5.98032 16.0416C5.92305 16.0369 5.88056 15.9813 5.8851 15.9121C5.88963 15.8429 5.93833 15.7871 5.99392 15.7902C6.04951 15.7932 6.09233 15.856 6.08931 15.9265C6.08629 15.9971 6.03742 16.0463 5.98032 16.0416Z"
          fill="white"
        />
        <path
          d="M6.00623 15.5269C5.95216 15.5254 5.91169 15.4663 5.91639 15.4018C5.92109 15.3373 5.96811 15.2925 6.02084 15.2925C6.07358 15.2925 6.11338 15.3385 6.11019 15.4043C6.107 15.4702 6.06048 15.5283 6.00623 15.5269Z"
          fill="white"
        />
        <path
          d="M4.9957 17.6996C4.96622 17.6916 4.94034 17.6738 4.92233 17.6491C4.90432 17.6245 4.89525 17.5944 4.89662 17.5639C4.90065 17.5021 4.95086 17.4618 5.01065 17.4766C5.04081 17.4855 5.06716 17.5042 5.08554 17.5298C5.10393 17.5553 5.11333 17.5862 5.11225 17.6176C5.10822 17.6794 5.05515 17.7144 4.9957 17.6996Z"
          fill="white"
        />
        <path
          d="M4.63688 16.6559C4.60871 16.646 4.58463 16.6271 4.56842 16.602C4.55221 16.577 4.54481 16.5472 4.54737 16.5175C4.54779 16.5042 4.55137 16.4911 4.55782 16.4795C4.56427 16.4678 4.5734 16.4579 4.58446 16.4504C4.59552 16.443 4.60819 16.4383 4.62142 16.4367C4.63466 16.4352 4.64807 16.4368 4.66056 16.4414C4.68925 16.4516 4.71379 16.471 4.73042 16.4965C4.74705 16.522 4.75484 16.5523 4.75259 16.5827C4.75188 16.596 4.74797 16.6091 4.7412 16.6206C4.73442 16.6322 4.72497 16.642 4.71364 16.6491C4.70232 16.6563 4.68944 16.6607 4.67608 16.6618C4.66273 16.663 4.64929 16.661 4.63688 16.6559Z"
          fill="white"
        />
        <path
          d="M5.02382 17.237C4.96387 17.2222 4.91869 17.1549 4.92205 17.0896C4.92541 17.0242 4.97663 16.9866 5.03658 17.0016C5.06713 17.0102 5.09385 17.029 5.11241 17.0547C5.13097 17.0805 5.14029 17.1117 5.13886 17.1435C5.13533 17.2083 5.08361 17.2514 5.02382 17.237Z"
          fill="white"
        />
        <path
          d="M5.05759 16.7751C5.02824 16.7677 5.00246 16.7502 4.98484 16.7256C4.96722 16.701 4.9589 16.6709 4.96136 16.6408C4.96217 16.6269 4.96606 16.6134 4.97273 16.6012C4.9794 16.589 4.98869 16.5784 4.99994 16.5703C5.01119 16.5621 5.02411 16.5565 5.03776 16.554C5.05142 16.5514 5.06548 16.5519 5.07892 16.5554C5.10851 16.5637 5.13438 16.5818 5.15222 16.6068C5.17007 16.6318 5.17881 16.6622 5.17699 16.6928C5.17612 16.7068 5.1721 16.7203 5.16522 16.7325C5.15834 16.7446 5.14879 16.7551 5.13729 16.763C5.12579 16.7709 5.11264 16.7761 5.09884 16.7782C5.08503 16.7803 5.07093 16.7793 5.05759 16.7751Z"
          fill="white"
        />
        <path
          d="M5.15012 15.927C5.12167 15.9204 5.09686 15.9031 5.08089 15.8787C5.06492 15.8542 5.05904 15.8245 5.06447 15.7958C5.07421 15.7334 5.12762 15.6894 5.18371 15.6994C5.2398 15.7095 5.27843 15.7713 5.26969 15.8353C5.26096 15.8993 5.20722 15.9391 5.15012 15.927Z"
          fill="white"
        />
        <path
          d="M4.33109 15.6904C4.30318 15.6783 4.27993 15.6575 4.2648 15.6312C4.24966 15.6048 4.24346 15.5743 4.24712 15.5441C4.2482 15.5307 4.25246 15.5178 4.25955 15.5065C4.26663 15.4951 4.27633 15.4856 4.28783 15.4787C4.29934 15.4719 4.31232 15.4679 4.32568 15.4671C4.33905 15.4663 4.35242 15.4686 4.36467 15.4741C4.39301 15.4864 4.41655 15.5076 4.43171 15.5345C4.44686 15.5614 4.45281 15.5926 4.44864 15.6232C4.44714 15.6363 4.44258 15.6488 4.43533 15.6598C4.42807 15.6709 4.41832 15.68 4.40688 15.6865C4.39543 15.6931 4.3826 15.6968 4.36944 15.6975C4.35627 15.6982 4.34313 15.6957 4.33109 15.6904Z"
          fill="white"
        />
        <path
          d="M3.97147 15.0589C3.91169 15.0278 3.8655 14.9489 3.86937 14.8891C3.87323 14.8293 3.92831 14.8144 3.99112 14.8469C4.05393 14.8795 4.09843 14.9462 4.09188 15.0043C4.08533 15.0624 4.03143 15.0898 3.97147 15.0589Z"
          fill="white"
        />
        <path
          d="M3.54629 14.8099C3.51799 14.7894 3.4946 14.7628 3.47781 14.7321C3.46103 14.7015 3.45126 14.6674 3.44922 14.6325C3.45006 14.5709 3.49624 14.5545 3.54897 14.5875C3.57609 14.606 3.59846 14.6306 3.61425 14.6593C3.63004 14.6881 3.63881 14.7202 3.63983 14.753C3.63916 14.8146 3.59919 14.8431 3.54629 14.8099Z"
          fill="white"
        />
        <path
          d="M3.54194 15.2814C3.51319 15.2615 3.48937 15.2353 3.47228 15.2048C3.4552 15.1743 3.44531 15.1403 3.44336 15.1054C3.44336 15.0409 3.49072 15.0161 3.54412 15.0492C3.59753 15.0823 3.63649 15.1607 3.63598 15.2252C3.63548 15.2897 3.59501 15.3145 3.54194 15.2814Z"
          fill="white"
        />
        <path
          d="M3.11367 15.0034C3.04901 14.9596 2.99494 14.8724 2.99611 14.8077C2.99729 14.7431 3.05204 14.7268 3.11636 14.7708C3.18068 14.8148 3.23005 14.9019 3.22938 14.9666C3.22871 15.0313 3.17816 15.0467 3.11367 15.0034Z"
          fill="white"
        />
        <path
          d="M2.62112 14.6468C2.55059 14.5923 2.49282 14.4968 2.49416 14.432C2.49551 14.3671 2.55513 14.3593 2.62532 14.4137C2.69552 14.4681 2.74993 14.5648 2.74893 14.6291C2.74792 14.6934 2.69166 14.701 2.62112 14.6468Z"
          fill="white"
        />
        <path
          d="M2.61543 15.4325C2.54288 15.3734 2.48242 15.2822 2.48242 15.2281C2.48242 15.174 2.54271 15.1777 2.61476 15.2351C2.6868 15.2926 2.74407 15.3846 2.74441 15.4399C2.74474 15.4951 2.68798 15.4916 2.61543 15.4325Z"
          fill="white"
        />
        <path
          d="M2.29443 16.0159C2.22978 15.9588 2.1626 15.8526 2.1448 15.7766C2.127 15.7005 2.16781 15.6869 2.23515 15.7472C2.30249 15.8075 2.3695 15.9163 2.38378 15.989C2.39805 16.0617 2.35959 16.0728 2.29443 16.0159Z"
          fill="white"
        />
        <path
          d="M1.21569 14.4354C1.15355 14.347 1.10708 14.2486 1.07832 14.1444C1.05884 14.0625 1.08772 14.0641 1.1455 14.1409C1.20327 14.2176 1.26691 14.3399 1.28623 14.421C1.30554 14.5021 1.27279 14.5114 1.21569 14.4354Z"
          fill="white"
        />
        <path
          d="M1.20392 12.647C1.15035 12.5613 1.11693 12.4349 1.12398 12.3726C1.13104 12.3103 1.17436 12.3376 1.2271 12.4229C1.27983 12.5083 1.32198 12.6208 1.3161 12.6827C1.31023 12.7447 1.25766 12.7326 1.20392 12.647Z"
          fill="white"
        />
        <path
          d="M1.65482 13.2512C1.58999 13.1763 1.54431 13.0589 1.54918 12.9973C1.55405 12.9357 1.60662 12.9543 1.67043 13.0295C1.73425 13.1048 1.78598 13.2079 1.78195 13.2693C1.77792 13.3308 1.71947 13.3263 1.65482 13.2512Z"
          fill="white"
        />
        <path
          d="M2.14476 13.7574C2.0749 13.6927 2.02234 13.5842 2.02553 13.5223C2.02872 13.4603 2.08599 13.4711 2.15518 13.5357C2.22437 13.6004 2.27979 13.6953 2.2771 13.7567C2.27441 13.8182 2.21463 13.822 2.14476 13.7574Z"
          fill="white"
        />
        <path
          d="M4.21629 16.9694C4.16591 16.9474 4.12728 16.8747 4.12896 16.8098C4.13064 16.745 4.17229 16.7134 4.22334 16.7353C4.27439 16.7571 4.3152 16.8243 4.31285 16.8893C4.3105 16.9543 4.26684 16.9914 4.21629 16.9694Z"
          fill="white"
        />
        <path
          d="M3.19262 16.7403C3.13569 16.6971 3.08296 16.6123 3.07675 16.5482C3.07053 16.484 3.11554 16.4682 3.17499 16.5136C3.23444 16.5589 3.28381 16.6466 3.28751 16.7086C3.2912 16.7705 3.24955 16.7835 3.19262 16.7403Z"
          fill="white"
        />
        <path
          d="M5.21492 15.4622C5.15967 15.4537 5.12255 15.3937 5.13246 15.3351C5.13673 15.3083 5.15136 15.2843 5.1732 15.2682C5.19504 15.2521 5.22233 15.2452 5.24918 15.2491C5.26226 15.2508 5.27485 15.2551 5.28616 15.2619C5.29747 15.2687 5.30725 15.2777 5.31489 15.2885C5.32252 15.2992 5.32785 15.3114 5.33053 15.3243C5.33321 15.3372 5.33319 15.3506 5.33046 15.3635C5.32156 15.4231 5.27 15.4708 5.21492 15.4622Z"
          fill="white"
        />
        <path
          d="M5.61149 15.5071C5.55708 15.5029 5.51879 15.4441 5.52634 15.3827C5.52876 15.3553 5.5415 15.3298 5.56198 15.3114C5.58246 15.293 5.60916 15.2831 5.63668 15.2836C5.68975 15.2863 5.72736 15.3321 5.72065 15.3949C5.71393 15.4577 5.66607 15.5116 5.61149 15.5071Z"
          fill="white"
        />
        <path
          d="M6.77844 15.534C6.72352 15.534 6.67919 15.4717 6.67969 15.4032C6.6802 15.3347 6.72487 15.287 6.78045 15.2875C6.83604 15.288 6.87954 15.3363 6.8792 15.405C6.87887 15.4737 6.83352 15.5347 6.77844 15.534Z"
          fill="white"
        />
        <path
          d="M6.39786 15.532C6.34344 15.532 6.30079 15.4713 6.3028 15.4043C6.30482 15.3373 6.34983 15.2896 6.40357 15.2889C6.45731 15.2883 6.49929 15.3353 6.49828 15.4033C6.49728 15.4713 6.45227 15.5323 6.39786 15.532Z"
          fill="white"
        />
        <path
          d="M11.7394 17.4372C11.6808 17.4372 11.6348 17.3817 11.6368 17.3148C11.6388 17.248 11.6872 17.1973 11.7459 17.1973C11.8047 17.1973 11.8501 17.2458 11.8484 17.3128C11.8467 17.3798 11.7978 17.4364 11.7394 17.4372Z"
          fill="white"
        />
        <path
          d="M12.5774 15.5129C12.5213 15.5129 12.4788 15.4538 12.4827 15.388C12.4865 15.3222 12.5347 15.2763 12.5903 15.2765C12.6459 15.2767 12.6884 15.3223 12.685 15.388C12.6817 15.4537 12.6335 15.5129 12.5774 15.5129Z"
          fill="white"
        />
        <path
          d="M13.8601 16.9066C13.846 16.9089 13.8316 16.908 13.8179 16.9041C13.8042 16.9001 13.7916 16.8932 13.7809 16.8838C13.7702 16.8744 13.7617 16.8627 13.756 16.8497C13.7503 16.8366 13.7476 16.8224 13.7481 16.8082C13.749 16.777 13.7612 16.7471 13.7824 16.7242C13.8036 16.7013 13.8324 16.6869 13.8635 16.6836C13.878 16.6817 13.8928 16.683 13.9068 16.6873C13.9207 16.6917 13.9336 16.699 13.9446 16.7088C13.9555 16.7185 13.9642 16.7305 13.9701 16.7439C13.976 16.7573 13.9789 16.7719 13.9787 16.7865C13.9773 16.848 13.9234 16.901 13.8601 16.9066Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_4286_2909"
        x1="0.499916"
        x2="16.4564"
        y1="14.3726"
        y2="14.3726"
      >
        <stop stopColor="#F4C844" />
        <stop offset="1" stopColor="#A02B23" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_4286_2909"
        x1="0.859122"
        x2="12.9669"
        y1="17.9402"
        y2="17.9402"
      >
        <stop stopColor="#F4C844" />
        <stop offset="1" stopColor="#A02B23" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_4286_2909"
        x1="0.942211"
        x2="14.5736"
        y1="7.48187"
        y2="7.48187"
      >
        <stop stopColor="#A02B23" />
        <stop offset="0.04" stopColor="#A53325" />
        <stop offset="0.38" stopColor="#C67332" />
        <stop offset="0.66" stopColor="#DFA13C" />
        <stop offset="0.87" stopColor="#EEBD42" />
        <stop offset="1" stopColor="#F4C844" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_4286_2909"
        x1="9.14844"
        x2="19.4854"
        y1="10.5492"
        y2="10.5492"
      >
        <stop stopColor="#A02B23" />
        <stop offset="0.39" stopColor="#C0662F" />
        <stop offset="1" stopColor="#F4C844" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
