import { SvgIcon, SvgIconProps } from '@mui/material';

export default function AutonomeLogo({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: '180px', height: '32px', ...sx }} viewBox="0 0 180 32" {...props}>
      <path
        d="M57.9578 6.19141H61.6571L68.5341 25.3703H65.2184L63.4105 19.891H56.0676L54.2598 25.3703H51.1086L57.9591 6.19141H57.9578ZM62.561 17.3438L60.3961 10.8504L59.7391 8.65895C59.5466 9.39828 59.3276 10.0832 59.082 10.8504L56.9171 17.3438H62.561Z"
        fill="#1D2723"
      />
      <path
        d="M70.6168 20.4934V12.082H73.4666V19.9731C73.4666 22.1646 74.3161 23.5623 76.2886 23.5623C78.261 23.5623 79.4941 22.0557 79.4941 19.6439V12.082H82.3439V25.3701H79.522V24.6852C79.522 24.3016 79.5499 23.8914 79.6043 23.5344C78.9194 24.8777 77.5217 25.8081 75.4948 25.8081C72.5083 25.8081 70.6181 23.918 70.6181 20.4921L70.6168 20.4934Z"
        fill="#1D2723"
      />
      <path
        d="M88.8919 20.6301V14.3836H85.0559V12.082H88.8919V7.47876H91.7418V12.082H96.0981V14.3836H91.7418V20.2744C91.7418 22.3836 92.6736 23.1508 94.7827 23.1508C95.2765 23.1508 95.7145 23.1229 96.207 23.0406V25.3701C95.6866 25.5068 94.946 25.617 94.1801 25.617C90.9467 25.617 88.8919 24.1927 88.8919 20.6301Z"
        fill="#1D2723"
      />
      <path
        d="M98.7565 18.7403C98.7565 14.4941 101.524 11.6443 105.442 11.6443C109.361 11.6443 112.128 14.4941 112.128 18.7403C112.128 22.9865 109.361 25.8098 105.442 25.8098C101.524 25.8098 98.7565 23.0701 98.7565 18.7403ZM105.442 23.5904C107.744 23.5904 109.223 21.7003 109.223 18.7403C109.223 15.7803 107.716 13.8902 105.442 13.8902C103.169 13.8902 101.689 15.7259 101.689 18.7403C101.689 21.7547 103.141 23.5904 105.442 23.5904Z"
        fill="#1D2723"
      />
      <path
        d="M115.553 12.0823H118.403V12.8216C118.403 13.1774 118.375 13.5066 118.348 13.918C119.061 12.6849 120.485 11.6443 122.513 11.6443C125.582 11.6443 127.364 13.699 127.364 17.0426V25.3717H124.514V17.4262C124.514 15.2069 123.636 13.8915 121.746 13.8915C119.692 13.8915 118.404 15.5347 118.404 17.5085V25.3717H115.554V12.0836L115.553 12.0823Z"
        fill="#1D2723"
      />
      <path
        d="M130.568 18.7403C130.568 14.4941 133.336 11.6443 137.254 11.6443C141.172 11.6443 143.94 14.4941 143.94 18.7403C143.94 22.9865 141.172 25.8098 137.254 25.8098C133.336 25.8098 130.568 23.0701 130.568 18.7403ZM137.254 23.5904C139.556 23.5904 141.034 21.7003 141.034 18.7403C141.034 15.7803 139.528 13.8902 137.254 13.8902C134.98 13.8902 133.5 15.7259 133.5 18.7403C133.5 21.7547 134.952 23.5904 137.254 23.5904Z"
        fill="#1D2723"
      />
      <path
        d="M146.76 12.0823H149.445V12.9584C149.445 13.2597 149.445 13.6154 149.417 13.918C150.02 12.6026 151.143 11.6443 152.733 11.6443C154.185 11.6443 155.418 12.5217 155.939 14.1105C156.624 12.7128 157.857 11.6443 159.638 11.6443C161.994 11.6443 163.556 13.4256 163.556 16.5488V25.3717H160.845V16.9869C160.845 15.0688 160.296 13.8915 158.872 13.8915C157.283 13.8915 156.488 15.4259 156.488 17.316V25.3717H153.803V17.0426C153.803 15.1246 153.255 13.8915 151.83 13.8915C150.268 13.8915 149.447 15.4259 149.447 17.3718V25.3717H146.761V12.0836L146.76 12.0823Z"
        fill="#1D2723"
      />
      <path
        d="M166.406 18.6859C166.406 14.5485 169.146 11.6443 172.899 11.6443C176.9 11.6443 179.174 14.4397 179.174 18.7403V19.3429H169.282C169.337 21.918 170.871 23.5904 173.036 23.5904C174.571 23.5904 175.912 22.8511 176.187 21.1521H179.064C178.789 23.6727 176.708 25.8098 173.008 25.8098C169.035 25.8098 166.405 23.2891 166.405 18.6859H166.406ZM176.187 17.316C176.187 15.2334 174.926 13.8092 172.872 13.8092C170.817 13.8092 169.501 15.3157 169.31 17.316H176.187Z"
        fill="#1D2723"
      />
      <path
        d="M20.9527 14.0987C22.1395 14.0987 23.1017 13.1366 23.1017 11.9498C23.1017 10.7629 22.1395 9.80078 20.9527 9.80078C19.7658 9.80078 18.8037 10.7629 18.8037 11.9498C18.8037 13.1366 19.7658 14.0987 20.9527 14.0987Z"
        fill="#1D2723"
      />
      <path
        d="M27.3892 25.7252C28.5761 25.7252 29.5382 24.7631 29.5382 23.5762C29.5382 22.3894 28.5761 21.4272 27.3892 21.4272C26.2024 21.4272 25.2402 22.3894 25.2402 23.5762C25.2402 24.7631 26.2024 25.7252 27.3892 25.7252Z"
        fill="#1D2723"
      />
      <path
        d="M14.5564 25.7252C15.7433 25.7252 16.7054 24.7631 16.7054 23.5762C16.7054 22.3894 15.7433 21.4272 14.5564 21.4272C13.3696 21.4272 12.4075 22.3894 12.4075 23.5762C12.4075 24.7631 13.3696 25.7252 14.5564 25.7252Z"
        fill="#1D2723"
      />
      <path
        d="M20.9527 20.9073C22.1395 20.9073 23.1017 19.9452 23.1017 18.7584C23.1017 17.5715 22.1395 16.6094 20.9527 16.6094C19.7658 16.6094 18.8037 17.5715 18.8037 18.7584C18.8037 19.9452 19.7658 20.9073 20.9527 20.9073Z"
        fill="#1D2723"
      />
      <path
        d="M9.03181 31.9202H32.7635C33.2334 31.9202 33.5161 31.3189 33.2599 30.8636L31.3193 27.4802C31.1454 27.1709 30.8468 26.9851 30.5269 26.9851H11.3202C10.8423 26.9851 10.4016 27.2785 10.1614 27.7563L8.45441 30.7681C8.19691 31.2804 8.51812 31.9215 9.03048 31.9215L9.03181 31.9202Z"
        fill="#1D2723"
      />
      <path
        d="M0.13994 30.7189L15.9553 3.21629C16.2752 2.66544 16.988 2.63623 17.207 3.16452L19.0148 6.29308C19.2697 6.69792 19.3108 7.21027 19.0945 7.63635C19.0825 7.66025 19.0693 7.68414 19.056 7.7067L5.62189 31.0309C5.47456 31.2844 5.28076 31.4981 5.05909 31.6614C4.74451 31.8923 4.35958 32.0038 3.96934 31.9998L0.746539 31.972C0.129322 32.0158 -0.207825 31.3202 0.13994 30.7189Z"
        fill="#1D2723"
      />
      <path
        d="M41.3091 30.6898L24.3151 0.916052C23.9965 0.365202 23.4138 0.00947367 22.778 0.00416428C22.1422 -0.00114512 20.7644 -0.00114236 20.0689 0.00283968C19.3349 0.00682173 18.5663 0.816503 18.9234 1.45761L36.1059 31.2433C36.3701 31.6999 37.6961 31.9282 37.8726 31.9322L40.7131 31.9587C41.3343 32.0092 41.6662 31.3044 41.3105 30.6898H41.3091Z"
        fill="#1D2723"
      />
    </SvgIcon>
  );
}
