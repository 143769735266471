import { BoxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormTextFieldBase, IFormTextFieldBase } from 'shared/components/form';
import { Option } from 'shared/components/Option';
import { WithInfo } from 'shared/components/WithInfo';

export interface IFormTextField extends IFormTextFieldBase {
  tooltipContent?: string;
  optionProps?: BoxProps;
  hideLabel?: boolean;
  label?: ReactNode;
}

export const FormTextField = ({
  component,
  fieldConfig,
  hideLabel,
  label,
  optionProps,
  tooltipContent,
  ...props
}: IFormTextField) => {
  const renderTextField = () => (
    <FormTextFieldBase component={component} fieldConfig={fieldConfig} {...props} />
  );

  return hideLabel ? (
    renderTextField()
  ) : label ? (
    <>
      {label}
      {renderTextField()}
    </>
  ) : (
    <Option
      optionTitle={
        tooltipContent ? (
          <WithInfo
            info={tooltipContent}
            text={<Typography variant="bodySmallC">{fieldConfig.label}</Typography>}
          />
        ) : (
          fieldConfig.label
        )
      }
      {...optionProps}
    >
      {renderTextField()}
    </Option>
  );
};
