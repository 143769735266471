import { QueryClient } from '@tanstack/react-query';
import { SETTLEMENT_CHAINS } from 'types/deployments';
import { DAType, RollupType } from 'types/protoc-gen/rollup';
import { getDeploymentNameValidation } from 'utils/yupCustomValidations';
import * as yup from 'yup';

const MAX_UINT_32 = 4_294_967_295;

export const getValidationSchema = (queryClient?: QueryClient) =>
  yup
    .object()
    .shape({
      rollupType: yup.number().oneOf(Object.values(RollupType)).required(),
      isTestnet: yup.boolean().required(),
      network: yup.number().oneOf(Object.values(SETTLEMENT_CHAINS)).required(),
      daType: yup.number().oneOf(Object.values(DAType)).required(),
      babylonEnabled: yup.boolean().required(),
      // babylonFpDetails: yup.string().when('babylonEnabled', {
      //   is: true,
      //   then: schema => schema.required(),
      //   otherwise: schema => schema.notRequired(),
      // }), // TODO: revert when feature is ready
      challengePeriod: yup
        .number()
        .max(
          60 * 60 * 24 * 21,
          `Please enter a challenge period of max 21 days (${60 * 60 * 24 * 21} seconds)`,
        )
        .min(2)
        .integer()
        .required(),
      networkName: getDeploymentNameValidation(queryClient),
      chainId: yup.number().min(1).max(MAX_UINT_32).integer().required(),
    })
    .required();
