import { Typography } from '@mui/material';

export const ErrorCaption = ({ error, message }: { error?: boolean; message?: string }) => {
  return (
    <>
      {error && (
        <Typography
          sx={{
            mx: '16px',
            color: theme => theme.colors.schema.error,
          }}
          variant="caption"
        >
          {message}
        </Typography>
      )}
    </>
  );
};
