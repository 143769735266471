import { useMutation, useQueryClient } from '@tanstack/react-query';
import { raas } from 'api';
import { AxiosError } from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { AAAHostType, CreateAAAAppRequest } from 'types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'types/react-query';

import { FormValues } from './types';

export default function usePublishAgent() {
  const { addAlert } = useAlerts();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: publishAgent, ...mutationReturn } = useMutation({
    mutationFn: async (reqBody: CreateAAAAppRequest) => {
      await raas.post('/bff/apps', reqBody);
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Agent listing request submitted successfully.',
      });
      queryClient?.invalidateQueries({ queryKey: [QUERY_KEYS.GET_LIST_AAA_TYPES] });
      navigate(PATHS.AAAS_DEPLOYMENTS);
    },
    onError: err => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title:
          (err as AxiosError<{ message: string }>)?.response?.data?.message ||
          'Failed to submit agent listing request.',
      });
    },
  });

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    data => {
      console.log('form values: ', data);
      publishAgent({
        name: String(data?.imageName),
        type: AAAHostType.AAAHOSTTYPE_CHATBOX,
        dockerImage: String(data?.imageId),
        chatSubPath: String(data?.livenessProbePath),
        description: String(data?.imageDesc),
        iconLink: String(data?.agentLogo),
        envList: data?.envVars?.map(cur => cur?.name) || [],
        configExample: '', // TODO: add config example field
      });
    },
    [publishAgent],
  );

  return useMemo(() => ({ publish: onSubmit, ...mutationReturn }), [mutationReturn, onSubmit]);
}
