import { create } from 'zustand';

import { MessageHistoryItem } from './views/ChatView/types';

interface State {
  deployed: {
    [appId: string]: boolean;
  };
  deploying: {
    [appId: string]: boolean;
  };
  messages: {
    [appId: string]: MessageHistoryItem[];
  };
}

interface Action {
  setDeployed: (appId?: string) => void;
  setDeploying: (appId?: string) => void;
  registerConversation: (appId?: string) => void;
  addMessage: (appId?: string, message?: MessageHistoryItem) => void;
  reset: (appId?: string) => void;
}

const init: State = {
  deploying: {},
  deployed: {},
  messages: {},
};

export const useChatStore = create<State & Action>(set => ({
  setDeployed: appId =>
    appId &&
    set(state => ({
      deployed: {
        ...state.deployed,
        [appId]: true,
      },
      deploying: {
        ...state.deploying,
        [appId]: false,
      },
    })),
  setDeploying: appId =>
    appId &&
    set(state => ({
      deploying: {
        ...state.deploying,
        [appId]: true,
      },
      deployed: {
        ...state.deployed,
        [appId]: false,
      },
    })),
  registerConversation: appId =>
    appId &&
    set(state => {
      // const lsMessages: MessageHistoryItem[] = JSON.parse(
      //   localStorage.getItem(`chat.${appId}.messages`) ?? '[]',
      // );

      return {
        messages: {
          ...state.messages,
          [appId]: [
            // ...lsMessages
          ],
        },
      };
    }),
  addMessage: (appId, message) => {
    if (!appId || !message) {
      return;
    }

    set(state => {
      // const lsMessages: MessageHistoryItem[] = JSON.parse(
      //   localStorage.getItem(`chat.${appId}.messages`) ?? '[]',
      // );

      if (state.messages[appId] && state.messages[appId].length > 0) {
        const msgs = [...state.messages[appId], message];

        localStorage.setItem(`chat.${appId}.messages`, JSON.stringify(msgs));

        return {
          messages: {
            ...state.messages,
            [appId]: msgs,
          },
        };
      }

      // if (lsMessages?.length === 0) {
      //   localStorage.setItem(`chat.${appId}.messages`, JSON.stringify([message]));
      // }

      return {
        messages: {
          ...state.messages,
          [appId]: [message],
        },
      };
    });
  },
  reset: appId =>
    appId &&
    set(state => ({
      deploying: {
        ...state.deploying,
        [appId]: false,
      },
      deployed: {
        ...state.deployed,
        [appId]: false,
      },
    })),
  ...init,
}));
