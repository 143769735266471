import { Box, BoxProps, FormHelperText, Grid, Typography } from '@mui/material';
import { deferredArgNames, staticArgs } from 'constants/customAvs';
import { ReactNode, useCallback, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormTextField } from 'shared/components/form';

import { AVSFormValues } from '..';

export default function CustomArgs({
  fieldArrName,
  label,
  sx,
  ...props
}: {
  fieldArrName: 'initArgs' | 'constructorArgs';
  label?: ReactNode;
} & BoxProps) {
  const { control, formState, register, setValue } = useFormContext<AVSFormValues>();

  const compiledOutput = useWatch<AVSFormValues>({ name: 'compiledOutput' });

  const fieldArray = useFieldArray<AVSFormValues>({
    name: fieldArrName,
    control,
  });

  // Workaround. fieldArray.replace() does not have the option to touch the field array, so it is required to touch all fields in the field array instead. https://react-hook-form.com/docs/useform/setvalue
  const touchFieldArray = useCallback(() => {
    fieldArray?.fields.forEach((field, index) => {
      setValue(`${fieldArrName}.${index}`, field, { shouldTouch: true });
    });
  }, [fieldArrName, fieldArray?.fields, setValue]);

  useEffect(() => {
    const abi = compiledOutput?.abi;

    if (compiledOutput && !fieldArray.fields.length) {
      if (fieldArrName === 'initArgs') {
        const initArgs = abi?.find((cur: any) => cur.name === 'initialize')?.inputs;

        const withStaticInitVals = initArgs?.map((cur: any) => {
          const staticArg = staticArgs?.find(arg => arg.name === cur?.name);

          if (!staticArg) {
            return cur;
          }

          return { ...cur, value: staticArg?.value };
        });

        if (withStaticInitVals) {
          fieldArray.replace(withStaticInitVals);
        }
      }

      if (fieldArrName === 'constructorArgs') {
        const constructorArgs = abi?.find((cur: any) => cur.type === 'constructor')?.inputs;

        const withStaticArgValues = constructorArgs?.map((cur: any) => {
          const staticArg = staticArgs?.find(arg => arg.name === cur?.name);

          if (!staticArg) {
            return cur;
          }

          return { ...cur, value: staticArg?.value };
        });

        if (withStaticArgValues) {
          fieldArray.replace(withStaticArgValues);
        }
      }
    }

    if (!compiledOutput && fieldArray.fields.length) {
      fieldArray.replace([]);
    }
  }, [compiledOutput, fieldArrName, fieldArray, touchFieldArray]);

  useEffect(() => {
    if (!fieldArray?.fields?.length || formState?.touchedFields?.[fieldArrName]) {
      return;
    }

    const isUserInputRequired = !fieldArray?.fields?.every(
      (cur: any) =>
        staticArgs?.find(arg => arg?.name === cur?.name) || deferredArgNames?.includes(cur?.name),
    );

    console.debug('isUserInputRequired: ', isUserInputRequired);

    if (!isUserInputRequired) {
      touchFieldArray();
    }
  }, [fieldArrName, fieldArray, formState?.touchedFields, touchFieldArray]);

  return !compiledOutput || !fieldArray.fields.length ? null : (
    <Box id={`step_${fieldArrName}`} mt={5} sx={sx} {...props}>
      {label}
      <Typography variant="bodySmall">
        {fieldArrName === 'initArgs'
          ? `initialize(${fieldArray.fields.map(cur => cur?.type)?.join(',')})`
          : `constructor(${fieldArray.fields.map(cur => cur?.type)?.join(',')})`}
      </Typography>
      <Grid alignItems="center" container spacing={2}>
        {fieldArray.fields.map((fieldData, index) => {
          return (
            <Controller
              control={control}
              key={fieldData.id}
              name={`${fieldArrName}.${index}`}
              render={({ field, formState: _formState }) => {
                const staticArg = staticArgs?.find(cur => cur.name === fieldData?.name);
                const isDeferred = deferredArgNames?.includes(fieldData?.name);

                return (
                  <Grid item lg={6} xs={12}>
                    <FormTextField
                      fieldConfig={{
                        name: `${field?.name}.value`,
                        label: (
                          <Typography sx={{ textTransform: 'none' }} variant="bodySmall">
                            [{index}] {fieldData?.name}
                          </Typography>
                        ),
                        placeholder: isDeferred ? 'Deferred' : `(${fieldData.type})`,
                      }}
                      id="step_avsName"
                      optionProps={{ sx: { '&&': { pl: 0 } } }}
                      {...(isDeferred
                        ? {
                            tooltipContent:
                              'Will be fetched from base contract after initialization',
                            disabled: true,
                          }
                        : undefined)}
                      {...(staticArg ? { disabled: true } : undefined)}
                    />
                  </Grid>
                );
              }}
              {...register(fieldArrName).ref}
            />
          );
        })}
        {formState.errors?.chains?.root?.message && (
          <FormHelperText error>{String(formState?.errors?.chains?.root?.message)}</FormHelperText>
        )}
      </Grid>
    </Box>
  );
}
