import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { raas } from 'api';
import dayjs from 'dayjs';
import { useRaasDeployment } from 'hooks/raas/useRaasDeployment';
import { chartDefaults, DataKey } from 'shared/components/charts';
import { Panel, panelToJSON } from 'types/protoc-gen/raas-metrics';
import { QUERY_KEYS } from 'types/react-query';

import { RaasMetricsData, RaasMetricsResult, RaasMetricsSeriesData, Timeframe } from './types';

export interface IUseRaasMetrics
  extends Omit<UseQueryOptions<RaasMetricsResult>, 'queryFn' | 'queryKey'> {
  rollupId: string | undefined;
  panel: Panel;
  start?: string;
  end?: string;
  step?: string;
  template?: string;
  timeframe?: Timeframe;
}

export const useRaasMetrics = ({
  end,
  panel,
  rollupId,
  start,
  step,
  template,
  timeframe,
  ...queryOptions
}: IUseRaasMetrics): UseQueryResult<RaasMetricsResult> => {
  const { data: rollup } = useRaasDeployment();

  return useQuery<RaasMetricsResult>({
    queryKey: [
      QUERY_KEYS.GET_RAAS_METRICS,
      {
        panel: panelToJSON(panel),
        rollupId,
        timeframe,
        step,
      },
    ],
    queryFn: async (): Promise<RaasMetricsResult> => {
      if (!rollupId) throw new Error('rollupId is required');

      const res = await raas.get<RaasMetricsSeriesData>('/bff/metrics/series', {
        params: {
          id: rollupId,
          panel,
          start: dayjs(start).toISOString(),
          end: dayjs(end).toISOString(),
          step,
        },
      });

      const data: RaasMetricsData[] = [];
      const dataKeys: DataKey[] = res.data.series.map((v, index) => {
        const strokeColor = chartDefaults.strokeColors[index % chartDefaults.strokeColors.length];
        const fill = chartDefaults.linearGradientIds?.[index] ?? strokeColor;

        return {
          name: v.label,
          color: strokeColor,
          fill,
        };
      });

      for (const series of res.data.series ?? []) {
        for (const point of series.points) {
          const idx = data.findIndex(
            d => dayjs(point.timestamp).format(template ?? 'YYYY-MM-DD') === d.date,
          );

          if (idx !== -1) {
            data[idx][series.label] = point.value;
            continue;
          }

          data.push({
            date: dayjs(point.timestamp).format(template ?? 'YYYY-MM-DD'),
            [series.label]: point.value,
          });
        }
      }

      return {
        data,
        dataKeys,
      };
    },
    enabled: !!rollup,
    ...queryOptions,
  });
};
