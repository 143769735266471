import { LoadingButton } from '@mui/lab';
import { IconButton, Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { IconCopy } from 'components/icons/IconCopy';
import { useCopyToClipboard } from 'hooks/ui';
import { FundOrbitAccountsDialog } from 'pages/raas/NewDeployment/FundOrbitAccountsDialog';
import { FormValues } from 'pages/raas/NewDeployment/types';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { MarkdownRenderer } from 'shared/components/MarkdownRenderer';
import { CreateOrbitRequest } from 'types/protoc-gen/raas-rollup';
import { DAType, RollupType } from 'types/protoc-gen/rollup';

import { MessageHistoryItem } from './types';

export interface IChatHistory {
  messageHistory: MessageHistoryItem[];
  loading?: boolean;
}

export const ChatHistory: React.FC<IChatHistory> = ({ loading, messageHistory }) => {
  const [dialogRollupType, setDialogRollupType] = useState(RollupType.UNRECOGNIZED);
  const [orbitPayloadPartial, setOrbitPayloadPartial] = useState<Partial<CreateOrbitRequest>>({});

  const mappedFormValues: FormValues = useMemo(
    () => ({
      rollupType: RollupType.TYPE_ORBIT,
      isTestnet: true,
      network: orbitPayloadPartial?.parentChainId,
      enableAnytrust: orbitPayloadPartial?.enableAnytrust,
      daType: orbitPayloadPartial?.enableAnytrust ? DAType.TYPE_ANYTRUST : DAType.UNRECOGNIZED,
      babylonEnabled: false,
      challengePeriod: 12,
      networkName: orbitPayloadPartial?.chainName,
      chainId: String(orbitPayloadPartial?.chainId),
    }),
    [orbitPayloadPartial],
  );

  useEffect(() => {
    const lastMsg = messageHistory?.[messageHistory?.length - 1]?.message;

    if (lastMsg?.includes('```json')) {
      const jsonMatch = lastMsg.match(/```json\n([\s\S]+)\n```/);

      if (jsonMatch?.[1]) {
        try {
          const orbitReqPartial = JSON.parse(jsonMatch?.[1]);

          setOrbitPayloadPartial(orbitReqPartial);
        } catch (error) {
          console.error('Failed to parse JSON:', error);
        }
      }
    } else {
      setOrbitPayloadPartial({});
    }
  }, [messageHistory]);

  return (
    messageHistory?.length > 0 && (
      <>
        {orbitPayloadPartial?.parentChainId && (
          <FundOrbitAccountsDialog
            data={mappedFormValues}
            onClose={() => setDialogRollupType(RollupType.UNRECOGNIZED)}
            open={dialogRollupType === RollupType.TYPE_ORBIT}
            promoCode={orbitPayloadPartial?.promoCode || ''}
            selectedRollupType={RollupType.TYPE_ORBIT}
            settlementChain={orbitPayloadPartial?.parentChainId}
          />
        )}
        <Stack
          id="chat-history"
          spacing="24px"
          sx={{
            background: '#fff',
            minHeight: '35vh',
            maxHeight: '80vh',
            overflow: 'auto',
          }}
        >
          {messageHistory.map(({ author, message }, index) => {
            const shouldDisplayDeployOrbitButton =
              author === 'agent' &&
              index === messageHistory?.length - 1 &&
              orbitPayloadPartial &&
              Object.keys(orbitPayloadPartial).length > 0;

            return (
              <Stack key={[message, author, index].join('-')} spacing="12px">
                <Message author={author} message={message} />
                {shouldDisplayDeployOrbitButton && (
                  <Message
                    author="agent"
                    message={[
                      'Would you like to deploy an orbit rollup with the following details?',
                      '```json',
                      JSON.stringify(orbitPayloadPartial, undefined, 2),
                      '```',
                      'Let me know if you would like to make any changes.',
                    ].join('\n')}
                  >
                    <LoadingButton
                      fullWidth
                      loading={dialogRollupType === RollupType.TYPE_ORBIT}
                      onClick={() => {
                        setDialogRollupType(RollupType.TYPE_ORBIT);
                      }}
                      sx={{ mb: 2 }}
                      variant="contained"
                    >
                      Deploy Orbit Rollup
                    </LoadingButton>
                  </Message>
                )}
              </Stack>
            );
          })}

          {loading && (
            <MessageContainer>
              <Skeleton
                sx={{
                  minHeight: '60px',
                  minWidth: '220px',
                }}
                variant="rectangular"
              />
            </MessageContainer>
          )}

          <Stack id="chat-history-bottom" />
        </Stack>
      </>
    )
  );
};

const MessageContainer: React.FC<StackProps> = ({ children, ...props }) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    spacing="8px"
    {...props}
  >
    {children}
  </Stack>
);

const Message: React.FC<{ message: string; author: 'user' | 'agent'; children?: ReactNode }> = ({
  author,
  children,
  message,
}) => {
  const [, copy] = useCopyToClipboard();

  return (
    <MessageContainer justifyContent={author === 'user' ? 'end' : 'start'}>
      <Stack
        alignItems="center"
        direction="row"
        spacing="24px"
        sx={{
          background: theme =>
            author === 'agent'
              ? theme.colors.schema.metalPrimary
              : theme.colors.schema.metalSecondary,
          px: '16px',
          minHeight: '60px',
          maxWidth: '75%',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          overflow: 'auto',
        }}
      >
        <Typography
          sx={{
            overflowWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            '& > code': {
              whiteSpace: 'pre-wrap !important',
            },
          }}
        >
          <MarkdownRenderer markdown={message} />
          {children}
        </Typography>
      </Stack>
      {author === 'agent' && (
        <Stack alignItems="start" justifyContent="center">
          <IconButton onClick={() => copy(message)} size="small">
            <IconCopy />
          </IconButton>
        </Stack>
      )}
    </MessageContainer>
  );
};
