// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.1
// source: bffaaa.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Struct } from "./google/protobuf/struct";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "aaa.v1";

export enum OperateAction {
  OPERATE_ACTION_UNSPECIFIED = 0,
  OPERATE_ACTION_PUBLISH = 1,
  OPERATE_ACTION_APPROVE = 2,
  OPERATE_ACTION_DELETE = 3,
  OPERATE_ACTION_REJECT = 4,
  UNRECOGNIZED = -1,
}

export function operateActionFromJSON(object: any): OperateAction {
  switch (object) {
    case 0:
    case "OPERATE_ACTION_UNSPECIFIED":
      return OperateAction.OPERATE_ACTION_UNSPECIFIED;
    case 1:
    case "OPERATE_ACTION_PUBLISH":
      return OperateAction.OPERATE_ACTION_PUBLISH;
    case 2:
    case "OPERATE_ACTION_APPROVE":
      return OperateAction.OPERATE_ACTION_APPROVE;
    case 3:
    case "OPERATE_ACTION_DELETE":
      return OperateAction.OPERATE_ACTION_DELETE;
    case 4:
    case "OPERATE_ACTION_REJECT":
      return OperateAction.OPERATE_ACTION_REJECT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OperateAction.UNRECOGNIZED;
  }
}

export function operateActionToJSON(object: OperateAction): string {
  switch (object) {
    case OperateAction.OPERATE_ACTION_UNSPECIFIED:
      return "OPERATE_ACTION_UNSPECIFIED";
    case OperateAction.OPERATE_ACTION_PUBLISH:
      return "OPERATE_ACTION_PUBLISH";
    case OperateAction.OPERATE_ACTION_APPROVE:
      return "OPERATE_ACTION_APPROVE";
    case OperateAction.OPERATE_ACTION_DELETE:
      return "OPERATE_ACTION_DELETE";
    case OperateAction.OPERATE_ACTION_REJECT:
      return "OPERATE_ACTION_REJECT";
    case OperateAction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AAAType {
  AAATYPE_UNSPECIFIED = 0,
  AAATYPE_BASE_AGENT = 1,
  AAATYPE_PERPLEXICA = 2,
  AAATYPE_AGENTKIT = 3,
  AAATYPE_CUSTOM = 4,
  AAATYPE_AGENTKIT_ROLLUP = 5,
  AAATYPE_ELIZA = 6,
  AAATYPE_APP_STORE = 7,
  UNRECOGNIZED = -1,
}

export function aAATypeFromJSON(object: any): AAAType {
  switch (object) {
    case 0:
    case "AAATYPE_UNSPECIFIED":
      return AAAType.AAATYPE_UNSPECIFIED;
    case 1:
    case "AAATYPE_BASE_AGENT":
      return AAAType.AAATYPE_BASE_AGENT;
    case 2:
    case "AAATYPE_PERPLEXICA":
      return AAAType.AAATYPE_PERPLEXICA;
    case 3:
    case "AAATYPE_AGENTKIT":
      return AAAType.AAATYPE_AGENTKIT;
    case 4:
    case "AAATYPE_CUSTOM":
      return AAAType.AAATYPE_CUSTOM;
    case 5:
    case "AAATYPE_AGENTKIT_ROLLUP":
      return AAAType.AAATYPE_AGENTKIT_ROLLUP;
    case 6:
    case "AAATYPE_ELIZA":
      return AAAType.AAATYPE_ELIZA;
    case 7:
    case "AAATYPE_APP_STORE":
      return AAAType.AAATYPE_APP_STORE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AAAType.UNRECOGNIZED;
  }
}

export function aAATypeToJSON(object: AAAType): string {
  switch (object) {
    case AAAType.AAATYPE_UNSPECIFIED:
      return "AAATYPE_UNSPECIFIED";
    case AAAType.AAATYPE_BASE_AGENT:
      return "AAATYPE_BASE_AGENT";
    case AAAType.AAATYPE_PERPLEXICA:
      return "AAATYPE_PERPLEXICA";
    case AAAType.AAATYPE_AGENTKIT:
      return "AAATYPE_AGENTKIT";
    case AAAType.AAATYPE_CUSTOM:
      return "AAATYPE_CUSTOM";
    case AAAType.AAATYPE_AGENTKIT_ROLLUP:
      return "AAATYPE_AGENTKIT_ROLLUP";
    case AAAType.AAATYPE_ELIZA:
      return "AAATYPE_ELIZA";
    case AAAType.AAATYPE_APP_STORE:
      return "AAATYPE_APP_STORE";
    case AAAType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AAAHostType {
  AAAHOSTTYPE_UNSPECIFIED = 0,
  AAAHOSTTYPE_HOSTYPE = 1,
  AAAHOSTTYPE_CHATBOX = 2,
  UNRECOGNIZED = -1,
}

export function aAAHostTypeFromJSON(object: any): AAAHostType {
  switch (object) {
    case 0:
    case "AAAHOSTTYPE_UNSPECIFIED":
      return AAAHostType.AAAHOSTTYPE_UNSPECIFIED;
    case 1:
    case "AAAHOSTTYPE_HOSTYPE":
      return AAAHostType.AAAHOSTTYPE_HOSTYPE;
    case 2:
    case "AAAHOSTTYPE_CHATBOX":
      return AAAHostType.AAAHOSTTYPE_CHATBOX;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AAAHostType.UNRECOGNIZED;
  }
}

export function aAAHostTypeToJSON(object: AAAHostType): string {
  switch (object) {
    case AAAHostType.AAAHOSTTYPE_UNSPECIFIED:
      return "AAAHOSTTYPE_UNSPECIFIED";
    case AAAHostType.AAAHOSTTYPE_HOSTYPE:
      return "AAAHOSTTYPE_HOSTYPE";
    case AAAHostType.AAAHOSTTYPE_CHATBOX:
      return "AAAHOSTTYPE_CHATBOX";
    case AAAHostType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AAAAppStatus {
  AAA_APP_STATUS_UNSPECIFIED = 0,
  AAA_APP_STATUS_SUBMITTED = 1,
  AAA_APP_STATUS_PENDING = 2,
  AAA_APP_STATUS_PUBLISHED = 3,
  AAA_APP_STATUS_REJECTED = 4,
  AAA_APP_STATUS_DELETED = 5,
  UNRECOGNIZED = -1,
}

export function aAAAppStatusFromJSON(object: any): AAAAppStatus {
  switch (object) {
    case 0:
    case "AAA_APP_STATUS_UNSPECIFIED":
      return AAAAppStatus.AAA_APP_STATUS_UNSPECIFIED;
    case 1:
    case "AAA_APP_STATUS_SUBMITTED":
      return AAAAppStatus.AAA_APP_STATUS_SUBMITTED;
    case 2:
    case "AAA_APP_STATUS_PENDING":
      return AAAAppStatus.AAA_APP_STATUS_PENDING;
    case 3:
    case "AAA_APP_STATUS_PUBLISHED":
      return AAAAppStatus.AAA_APP_STATUS_PUBLISHED;
    case 4:
    case "AAA_APP_STATUS_REJECTED":
      return AAAAppStatus.AAA_APP_STATUS_REJECTED;
    case 5:
    case "AAA_APP_STATUS_DELETED":
      return AAAAppStatus.AAA_APP_STATUS_DELETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AAAAppStatus.UNRECOGNIZED;
  }
}

export function aAAAppStatusToJSON(object: AAAAppStatus): string {
  switch (object) {
    case AAAAppStatus.AAA_APP_STATUS_UNSPECIFIED:
      return "AAA_APP_STATUS_UNSPECIFIED";
    case AAAAppStatus.AAA_APP_STATUS_SUBMITTED:
      return "AAA_APP_STATUS_SUBMITTED";
    case AAAAppStatus.AAA_APP_STATUS_PENDING:
      return "AAA_APP_STATUS_PENDING";
    case AAAAppStatus.AAA_APP_STATUS_PUBLISHED:
      return "AAA_APP_STATUS_PUBLISHED";
    case AAAAppStatus.AAA_APP_STATUS_REJECTED:
      return "AAA_APP_STATUS_REJECTED";
    case AAAAppStatus.AAA_APP_STATUS_DELETED:
      return "AAA_APP_STATUS_DELETED";
    case AAAAppStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface OperateAAAAppRequest {
  id: string;
  OperateAction: OperateAction;
}

export interface OperateAAAAppReply {
}

export interface CreateAAAAppRequest {
  name: string;
  type: AAAHostType;
  dockerImage: string;
  chatSubPath: string;
  description: string;
  iconLink: string;
  envList: string[];
  configExample: string;
}

export interface CreateAAAAppReply {
}

export interface AAA {
  id: string;
  name: string;
  endpoints: { [key: string]: any } | undefined;
  app: string;
  hostType: AAAHostType;
  createdAt: Date | undefined;
  chatSubPath: string;
}

export interface ListAAARequest {
}

export interface ListAAAReply {
  aaas: AAA[];
}

export interface TerminateAAARequest {
  id: string;
}

export interface TerminateAAAReply {
}

export interface CreateAAARequest {
  name: string;
  app: AAAType;
  promoCode: string;
  imageId: string;
  envList: { [key: string]: any } | undefined;
  config: string;
  appStoreID: string;
}

export interface CreateAAAReply {
  aaa: AAA | undefined;
}

export interface APP {
  type: AAAType;
  description: string;
  hostType: AAAHostType;
  envList: string[];
  chatSubPath: string;
  configExample: string;
  appStoreID: string;
  iconLink: string;
  status: AAAAppStatus;
  name: string;
}

export interface ListAAAAppTypeRequest {
  isPending: boolean;
}

export interface ListAAAAppTypeReply {
  templates: APP[];
  appStore: APP[];
  selfOwned: APP[];
}

export interface ListAAAWithLabelsRequest {
}

export interface ListAAAWithLabelsReply {
  aaas: AAA[];
}

function createBaseOperateAAAAppRequest(): OperateAAAAppRequest {
  return { id: "", OperateAction: 0 };
}

export const OperateAAAAppRequest = {
  encode(message: OperateAAAAppRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.OperateAction !== 0) {
      writer.uint32(16).int32(message.OperateAction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OperateAAAAppRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOperateAAAAppRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.OperateAction = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OperateAAAAppRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      OperateAction: isSet(object.OperateAction) ? operateActionFromJSON(object.OperateAction) : 0,
    };
  },

  toJSON(message: OperateAAAAppRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.OperateAction !== 0) {
      obj.OperateAction = operateActionToJSON(message.OperateAction);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OperateAAAAppRequest>, I>>(base?: I): OperateAAAAppRequest {
    return OperateAAAAppRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OperateAAAAppRequest>, I>>(object: I): OperateAAAAppRequest {
    const message = createBaseOperateAAAAppRequest();
    message.id = object.id ?? "";
    message.OperateAction = object.OperateAction ?? 0;
    return message;
  },
};

function createBaseOperateAAAAppReply(): OperateAAAAppReply {
  return {};
}

export const OperateAAAAppReply = {
  encode(_: OperateAAAAppReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OperateAAAAppReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOperateAAAAppReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): OperateAAAAppReply {
    return {};
  },

  toJSON(_: OperateAAAAppReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<OperateAAAAppReply>, I>>(base?: I): OperateAAAAppReply {
    return OperateAAAAppReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OperateAAAAppReply>, I>>(_: I): OperateAAAAppReply {
    const message = createBaseOperateAAAAppReply();
    return message;
  },
};

function createBaseCreateAAAAppRequest(): CreateAAAAppRequest {
  return {
    name: "",
    type: 0,
    dockerImage: "",
    chatSubPath: "",
    description: "",
    iconLink: "",
    envList: [],
    configExample: "",
  };
}

export const CreateAAAAppRequest = {
  encode(message: CreateAAAAppRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.dockerImage !== "") {
      writer.uint32(34).string(message.dockerImage);
    }
    if (message.chatSubPath !== "") {
      writer.uint32(42).string(message.chatSubPath);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.iconLink !== "") {
      writer.uint32(58).string(message.iconLink);
    }
    for (const v of message.envList) {
      writer.uint32(66).string(v!);
    }
    if (message.configExample !== "") {
      writer.uint32(74).string(message.configExample);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAAAAppRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAAAAppRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dockerImage = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.chatSubPath = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.iconLink = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.envList.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.configExample = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAAAAppRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      type: isSet(object.type) ? aAAHostTypeFromJSON(object.type) : 0,
      dockerImage: isSet(object.dockerImage) ? globalThis.String(object.dockerImage) : "",
      chatSubPath: isSet(object.chatSubPath) ? globalThis.String(object.chatSubPath) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      iconLink: isSet(object.iconLink) ? globalThis.String(object.iconLink) : "",
      envList: globalThis.Array.isArray(object?.envList) ? object.envList.map((e: any) => globalThis.String(e)) : [],
      configExample: isSet(object.configExample) ? globalThis.String(object.configExample) : "",
    };
  },

  toJSON(message: CreateAAAAppRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.type !== 0) {
      obj.type = aAAHostTypeToJSON(message.type);
    }
    if (message.dockerImage !== "") {
      obj.dockerImage = message.dockerImage;
    }
    if (message.chatSubPath !== "") {
      obj.chatSubPath = message.chatSubPath;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.iconLink !== "") {
      obj.iconLink = message.iconLink;
    }
    if (message.envList?.length) {
      obj.envList = message.envList;
    }
    if (message.configExample !== "") {
      obj.configExample = message.configExample;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAAAAppRequest>, I>>(base?: I): CreateAAAAppRequest {
    return CreateAAAAppRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAAAAppRequest>, I>>(object: I): CreateAAAAppRequest {
    const message = createBaseCreateAAAAppRequest();
    message.name = object.name ?? "";
    message.type = object.type ?? 0;
    message.dockerImage = object.dockerImage ?? "";
    message.chatSubPath = object.chatSubPath ?? "";
    message.description = object.description ?? "";
    message.iconLink = object.iconLink ?? "";
    message.envList = object.envList?.map((e) => e) || [];
    message.configExample = object.configExample ?? "";
    return message;
  },
};

function createBaseCreateAAAAppReply(): CreateAAAAppReply {
  return {};
}

export const CreateAAAAppReply = {
  encode(_: CreateAAAAppReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAAAAppReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAAAAppReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CreateAAAAppReply {
    return {};
  },

  toJSON(_: CreateAAAAppReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAAAAppReply>, I>>(base?: I): CreateAAAAppReply {
    return CreateAAAAppReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAAAAppReply>, I>>(_: I): CreateAAAAppReply {
    const message = createBaseCreateAAAAppReply();
    return message;
  },
};

function createBaseAAA(): AAA {
  return { id: "", name: "", endpoints: undefined, app: "", hostType: 0, createdAt: undefined, chatSubPath: "" };
}

export const AAA = {
  encode(message: AAA, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.endpoints !== undefined) {
      Struct.encode(Struct.wrap(message.endpoints), writer.uint32(26).fork()).ldelim();
    }
    if (message.app !== "") {
      writer.uint32(34).string(message.app);
    }
    if (message.hostType !== 0) {
      writer.uint32(40).int32(message.hostType);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.chatSubPath !== "") {
      writer.uint32(58).string(message.chatSubPath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AAA {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAAA();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endpoints = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.app = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.hostType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.chatSubPath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AAA {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      endpoints: isObject(object.endpoints) ? object.endpoints : undefined,
      app: isSet(object.app) ? globalThis.String(object.app) : "",
      hostType: isSet(object.hostType) ? aAAHostTypeFromJSON(object.hostType) : 0,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      chatSubPath: isSet(object.chatSubPath) ? globalThis.String(object.chatSubPath) : "",
    };
  },

  toJSON(message: AAA): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.endpoints !== undefined) {
      obj.endpoints = message.endpoints;
    }
    if (message.app !== "") {
      obj.app = message.app;
    }
    if (message.hostType !== 0) {
      obj.hostType = aAAHostTypeToJSON(message.hostType);
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.chatSubPath !== "") {
      obj.chatSubPath = message.chatSubPath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AAA>, I>>(base?: I): AAA {
    return AAA.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AAA>, I>>(object: I): AAA {
    const message = createBaseAAA();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.endpoints = object.endpoints ?? undefined;
    message.app = object.app ?? "";
    message.hostType = object.hostType ?? 0;
    message.createdAt = object.createdAt ?? undefined;
    message.chatSubPath = object.chatSubPath ?? "";
    return message;
  },
};

function createBaseListAAARequest(): ListAAARequest {
  return {};
}

export const ListAAARequest = {
  encode(_: ListAAARequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAARequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAARequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListAAARequest {
    return {};
  },

  toJSON(_: ListAAARequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAARequest>, I>>(base?: I): ListAAARequest {
    return ListAAARequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAARequest>, I>>(_: I): ListAAARequest {
    const message = createBaseListAAARequest();
    return message;
  },
};

function createBaseListAAAReply(): ListAAAReply {
  return { aaas: [] };
}

export const ListAAAReply = {
  encode(message: ListAAAReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.aaas) {
      AAA.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aaas.push(AAA.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAAReply {
    return { aaas: globalThis.Array.isArray(object?.aaas) ? object.aaas.map((e: any) => AAA.fromJSON(e)) : [] };
  },

  toJSON(message: ListAAAReply): unknown {
    const obj: any = {};
    if (message.aaas?.length) {
      obj.aaas = message.aaas.map((e) => AAA.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAReply>, I>>(base?: I): ListAAAReply {
    return ListAAAReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAReply>, I>>(object: I): ListAAAReply {
    const message = createBaseListAAAReply();
    message.aaas = object.aaas?.map((e) => AAA.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTerminateAAARequest(): TerminateAAARequest {
  return { id: "" };
}

export const TerminateAAARequest = {
  encode(message: TerminateAAARequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TerminateAAARequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTerminateAAARequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TerminateAAARequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: TerminateAAARequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TerminateAAARequest>, I>>(base?: I): TerminateAAARequest {
    return TerminateAAARequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TerminateAAARequest>, I>>(object: I): TerminateAAARequest {
    const message = createBaseTerminateAAARequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseTerminateAAAReply(): TerminateAAAReply {
  return {};
}

export const TerminateAAAReply = {
  encode(_: TerminateAAAReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TerminateAAAReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTerminateAAAReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TerminateAAAReply {
    return {};
  },

  toJSON(_: TerminateAAAReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TerminateAAAReply>, I>>(base?: I): TerminateAAAReply {
    return TerminateAAAReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TerminateAAAReply>, I>>(_: I): TerminateAAAReply {
    const message = createBaseTerminateAAAReply();
    return message;
  },
};

function createBaseCreateAAARequest(): CreateAAARequest {
  return { name: "", app: 0, promoCode: "", imageId: "", envList: undefined, config: "", appStoreID: "" };
}

export const CreateAAARequest = {
  encode(message: CreateAAARequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.app !== 0) {
      writer.uint32(16).int32(message.app);
    }
    if (message.promoCode !== "") {
      writer.uint32(26).string(message.promoCode);
    }
    if (message.imageId !== "") {
      writer.uint32(34).string(message.imageId);
    }
    if (message.envList !== undefined) {
      Struct.encode(Struct.wrap(message.envList), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== "") {
      writer.uint32(50).string(message.config);
    }
    if (message.appStoreID !== "") {
      writer.uint32(58).string(message.appStoreID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAAARequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAAARequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.app = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.promoCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.imageId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.envList = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.appStoreID = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAAARequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      app: isSet(object.app) ? aAATypeFromJSON(object.app) : 0,
      promoCode: isSet(object.promoCode) ? globalThis.String(object.promoCode) : "",
      imageId: isSet(object.imageId) ? globalThis.String(object.imageId) : "",
      envList: isObject(object.envList) ? object.envList : undefined,
      config: isSet(object.config) ? globalThis.String(object.config) : "",
      appStoreID: isSet(object.appStoreID) ? globalThis.String(object.appStoreID) : "",
    };
  },

  toJSON(message: CreateAAARequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.app !== 0) {
      obj.app = aAATypeToJSON(message.app);
    }
    if (message.promoCode !== "") {
      obj.promoCode = message.promoCode;
    }
    if (message.imageId !== "") {
      obj.imageId = message.imageId;
    }
    if (message.envList !== undefined) {
      obj.envList = message.envList;
    }
    if (message.config !== "") {
      obj.config = message.config;
    }
    if (message.appStoreID !== "") {
      obj.appStoreID = message.appStoreID;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAAARequest>, I>>(base?: I): CreateAAARequest {
    return CreateAAARequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAAARequest>, I>>(object: I): CreateAAARequest {
    const message = createBaseCreateAAARequest();
    message.name = object.name ?? "";
    message.app = object.app ?? 0;
    message.promoCode = object.promoCode ?? "";
    message.imageId = object.imageId ?? "";
    message.envList = object.envList ?? undefined;
    message.config = object.config ?? "";
    message.appStoreID = object.appStoreID ?? "";
    return message;
  },
};

function createBaseCreateAAAReply(): CreateAAAReply {
  return { aaa: undefined };
}

export const CreateAAAReply = {
  encode(message: CreateAAAReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.aaa !== undefined) {
      AAA.encode(message.aaa, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAAAReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAAAReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aaa = AAA.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAAAReply {
    return { aaa: isSet(object.aaa) ? AAA.fromJSON(object.aaa) : undefined };
  },

  toJSON(message: CreateAAAReply): unknown {
    const obj: any = {};
    if (message.aaa !== undefined) {
      obj.aaa = AAA.toJSON(message.aaa);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAAAReply>, I>>(base?: I): CreateAAAReply {
    return CreateAAAReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAAAReply>, I>>(object: I): CreateAAAReply {
    const message = createBaseCreateAAAReply();
    message.aaa = (object.aaa !== undefined && object.aaa !== null) ? AAA.fromPartial(object.aaa) : undefined;
    return message;
  },
};

function createBaseAPP(): APP {
  return {
    type: 0,
    description: "",
    hostType: 0,
    envList: [],
    chatSubPath: "",
    configExample: "",
    appStoreID: "",
    iconLink: "",
    status: 0,
    name: "",
  };
}

export const APP = {
  encode(message: APP, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.hostType !== 0) {
      writer.uint32(24).int32(message.hostType);
    }
    for (const v of message.envList) {
      writer.uint32(34).string(v!);
    }
    if (message.chatSubPath !== "") {
      writer.uint32(42).string(message.chatSubPath);
    }
    if (message.configExample !== "") {
      writer.uint32(50).string(message.configExample);
    }
    if (message.appStoreID !== "") {
      writer.uint32(58).string(message.appStoreID);
    }
    if (message.iconLink !== "") {
      writer.uint32(66).string(message.iconLink);
    }
    if (message.status !== 0) {
      writer.uint32(72).int32(message.status);
    }
    if (message.name !== "") {
      writer.uint32(82).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): APP {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAPP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hostType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.envList.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.chatSubPath = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.configExample = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.appStoreID = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.iconLink = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): APP {
    return {
      type: isSet(object.type) ? aAATypeFromJSON(object.type) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      hostType: isSet(object.hostType) ? aAAHostTypeFromJSON(object.hostType) : 0,
      envList: globalThis.Array.isArray(object?.envList) ? object.envList.map((e: any) => globalThis.String(e)) : [],
      chatSubPath: isSet(object.chatSubPath) ? globalThis.String(object.chatSubPath) : "",
      configExample: isSet(object.configExample) ? globalThis.String(object.configExample) : "",
      appStoreID: isSet(object.appStoreID) ? globalThis.String(object.appStoreID) : "",
      iconLink: isSet(object.iconLink) ? globalThis.String(object.iconLink) : "",
      status: isSet(object.status) ? aAAAppStatusFromJSON(object.status) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: APP): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = aAATypeToJSON(message.type);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.hostType !== 0) {
      obj.hostType = aAAHostTypeToJSON(message.hostType);
    }
    if (message.envList?.length) {
      obj.envList = message.envList;
    }
    if (message.chatSubPath !== "") {
      obj.chatSubPath = message.chatSubPath;
    }
    if (message.configExample !== "") {
      obj.configExample = message.configExample;
    }
    if (message.appStoreID !== "") {
      obj.appStoreID = message.appStoreID;
    }
    if (message.iconLink !== "") {
      obj.iconLink = message.iconLink;
    }
    if (message.status !== 0) {
      obj.status = aAAAppStatusToJSON(message.status);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<APP>, I>>(base?: I): APP {
    return APP.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<APP>, I>>(object: I): APP {
    const message = createBaseAPP();
    message.type = object.type ?? 0;
    message.description = object.description ?? "";
    message.hostType = object.hostType ?? 0;
    message.envList = object.envList?.map((e) => e) || [];
    message.chatSubPath = object.chatSubPath ?? "";
    message.configExample = object.configExample ?? "";
    message.appStoreID = object.appStoreID ?? "";
    message.iconLink = object.iconLink ?? "";
    message.status = object.status ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListAAAAppTypeRequest(): ListAAAAppTypeRequest {
  return { isPending: false };
}

export const ListAAAAppTypeRequest = {
  encode(message: ListAAAAppTypeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isPending !== false) {
      writer.uint32(8).bool(message.isPending);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAAppTypeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAAppTypeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isPending = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAAAppTypeRequest {
    return { isPending: isSet(object.isPending) ? globalThis.Boolean(object.isPending) : false };
  },

  toJSON(message: ListAAAAppTypeRequest): unknown {
    const obj: any = {};
    if (message.isPending !== false) {
      obj.isPending = message.isPending;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAAppTypeRequest>, I>>(base?: I): ListAAAAppTypeRequest {
    return ListAAAAppTypeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAAppTypeRequest>, I>>(object: I): ListAAAAppTypeRequest {
    const message = createBaseListAAAAppTypeRequest();
    message.isPending = object.isPending ?? false;
    return message;
  },
};

function createBaseListAAAAppTypeReply(): ListAAAAppTypeReply {
  return { templates: [], appStore: [], selfOwned: [] };
}

export const ListAAAAppTypeReply = {
  encode(message: ListAAAAppTypeReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      APP.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.appStore) {
      APP.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.selfOwned) {
      APP.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAAppTypeReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAAppTypeReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(APP.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.appStore.push(APP.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selfOwned.push(APP.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAAAppTypeReply {
    return {
      templates: globalThis.Array.isArray(object?.templates) ? object.templates.map((e: any) => APP.fromJSON(e)) : [],
      appStore: globalThis.Array.isArray(object?.appStore) ? object.appStore.map((e: any) => APP.fromJSON(e)) : [],
      selfOwned: globalThis.Array.isArray(object?.selfOwned) ? object.selfOwned.map((e: any) => APP.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListAAAAppTypeReply): unknown {
    const obj: any = {};
    if (message.templates?.length) {
      obj.templates = message.templates.map((e) => APP.toJSON(e));
    }
    if (message.appStore?.length) {
      obj.appStore = message.appStore.map((e) => APP.toJSON(e));
    }
    if (message.selfOwned?.length) {
      obj.selfOwned = message.selfOwned.map((e) => APP.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAAppTypeReply>, I>>(base?: I): ListAAAAppTypeReply {
    return ListAAAAppTypeReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAAppTypeReply>, I>>(object: I): ListAAAAppTypeReply {
    const message = createBaseListAAAAppTypeReply();
    message.templates = object.templates?.map((e) => APP.fromPartial(e)) || [];
    message.appStore = object.appStore?.map((e) => APP.fromPartial(e)) || [];
    message.selfOwned = object.selfOwned?.map((e) => APP.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListAAAWithLabelsRequest(): ListAAAWithLabelsRequest {
  return {};
}

export const ListAAAWithLabelsRequest = {
  encode(_: ListAAAWithLabelsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAWithLabelsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAWithLabelsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListAAAWithLabelsRequest {
    return {};
  },

  toJSON(_: ListAAAWithLabelsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAWithLabelsRequest>, I>>(base?: I): ListAAAWithLabelsRequest {
    return ListAAAWithLabelsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAWithLabelsRequest>, I>>(_: I): ListAAAWithLabelsRequest {
    const message = createBaseListAAAWithLabelsRequest();
    return message;
  },
};

function createBaseListAAAWithLabelsReply(): ListAAAWithLabelsReply {
  return { aaas: [] };
}

export const ListAAAWithLabelsReply = {
  encode(message: ListAAAWithLabelsReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.aaas) {
      AAA.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAWithLabelsReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAWithLabelsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aaas.push(AAA.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAAWithLabelsReply {
    return { aaas: globalThis.Array.isArray(object?.aaas) ? object.aaas.map((e: any) => AAA.fromJSON(e)) : [] };
  },

  toJSON(message: ListAAAWithLabelsReply): unknown {
    const obj: any = {};
    if (message.aaas?.length) {
      obj.aaas = message.aaas.map((e) => AAA.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAWithLabelsReply>, I>>(base?: I): ListAAAWithLabelsReply {
    return ListAAAWithLabelsReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAWithLabelsReply>, I>>(object: I): ListAAAWithLabelsReply {
    const message = createBaseListAAAWithLabelsReply();
    message.aaas = object.aaas?.map((e) => AAA.fromPartial(e)) || [];
    return message;
  },
};

export type AAAServiceDefinition = typeof AAAServiceDefinition;
export const AAAServiceDefinition = {
  name: "AAAService",
  fullName: "aaa.v1.AAAService",
  methods: {
    listAAA: {
      name: "ListAAA",
      requestType: ListAAARequest,
      requestStream: false,
      responseType: ListAAAReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [new Uint8Array([14, 18, 12, 47, 118, 49, 47, 98, 102, 102, 47, 97, 97, 97, 115])],
        },
      },
    },
    listAAAWithLabels: {
      name: "ListAAAWithLabels",
      requestType: ListAAAWithLabelsRequest,
      requestStream: false,
      responseType: ListAAAWithLabelsReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              24,
              34,
              19,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              115,
              47,
              108,
              97,
              98,
              101,
              108,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    createAAA: {
      name: "CreateAAA",
      requestType: CreateAAARequest,
      requestStream: false,
      responseType: CreateAAAReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [new Uint8Array([17, 34, 12, 47, 118, 49, 47, 98, 102, 102, 47, 97, 97, 97, 115, 58, 1, 42])],
        },
      },
    },
    terminateAAA: {
      name: "TerminateAAA",
      requestType: TerminateAAARequest,
      requestStream: false,
      responseType: TerminateAAAReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              27,
              34,
              22,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              115,
              47,
              116,
              101,
              114,
              109,
              105,
              110,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    listAAAAppType: {
      name: "ListAAAAppType",
      requestType: ListAAAAppTypeRequest,
      requestStream: false,
      responseType: ListAAAAppTypeReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [new Uint8Array([14, 18, 12, 47, 118, 49, 47, 98, 102, 102, 47, 97, 112, 112, 115])],
        },
      },
    },
    createAAAApp: {
      name: "CreateAAAApp",
      requestType: CreateAAAAppRequest,
      requestStream: false,
      responseType: CreateAAAAppReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [new Uint8Array([17, 34, 12, 47, 118, 49, 47, 98, 102, 102, 47, 97, 112, 112, 115, 58, 1, 42])],
        },
      },
    },
    operateAAAApp: {
      name: "OperateAAAApp",
      requestType: OperateAAAAppRequest,
      requestStream: false,
      responseType: OperateAAAAppReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              25,
              34,
              20,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              112,
              112,
              115,
              47,
              111,
              112,
              101,
              114,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
