import { Grid } from '@mui/material';
import { BASE_FEE_ADDR, L1_FEE_ADDR, SEQUENCER_FEE_ADDR } from 'constants/raas';
import { useRaasDeployment } from 'hooks';
import { IUseBatchBalances, useBatchBalances } from 'hooks/viem/useBatchBalances';
import { getTokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import { MetricWidget } from 'shared/components/metric/MetricWidget';
import { Section } from 'shared/components/Section';
import { formatWithPrecision } from 'utils/strings';

const accounts: IUseBatchBalances['accounts'] = [
  { label: 'Base Fee Balance', account: BASE_FEE_ADDR },
  { label: 'L1 Fee Balance', account: L1_FEE_ADDR },
  { label: 'Sequencer Fee Balance', account: SEQUENCER_FEE_ADDR },
];

export const OverviewSection = () => {
  const { data: rollup } = useRaasDeployment();
  const { data: balances, isPending: getBalancesPending } = useBatchBalances({
    viem: false,
    accounts,
    rollup,
  });

  return (
    <Section
      renderContent={
        <Grid container>
          {!balances || getBalancesPending
            ? accounts.map(v => (
                <MetricWidget key={typeof v === 'string' ? v : v.account} loading tooltip="..." />
              ))
            : balances?.map(({ account, balance, label }) => {
                return (
                  <>
                    {balance && (
                      <MetricWidget
                        key={account}
                        label={label}
                        tooltip={`Balance in ${getTokenSymbol(rollup)} for account ${account}`}
                        value={+formatWithPrecision(balance)}
                        valueSuffix={getTokenSymbol(rollup)}
                      />
                    )}
                  </>
                );
              })}
        </Grid>
      }
      title="Fee vault balance"
    />
  );
};
