import { Button, FormHelperText, Grid, Stack, TextField, Typography } from '@mui/material';
import { useFormContext, useFormState } from 'react-hook-form';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FormFieldArray } from 'shared/components/form';
import { Option } from 'shared/components/Option';
import { WithInfo } from 'shared/components/WithInfo';

import { FormValues } from './types';

export default function EnvVarsFieldArray() {
  const { register, setValue, trigger: validate } = useFormContext<FormValues>();
  const formState = useFormState<FormValues>();

  return (
    <Option
      optionTitle={
        <WithInfo
          info="Users will have to specify these env vars when using your agent image."
          text={
            <Typography variant="bodySmallC">Specify Environment Variables (Optional)</Typography>
          }
        />
      }
    >
      <Stack mt={3}>
        <FormFieldArray<FormValues>
          addRowText="Add Environment Variable"
          fieldArrayConfig={{
            name: 'envVars',
            label: (
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="caption">NAME</Typography>
                </Grid>
                {/* TODO: uncomment once validation feature is available on BE
                <Grid item xs={4}>
                  <WithInfo
                    info="Env vars will be validated with this regex. If left empty, users will be allowed to specify any value"
                    justifyContent="flex-start"
                    text="VALIDATION REGEX (recommended)"
                    textProps={{ variant: 'caption' }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <WithInfo
                    info="Example valid value for your env var. This should fulfill the regex validation"
                    justifyContent="flex-start"
                    text="EXAMPLE VALUE (recommended)"
                    textProps={{ variant: 'caption' }}
                  />
                </Grid> */}
              </Grid>
            ),
          }}
          initialRows={0}
          inputRef={register('envVars').ref}
          newRowDefaultValue={{ name: '', regex: '' }}
          renderField={({ field, fieldArray, formState, index }) => {
            return (
              <>
                <Grid
                  alignItems="center"
                  container
                  sx={{
                    '.MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid rgba(32, 39, 35, 0.08)',
                    },
                  }}
                >
                  <Grid item xs={11}>
                    <TextField
                      {...field}
                      error={Boolean(formState.errors?.envVars?.[index]?.name)}
                      fullWidth
                      name={`${field.name}.name`}
                      onChange={e => {
                        const val = e?.target?.value;

                        setValue(`envVars.${index}.name`, val, { shouldValidate: true });
                      }}
                      placeholder={`EnvVarName${index}`}
                      ref={undefined}
                      sx={{ my: 1 }}
                      value={field.value?.name || ''}
                    />
                  </Grid>
                  {/* TODO: uncomment once validation feature is available on BE
                  <Grid container item justifyContent="center" xs={4}>
                    <TextField
                      {...field}
                      error={Boolean(formState.errors?.envVars?.[index]?.regex)}
                      fullWidth
                      name={`${field.name}.regex`}
                      onChange={e => {
                        const val = e?.target?.value;

                        setValue(`envVars.${index}.regex`, val, { shouldValidate: true });
                      }}
                      placeholder={`/^\\d+$/`}
                      ref={undefined}
                      sx={{ my: 1 }}
                      value={field.value?.regex || ''}
                    />
                  </Grid>
                  <Grid container item justifyContent="center" xs={3}>
                    <TextField
                      {...field}
                      disabled={!field.value?.regex}
                      error={Boolean(formState.errors?.envVars?.[index]?.example)}
                      fullWidth
                      name={`${field.name}.example`}
                      onChange={e => {
                        const val = e?.target?.value;

                        setValue(`envVars.${index}.example`, val, { shouldValidate: true });
                      }}
                      placeholder="123"
                      ref={undefined}
                      sx={{ my: 1 }}
                      value={field.value?.example || ''}
                    />
                  </Grid>
                  */}
                  <Grid
                    component={Button}
                    item
                    onClick={() => {
                      if (fieldArray.fields.map(cur => !!cur).length > 1) {
                        fieldArray.remove(index);
                      } else {
                        fieldArray.replace([]);
                      }

                      validate('envVars');
                    }}
                    sx={{
                      '&&': {
                        display: 'inline-flex',
                        minWidth: 'unset',
                        height: '4.3rem',
                        background: theme => theme.colors.gradients.sheet,
                        border: '1px solid rgba(32, 39, 35, 0.08)',
                      },
                      '&:hover': { background: theme => theme.colors.gradients.metal },
                    }}
                    variant="text"
                    xs={1}
                  >
                    <RiDeleteBin6Line color="#202723" size={20} />
                  </Grid>
                </Grid>
                {formState.errors?.envVars?.[index] && (
                  <FormHelperText error>
                    {formState.errors?.envVars?.[index]?.name?.message ||
                      formState.errors?.envVars?.[index]?.regex?.message ||
                      formState.errors?.envVars?.[index]?.example?.message}
                  </FormHelperText>
                )}
              </>
            );
          }}
        />
        {formState.errors?.envVars?.root?.message && (
          <FormHelperText error>{String(formState?.errors?.envVars?.root?.message)}</FormHelperText>
        )}
      </Stack>
    </Option>
  );
}
