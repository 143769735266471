import dayjs from 'dayjs';
import { Timeframe } from 'hooks';
import { useMemo } from 'react';

export const useTimeFilterOptions = (
  timeframe?: Timeframe,
): {
  start: string;
  end: string;
  step: '1h' | '10m' | '1d' | '24h' | '48h';
  timeframe?: Timeframe;
} =>
  useMemo(() => {
    return {
      start:
        timeframe === 'month'
          ? dayjs().subtract(30, 'days').startOf('day').format('YYYY-MM-DD')
          : dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
      end: dayjs().endOf('day').format('YYYY-MM-DD'),
      step: '24h',
      timeframe,
    };
  }, [timeframe]);
