import { BoxProps, Grid, Stack, Typography } from '@mui/material';
import IconArb from 'components/icons/IconArb';
import IconOP from 'components/icons/IconOP';
import IconPolygon from 'components/icons/IconPolygon';
import IconZKSync from 'components/icons/IconZKSync';
import { FormValues } from 'pages/raas/NewDeployment/types';
import { ReactNode } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import SelectableCard from 'shared/components/Card/SelectableCard';
import { FormRadioButton } from 'shared/components/form';
import { Option } from 'shared/components/Option';
import { RollupType } from 'types/protoc-gen/rollup';

interface StackTypeOption {
  icon: ReactNode;
  title: ReactNode;
  content: ReactNode;
  value: RollupType;
  disabled?: boolean;
}

const stackTypeOptions: StackTypeOption[] = [
  {
    icon: <IconArb sx={{ height: '40px', width: '40px' }} />,
    title: <Typography></Typography>,
    content: <Typography variant="bodySmall">Arbitrum Orbit</Typography>,
    value: RollupType.TYPE_ORBIT,
  },
  {
    icon: <IconOP sx={{ height: '40px', width: '40px' }} />,
    title: <Typography variant="captionC">Coming Soon</Typography>,
    content: <Typography variant="bodySmall">Optimism</Typography>,
    value: RollupType.UNRECOGNIZED,
    disabled: true,
  },
  {
    icon: <IconPolygon sx={{ height: '40px', width: '40px' }} />,
    title: <Typography variant="captionC">Coming Soon</Typography>,
    content: <Typography variant="bodySmall">Polygon CDK</Typography>,
    value: RollupType.TYPE_CDK,
    disabled: true,
  },
  {
    icon: <IconZKSync sx={{ height: '40px', width: '40px' }} />,
    title: <Typography variant="captionC">Coming Soon</Typography>,
    content: <Typography variant="bodySmall">ZkSync ZK Stack</Typography>,
    value: RollupType.TYPE_ZKSYNC,
    disabled: true,
  },
];

export default function SelectStackType(props: BoxProps) {
  const form = useFormContext<FormValues>();
  const selectedStackType = useWatch<FormValues, 'rollupType'>({
    control: form.control,
    name: 'rollupType',
  });

  return (
    <Option optionTitle="CHOOSE A STACK" {...props}>
      <Grid container>
        {stackTypeOptions.map(cur => (
          <Grid item key={cur.value} xs={Math.floor(12 / stackTypeOptions.length)}>
            <SelectableCard
              icon={cur.icon}
              isSelected={cur.value === selectedStackType}
              onClick={() => {
                form.setValue('rollupType', cur.value, { shouldTouch: true, shouldDirty: true });
              }}
              rightButtonGroup={
                <FormRadioButton
                  assignedValue={cur.value}
                  labelProps={{
                    sx: {
                      '& .MuiRadio-root': {
                        padding: 0,
                        width: 'unset',
                        height: 'unset',
                        // bgcolor: 'transparent',
                      },
                    },
                  }}
                  radioGroupConfig={{ label: cur.title, name: 'rollupType' }}
                />
              }
              sx={{
                height: '100%',
                ...(cur.disabled && {
                  cursor: 'not-allowed',
                  opacity: 0.7,
                  pointerEvents: 'none',
                }),
              }}
            >
              <Stack>
                {cur.title}
                {cur.content}
              </Stack>
            </SelectableCard>
          </Grid>
        ))}
      </Grid>
    </Option>
  );
}
