import { Box } from '@mui/material';
import { PiCaretDownBold } from 'react-icons/pi';

export const AnimatedIconChevronDown: React.FC<{ open: boolean }> = ({ open }) => (
  <Box
    component={PiCaretDownBold}
    size={20}
    sx={{
      color: theme => theme.colors.functional.text.primary,
      transform: open ? 'rotate(-180deg)' : 'none',
      transition: 'all 0.2s ease-in-out',
    }}
  />
);
