import { debounce } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { axiosAuth } from 'api';
import { AxiosError } from 'axios';
import { useMemo } from 'react';

export default function useValidateOrgName() {
  const {
    error,
    mutate: validateOrgName,
    reset,
  } = useMutation({
    mutationFn: async (orgName: string) => {
      const res = await axiosAuth.get<{ valid: boolean }>(`/org/validate/${orgName}`);

      return res?.data?.valid;
    },
    onError: (err: AxiosError<{ message: string }>) => {
      console.error(err?.response?.data?.message);
    },
  });

  const debouncedValidateOrgName = useMemo(() => debounce(validateOrgName, 500), [validateOrgName]);

  return useMemo(
    () => ({ error, reset, validateOrgName: debouncedValidateOrgName }),
    [error, reset, debouncedValidateOrgName],
  );
}
