import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconNear: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.2952 2C15.7026 2 15.1524 2.30622 14.842 2.80962L11.4976 7.75868C11.3887 7.92181 11.4329 8.14168 11.5965 8.25029C11.7292 8.33842 11.9046 8.32751 12.0255 8.22393L15.3175 5.37797C15.3722 5.32891 15.4565 5.33391 15.5057 5.38842C15.528 5.41341 15.5399 5.44567 15.5399 5.47884V14.3893C15.5399 14.4628 15.4802 14.5219 15.4063 14.5219C15.3667 14.5219 15.3293 14.5046 15.3042 14.4742L5.35306 2.60154C5.02898 2.22035 4.55309 2.00045 4.05213 2H3.70434C2.76305 2 2 2.76056 2 3.69876V16.2483C2 17.1866 2.76305 17.9471 3.70434 17.9471C4.29691 17.9471 4.8471 17.6409 5.15752 17.1375L8.50195 12.1885C8.61086 12.0253 8.56665 11.8055 8.40298 11.6968C8.27034 11.6087 8.09487 11.6196 7.97409 11.7232L4.68209 14.5692C4.62739 14.6182 4.54306 14.6132 4.49383 14.5587C4.47149 14.5337 4.45964 14.5015 4.4601 14.4683V5.55562C4.4601 5.48202 4.51981 5.42295 4.59365 5.42295C4.63286 5.42295 4.67069 5.44022 4.69576 5.47066L14.6456 17.3456C14.9696 17.7268 15.4456 17.9467 15.9465 17.9471H16.2943C17.2356 17.9476 17.9991 17.1875 18 16.2493V3.69876C18 2.76056 17.2365 2 16.2952 2Z"
      fill="black"
    />
  </SvgIcon>
);
