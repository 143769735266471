import { Navigate, RouteObject } from 'react-router-dom';

import { AAAS_PATHS, routes as aaasRoutes } from './pages/aaas/routes';
import { AcceptInvite } from './pages/AcceptInvite';
import { ADMIN_PATHS, routes as adminRoutes } from './pages/admin/routes';
import { AVS_PATHS, routes as avsRoutes } from './pages/avs/routes';
import { CreateOrganization } from './pages/CreateOrganization';
import { Disclaimer } from './pages/Disclaimer';
import { Login } from './pages/Login';
import { Privacy } from './pages/Privacy';
import { RAAS_PATHS, routes as raasRoutes } from './pages/raas/routes';
import { SelectLayerType } from './pages/SelectLayerType';
import { RequireLoginGuard } from './guards';

const getDefaultPath = (): string => {
  switch (window.location.origin) {
    case process.env.REACT_APP_AUTONOME_ORIGIN:
      return AAAS_PATHS.AAAS_DEPLOYMENTS;
    default:
      return GENERAL_PATHS.SELECT_LAYER_TYPE;
  }
};

export enum GENERAL_PATHS {
  SETTINGS = '/settings',
  LOGIN = '/login',
  CREATE_ORG = '/create-org',
  NEW_DEPLOYMENT_DEPR = '/new-deployment',
  ACCEPT_INVITE = '/invite/:id',
  SELECT_LAYER_TYPE = '/select',
}

export const PATHS = {
  DEFAULT: getDefaultPath(),
  ...GENERAL_PATHS,
  ...AAAS_PATHS,
  ...RAAS_PATHS,
  ...AVS_PATHS,
  ...ADMIN_PATHS,
};

const DefaultRoute: React.FC = () => {
  return <Navigate to={PATHS.DEFAULT} />;
};

export const routes: RouteObject[] = [
  {
    element: <Login />,
    path: PATHS.LOGIN,
  },
  {
    element: <AcceptInvite />,
    path: PATHS.ACCEPT_INVITE,
  },
  {
    element: <CreateOrganization />,
    path: PATHS.CREATE_ORG,
  },
  {
    element: (
      <RequireLoginGuard>
        <SelectLayerType />
      </RequireLoginGuard>
    ),
    path: PATHS.SELECT_LAYER_TYPE,
  },
  {
    element: <Privacy />,
    path: '/privacy',
  },
  {
    element: <Disclaimer />,
    path: '/disclaimer',
  },
  {
    element: <DefaultRoute />,
    path: '*',
  },
  ...aaasRoutes,
  ...raasRoutes,
  ...avsRoutes,
  ...adminRoutes,
];
