import { LoadingButton } from '@mui/lab';
import { Grid, lighten, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { AnimatedIconChevronDown } from 'components/icons/AnimatedIconChevronDown';
import { useRaasDeployment } from 'hooks';
import { useMenuDisclosure } from 'hooks/ui/useMenuDisclosure';
import { StackRowFieldValue } from 'pages/raas/DeploymentDetails/components/StackRowFieldValue';
import { getTokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import {
  useWFStatus,
  useWFTrigger,
} from 'pages/raas/DeploymentDetails/views/AutoWithdrawalView/hooks';
import { useMemo, useState } from 'react';
import { Section } from 'shared/components/Section';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';
import { FeeVault, feeVaultFromJSON } from 'types/protoc-gen/raas-rollup';
import { formatWithPrecision } from 'utils/strings';
import { isAddress, isHash } from 'viem';

const feeVaultLabel = {
  [FeeVault.BASE_FEE_VAULT]: 'Base Fee Vault',
  [FeeVault.L1_FEE_VAULT]: 'L1 Fee Vault',
  [FeeVault.SEQUENCER_FEE_VAULT]: 'Sequencer Fee Vault',
  [FeeVault.UNRECOGNIZED]: 'Unrecognized',
};

export const VaultBalanceSection = () => {
  const {
    anchorEl: feeVaultMenuAnchorEl,
    onClose: onCloseFeeVaultMenu,
    onOpen: onOpenFeeVaultMenu,
    open: openedFeeVaultMenu,
  } = useMenuDisclosure();
  const [feeVault, setFeeVault] = useState<FeeVault>(FeeVault.L1_FEE_VAULT);

  const { data: rollup } = useRaasDeployment();
  const { isPending: withdrawTriggerPending, mutate: withdrawTrigger } = useWFTrigger();
  const { data: withdrawStatuses, isPending: withdrawStatusPending } = useWFStatus({
    params: {
      rollupId: rollup?.id ?? '',
    },
    enabled: Boolean(rollup?.id),
  });

  const currentVault = withdrawStatuses?.find(v => v.vault === feeVault);
  const canWithdraw =
    Number(currentVault?.vaultBalance || 0) < Number(currentVault?.minWithdrawalAmount || 0);

  const fieldValueMap = useMemo(
    () => [
      {
        field: 'Receipt',
        href: `${rollup?.explorer}/tx/${currentVault?.receipt}`,
        value: currentVault?.receipt ?? '',
      },
      {
        field: 'Vault balance',
        value: currentVault?.vaultBalance
          ? `${formatWithPrecision(+currentVault?.vaultBalance)} ${getTokenSymbol(rollup)}`
          : '',
      },
      // {
      //   field: 'Missing receipt in L1',
      //   href: `${rollup?.explorer}/tx/${zeroHash}`,
      //   value: zeroHash,
      // }, // TODO: when the following fields are available, uncomment them
      // {
      //   field: 'Missing receipt balance',
      //   value: '1.46478930742 ETH',
      // }, // TODO: when the following fields are available, uncomment them
      {
        field: 'Minimum withdrawal balance',
        value: currentVault?.minWithdrawalAmount
          ? `${formatWithPrecision(+currentVault?.minWithdrawalAmount)} ${getTokenSymbol(rollup)}`
          : '',
      },
      {
        field: 'Target network',
        value: rollup?.settlementLayer ?? '',
      },
    ],
    [rollup, currentVault?.minWithdrawalAmount, currentVault?.receipt, currentVault?.vaultBalance],
  );

  return (
    <Section
      renderContent={
        <Stack spacing="8px">
          <Grid
            container
            sx={theme => ({
              background: '#fff',
              px: '40px',
              py: '24px',
              borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
            })}
          >
            <Stack spacing="16px" width="100%">
              {fieldValueMap.map(({ field, href, value }) => (
                <StackRowFieldValue
                  copyable={!!href}
                  field={field}
                  href={href}
                  key={field}
                  loading={withdrawStatusPending}
                  showTooltip={isAddress(value) || isHash(value)}
                  textTruncateBackChars={isHash(value) ? 16 : isAddress(value) ? 6 : undefined}
                  textTruncateFrontChars={isHash(value) ? 16 : isAddress(value) ? 8 : undefined}
                  truncate={isAddress(value) || isHash(value)}
                  value={value}
                />
              ))}
            </Stack>
          </Grid>
        </Stack>
      }
      renderSubHeaderEndContent={
        !rollup?.name.includes('demo') &&
        rollup?.environment.toLowerCase() !== 'testnet' && (
          <ConditionalTooltip
            enabled
            title={`The vault balance has not reached the minimum withdrawal balance (${
              currentVault?.minWithdrawalAmount
            } ${getTokenSymbol(rollup)}) required.`.trim()}
          >
            <LoadingButton
              disabled={withdrawStatusPending || withdrawTriggerPending || canWithdraw}
              loading={withdrawTriggerPending}
              onClick={() => withdrawTrigger({ opstackId: rollup?.id ?? '', vault: feeVault })}
              sx={{
                '&:disabled, &:hover': {
                  pointerEvents: 'auto',
                  background: theme => lighten(theme.colors.functional.container.primary, 0.55),
                },
              }}
              variant="contained"
            >
              Withdraw Now
            </LoadingButton>
          </ConditionalTooltip>
        )
      }
      renderSubHeaderStartContent={
        <>
          <LoadingButton
            disabled={withdrawStatusPending}
            loading={withdrawStatusPending}
            onClick={onOpenFeeVaultMenu}
            sx={theme => ({
              border: `1px solid ${theme.colors.functional.subject.border}`,
              '&, &:hover': {
                background: '#fff',
                color: theme.colors.functional.text.primary,
                p: 0,
              },
            })}
          >
            <Stack direction="row" spacing="32px" sx={{ p: '16px' }}>
              <Typography variant="bodySmallM">{feeVaultLabel[feeVault]}</Typography>
              <AnimatedIconChevronDown open={openedFeeVaultMenu} />
            </Stack>
          </LoadingButton>
          <Menu
            anchorEl={feeVaultMenuAnchorEl}
            onClose={onCloseFeeVaultMenu}
            open={openedFeeVaultMenu}
          >
            {Object.values(FeeVault)
              .filter(v => +v >= 0)
              .map(v => {
                const feeVault = feeVaultFromJSON(v);
                const vault = feeVaultLabel[feeVault];

                return (
                  <MenuItem
                    key={vault}
                    onClick={() => {
                      setFeeVault(feeVault);
                      onCloseFeeVaultMenu();
                    }}
                  >
                    {vault}
                  </MenuItem>
                );
              })}
          </Menu>
        </>
      }
      subHeaderSx={{ height: '100px' }}
    />
  );
};
