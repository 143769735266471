import { Button, ButtonProps, Stack, Typography, useTheme } from '@mui/material';
import { ReactElement } from 'react';

export const CompactButton = ({
  children,
  renderIcon,
  sx,
  ...props
}: ButtonProps & {
  renderIcon?: ReactElement;
}) => {
  const theme = useTheme();

  return (
    <Button
      size="small"
      {...props}
      sx={{
        p: 0,
        height: '20px',
        color: theme.colors.functional.text.primary,
        background: 'transparent',
        '&:hover': {
          color: theme.colors.functional.text.primary,
          background: 'transparent',
        },
        '&:disabled': {
          background: 'transparent',
        },
        ...sx,
      }}
    >
      <Stack direction="row" spacing="8px">
        {renderIcon}
        <Typography variant="bodySmallC">{children}</Typography>
      </Stack>
    </Button>
  );
};
