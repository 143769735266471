import { Box, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export function WizardLogin({
  handleGoogleLoginSuccess,
}: PropsWithChildren & {
  handleGoogleLoginSuccess: (credentialResponse: CredentialResponse) => void;
}) {
  const theme = useTheme();

  return (
    <Grid container>
      <Grid
        container
        item
        md={6}
        sx={{
          display: { xs: 'none', md: 'flex' },
          flex: 1,
          background: theme.colors.gradients.water,
          height: '85vh',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
          pr: 20,
        }}
      >
        <Box
          component="img"
          src="imgs/altLayerLg.svg"
          sx={{
            position: 'absolute',
            transform: 'translate(-50%,-50%)',
            left: '50%',
            top: '45%',
          }}
        />
        <Box
          sx={{
            background: `url(${process.env.PUBLIC_URL}/imgs/loginBgLeft.png)`,
            minWidth: '756px',
            flex: 1,
            backgroundSize: 'contain',
            backgroundRepeat: { xs: 'repeat', md: 'no-repeat' },
            backgroundPositionX: 'right',
            mt: 3,
            mb: 1.5,
          }}
        />
      </Grid>
      <Grid
        item
        md={6}
        sx={{
          flex: 1,
          background: theme.colors.gradients.water,
          height: '85vh',
          overflow: 'hidden',
          pl: 7,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            background: {
              xs: `url(${process.env.PUBLIC_URL}/imgs/loginBgRight.png)`,
              sm: `url(${process.env.PUBLIC_URL}/imgs/loginBgRightSm.png)`,
              xl: `url(${process.env.PUBLIC_URL}/imgs/loginBgRight.png)`,
            },
            minWidth: '1000px',
            height: { xs: 'calc(100% - 80px)', xl: '100%' },
            backgroundSize: { xs: 'contain', sm: 'contain', xl: 'contain' },
            backgroundRepeat: { xs: 'repeat', xl: 'no-repeat' },
          }}
        >
          <Stack flex={1} justifyContent="center" sx={{ pt: '25vh' }}>
            <Typography variant="h2">
              Welcome to
              <br />
              Wizard
            </Typography>
            <Box sx={{ maxWidth: '20rem', width: 'max-content', mt: 3 }}>
              <GoogleLogin
                onError={() => {
                  console.error('Login Failed');
                }}
                onSuccess={handleGoogleLoginSuccess}
              />
            </Box>
          </Stack>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '40px',
            right: '40px',
            textAlign: 'right',
            background: 'transparent',
          }}
        >
          <Link component={RouterLink} to="/disclaimer">
            TERMS OF SERVICE
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
}
