export const OPTIMISM_MD = `
## Brief Introduction
---

1.  **What Optimism versions do you support?**

    We regularly update our version support as new releases come in. At the moment, we deploy OP chains using v1.7.7.\
  For more details, the different components are:

    -   op-batcher: v1.9.4

    -   op-proposer: v1.9.4

    -   op-node: v1.9.4

    -   op-contracts: 2.0.0-beta.2

    -   The contract is deployed in the settlement chain and the rollup itself.

    -   For the settlement: \`packages/contracts-bedrock/src/L1\`

    -   For the rollup itself: \`packages/contracts-bedrock/src/L2\`
  
2.  **What data availability options do you support?**

    We support Ethereum blobs, OP Plasma, EigenDA, Celestia, Avail, and DAC, at the moment using v1.7.7.

3.  **Can I quickly try out an OP rollup somewhere?**

    We provide a public testnet that you can use to test out the Optimism rollup: [https://rollup-info.altlayer.io/public/op_testnet](https://rollup-info.altlayer.io/public/op_testnet)

## Parameter Settings
---

1.  **What parameters can we configure for our OP chain?**

    You can provide us with your preferred:

    -   Chain ID (if no preference, we'll generate a random ID)

    -   Network name

    -   Block time (if no preference, we'll default to 10s)

    -   FinalizePeriod (if not, using 7 days)

### ChainId

ChainId is the most important value for an Ethereum-like chain. So you need to check an unique chain id before we launch the rollup.

Please check you expected chain id in this repo, and create pr to this repo for your chainid

<https://github.com/ethereum-lists/chains>

<https://github.com/DefiLlama/chainlist>

<https://github.com/wevm/viem/tree/main/src/chains/definitions>


_Risk alert: this repo review pr in a very low speed, please must ensure your chain id is unique with other Ethereum-like chain._

While for the endpoint and explorer url, you can set the one under your domain, in future after we launch your rollup, we can set our service under this domain, or you can replace later in this repo.

### Gas fees

For all the information and FAQ about gas fees, have a look at [this document](https://www.notion.so/alt-research/OP-Gas-Fees-FAQ-a5ff589eb0784322b58fb20ca762ff23).

### Gas token

1.  **What is the Wrapped (ERC-20) Gas Token?**

    The WETH predeploy at \`0x4200000000000000000000000000000000000006\` represents the wrapped custom gas token. If you wish to transact with your native gas token as an ERC-20, you can deposit and withdraw from this contract to wrap and unwrap your token.

    What this means is the asset at the WETH predeploy is not ether but instead is the wrapped version of your custom gas token. It's an ERC20 token, the name() will be "Wrapped ..." (whatever the name of your token is).

2.  **Is custom gas token supported?**

    Yes. You can now set an L1 ERC-20 token address at the time of deploying the contracts of their L2 chain. When deposited, this L1 ERC-20 token will become the native gas token on the L2 and can be used to pay for gas fees.

3.  **Can I migrate the chain into being custom gas token?**

    If you are already live using ether to pay for gas, you cannot become a custom gas token chain. It would require a large migration and chain downtime and it is not a service that we provide.

    Custom gas token hack to standard gas token is possible with architectural changes. L1 and L2 architectural changes are required, messengers and bridges on both sides, OptimismPortal (L1), and SystemConfig (L1). Additional information can be found in the[  specs](https://specs.optimism.io/experimental/custom-gas-token.html).

4.  **How do I charge fees as the chain operator?**

    For the initial release of custom gas token, the chain operator will need to manage this either out of band or by manually tuning the fee parameters to ensure profitability. A future release may include [L1 Fee Abstraction](https://github.com/ethereum-optimism/specs/issues/73) where the L1 fee is calculated in a smart contract instead of native code. This would enable an on chain oracle to take into account the exchange rate of the custom gas token. This oracle could be something like Uniswap TWAP or Chainlink.

## Launch Process
---

1.  **What is included in the standard setup?**

    | Feature         | Description |
    | - | - |
    | OP Chain        | 1 sequencer node (geth / node / batcher / proposer) |
    | RPC / WS data   | 1 or more load-balanced full nodes, meanwhile as bootnode |
    | Block Explorer  | Standard Blockscout v2 application |
    | Bridge          | Scroll Bridge UI |

2.  **What is the rollup setup process like?**

    In brief, the steps are:

    1.  Deployment of rollup contracts (Bedrock) on the parent chain

    2.  Deployment of the nodes for your chain

    3.  Registration in the bridge application

3.  **Can we host our own full nodes?**

    Yes, and in fact hosting your own full nodes is recommended if you have query-intensive applications for your chain, such as an indexer. Upon request, we will provide you with the scripts for launching your own full nodes and a [guide](https://github.com/alt-research/opstack-fullnode-sync/tree/v0.3.0).

4.  **Can we use our own domain for the chain URLs?**

    Yes, you can CNAME the RPC, websocket (if any) and explorer URLs to your own domain.

    For these customizations, you will need to create CNAME records on your DNS provider. We will provide you the target for these records.

5.  **What can we expect after the chain setup is completed?**

    You will receive a rollup info page containing all the L2 details and links, similar to [this](https://rollup-info.altlayer.io/public/op_testnet).

## Product Instruction
---
### Bridge

1.  **What are our bridging options?**

    By default, your rollup chain is registered into our bridge application. Should you instead wish to maintain your own bridge, all the necessary contract addresses are provided to you.

    **Bridge features:**

    -   Withdraw fees

        By now, we're doing the withdrawal for you if asked. In the future, we'll provide a web page for the clients to withdraw fee by themselves.

    -   Deploy an ERC20 contract on L2

        To handle tokens on the Optimism network, a corresponding ERC20 contract must be deployed on Layer 2. This contract will manage token balances and transactions within the Layer 2 environment, independent of Layer 1.

        You can provide the ERC20 contract address on L1 to us, we will help you to deploy the contract on L2.

2.  **If I want to deploy my ERC20 token, what do I need to prepare?**

    You have to make sure the ERC20 token has already been created on L1. You can do it by yourself, or provide the L1 address to us, we will help you to deploy the contract on L2.

3.  **What's the bridged standard for USDC?**

    Circle introduced the [Bridged USDC Standard](https://www.circle.com/blog/bridged-usdc-standard) to ensure that chains can easily deploy a form of USDC that is capable of being upgraded in-place by Circle to Native USDC. [Here](https://www.circle.com/blog/bridged-usdc-standard) is the introduction.

    The feature is available now. If you need to deposit/withdraw USDC on your chain, please let us know and we will set up the bridged USDC feature for you.

    If you want to upgrade the bridged USDC contract to Native USDC, we need to get the approval from Circle. There is no exact threshold of supply or holders. In general, both the size and growth rate of bridged USDC supply, holders, and apps supported will contribute significantly to how Circle prioritizes native issuance for USDC.

### Block Explorer

1.  **Can we customize our block explorer UI?**

    Yes, we can customize settings such as the logos, icons, color scheme, and text content. Please request our [Blockscout order form](https://docs.google.com/forms/d/e/1FAIpQLSe66onMMxoN5EYfVyo0Kcq3GFK7YWMEFbQ1uPfEgZQTP5PNHQ/viewform) to see all the customizations available.

    Pay attention to the image size we require in the form, e.g., rectangle logo size is 120*24.

2.  **Can I verify contracts on the block explorer?**

    Yes. Please follow this [sample](https://github.com/alt-research/contract-verify-sample) for verifying a deployed contract using hardhat or foundry.

3.  **Why in the welcome page shows: "98% Blocks Indexed - We're indexing this chain right now. Some of the counts may be inaccurate"**

    This info normally shows when this rollup just launched recently. It means the blockscout need to take some time to scan the block status then save to the blockscout background database.

    Usually after some time, it will disappear after the blockscout sync all data.

### Faucet

1.  **What are our faucet options?**

    You can choose to use [our partners' faucet solutions](https://atanetwork.notion.site/Automata-Testnet-Faucet-Service-850d288f6556425d8bc8ed0366acb470), with three options available.

## Tech Details
---

1.  **How can I verify the deployed ERC20 contract?**

    Here is a quick guide to verify your ERC20 contract:

    1.  \`git clone <https://github.com/ethereum-optimism/optimism.git>\` to your computer.

    2.  In testnet, checkout to commit \`65e013162622897561f58682b08d0df84ef0a52e\`. In mainnet, checkout to tag \`1.4.0-rc.1\`.

    3.  \`cd packages/contracts-bedrock\`.

    4.  Run the command \`forge build\`. In order to run the command, [Foundry](https://book.getfoundry.sh/getting-started/installation) must be installed in your computer.

    5.  Run the command

        \`\`\`
        forge verify-contract <Your Contract Name> src/universal/OptimismMintableERC20.sol:OptimismMintableERC20 --verifier blockscout --verifier-url <Your Blockscout URL>/api --chain-id <Your ChainID> --watch
        \`\`\`

2.  **Why does my withdrawal transaction meet the error like the pic below, but I still receive my token successfully?**

    ![](https://lh7-rt.googleusercontent.com/docsz/AD_4nXf0vm7Izy8RlXTK5ehHWS0EGMPS0Mqf9GBflMKMSGNCbZY08EvK-jgBb_TGi9f-2xhhIvDeXYIObeKnMF6WRdtdfJESkvILQ8pDDGe1e_wtkYJ_iqd2vdkjDw7_022zaQPQ21wpPLzMMgiByLrvCAc5a2_V?key=sBLsGTpWxuk0M9Pm2ePidw)

    Take [this transaction](https://dashboard.tenderly.co/tx/mainnet/0x274ca413ec2fe729a082b70a65732b3a0fb49e47ce80cedef886261ff14a3192?trace=0.1.16.1.4.10.0.0.3.5.1.0.0.0.0) as an example, you can see that the revert happened in this line:

    ![](https://lh7-rt.googleusercontent.com/docsz/AD_4nXfEQA_TrHzJbeWaYD2SUXv1jnYunoIx1ahGm6uGKG5BMeP32QmCvaC5rcJkACNIHYjbMOQMR729KyLlrU8hSjVhdsUp9fN0rV_16a_Ajc4jVfi5qQhISTQFFqSzV0GiPb9PMzsKn6eetWceOguEdHRyFC41?key=sBLsGTpWxuk0M9Pm2ePidw)

    And the error reporting function was called by L1StandardBridge.supportsERC165. Let's jump to \`optimism/packages/contracts-bedrock/src/universal/StandardBridge.sol\` to see the related code, you can see the function was used by \`_isOptimismMintableERC20()\`. Similar calling logic has appeared in the code several times. The StandardBridge has several layers of SafeCall which may revert. However, it won't affect the withdrawal to be finalized.

    ![](https://lh7-rt.googleusercontent.com/docsz/AD_4nXdD0u58EszdSV7f4InLbWgHAk5voRIjjrp9itpe72qt6071Wyno7j0N6_CFwmEl1d1v71K2EUo5ZykpJi5rwEXB-RxpsW3tC3HgjqRWRjVIDRo-zEOX2rzggGSyzpQgh9u2ReQ62kQxcJG8l7537xFZiOAC?key=sBLsGTpWxuk0M9Pm2ePidw)

3.  **How can I get the unique address count of bridging?**

    You can watch the L1 to L2 crosschain event of <https://github.com/ethereum-optimism/optimism/blob/op-contracts/v1.4.0-rc.1/packages/contracts-bedrock/src/L1/OptimismPortal.sol#L373> in your own code.

4.  **If I want to generate snapshots to let newly added nodes sync data, what can I do?**

    There's also an [official guide](https://docs.optimism.io/builders/node-operators/management/snapshots) to  manage snapshots.

    However, you can simply copy the disk as a faster solution.

### Custom domain

1.  **If I want to use a custom domain but my domain is an APEX domain, what should I do?**

    Since we cannot use CNAME to point to the target, we can try Alias or DDNS.

    **a. Alia**

      Alias is a special type of A-type DNS record, not all DNS providers support it.

      -   In **Cloudflare**, it's called [CNAME Flattening](https://developers.cloudflare.com/dns/cname-flattening/)

      -   In **Namecheap**, it's also called [ANAME](https://www.namecheap.com/support/knowledgebase/article.aspx/10128/2237/how-to-create-an-alias-record/)

      -   In **Route53**, you can set a Route53 Alias A record on the APEX domain like the pic below. You can ask us for the correct load balancer url.

      ![](https://lh7-rt.googleusercontent.com/docsz/AD_4nXcAou3WDTWN64NC6chjwWn_iPDOq91qrP8OfHNfcanl1c3FnxBe2B6IfKrt_mf7Gwe0TTHtdVgCSqd7U9Cg8Fun54XR37gQuSgJLSWiFv_XKFk0CNTo0rVY91aUll8NvP47N1uDDIZXlCj-Y-_PDhbMoEA?key=sBLsGTpWxuk0M9Pm2ePidw)

      For other DNS providers, we need to check if they support Alias.

    **b. DDNS (Dynamic DNS) Service**

      DDNS is not a record type, it is a service that can update the DNS record dynamically.

      We will provide a script to you, you need to save this as \`update-route53-record.sh\`, and add it as a cron job to update the Route53 record.

      \`\`\`bash
      # Add the cron job to update the Route53 record every 5 minutes */5 * * * * /path/to/update-route53-record.sh <hosted_zone_id> <route53_record_name> <domain>
      \`\`\`
`;
