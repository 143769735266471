import { Box, Skeleton, Typography } from '@mui/material';
import { hideFeature } from 'helpers/visibility';
import { useBlockscoutStats, useRaasDeployment } from 'hooks';
import { SectionButtonTimeFilter } from 'pages/raas/DeploymentDetails/components/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { AverageFeeTipsTrendMetric } from 'pages/raas/DeploymentDetails/views/FeeTrackerView/AverageFeeTipsTrendMetric';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { AverageGasPriceMetric } from './AverageGasPriceMetric';
import { BaseFeeBalanceMetric } from './BaseFeeBalanceMetric';
import { GasUsedGrowthMetric } from './GasUsedGrowthMetric';
import { L1FeeBalanceMetric } from './L1FeeBalanceMetric';
import { OverviewSection } from './OverviewSection';
import { SequencerFeeBalanceMetric } from './SequencerFeeBalanceMetric';

export const FeeTrackerView = () => {
  const { isPending: getRollupLoading } = useRaasDeployment();
  const { data: stats, isPending } = useBlockscoutStats();

  const metrics = [
    { id: 1, component: <AverageGasPriceMetric />, show: true },
    { id: 2, component: <GasUsedGrowthMetric />, show: true },
    { id: 3, component: <AverageFeeTipsTrendMetric />, show: !hideFeature },
    { id: 4, component: <L1FeeBalanceMetric />, show: false },
    { id: 5, component: <BaseFeeBalanceMetric />, show: false },
    { id: 6, component: <SequencerFeeBalanceMetric />, show: true },
  ];

  return (
    <>
      <OverviewSection />
      <Section
        renderGridContent={metrics
          .filter(metric => metric.show)
          .map(metric => (
            <MetricGridItem item key={metric.id}>
              {metric.component}
            </MetricGridItem>
          ))}
        renderSubHeaderEndContent={
          <SectionButtonTimeFilter
            loading={getRollupLoading}
            variant={TimeFilterVariant.FEE_TRACKER_GAS_SECTION}
          />
        }
        renderTitleSuffix={
          isPending ? (
            <Skeleton
              height="30px"
              sx={{ ml: '16px', px: '6px', py: '1px' }}
              variant="text"
              width="50px"
            />
          ) : (
            stats?.gas_prices?.average && (
              <Box sx={{ ml: '16px', background: '#fff', px: '6px', py: '1px' }}>
                <Typography
                  sx={{ color: theme => theme.colors.functional.text.primary }}
                  variant="caption"
                >
                  {`${stats?.gas_prices?.average} Gwei`}
                </Typography>
              </Box>
            )
          )
        }
        sectionContentSx={{ p: '0px' }}
        title="Gas"
      />
    </>
  );
};
