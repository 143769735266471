import {
  Drawer,
  DrawerProps,
  IconButton,
  Skeleton,
  Stack,
  StackOwnProps,
  Typography,
} from '@mui/material';
import { IconClose } from 'components/icons/IconClose';
import { RaasMetricsData, RaasMetricsResult } from 'hooks';
import { ResponsiveContainer, XAxisProps, YAxisProps } from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';
import { AreaChart, Formatter, LineChart } from 'shared/components/charts';

export interface IMetricExpandDrawer<T extends RaasMetricsResult<RaasMetricsData>>
  extends DrawerProps {
  chartId?: string;
  curveType?: CurveType;
  loading?: boolean;
  data?: T;
  title?: string;
  description?: string;
  tooltipKeyFormatter?: Formatter;
  tooltipValueFormatter?: Formatter;
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
  chartType?: 'area' | 'line';
  legendDirection?: StackOwnProps['direction'];
  legendSpacing?: StackOwnProps['spacing'];
  hideTooltipKeys?: boolean;
  hideTooltipKeysSvg?: boolean;
  hideLegend?: boolean;
  isAnimationActive?: boolean;
}

export const MetricExpandDrawer = <T extends RaasMetricsResult<RaasMetricsData>>({
  chartId,
  chartType,
  curveType,
  data: result,
  description,
  hideLegend,
  hideTooltipKeys,
  hideTooltipKeysSvg,
  isAnimationActive,
  legendDirection,
  legendSpacing,
  loading: isPending,
  onClose,
  title,
  tooltipKeyFormatter,
  tooltipValueFormatter,
  xAxisProps,
  yAxisProps,
  ...props
}: IMetricExpandDrawer<T>) => {
  return (
    <Drawer anchor="bottom" onClose={onClose} {...props}>
      <Stack
        alignItems="start"
        height={'100%'}
        justifyContent="space-between"
        minHeight="75vh"
        p="40px"
        spacing="24px"
        width={'100%'}
      >
        <Stack
          alignItems="start"
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Stack spacing="8px">
            <Typography variant="h5">{title}</Typography>
            {description && (
              <Typography color="rgba(32, 39, 35, 0.5)" variant="h6">
                {description}
              </Typography>
            )}
          </Stack>
          <IconButton onClick={e => onClose?.(e, 'backdropClick')} size="small">
            <IconClose />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            px: '12px',
          }}
        >
          <ResponsiveContainer aspect={3} minHeight={'100%'} width={'100%'}>
            {isPending ? (
              <Skeleton height="100%" sx={{ width: '50%' }} variant="rectangular" />
            ) : chartType === 'line' ? (
              <LineChart
                curveType={curveType}
                data={result?.data}
                dataKeys={result?.dataKeys}
                hideLegend={hideLegend ?? result?.dataKeys.length === 1}
                hideTooltipKeys={hideTooltipKeys ?? result?.dataKeys.length === 1}
                hideTooltipKeysSvg={hideTooltipKeysSvg ?? result?.dataKeys.length === 1}
                id={chartId}
                isAnimationActive={isAnimationActive}
                legendDirection={legendDirection}
                legendSpacing={legendSpacing}
                tooltipValueFormatter={tooltipValueFormatter}
                xAxisProps={xAxisProps}
                yAxisProps={yAxisProps}
              />
            ) : (
              <AreaChart
                curveType={curveType}
                data={result?.data}
                dataKeys={result?.dataKeys}
                hideLegend={hideLegend ?? result?.dataKeys.length === 1}
                hideTooltipKeys={hideTooltipKeys ?? result?.dataKeys.length === 1}
                id={chartId}
                isAnimationActive={isAnimationActive}
                tooltipKeyFormatter={tooltipKeyFormatter}
                tooltipValueFormatter={tooltipValueFormatter}
                xAxisProps={xAxisProps}
                yAxisProps={yAxisProps}
              />
            )}
          </ResponsiveContainer>
        </Stack>
      </Stack>
    </Drawer>
  );
};
