import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';
import Web3LoadingButton from 'components/Web3/Web3LoadingButton';
import ProxyAdminAbi from 'contracts/ProxyAdminAbi';
import { useAvsRoles } from 'hooks/useAvsRoles';
import { useWriteTx } from 'hooks/useWriteTx';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import CopyableText from 'shared/components/Copyable/CopyableText';
import { FormTextField } from 'shared/components/form';
import { AVSDeployment } from 'types/protoc-gen/avs';
import { Address } from 'viem';
import { useReadContract } from 'wagmi';
import { object, string } from 'yup';

export default function UpgradeSvcMgrDialog({
  avs,
  onClose,
  open,
  ...rest
}: DialogProps & { avs: AVSDeployment }) {
  const { svcMngrOwner } = useAvsRoles(avs);
  const form = useForm<{ newAddress: string }>({
    mode: 'onTouched',
    resolver: yupResolver(object().shape({ newAddress: string().address().required() })),
  });
  const newImplAddrInput = form.watch('newAddress');
  const { data: implementationAddress, refetch: refetchSvcMgrImplAddr } = useReadContract({
    address: avs?.proxyAddress as Address,
    abi: ProxyAdminAbi,
    functionName: 'getProxyImplementation',
    args: [avs?.serviceManagerProxy as Address],
    query: {
      enabled: Boolean(avs?.proxyAddress && avs?.serviceManagerProxy),
    },
  });

  const { isPending, write: updateSvcMgr } = useWriteTx({
    contractAddress: avs?.proxyAddress as Address,
    functionArgs: [avs?.serviceManagerProxy, newImplAddrInput],
    contractAbi: ProxyAdminAbi,
    functionName: 'upgrade',
    txKey: `update_${avs?.serviceManagerProxy}`,
    onTxConfirmed: () => {
      refetchSvcMgrImplAddr();
      onClose?.({}, 'escapeKeyDown');
    },
  });

  const onSubmit: SubmitHandler<{ newAddress: string }> = data => {
    console.log(data);
    updateSvcMgr();
  };

  const onError: SubmitErrorHandler<{ newAddress: string }> = formErrors => {
    console.error(formErrors);
  };

  return (
    <Dialog
      disablePortal
      onClose={onClose}
      open={open}
      sx={{ '& .MuiPaper-root': { p: 3, background: '#FFFFFF' } }}
      {...rest}
    >
      <DialogTitle>
        <Typography fontWeight={500} variant="h5">
          Upgrade Service Manager
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            {/* <Typography>Current Proxy:</Typography>
            <CopyableText
              href={`https://holesky.etherscan.io/address/${avs?.serviceManagerProxy}`}
              linkProps={{ sx: { '&:hover': { textDecoration: 'underline' } } }}
              placement="right"
              showTooltip={false}
              text={avs?.serviceManagerProxy || ''}
            >
              <Typography>{avs?.serviceManagerProxy}</Typography>
            </CopyableText> */}
            <Typography mt={1}>Current Implementation Contract:</Typography>
            <CopyableText
              href={`https://holesky.etherscan.io/address/${implementationAddress}`}
              linkProps={{ sx: { '&:hover': { textDecoration: 'underline' } } }}
              placement="right"
              showTooltip={false}
              text={implementationAddress || ''}
            >
              <Typography>{implementationAddress}</Typography>
            </CopyableText>
            <FormTextField
              fieldConfig={{
                name: 'newAddress',
                placeholder: 'Contract address',
                label: 'Enter your new implementation contract address',
              }}
              optionProps={{ sx: { '&&': { pl: 0 } } }}
            />
            <Web3LoadingButton
              fullWidth
              loading={isPending}
              requiredSender={svcMngrOwner}
              sx={{ mt: 4 }}
              type="submit"
              variant="contained"
            >
              Upgrade
            </Web3LoadingButton>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
