import { Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Bar, BarChart, LabelList, Legend, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { MetricContainer } from 'shared/components/metric';

enum DataKey {
  STEP_1 = 'Step1: Waiting for state root',
  STEP_2 = 'Step2: Ready to prove',
  STEP_3 = 'Step3: In challenge period',
  STEP_4 = 'Step4: Ready for relay',
  STEP_5 = 'Step5: Relayed',
}

export const WithdrawalStatusMetric = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined);
  const theme = useTheme();
  const _linearGradientPrefix = useMemo(() => 'withdrawal-status', []);
  const _linearGradients = useMemo<
    {
      id: string;
      stops: React.SVGAttributes<SVGStopElement>[];
    }[]
  >(
    () => [
      {
        id: [_linearGradientPrefix, 'metal'].join('-'),
        stops: [
          { offset: '5%', stopColor: '#F6F6F6', stopOpacity: 0.9 },
          { offset: '95%', stopColor: '#E7E7E7', stopOpacity: 1 },
        ],
      },
      {
        id: [_linearGradientPrefix, 'water'].join('-'),
        stops: [
          { offset: '5%', stopColor: '#93B4F3', stopOpacity: 0.9 },
          { offset: '95%', stopColor: '#5C8CE1', stopOpacity: 1 },
        ],
      },
      {
        id: [_linearGradientPrefix, 'cream'].join('-'),
        stops: [
          { offset: '5%', stopColor: '#FFF5D5', stopOpacity: 0.9 },
          { offset: '95%', stopColor: '#EFEADB', stopOpacity: 1 },
        ],
      },
      {
        id: [_linearGradientPrefix, 'pistachio'].join('-'),
        stops: [
          { offset: '5%', stopColor: '#EAF4DC', stopOpacity: 0.9 },
          { offset: '95%', stopColor: '#D5E9BB', stopOpacity: 1 },
        ],
      },
      {
        id: [_linearGradientPrefix, 'leaf'].join('-'),
        stops: [
          { offset: '5%', stopColor: '#74BA94', stopOpacity: 0.9 },
          { offset: '95%', stopColor: '#97C8AD', stopOpacity: 1 },
        ],
      },
    ],
    [_linearGradientPrefix],
  );
  const [metal, water, cream, pistachio, leaf] = useMemo(
    () => [
      [`url('#`, [_linearGradientPrefix, 'metal'].join('-'), `')`].join(''),
      [`url('#`, [_linearGradientPrefix, 'water'].join('-'), `')`].join(''),
      [`url('#`, [_linearGradientPrefix, 'cream'].join('-'), `')`].join(''),
      [`url('#`, [_linearGradientPrefix, 'pistachio'].join('-'), `')`].join(''),
      [`url('#`, [_linearGradientPrefix, 'leaf'].join('-'), `')`].join(''),
    ],
    [_linearGradientPrefix],
  );
  const _dataKeys = useMemo(
    () => [
      {
        name: DataKey.STEP_1,
        fill: metal,
      },
      {
        name: DataKey.STEP_2,
        fill: water,
      },
      {
        name: DataKey.STEP_3,
        fill: cream,
      },
      {
        name: DataKey.STEP_4,
        fill: pistachio,
      },
      {
        name: DataKey.STEP_5,
        fill: leaf,
      },
    ],
    [metal, water, cream, pistachio, leaf],
  );
  const _data = useMemo<
    {
      name: string;
      av?: number;
      bv?: number;
      cv?: number;
      dv?: number;
      ev?: number;
    }[]
  >(
    () => [
      {
        name: 'Access from',
        [DataKey.STEP_1]: 450,
        [DataKey.STEP_2]: 400,
        [DataKey.STEP_3]: 350,
        [DataKey.STEP_4]: 375,
        [DataKey.STEP_5]: 150,
      },
    ],
    [],
  );

  return (
    <MetricContainer responsiveContainerSx={{ minHeight: '140px' }} title="Withdrawal status">
      <BarChart
        data={_data}
        layout="vertical"
        margin={{ top: 40, left: -30, right: 24 }}
        // stackOffset="expand"
      >
        <defs>
          {_linearGradients.map(linearGradient => (
            <linearGradient
              id={linearGradient.id}
              key={linearGradient.id}
              x1="0"
              x2="1"
              y1="0"
              y2="0"
            >
              {linearGradient.stops.map(({ offset, stopColor, stopOpacity }) => (
                <stop
                  key={stopColor}
                  offset={offset}
                  stopColor={stopColor}
                  stopOpacity={stopOpacity}
                />
              ))}
            </linearGradient>
          ))}
        </defs>

        <XAxis hide type="number" />
        <YAxis dataKey="name" fontSize="12" stroke="#FFFFFF" type="category" />

        <Tooltip
          content={<CustomTooltip hoveredIndex={hoveredIndex} />}
          formatter={value => {
            if (typeof value === 'number') {
              return new Intl.NumberFormat().format(value);
            }

            return value;
          }}
          itemStyle={{
            minWidth: '172px',
            color: theme.colors.functional.text.primary,
            justifyContent: 'space-between',
          }}
          position={{ y: -25 }}
        />

        <Legend
          color={theme.colors.functional.text.primary}
          content={({ payload }) => (
            <Stack direction="row" spacing="16px" sx={{ mt: '16px', ml: '40px', pl: '12px' }}>
              {payload?.map((v, index) => (
                <Stack
                  alignItems="center"
                  direction="row"
                  key={v.dataKey?.toString() ?? index.toString()}
                  spacing="4px"
                >
                  <SvgIcon sx={{ height: '8px', width: '8px' }} viewBox="0 0 8 8">
                    <rect fill={v?.color} height="8" width="8" />
                  </SvgIcon>
                  <Typography variant="caption">{v.dataKey?.toString()}</Typography>
                </Stack>
              ))}
            </Stack>
          )}
          formatter={value => {
            if (typeof value === 'number') {
              return new Intl.NumberFormat().format(value);
            }

            return value;
          }}
          iconSize={8}
          iconType="square"
          margin={{ left: 40, top: 20, bottom: 0 }}
          verticalAlign="bottom"
        />

        {_dataKeys.map((v, index) => (
          <Bar
            cursor="pointer"
            dataKey={v.name}
            fill={v.fill}
            key={[v.fill, v.name].join('-')}
            onMouseOver={() => setHoveredIndex(index)}
            scale={hoveredIndex === index ? '1.1' : undefined}
            stackId="a"
            stroke={v.fill}
          >
            <LabelList dataKey={v.name} fill="transparent" position="center" stroke="transparent" />
          </Bar>
        ))}
      </BarChart>
    </MetricContainer>
  );
};

export const CustomTooltip = ({
  hoveredIndex,
  payload,
  ...props
}: TooltipProps<any, any> & { hoveredIndex: number | undefined }) => {
  const getValuePercentage = useCallback(
    (value: number) => {
      const values = payload?.map(v => v.value);
      const sum = values?.reduce((acc, v) => acc + v, 0);

      if (!sum) return 0;

      return Number((value / sum) * 100).toFixed();
    },
    [payload],
  );

  return (
    <Stack
      spacing="4px"
      sx={_ => ({
        boxShadow: '0px 2px 4px 0px rgba(32, 39, 35, 0.16)',
        background: '#fff',
        px: '9px',
        py: '13px',
      })}
    >
      <Typography variant="caption">{props.label}</Typography>

      {payload?.map(
        (v, index) =>
          hoveredIndex === index && (
            <Stack key={v.name}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing="16px"
              >
                <Stack alignItems="center" direction="row" spacing="4px">
                  <SvgIcon sx={{ height: '8px', width: '8px' }} viewBox="0 0 8 8">
                    <rect fill={v.stroke} height="8" width="8" />
                  </SvgIcon>

                  <Typography variant="caption">{v.name}</Typography>
                </Stack>
                <Typography variant="captionM">
                  {new Intl.NumberFormat().format(+String(v.value ?? '0'))};{' '}
                  {[getValuePercentage(v.value), '%'].join('')}
                </Typography>
              </Stack>
            </Stack>
          ),
      )}
    </Stack>
  );
};
