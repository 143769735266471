import { hideFeature } from 'helpers/visibility';

import { DepositsSection } from './DepositsSection';
import { GeneralSection } from './GeneralSection';
import { WithdrawalsSection } from './WithdrawalsSection';

export const BridgeMetricsView: React.FC = () => {
  return (
    <>
      {!hideFeature && <GeneralSection />}
      <DepositsSection />
      <WithdrawalsSection />
    </>
  );
};
