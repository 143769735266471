import { APP } from 'types/protoc-gen/bffaaa';

export type AgentTemplate = APP;

export enum DeploymentType {
  TEE = 'Verifiable(TEE)',
  NON_TEE = 'Non-Verifiable',
}

export interface FormValues {
  agentPrefixName?: string;
  deploymentType?: DeploymentType;
  template?: string;
  imageId?: string;
  config?: string;
}

export interface EnvVarsFormValues {
  [x: string]: string;
}
