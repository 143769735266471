import { AreaChart } from 'shared/components/charts';
import { MetricContainer } from 'shared/components/metric/MetricContainer';

export const AverageFeeTipsTrendMetric = () => {
  return (
    <MetricContainer
      description="Average fee tips trend for the period (Gwei)"
      title="Average fee tips trend"
    >
      <AreaChart />
    </MetricContainer>
  );
};
