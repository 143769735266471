import { ArrowOutward } from '@mui/icons-material';
import { Box, Button, Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { IconOverview } from 'components/icons/IconOverview';
import { hideFeature } from 'helpers/visibility';
import { useRaasDeployment } from 'hooks';
import { RollupType } from 'labels/raas';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATHS } from 'routes';

import { SubHeader } from '../../../shared/components/SubHeader';
import { getSideNavigationItems } from './navigation';

export interface ISideNavigation extends Omit<StackProps, 'children'> {}

export const SideNavigation = ({ ...props }: ISideNavigation) => {
  const { data: rollup, isPending } = useRaasDeployment();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const path = useMemo(() => `${PATHS.RAAS_DEPLOYMENTS}/${params?.rollupId}`, [params?.rollupId]);
  const sideNavigationItems = useMemo(
    () => getSideNavigationItems(path, rollup?.type as RollupType),
    [path, rollup?.type],
  );

  return (
    <Stack flexDirection="column" width="504px" {...props}>
      <Stack py="12px">
        <Button
          onClick={() => navigate(`${PATHS.RAAS_DEPLOYMENTS}/${params?.rollupId}`)}
          size={'small'}
          sx={theme => ({
            color: theme.colors.functional.text.primary,
            justifyContent: 'space-between',
            alignItems: 'center',
            direction: 'row',
            cursor: 'pointer',
            px: '40px',
            py: '12px',
            background:
              path === location.pathname ? theme.colors.schema.leafPrimary : 'transparent',
            '&:hover': {
              background: path === location.pathname ? theme.colors.schema.leafPrimary : '#f7f7f7',
            },
          })}
          variant="contained"
        >
          <Stack alignItems="center" direction="row" justifyContent="start" spacing="8px">
            <IconOverview />

            <Typography>Overview</Typography>
          </Stack>
        </Button>
      </Stack>

      {isPending
        ? Array.from({ length: 4 }).map((_, idx) => (
            <Box key={idx.toString()}>
              <SubHeader
                sx={theme => ({
                  background: theme.colors.gradients.metal,
                  borderBottom: '1px solid #20272314',
                })}
              >
                <Skeleton variant="text" width="65%" />
              </SubHeader>
              <Stack flex="1" py="12px">
                {Array.from({ length: 4 }).map((_, itemsIdx) => (
                  <Skeleton
                    key={[idx, itemsIdx].join('-')}
                    sx={{
                      mx: '40px',
                      my: '12px',
                    }}
                  />
                ))}
              </Stack>
            </Box>
          ))
        : sideNavigationItems.map((sideNavigationItem, index) => (
            <Box
              id={sideNavigationItem.id}
              key={sideNavigationItem.label}
              sx={{
                mb: sideNavigationItems.length - 1 === index ? '100px' : 0,
              }}
            >
              <SubHeader
                id={sideNavigationItem.id}
                sx={theme => ({
                  background: theme.colors.gradients.metal,
                  borderBottom: '1px solid #20272314',
                })}
              >
                <Typography variant="bodySmallC">{sideNavigationItem.label}</Typography>
              </SubHeader>
              <Stack flex="1" py="12px">
                {sideNavigationItem.items
                  .filter(item => (hideFeature ? !item.hidden : true))
                  .map(item => (
                    <Button
                      key={item.label}
                      onClick={() =>
                        item.href ? window.open(item.href, '_blank') : navigate(item.to)
                      }
                      size={'small'}
                      sx={theme => ({
                        color: theme.colors.functional.text.primary,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        direction: 'row',
                        cursor: 'pointer',
                        px: '40px',
                        py: '12px',
                        background:
                          item.to === location.pathname
                            ? theme.colors.schema.leafPrimary
                            : 'transparent',
                        '&:hover': {
                          background:
                            item.to === location.pathname
                              ? theme.colors.schema.leafPrimary
                              : '#f7f7f7',
                        },
                      })}
                      variant="contained"
                    >
                      <Typography variant="body1">{item.label}</Typography>

                      {item.href && <ArrowOutward sx={{ height: '18px', width: '18px' }} />}
                    </Button>
                  ))}
              </Stack>
            </Box>
          ))}
    </Stack>
  );
};
