import dayjs from "dayjs";

export const getTimeFilterOpts = () => {
  const [now, template] = [dayjs(), 'YYYY-MM-DD HH:mm:ss'];
  const [start, end] = [now.subtract(1, 'hour').format(template), now.format(template)];
  const step = '5m';

  return {
    start,
    end,
    step,
    template,
  };
};