// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.1
// source: raas-rollup.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Duration } from "./google/protobuf/duration";
import { Struct } from "./google/protobuf/struct";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "rollup.v1";

export enum KeyType {
  KEYTYPE_UNSPECIFIED = 0,
  KEYYPTE_ORBIT = 1,
  KEYTYPE_OPTSTACK = 2,
  UNRECOGNIZED = -1,
}

export function keyTypeFromJSON(object: any): KeyType {
  switch (object) {
    case 0:
    case "KEYTYPE_UNSPECIFIED":
      return KeyType.KEYTYPE_UNSPECIFIED;
    case 1:
    case "KEYYPTE_ORBIT":
      return KeyType.KEYYPTE_ORBIT;
    case 2:
    case "KEYTYPE_OPTSTACK":
      return KeyType.KEYTYPE_OPTSTACK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return KeyType.UNRECOGNIZED;
  }
}

export function keyTypeToJSON(object: KeyType): string {
  switch (object) {
    case KeyType.KEYTYPE_UNSPECIFIED:
      return "KEYTYPE_UNSPECIFIED";
    case KeyType.KEYYPTE_ORBIT:
      return "KEYYPTE_ORBIT";
    case KeyType.KEYTYPE_OPTSTACK:
      return "KEYTYPE_OPTSTACK";
    case KeyType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FeeVault {
  L1_FEE_VAULT = 0,
  SEQUENCER_FEE_VAULT = 1,
  BASE_FEE_VAULT = 2,
  UNRECOGNIZED = -1,
}

export function feeVaultFromJSON(object: any): FeeVault {
  switch (object) {
    case 0:
    case "L1_FEE_VAULT":
      return FeeVault.L1_FEE_VAULT;
    case 1:
    case "SEQUENCER_FEE_VAULT":
      return FeeVault.SEQUENCER_FEE_VAULT;
    case 2:
    case "BASE_FEE_VAULT":
      return FeeVault.BASE_FEE_VAULT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FeeVault.UNRECOGNIZED;
  }
}

export function feeVaultToJSON(object: FeeVault): string {
  switch (object) {
    case FeeVault.L1_FEE_VAULT:
      return "L1_FEE_VAULT";
    case FeeVault.SEQUENCER_FEE_VAULT:
      return "SEQUENCER_FEE_VAULT";
    case FeeVault.BASE_FEE_VAULT:
      return "BASE_FEE_VAULT";
    case FeeVault.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum WithdrawalNetwork {
  L1 = 0,
  L2 = 1,
  UNRECOGNIZED = -1,
}

export function withdrawalNetworkFromJSON(object: any): WithdrawalNetwork {
  switch (object) {
    case 0:
    case "L1":
      return WithdrawalNetwork.L1;
    case 1:
    case "L2":
      return WithdrawalNetwork.L2;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WithdrawalNetwork.UNRECOGNIZED;
  }
}

export function withdrawalNetworkToJSON(object: WithdrawalNetwork): string {
  switch (object) {
    case WithdrawalNetwork.L1:
      return "L1";
    case WithdrawalNetwork.L2:
      return "L2";
    case WithdrawalNetwork.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Rollup {
  name: string;
  type: string;
  settlementLayer: string;
  dataAvailabilityLayer: string;
  status: string;
  chainId: number;
  rpcEndpoint: string;
  rpcWsEndpoint: string;
  bridge: string;
  explorer: string;
  l1Blockscout: string;
  l2Blockscout: string;
  metadata: { [key: string]: any } | undefined;
  contractAddresses: { [key: string]: any } | undefined;
  version: { [key: string]: any } | undefined;
  dataAvailabilityMetadata: { [key: string]: any } | undefined;
  id: string;
  tokenMetadata: { [key: string]: any } | undefined;
  environment: string;
  accounts: { [key: string]: any } | undefined;
  startupAt: Date | undefined;
  visibilityType: string;
  parentChainId: number;
}

export interface ListRollupRequest {
}

export interface ListRollupReply {
  rollups: Rollup[];
}

export interface CreateOpstackRequest {
}

export interface CreateOpstackReply {
}

export interface CreateOrbitRequest {
  deployerKey: string;
  l1conn: string;
  parentChainId: number;
  chainName: string;
  chainId: number;
  stakerKey: string;
  batchPosterKey: string;
  enableAnytrust: boolean;
  keysetBytes: string;
  daserverPrivateKey: string;
  daserverPublicKey: string;
  promoCode: string;
}

export interface CreateOrbitReply {
  transactionHash: string;
  rpc: string;
  ws: string;
  blockscout: string;
}

export interface GenerateKeyRequest {
  type: KeyType;
}

export interface GenerateKeyReply {
  keys: { [key: string]: any } | undefined;
}

export interface ValidatePromoCodeRequest {
  code: string;
}

export interface ValidatePromoCodeReply {
  valid: boolean;
}

export interface RegisterWithdrawalFeeRollupRequest {
  opstackId: string;
}

export interface RegisterWithdrawalFeeRollupReply {
}

export interface TriggerWithdrawFeeRequest {
  opstackId: string;
  vault: FeeVault;
}

export interface TriggerWithdrawFeeReply {
  txHash: string;
}

export interface WithdrawFeeStatusRequest {
  opstackId: string;
  vault: FeeVault;
}

export interface WithdrawFeeStatusReply {
  receipt: string;
  vaultBalance: string;
  minWithdrawalAmount: string;
  withdrawalNetwork: WithdrawalNetwork;
}

export interface WithdrawHistoryRequest {
  opstackId: string;
  vault: FeeVault;
}

export interface WithdrawHistoryReply {
  withdrawLogs: WithdrawLog[];
}

export interface WithdrawLog {
  actionName: string;
  /** the txn hash on L1 */
  txHash: string;
  timestamp:
    | Date
    | undefined;
  /** the amount of fee to be withdrawn */
  amount: string;
  /** the txn fee for this L1 txn */
  txFee: string;
  /** the txn hash of initiate withdrawal from L2, if action is "Initiated" */
  l2TxHash?: string | undefined;
}

export interface WithdrawFeeRollupRegisteredRequest {
  opstackId: string;
}

export interface WithdrawFeeRollupRegisteredReply {
  registered: boolean;
}

export interface ListPromoCodesRequest {
}

export interface ListPromoCodesReply {
  codes: PromoCode[];
}

export interface PromoCode {
  code: string;
  createdAt: Date | undefined;
  expireAt: Date | undefined;
  labels: Label[];
}

export interface Label {
  key: string;
  value: string;
}

export interface GeneratePromoCodesRequest {
  count: number;
  labels: Label[];
}

export interface GeneratePromoCodesReply {
  codes: PromoCode[];
}

export interface Account {
  id: number;
  rollupId: string;
  address: string;
  name: string;
  network: string;
  balance: string;
  threshold: string;
  personInCharge: string;
  createTime: Date | undefined;
  updateTime: Date | undefined;
  notifications: string[];
  lastNotificationTime: Date | undefined;
  notificationInterval: Duration | undefined;
  lastFundNotificationTime: Date | undefined;
  mainNet: boolean;
}

export interface CreateAccountRequest {
  rollupId: string;
  opstackBatcherThreshold?: string | undefined;
  opstackProposerThreshold?: string | undefined;
  orbitBatchPosterThreshold?: string | undefined;
  personInCharge?:
    | string
    | undefined;
  /** slack://, tgram://... */
  notifications: string[];
  notificationInterval: Duration | undefined;
}

export interface CreateAccountReply {
  accounts: Account[];
}

/** rollup_id is the opstack_id or orbit_id */
export interface ListAccountsRequest {
  rollupId: string;
}

export interface ListAccountsReply {
  accounts: Account[];
}

export interface UpdateAccountRequest {
  rollupId: string;
  accountId: number;
  notifications: string[];
  threshold?: string | undefined;
  personInCharge?: string | undefined;
  notificationInterval?: Duration | undefined;
}

export interface UpdateAccountReply {
  account: Account | undefined;
}

export interface DeleteAccountRequest {
  rollupId: string;
}

export interface DeleteAccountReply {
}

function createBaseRollup(): Rollup {
  return {
    name: "",
    type: "",
    settlementLayer: "",
    dataAvailabilityLayer: "",
    status: "",
    chainId: 0,
    rpcEndpoint: "",
    rpcWsEndpoint: "",
    bridge: "",
    explorer: "",
    l1Blockscout: "",
    l2Blockscout: "",
    metadata: undefined,
    contractAddresses: undefined,
    version: undefined,
    dataAvailabilityMetadata: undefined,
    id: "",
    tokenMetadata: undefined,
    environment: "",
    accounts: undefined,
    startupAt: undefined,
    visibilityType: "",
    parentChainId: 0,
  };
}

export const Rollup = {
  encode(message: Rollup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    if (message.settlementLayer !== "") {
      writer.uint32(26).string(message.settlementLayer);
    }
    if (message.dataAvailabilityLayer !== "") {
      writer.uint32(34).string(message.dataAvailabilityLayer);
    }
    if (message.status !== "") {
      writer.uint32(42).string(message.status);
    }
    if (message.chainId !== 0) {
      writer.uint32(48).uint32(message.chainId);
    }
    if (message.rpcEndpoint !== "") {
      writer.uint32(58).string(message.rpcEndpoint);
    }
    if (message.rpcWsEndpoint !== "") {
      writer.uint32(66).string(message.rpcWsEndpoint);
    }
    if (message.bridge !== "") {
      writer.uint32(74).string(message.bridge);
    }
    if (message.explorer !== "") {
      writer.uint32(82).string(message.explorer);
    }
    if (message.l1Blockscout !== "") {
      writer.uint32(90).string(message.l1Blockscout);
    }
    if (message.l2Blockscout !== "") {
      writer.uint32(98).string(message.l2Blockscout);
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(106).fork()).ldelim();
    }
    if (message.contractAddresses !== undefined) {
      Struct.encode(Struct.wrap(message.contractAddresses), writer.uint32(114).fork()).ldelim();
    }
    if (message.version !== undefined) {
      Struct.encode(Struct.wrap(message.version), writer.uint32(122).fork()).ldelim();
    }
    if (message.dataAvailabilityMetadata !== undefined) {
      Struct.encode(Struct.wrap(message.dataAvailabilityMetadata), writer.uint32(130).fork()).ldelim();
    }
    if (message.id !== "") {
      writer.uint32(138).string(message.id);
    }
    if (message.tokenMetadata !== undefined) {
      Struct.encode(Struct.wrap(message.tokenMetadata), writer.uint32(146).fork()).ldelim();
    }
    if (message.environment !== "") {
      writer.uint32(154).string(message.environment);
    }
    if (message.accounts !== undefined) {
      Struct.encode(Struct.wrap(message.accounts), writer.uint32(162).fork()).ldelim();
    }
    if (message.startupAt !== undefined) {
      Timestamp.encode(toTimestamp(message.startupAt), writer.uint32(170).fork()).ldelim();
    }
    if (message.visibilityType !== "") {
      writer.uint32(178).string(message.visibilityType);
    }
    if (message.parentChainId !== 0) {
      writer.uint32(184).uint32(message.parentChainId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Rollup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRollup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.settlementLayer = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dataAvailabilityLayer = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.status = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.chainId = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.rpcEndpoint = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.rpcWsEndpoint = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.bridge = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.explorer = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.l1Blockscout = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.l2Blockscout = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.contractAddresses = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.version = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.dataAvailabilityMetadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.id = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.tokenMetadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.environment = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.accounts = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.startupAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.visibilityType = reader.string();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.parentChainId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Rollup {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      settlementLayer: isSet(object.settlementLayer) ? globalThis.String(object.settlementLayer) : "",
      dataAvailabilityLayer: isSet(object.dataAvailabilityLayer) ? globalThis.String(object.dataAvailabilityLayer) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      chainId: isSet(object.chainId) ? globalThis.Number(object.chainId) : 0,
      rpcEndpoint: isSet(object.rpcEndpoint) ? globalThis.String(object.rpcEndpoint) : "",
      rpcWsEndpoint: isSet(object.rpcWsEndpoint) ? globalThis.String(object.rpcWsEndpoint) : "",
      bridge: isSet(object.bridge) ? globalThis.String(object.bridge) : "",
      explorer: isSet(object.explorer) ? globalThis.String(object.explorer) : "",
      l1Blockscout: isSet(object.l1Blockscout) ? globalThis.String(object.l1Blockscout) : "",
      l2Blockscout: isSet(object.l2Blockscout) ? globalThis.String(object.l2Blockscout) : "",
      metadata: isObject(object.metadata) ? object.metadata : undefined,
      contractAddresses: isObject(object.contractAddresses) ? object.contractAddresses : undefined,
      version: isObject(object.version) ? object.version : undefined,
      dataAvailabilityMetadata: isObject(object.dataAvailabilityMetadata) ? object.dataAvailabilityMetadata : undefined,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      tokenMetadata: isObject(object.tokenMetadata) ? object.tokenMetadata : undefined,
      environment: isSet(object.environment) ? globalThis.String(object.environment) : "",
      accounts: isObject(object.accounts) ? object.accounts : undefined,
      startupAt: isSet(object.startupAt) ? fromJsonTimestamp(object.startupAt) : undefined,
      visibilityType: isSet(object.visibilityType) ? globalThis.String(object.visibilityType) : "",
      parentChainId: isSet(object.parentChainId) ? globalThis.Number(object.parentChainId) : 0,
    };
  },

  toJSON(message: Rollup): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.settlementLayer !== "") {
      obj.settlementLayer = message.settlementLayer;
    }
    if (message.dataAvailabilityLayer !== "") {
      obj.dataAvailabilityLayer = message.dataAvailabilityLayer;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.chainId !== 0) {
      obj.chainId = Math.round(message.chainId);
    }
    if (message.rpcEndpoint !== "") {
      obj.rpcEndpoint = message.rpcEndpoint;
    }
    if (message.rpcWsEndpoint !== "") {
      obj.rpcWsEndpoint = message.rpcWsEndpoint;
    }
    if (message.bridge !== "") {
      obj.bridge = message.bridge;
    }
    if (message.explorer !== "") {
      obj.explorer = message.explorer;
    }
    if (message.l1Blockscout !== "") {
      obj.l1Blockscout = message.l1Blockscout;
    }
    if (message.l2Blockscout !== "") {
      obj.l2Blockscout = message.l2Blockscout;
    }
    if (message.metadata !== undefined) {
      obj.metadata = message.metadata;
    }
    if (message.contractAddresses !== undefined) {
      obj.contractAddresses = message.contractAddresses;
    }
    if (message.version !== undefined) {
      obj.version = message.version;
    }
    if (message.dataAvailabilityMetadata !== undefined) {
      obj.dataAvailabilityMetadata = message.dataAvailabilityMetadata;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.tokenMetadata !== undefined) {
      obj.tokenMetadata = message.tokenMetadata;
    }
    if (message.environment !== "") {
      obj.environment = message.environment;
    }
    if (message.accounts !== undefined) {
      obj.accounts = message.accounts;
    }
    if (message.startupAt !== undefined) {
      obj.startupAt = message.startupAt.toISOString();
    }
    if (message.visibilityType !== "") {
      obj.visibilityType = message.visibilityType;
    }
    if (message.parentChainId !== 0) {
      obj.parentChainId = Math.round(message.parentChainId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Rollup>, I>>(base?: I): Rollup {
    return Rollup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Rollup>, I>>(object: I): Rollup {
    const message = createBaseRollup();
    message.name = object.name ?? "";
    message.type = object.type ?? "";
    message.settlementLayer = object.settlementLayer ?? "";
    message.dataAvailabilityLayer = object.dataAvailabilityLayer ?? "";
    message.status = object.status ?? "";
    message.chainId = object.chainId ?? 0;
    message.rpcEndpoint = object.rpcEndpoint ?? "";
    message.rpcWsEndpoint = object.rpcWsEndpoint ?? "";
    message.bridge = object.bridge ?? "";
    message.explorer = object.explorer ?? "";
    message.l1Blockscout = object.l1Blockscout ?? "";
    message.l2Blockscout = object.l2Blockscout ?? "";
    message.metadata = object.metadata ?? undefined;
    message.contractAddresses = object.contractAddresses ?? undefined;
    message.version = object.version ?? undefined;
    message.dataAvailabilityMetadata = object.dataAvailabilityMetadata ?? undefined;
    message.id = object.id ?? "";
    message.tokenMetadata = object.tokenMetadata ?? undefined;
    message.environment = object.environment ?? "";
    message.accounts = object.accounts ?? undefined;
    message.startupAt = object.startupAt ?? undefined;
    message.visibilityType = object.visibilityType ?? "";
    message.parentChainId = object.parentChainId ?? 0;
    return message;
  },
};

function createBaseListRollupRequest(): ListRollupRequest {
  return {};
}

export const ListRollupRequest = {
  encode(_: ListRollupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRollupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRollupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListRollupRequest {
    return {};
  },

  toJSON(_: ListRollupRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListRollupRequest>, I>>(base?: I): ListRollupRequest {
    return ListRollupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListRollupRequest>, I>>(_: I): ListRollupRequest {
    const message = createBaseListRollupRequest();
    return message;
  },
};

function createBaseListRollupReply(): ListRollupReply {
  return { rollups: [] };
}

export const ListRollupReply = {
  encode(message: ListRollupReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rollups) {
      Rollup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRollupReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRollupReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rollups.push(Rollup.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRollupReply {
    return {
      rollups: globalThis.Array.isArray(object?.rollups) ? object.rollups.map((e: any) => Rollup.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListRollupReply): unknown {
    const obj: any = {};
    if (message.rollups?.length) {
      obj.rollups = message.rollups.map((e) => Rollup.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListRollupReply>, I>>(base?: I): ListRollupReply {
    return ListRollupReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListRollupReply>, I>>(object: I): ListRollupReply {
    const message = createBaseListRollupReply();
    message.rollups = object.rollups?.map((e) => Rollup.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateOpstackRequest(): CreateOpstackRequest {
  return {};
}

export const CreateOpstackRequest = {
  encode(_: CreateOpstackRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOpstackRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOpstackRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CreateOpstackRequest {
    return {};
  },

  toJSON(_: CreateOpstackRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOpstackRequest>, I>>(base?: I): CreateOpstackRequest {
    return CreateOpstackRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOpstackRequest>, I>>(_: I): CreateOpstackRequest {
    const message = createBaseCreateOpstackRequest();
    return message;
  },
};

function createBaseCreateOpstackReply(): CreateOpstackReply {
  return {};
}

export const CreateOpstackReply = {
  encode(_: CreateOpstackReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOpstackReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOpstackReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CreateOpstackReply {
    return {};
  },

  toJSON(_: CreateOpstackReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOpstackReply>, I>>(base?: I): CreateOpstackReply {
    return CreateOpstackReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOpstackReply>, I>>(_: I): CreateOpstackReply {
    const message = createBaseCreateOpstackReply();
    return message;
  },
};

function createBaseCreateOrbitRequest(): CreateOrbitRequest {
  return {
    deployerKey: "",
    l1conn: "",
    parentChainId: 0,
    chainName: "",
    chainId: 0,
    stakerKey: "",
    batchPosterKey: "",
    enableAnytrust: false,
    keysetBytes: "",
    daserverPrivateKey: "",
    daserverPublicKey: "",
    promoCode: "",
  };
}

export const CreateOrbitRequest = {
  encode(message: CreateOrbitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deployerKey !== "") {
      writer.uint32(10).string(message.deployerKey);
    }
    if (message.l1conn !== "") {
      writer.uint32(18).string(message.l1conn);
    }
    if (message.parentChainId !== 0) {
      writer.uint32(24).int64(message.parentChainId);
    }
    if (message.chainName !== "") {
      writer.uint32(34).string(message.chainName);
    }
    if (message.chainId !== 0) {
      writer.uint32(40).uint64(message.chainId);
    }
    if (message.stakerKey !== "") {
      writer.uint32(50).string(message.stakerKey);
    }
    if (message.batchPosterKey !== "") {
      writer.uint32(58).string(message.batchPosterKey);
    }
    if (message.enableAnytrust !== false) {
      writer.uint32(64).bool(message.enableAnytrust);
    }
    if (message.keysetBytes !== "") {
      writer.uint32(74).string(message.keysetBytes);
    }
    if (message.daserverPrivateKey !== "") {
      writer.uint32(82).string(message.daserverPrivateKey);
    }
    if (message.daserverPublicKey !== "") {
      writer.uint32(90).string(message.daserverPublicKey);
    }
    if (message.promoCode !== "") {
      writer.uint32(98).string(message.promoCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrbitRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrbitRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deployerKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.l1conn = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.parentChainId = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.chainName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.chainId = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.stakerKey = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.batchPosterKey = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.enableAnytrust = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.keysetBytes = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.daserverPrivateKey = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.daserverPublicKey = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.promoCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrbitRequest {
    return {
      deployerKey: isSet(object.deployerKey) ? globalThis.String(object.deployerKey) : "",
      l1conn: isSet(object.l1conn) ? globalThis.String(object.l1conn) : "",
      parentChainId: isSet(object.parentChainId) ? globalThis.Number(object.parentChainId) : 0,
      chainName: isSet(object.chainName) ? globalThis.String(object.chainName) : "",
      chainId: isSet(object.chainId) ? globalThis.Number(object.chainId) : 0,
      stakerKey: isSet(object.stakerKey) ? globalThis.String(object.stakerKey) : "",
      batchPosterKey: isSet(object.batchPosterKey) ? globalThis.String(object.batchPosterKey) : "",
      enableAnytrust: isSet(object.enableAnytrust) ? globalThis.Boolean(object.enableAnytrust) : false,
      keysetBytes: isSet(object.keysetBytes) ? globalThis.String(object.keysetBytes) : "",
      daserverPrivateKey: isSet(object.daserverPrivateKey) ? globalThis.String(object.daserverPrivateKey) : "",
      daserverPublicKey: isSet(object.daserverPublicKey) ? globalThis.String(object.daserverPublicKey) : "",
      promoCode: isSet(object.promoCode) ? globalThis.String(object.promoCode) : "",
    };
  },

  toJSON(message: CreateOrbitRequest): unknown {
    const obj: any = {};
    if (message.deployerKey !== "") {
      obj.deployerKey = message.deployerKey;
    }
    if (message.l1conn !== "") {
      obj.l1conn = message.l1conn;
    }
    if (message.parentChainId !== 0) {
      obj.parentChainId = Math.round(message.parentChainId);
    }
    if (message.chainName !== "") {
      obj.chainName = message.chainName;
    }
    if (message.chainId !== 0) {
      obj.chainId = Math.round(message.chainId);
    }
    if (message.stakerKey !== "") {
      obj.stakerKey = message.stakerKey;
    }
    if (message.batchPosterKey !== "") {
      obj.batchPosterKey = message.batchPosterKey;
    }
    if (message.enableAnytrust !== false) {
      obj.enableAnytrust = message.enableAnytrust;
    }
    if (message.keysetBytes !== "") {
      obj.keysetBytes = message.keysetBytes;
    }
    if (message.daserverPrivateKey !== "") {
      obj.daserverPrivateKey = message.daserverPrivateKey;
    }
    if (message.daserverPublicKey !== "") {
      obj.daserverPublicKey = message.daserverPublicKey;
    }
    if (message.promoCode !== "") {
      obj.promoCode = message.promoCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrbitRequest>, I>>(base?: I): CreateOrbitRequest {
    return CreateOrbitRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrbitRequest>, I>>(object: I): CreateOrbitRequest {
    const message = createBaseCreateOrbitRequest();
    message.deployerKey = object.deployerKey ?? "";
    message.l1conn = object.l1conn ?? "";
    message.parentChainId = object.parentChainId ?? 0;
    message.chainName = object.chainName ?? "";
    message.chainId = object.chainId ?? 0;
    message.stakerKey = object.stakerKey ?? "";
    message.batchPosterKey = object.batchPosterKey ?? "";
    message.enableAnytrust = object.enableAnytrust ?? false;
    message.keysetBytes = object.keysetBytes ?? "";
    message.daserverPrivateKey = object.daserverPrivateKey ?? "";
    message.daserverPublicKey = object.daserverPublicKey ?? "";
    message.promoCode = object.promoCode ?? "";
    return message;
  },
};

function createBaseCreateOrbitReply(): CreateOrbitReply {
  return { transactionHash: "", rpc: "", ws: "", blockscout: "" };
}

export const CreateOrbitReply = {
  encode(message: CreateOrbitReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionHash !== "") {
      writer.uint32(10).string(message.transactionHash);
    }
    if (message.rpc !== "") {
      writer.uint32(18).string(message.rpc);
    }
    if (message.ws !== "") {
      writer.uint32(26).string(message.ws);
    }
    if (message.blockscout !== "") {
      writer.uint32(34).string(message.blockscout);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrbitReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrbitReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionHash = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rpc = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ws = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.blockscout = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrbitReply {
    return {
      transactionHash: isSet(object.transactionHash) ? globalThis.String(object.transactionHash) : "",
      rpc: isSet(object.rpc) ? globalThis.String(object.rpc) : "",
      ws: isSet(object.ws) ? globalThis.String(object.ws) : "",
      blockscout: isSet(object.blockscout) ? globalThis.String(object.blockscout) : "",
    };
  },

  toJSON(message: CreateOrbitReply): unknown {
    const obj: any = {};
    if (message.transactionHash !== "") {
      obj.transactionHash = message.transactionHash;
    }
    if (message.rpc !== "") {
      obj.rpc = message.rpc;
    }
    if (message.ws !== "") {
      obj.ws = message.ws;
    }
    if (message.blockscout !== "") {
      obj.blockscout = message.blockscout;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrbitReply>, I>>(base?: I): CreateOrbitReply {
    return CreateOrbitReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrbitReply>, I>>(object: I): CreateOrbitReply {
    const message = createBaseCreateOrbitReply();
    message.transactionHash = object.transactionHash ?? "";
    message.rpc = object.rpc ?? "";
    message.ws = object.ws ?? "";
    message.blockscout = object.blockscout ?? "";
    return message;
  },
};

function createBaseGenerateKeyRequest(): GenerateKeyRequest {
  return { type: 0 };
}

export const GenerateKeyRequest = {
  encode(message: GenerateKeyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateKeyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateKeyRequest {
    return { type: isSet(object.type) ? keyTypeFromJSON(object.type) : 0 };
  },

  toJSON(message: GenerateKeyRequest): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = keyTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateKeyRequest>, I>>(base?: I): GenerateKeyRequest {
    return GenerateKeyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateKeyRequest>, I>>(object: I): GenerateKeyRequest {
    const message = createBaseGenerateKeyRequest();
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseGenerateKeyReply(): GenerateKeyReply {
  return { keys: undefined };
}

export const GenerateKeyReply = {
  encode(message: GenerateKeyReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.keys !== undefined) {
      Struct.encode(Struct.wrap(message.keys), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateKeyReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateKeyReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keys = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateKeyReply {
    return { keys: isObject(object.keys) ? object.keys : undefined };
  },

  toJSON(message: GenerateKeyReply): unknown {
    const obj: any = {};
    if (message.keys !== undefined) {
      obj.keys = message.keys;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateKeyReply>, I>>(base?: I): GenerateKeyReply {
    return GenerateKeyReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateKeyReply>, I>>(object: I): GenerateKeyReply {
    const message = createBaseGenerateKeyReply();
    message.keys = object.keys ?? undefined;
    return message;
  },
};

function createBaseValidatePromoCodeRequest(): ValidatePromoCodeRequest {
  return { code: "" };
}

export const ValidatePromoCodeRequest = {
  encode(message: ValidatePromoCodeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidatePromoCodeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidatePromoCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValidatePromoCodeRequest {
    return { code: isSet(object.code) ? globalThis.String(object.code) : "" };
  },

  toJSON(message: ValidatePromoCodeRequest): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValidatePromoCodeRequest>, I>>(base?: I): ValidatePromoCodeRequest {
    return ValidatePromoCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValidatePromoCodeRequest>, I>>(object: I): ValidatePromoCodeRequest {
    const message = createBaseValidatePromoCodeRequest();
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseValidatePromoCodeReply(): ValidatePromoCodeReply {
  return { valid: false };
}

export const ValidatePromoCodeReply = {
  encode(message: ValidatePromoCodeReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.valid !== false) {
      writer.uint32(16).bool(message.valid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidatePromoCodeReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidatePromoCodeReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.valid = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ValidatePromoCodeReply {
    return { valid: isSet(object.valid) ? globalThis.Boolean(object.valid) : false };
  },

  toJSON(message: ValidatePromoCodeReply): unknown {
    const obj: any = {};
    if (message.valid !== false) {
      obj.valid = message.valid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ValidatePromoCodeReply>, I>>(base?: I): ValidatePromoCodeReply {
    return ValidatePromoCodeReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValidatePromoCodeReply>, I>>(object: I): ValidatePromoCodeReply {
    const message = createBaseValidatePromoCodeReply();
    message.valid = object.valid ?? false;
    return message;
  },
};

function createBaseRegisterWithdrawalFeeRollupRequest(): RegisterWithdrawalFeeRollupRequest {
  return { opstackId: "" };
}

export const RegisterWithdrawalFeeRollupRequest = {
  encode(message: RegisterWithdrawalFeeRollupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.opstackId !== "") {
      writer.uint32(10).string(message.opstackId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterWithdrawalFeeRollupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterWithdrawalFeeRollupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.opstackId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterWithdrawalFeeRollupRequest {
    return { opstackId: isSet(object.opstackId) ? globalThis.String(object.opstackId) : "" };
  },

  toJSON(message: RegisterWithdrawalFeeRollupRequest): unknown {
    const obj: any = {};
    if (message.opstackId !== "") {
      obj.opstackId = message.opstackId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterWithdrawalFeeRollupRequest>, I>>(
    base?: I,
  ): RegisterWithdrawalFeeRollupRequest {
    return RegisterWithdrawalFeeRollupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterWithdrawalFeeRollupRequest>, I>>(
    object: I,
  ): RegisterWithdrawalFeeRollupRequest {
    const message = createBaseRegisterWithdrawalFeeRollupRequest();
    message.opstackId = object.opstackId ?? "";
    return message;
  },
};

function createBaseRegisterWithdrawalFeeRollupReply(): RegisterWithdrawalFeeRollupReply {
  return {};
}

export const RegisterWithdrawalFeeRollupReply = {
  encode(_: RegisterWithdrawalFeeRollupReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterWithdrawalFeeRollupReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterWithdrawalFeeRollupReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RegisterWithdrawalFeeRollupReply {
    return {};
  },

  toJSON(_: RegisterWithdrawalFeeRollupReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterWithdrawalFeeRollupReply>, I>>(
    base?: I,
  ): RegisterWithdrawalFeeRollupReply {
    return RegisterWithdrawalFeeRollupReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterWithdrawalFeeRollupReply>, I>>(
    _: I,
  ): RegisterWithdrawalFeeRollupReply {
    const message = createBaseRegisterWithdrawalFeeRollupReply();
    return message;
  },
};

function createBaseTriggerWithdrawFeeRequest(): TriggerWithdrawFeeRequest {
  return { opstackId: "", vault: 0 };
}

export const TriggerWithdrawFeeRequest = {
  encode(message: TriggerWithdrawFeeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.opstackId !== "") {
      writer.uint32(10).string(message.opstackId);
    }
    if (message.vault !== 0) {
      writer.uint32(16).int32(message.vault);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TriggerWithdrawFeeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTriggerWithdrawFeeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.opstackId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.vault = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TriggerWithdrawFeeRequest {
    return {
      opstackId: isSet(object.opstackId) ? globalThis.String(object.opstackId) : "",
      vault: isSet(object.vault) ? feeVaultFromJSON(object.vault) : 0,
    };
  },

  toJSON(message: TriggerWithdrawFeeRequest): unknown {
    const obj: any = {};
    if (message.opstackId !== "") {
      obj.opstackId = message.opstackId;
    }
    if (message.vault !== 0) {
      obj.vault = feeVaultToJSON(message.vault);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TriggerWithdrawFeeRequest>, I>>(base?: I): TriggerWithdrawFeeRequest {
    return TriggerWithdrawFeeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TriggerWithdrawFeeRequest>, I>>(object: I): TriggerWithdrawFeeRequest {
    const message = createBaseTriggerWithdrawFeeRequest();
    message.opstackId = object.opstackId ?? "";
    message.vault = object.vault ?? 0;
    return message;
  },
};

function createBaseTriggerWithdrawFeeReply(): TriggerWithdrawFeeReply {
  return { txHash: "" };
}

export const TriggerWithdrawFeeReply = {
  encode(message: TriggerWithdrawFeeReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.txHash !== "") {
      writer.uint32(10).string(message.txHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TriggerWithdrawFeeReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTriggerWithdrawFeeReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.txHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TriggerWithdrawFeeReply {
    return { txHash: isSet(object.txHash) ? globalThis.String(object.txHash) : "" };
  },

  toJSON(message: TriggerWithdrawFeeReply): unknown {
    const obj: any = {};
    if (message.txHash !== "") {
      obj.txHash = message.txHash;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TriggerWithdrawFeeReply>, I>>(base?: I): TriggerWithdrawFeeReply {
    return TriggerWithdrawFeeReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TriggerWithdrawFeeReply>, I>>(object: I): TriggerWithdrawFeeReply {
    const message = createBaseTriggerWithdrawFeeReply();
    message.txHash = object.txHash ?? "";
    return message;
  },
};

function createBaseWithdrawFeeStatusRequest(): WithdrawFeeStatusRequest {
  return { opstackId: "", vault: 0 };
}

export const WithdrawFeeStatusRequest = {
  encode(message: WithdrawFeeStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.opstackId !== "") {
      writer.uint32(10).string(message.opstackId);
    }
    if (message.vault !== 0) {
      writer.uint32(16).int32(message.vault);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WithdrawFeeStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWithdrawFeeStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.opstackId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.vault = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WithdrawFeeStatusRequest {
    return {
      opstackId: isSet(object.opstackId) ? globalThis.String(object.opstackId) : "",
      vault: isSet(object.vault) ? feeVaultFromJSON(object.vault) : 0,
    };
  },

  toJSON(message: WithdrawFeeStatusRequest): unknown {
    const obj: any = {};
    if (message.opstackId !== "") {
      obj.opstackId = message.opstackId;
    }
    if (message.vault !== 0) {
      obj.vault = feeVaultToJSON(message.vault);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WithdrawFeeStatusRequest>, I>>(base?: I): WithdrawFeeStatusRequest {
    return WithdrawFeeStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WithdrawFeeStatusRequest>, I>>(object: I): WithdrawFeeStatusRequest {
    const message = createBaseWithdrawFeeStatusRequest();
    message.opstackId = object.opstackId ?? "";
    message.vault = object.vault ?? 0;
    return message;
  },
};

function createBaseWithdrawFeeStatusReply(): WithdrawFeeStatusReply {
  return { receipt: "", vaultBalance: "", minWithdrawalAmount: "", withdrawalNetwork: 0 };
}

export const WithdrawFeeStatusReply = {
  encode(message: WithdrawFeeStatusReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.receipt !== "") {
      writer.uint32(10).string(message.receipt);
    }
    if (message.vaultBalance !== "") {
      writer.uint32(18).string(message.vaultBalance);
    }
    if (message.minWithdrawalAmount !== "") {
      writer.uint32(26).string(message.minWithdrawalAmount);
    }
    if (message.withdrawalNetwork !== 0) {
      writer.uint32(32).int32(message.withdrawalNetwork);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WithdrawFeeStatusReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWithdrawFeeStatusReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.receipt = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vaultBalance = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.minWithdrawalAmount = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.withdrawalNetwork = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WithdrawFeeStatusReply {
    return {
      receipt: isSet(object.receipt) ? globalThis.String(object.receipt) : "",
      vaultBalance: isSet(object.vaultBalance) ? globalThis.String(object.vaultBalance) : "",
      minWithdrawalAmount: isSet(object.minWithdrawalAmount) ? globalThis.String(object.minWithdrawalAmount) : "",
      withdrawalNetwork: isSet(object.withdrawalNetwork) ? withdrawalNetworkFromJSON(object.withdrawalNetwork) : 0,
    };
  },

  toJSON(message: WithdrawFeeStatusReply): unknown {
    const obj: any = {};
    if (message.receipt !== "") {
      obj.receipt = message.receipt;
    }
    if (message.vaultBalance !== "") {
      obj.vaultBalance = message.vaultBalance;
    }
    if (message.minWithdrawalAmount !== "") {
      obj.minWithdrawalAmount = message.minWithdrawalAmount;
    }
    if (message.withdrawalNetwork !== 0) {
      obj.withdrawalNetwork = withdrawalNetworkToJSON(message.withdrawalNetwork);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WithdrawFeeStatusReply>, I>>(base?: I): WithdrawFeeStatusReply {
    return WithdrawFeeStatusReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WithdrawFeeStatusReply>, I>>(object: I): WithdrawFeeStatusReply {
    const message = createBaseWithdrawFeeStatusReply();
    message.receipt = object.receipt ?? "";
    message.vaultBalance = object.vaultBalance ?? "";
    message.minWithdrawalAmount = object.minWithdrawalAmount ?? "";
    message.withdrawalNetwork = object.withdrawalNetwork ?? 0;
    return message;
  },
};

function createBaseWithdrawHistoryRequest(): WithdrawHistoryRequest {
  return { opstackId: "", vault: 0 };
}

export const WithdrawHistoryRequest = {
  encode(message: WithdrawHistoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.opstackId !== "") {
      writer.uint32(10).string(message.opstackId);
    }
    if (message.vault !== 0) {
      writer.uint32(16).int32(message.vault);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WithdrawHistoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWithdrawHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.opstackId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.vault = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WithdrawHistoryRequest {
    return {
      opstackId: isSet(object.opstackId) ? globalThis.String(object.opstackId) : "",
      vault: isSet(object.vault) ? feeVaultFromJSON(object.vault) : 0,
    };
  },

  toJSON(message: WithdrawHistoryRequest): unknown {
    const obj: any = {};
    if (message.opstackId !== "") {
      obj.opstackId = message.opstackId;
    }
    if (message.vault !== 0) {
      obj.vault = feeVaultToJSON(message.vault);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WithdrawHistoryRequest>, I>>(base?: I): WithdrawHistoryRequest {
    return WithdrawHistoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WithdrawHistoryRequest>, I>>(object: I): WithdrawHistoryRequest {
    const message = createBaseWithdrawHistoryRequest();
    message.opstackId = object.opstackId ?? "";
    message.vault = object.vault ?? 0;
    return message;
  },
};

function createBaseWithdrawHistoryReply(): WithdrawHistoryReply {
  return { withdrawLogs: [] };
}

export const WithdrawHistoryReply = {
  encode(message: WithdrawHistoryReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.withdrawLogs) {
      WithdrawLog.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WithdrawHistoryReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWithdrawHistoryReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.withdrawLogs.push(WithdrawLog.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WithdrawHistoryReply {
    return {
      withdrawLogs: globalThis.Array.isArray(object?.withdrawLogs)
        ? object.withdrawLogs.map((e: any) => WithdrawLog.fromJSON(e))
        : [],
    };
  },

  toJSON(message: WithdrawHistoryReply): unknown {
    const obj: any = {};
    if (message.withdrawLogs?.length) {
      obj.withdrawLogs = message.withdrawLogs.map((e) => WithdrawLog.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WithdrawHistoryReply>, I>>(base?: I): WithdrawHistoryReply {
    return WithdrawHistoryReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WithdrawHistoryReply>, I>>(object: I): WithdrawHistoryReply {
    const message = createBaseWithdrawHistoryReply();
    message.withdrawLogs = object.withdrawLogs?.map((e) => WithdrawLog.fromPartial(e)) || [];
    return message;
  },
};

function createBaseWithdrawLog(): WithdrawLog {
  return { actionName: "", txHash: "", timestamp: undefined, amount: "", txFee: "", l2TxHash: undefined };
}

export const WithdrawLog = {
  encode(message: WithdrawLog, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.actionName !== "") {
      writer.uint32(10).string(message.actionName);
    }
    if (message.txHash !== "") {
      writer.uint32(18).string(message.txHash);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(26).fork()).ldelim();
    }
    if (message.amount !== "") {
      writer.uint32(34).string(message.amount);
    }
    if (message.txFee !== "") {
      writer.uint32(42).string(message.txFee);
    }
    if (message.l2TxHash !== undefined) {
      writer.uint32(50).string(message.l2TxHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WithdrawLog {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWithdrawLog();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.actionName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.txHash = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.amount = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.txFee = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.l2TxHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WithdrawLog {
    return {
      actionName: isSet(object.actionName) ? globalThis.String(object.actionName) : "",
      txHash: isSet(object.txHash) ? globalThis.String(object.txHash) : "",
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      amount: isSet(object.amount) ? globalThis.String(object.amount) : "",
      txFee: isSet(object.txFee) ? globalThis.String(object.txFee) : "",
      l2TxHash: isSet(object.l2TxHash) ? globalThis.String(object.l2TxHash) : undefined,
    };
  },

  toJSON(message: WithdrawLog): unknown {
    const obj: any = {};
    if (message.actionName !== "") {
      obj.actionName = message.actionName;
    }
    if (message.txHash !== "") {
      obj.txHash = message.txHash;
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    if (message.amount !== "") {
      obj.amount = message.amount;
    }
    if (message.txFee !== "") {
      obj.txFee = message.txFee;
    }
    if (message.l2TxHash !== undefined) {
      obj.l2TxHash = message.l2TxHash;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WithdrawLog>, I>>(base?: I): WithdrawLog {
    return WithdrawLog.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WithdrawLog>, I>>(object: I): WithdrawLog {
    const message = createBaseWithdrawLog();
    message.actionName = object.actionName ?? "";
    message.txHash = object.txHash ?? "";
    message.timestamp = object.timestamp ?? undefined;
    message.amount = object.amount ?? "";
    message.txFee = object.txFee ?? "";
    message.l2TxHash = object.l2TxHash ?? undefined;
    return message;
  },
};

function createBaseWithdrawFeeRollupRegisteredRequest(): WithdrawFeeRollupRegisteredRequest {
  return { opstackId: "" };
}

export const WithdrawFeeRollupRegisteredRequest = {
  encode(message: WithdrawFeeRollupRegisteredRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.opstackId !== "") {
      writer.uint32(10).string(message.opstackId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WithdrawFeeRollupRegisteredRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWithdrawFeeRollupRegisteredRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.opstackId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WithdrawFeeRollupRegisteredRequest {
    return { opstackId: isSet(object.opstackId) ? globalThis.String(object.opstackId) : "" };
  },

  toJSON(message: WithdrawFeeRollupRegisteredRequest): unknown {
    const obj: any = {};
    if (message.opstackId !== "") {
      obj.opstackId = message.opstackId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WithdrawFeeRollupRegisteredRequest>, I>>(
    base?: I,
  ): WithdrawFeeRollupRegisteredRequest {
    return WithdrawFeeRollupRegisteredRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WithdrawFeeRollupRegisteredRequest>, I>>(
    object: I,
  ): WithdrawFeeRollupRegisteredRequest {
    const message = createBaseWithdrawFeeRollupRegisteredRequest();
    message.opstackId = object.opstackId ?? "";
    return message;
  },
};

function createBaseWithdrawFeeRollupRegisteredReply(): WithdrawFeeRollupRegisteredReply {
  return { registered: false };
}

export const WithdrawFeeRollupRegisteredReply = {
  encode(message: WithdrawFeeRollupRegisteredReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.registered !== false) {
      writer.uint32(8).bool(message.registered);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WithdrawFeeRollupRegisteredReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWithdrawFeeRollupRegisteredReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.registered = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WithdrawFeeRollupRegisteredReply {
    return { registered: isSet(object.registered) ? globalThis.Boolean(object.registered) : false };
  },

  toJSON(message: WithdrawFeeRollupRegisteredReply): unknown {
    const obj: any = {};
    if (message.registered !== false) {
      obj.registered = message.registered;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WithdrawFeeRollupRegisteredReply>, I>>(
    base?: I,
  ): WithdrawFeeRollupRegisteredReply {
    return WithdrawFeeRollupRegisteredReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WithdrawFeeRollupRegisteredReply>, I>>(
    object: I,
  ): WithdrawFeeRollupRegisteredReply {
    const message = createBaseWithdrawFeeRollupRegisteredReply();
    message.registered = object.registered ?? false;
    return message;
  },
};

function createBaseListPromoCodesRequest(): ListPromoCodesRequest {
  return {};
}

export const ListPromoCodesRequest = {
  encode(_: ListPromoCodesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListPromoCodesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListPromoCodesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListPromoCodesRequest {
    return {};
  },

  toJSON(_: ListPromoCodesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListPromoCodesRequest>, I>>(base?: I): ListPromoCodesRequest {
    return ListPromoCodesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListPromoCodesRequest>, I>>(_: I): ListPromoCodesRequest {
    const message = createBaseListPromoCodesRequest();
    return message;
  },
};

function createBaseListPromoCodesReply(): ListPromoCodesReply {
  return { codes: [] };
}

export const ListPromoCodesReply = {
  encode(message: ListPromoCodesReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.codes) {
      PromoCode.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListPromoCodesReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListPromoCodesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.codes.push(PromoCode.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListPromoCodesReply {
    return {
      codes: globalThis.Array.isArray(object?.codes) ? object.codes.map((e: any) => PromoCode.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListPromoCodesReply): unknown {
    const obj: any = {};
    if (message.codes?.length) {
      obj.codes = message.codes.map((e) => PromoCode.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListPromoCodesReply>, I>>(base?: I): ListPromoCodesReply {
    return ListPromoCodesReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListPromoCodesReply>, I>>(object: I): ListPromoCodesReply {
    const message = createBaseListPromoCodesReply();
    message.codes = object.codes?.map((e) => PromoCode.fromPartial(e)) || [];
    return message;
  },
};

function createBasePromoCode(): PromoCode {
  return { code: "", createdAt: undefined, expireAt: undefined, labels: [] };
}

export const PromoCode = {
  encode(message: PromoCode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.expireAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expireAt), writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.labels) {
      Label.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PromoCode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePromoCode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expireAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(Label.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PromoCode {
    return {
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      expireAt: isSet(object.expireAt) ? fromJsonTimestamp(object.expireAt) : undefined,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => Label.fromJSON(e)) : [],
    };
  },

  toJSON(message: PromoCode): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.expireAt !== undefined) {
      obj.expireAt = message.expireAt.toISOString();
    }
    if (message.labels?.length) {
      obj.labels = message.labels.map((e) => Label.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PromoCode>, I>>(base?: I): PromoCode {
    return PromoCode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PromoCode>, I>>(object: I): PromoCode {
    const message = createBasePromoCode();
    message.code = object.code ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.expireAt = object.expireAt ?? undefined;
    message.labels = object.labels?.map((e) => Label.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLabel(): Label {
  return { key: "", value: "" };
}

export const Label = {
  encode(message: Label, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Label {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLabel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Label {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: Label): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Label>, I>>(base?: I): Label {
    return Label.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Label>, I>>(object: I): Label {
    const message = createBaseLabel();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseGeneratePromoCodesRequest(): GeneratePromoCodesRequest {
  return { count: 0, labels: [] };
}

export const GeneratePromoCodesRequest = {
  encode(message: GeneratePromoCodesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).int32(message.count);
    }
    for (const v of message.labels) {
      Label.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeneratePromoCodesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeneratePromoCodesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.count = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.labels.push(Label.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GeneratePromoCodesRequest {
    return {
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => Label.fromJSON(e)) : [],
    };
  },

  toJSON(message: GeneratePromoCodesRequest): unknown {
    const obj: any = {};
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    if (message.labels?.length) {
      obj.labels = message.labels.map((e) => Label.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GeneratePromoCodesRequest>, I>>(base?: I): GeneratePromoCodesRequest {
    return GeneratePromoCodesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GeneratePromoCodesRequest>, I>>(object: I): GeneratePromoCodesRequest {
    const message = createBaseGeneratePromoCodesRequest();
    message.count = object.count ?? 0;
    message.labels = object.labels?.map((e) => Label.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGeneratePromoCodesReply(): GeneratePromoCodesReply {
  return { codes: [] };
}

export const GeneratePromoCodesReply = {
  encode(message: GeneratePromoCodesReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.codes) {
      PromoCode.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeneratePromoCodesReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeneratePromoCodesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.codes.push(PromoCode.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GeneratePromoCodesReply {
    return {
      codes: globalThis.Array.isArray(object?.codes) ? object.codes.map((e: any) => PromoCode.fromJSON(e)) : [],
    };
  },

  toJSON(message: GeneratePromoCodesReply): unknown {
    const obj: any = {};
    if (message.codes?.length) {
      obj.codes = message.codes.map((e) => PromoCode.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GeneratePromoCodesReply>, I>>(base?: I): GeneratePromoCodesReply {
    return GeneratePromoCodesReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GeneratePromoCodesReply>, I>>(object: I): GeneratePromoCodesReply {
    const message = createBaseGeneratePromoCodesReply();
    message.codes = object.codes?.map((e) => PromoCode.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccount(): Account {
  return {
    id: 0,
    rollupId: "",
    address: "",
    name: "",
    network: "",
    balance: "",
    threshold: "",
    personInCharge: "",
    createTime: undefined,
    updateTime: undefined,
    notifications: [],
    lastNotificationTime: undefined,
    notificationInterval: undefined,
    lastFundNotificationTime: undefined,
    mainNet: false,
  };
}

export const Account = {
  encode(message: Account, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.rollupId !== "") {
      writer.uint32(18).string(message.rollupId);
    }
    if (message.address !== "") {
      writer.uint32(26).string(message.address);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    if (message.network !== "") {
      writer.uint32(42).string(message.network);
    }
    if (message.balance !== "") {
      writer.uint32(50).string(message.balance);
    }
    if (message.threshold !== "") {
      writer.uint32(58).string(message.threshold);
    }
    if (message.personInCharge !== "") {
      writer.uint32(66).string(message.personInCharge);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(74).fork()).ldelim();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.notifications) {
      writer.uint32(90).string(v!);
    }
    if (message.lastNotificationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastNotificationTime), writer.uint32(98).fork()).ldelim();
    }
    if (message.notificationInterval !== undefined) {
      Duration.encode(message.notificationInterval, writer.uint32(106).fork()).ldelim();
    }
    if (message.lastFundNotificationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastFundNotificationTime), writer.uint32(114).fork()).ldelim();
    }
    if (message.mainNet !== false) {
      writer.uint32(120).bool(message.mainNet);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Account {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.rollupId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.address = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.network = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.balance = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.threshold = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.personInCharge = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.notifications.push(reader.string());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.lastNotificationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.notificationInterval = Duration.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.lastFundNotificationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.mainNet = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Account {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      rollupId: isSet(object.rollupId) ? globalThis.String(object.rollupId) : "",
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      network: isSet(object.network) ? globalThis.String(object.network) : "",
      balance: isSet(object.balance) ? globalThis.String(object.balance) : "",
      threshold: isSet(object.threshold) ? globalThis.String(object.threshold) : "",
      personInCharge: isSet(object.personInCharge) ? globalThis.String(object.personInCharge) : "",
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
      notifications: globalThis.Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => globalThis.String(e))
        : [],
      lastNotificationTime: isSet(object.lastNotificationTime)
        ? fromJsonTimestamp(object.lastNotificationTime)
        : undefined,
      notificationInterval: isSet(object.notificationInterval)
        ? Duration.fromJSON(object.notificationInterval)
        : undefined,
      lastFundNotificationTime: isSet(object.lastFundNotificationTime)
        ? fromJsonTimestamp(object.lastFundNotificationTime)
        : undefined,
      mainNet: isSet(object.mainNet) ? globalThis.Boolean(object.mainNet) : false,
    };
  },

  toJSON(message: Account): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.rollupId !== "") {
      obj.rollupId = message.rollupId;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.network !== "") {
      obj.network = message.network;
    }
    if (message.balance !== "") {
      obj.balance = message.balance;
    }
    if (message.threshold !== "") {
      obj.threshold = message.threshold;
    }
    if (message.personInCharge !== "") {
      obj.personInCharge = message.personInCharge;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime.toISOString();
    }
    if (message.notifications?.length) {
      obj.notifications = message.notifications;
    }
    if (message.lastNotificationTime !== undefined) {
      obj.lastNotificationTime = message.lastNotificationTime.toISOString();
    }
    if (message.notificationInterval !== undefined) {
      obj.notificationInterval = Duration.toJSON(message.notificationInterval);
    }
    if (message.lastFundNotificationTime !== undefined) {
      obj.lastFundNotificationTime = message.lastFundNotificationTime.toISOString();
    }
    if (message.mainNet !== false) {
      obj.mainNet = message.mainNet;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Account>, I>>(base?: I): Account {
    return Account.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Account>, I>>(object: I): Account {
    const message = createBaseAccount();
    message.id = object.id ?? 0;
    message.rollupId = object.rollupId ?? "";
    message.address = object.address ?? "";
    message.name = object.name ?? "";
    message.network = object.network ?? "";
    message.balance = object.balance ?? "";
    message.threshold = object.threshold ?? "";
    message.personInCharge = object.personInCharge ?? "";
    message.createTime = object.createTime ?? undefined;
    message.updateTime = object.updateTime ?? undefined;
    message.notifications = object.notifications?.map((e) => e) || [];
    message.lastNotificationTime = object.lastNotificationTime ?? undefined;
    message.notificationInterval = (object.notificationInterval !== undefined && object.notificationInterval !== null)
      ? Duration.fromPartial(object.notificationInterval)
      : undefined;
    message.lastFundNotificationTime = object.lastFundNotificationTime ?? undefined;
    message.mainNet = object.mainNet ?? false;
    return message;
  },
};

function createBaseCreateAccountRequest(): CreateAccountRequest {
  return {
    rollupId: "",
    opstackBatcherThreshold: undefined,
    opstackProposerThreshold: undefined,
    orbitBatchPosterThreshold: undefined,
    personInCharge: undefined,
    notifications: [],
    notificationInterval: undefined,
  };
}

export const CreateAccountRequest = {
  encode(message: CreateAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rollupId !== "") {
      writer.uint32(10).string(message.rollupId);
    }
    if (message.opstackBatcherThreshold !== undefined) {
      writer.uint32(18).string(message.opstackBatcherThreshold);
    }
    if (message.opstackProposerThreshold !== undefined) {
      writer.uint32(26).string(message.opstackProposerThreshold);
    }
    if (message.orbitBatchPosterThreshold !== undefined) {
      writer.uint32(34).string(message.orbitBatchPosterThreshold);
    }
    if (message.personInCharge !== undefined) {
      writer.uint32(42).string(message.personInCharge);
    }
    for (const v of message.notifications) {
      writer.uint32(50).string(v!);
    }
    if (message.notificationInterval !== undefined) {
      Duration.encode(message.notificationInterval, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rollupId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.opstackBatcherThreshold = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.opstackProposerThreshold = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orbitBatchPosterThreshold = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.personInCharge = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.notifications.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.notificationInterval = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAccountRequest {
    return {
      rollupId: isSet(object.rollupId) ? globalThis.String(object.rollupId) : "",
      opstackBatcherThreshold: isSet(object.opstackBatcherThreshold)
        ? globalThis.String(object.opstackBatcherThreshold)
        : undefined,
      opstackProposerThreshold: isSet(object.opstackProposerThreshold)
        ? globalThis.String(object.opstackProposerThreshold)
        : undefined,
      orbitBatchPosterThreshold: isSet(object.orbitBatchPosterThreshold)
        ? globalThis.String(object.orbitBatchPosterThreshold)
        : undefined,
      personInCharge: isSet(object.personInCharge) ? globalThis.String(object.personInCharge) : undefined,
      notifications: globalThis.Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => globalThis.String(e))
        : [],
      notificationInterval: isSet(object.notificationInterval)
        ? Duration.fromJSON(object.notificationInterval)
        : undefined,
    };
  },

  toJSON(message: CreateAccountRequest): unknown {
    const obj: any = {};
    if (message.rollupId !== "") {
      obj.rollupId = message.rollupId;
    }
    if (message.opstackBatcherThreshold !== undefined) {
      obj.opstackBatcherThreshold = message.opstackBatcherThreshold;
    }
    if (message.opstackProposerThreshold !== undefined) {
      obj.opstackProposerThreshold = message.opstackProposerThreshold;
    }
    if (message.orbitBatchPosterThreshold !== undefined) {
      obj.orbitBatchPosterThreshold = message.orbitBatchPosterThreshold;
    }
    if (message.personInCharge !== undefined) {
      obj.personInCharge = message.personInCharge;
    }
    if (message.notifications?.length) {
      obj.notifications = message.notifications;
    }
    if (message.notificationInterval !== undefined) {
      obj.notificationInterval = Duration.toJSON(message.notificationInterval);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAccountRequest>, I>>(base?: I): CreateAccountRequest {
    return CreateAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAccountRequest>, I>>(object: I): CreateAccountRequest {
    const message = createBaseCreateAccountRequest();
    message.rollupId = object.rollupId ?? "";
    message.opstackBatcherThreshold = object.opstackBatcherThreshold ?? undefined;
    message.opstackProposerThreshold = object.opstackProposerThreshold ?? undefined;
    message.orbitBatchPosterThreshold = object.orbitBatchPosterThreshold ?? undefined;
    message.personInCharge = object.personInCharge ?? undefined;
    message.notifications = object.notifications?.map((e) => e) || [];
    message.notificationInterval = (object.notificationInterval !== undefined && object.notificationInterval !== null)
      ? Duration.fromPartial(object.notificationInterval)
      : undefined;
    return message;
  },
};

function createBaseCreateAccountReply(): CreateAccountReply {
  return { accounts: [] };
}

export const CreateAccountReply = {
  encode(message: CreateAccountReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.accounts) {
      Account.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAccountReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accounts.push(Account.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAccountReply {
    return {
      accounts: globalThis.Array.isArray(object?.accounts) ? object.accounts.map((e: any) => Account.fromJSON(e)) : [],
    };
  },

  toJSON(message: CreateAccountReply): unknown {
    const obj: any = {};
    if (message.accounts?.length) {
      obj.accounts = message.accounts.map((e) => Account.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAccountReply>, I>>(base?: I): CreateAccountReply {
    return CreateAccountReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAccountReply>, I>>(object: I): CreateAccountReply {
    const message = createBaseCreateAccountReply();
    message.accounts = object.accounts?.map((e) => Account.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListAccountsRequest(): ListAccountsRequest {
  return { rollupId: "" };
}

export const ListAccountsRequest = {
  encode(message: ListAccountsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rollupId !== "") {
      writer.uint32(10).string(message.rollupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAccountsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAccountsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rollupId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAccountsRequest {
    return { rollupId: isSet(object.rollupId) ? globalThis.String(object.rollupId) : "" };
  },

  toJSON(message: ListAccountsRequest): unknown {
    const obj: any = {};
    if (message.rollupId !== "") {
      obj.rollupId = message.rollupId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAccountsRequest>, I>>(base?: I): ListAccountsRequest {
    return ListAccountsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAccountsRequest>, I>>(object: I): ListAccountsRequest {
    const message = createBaseListAccountsRequest();
    message.rollupId = object.rollupId ?? "";
    return message;
  },
};

function createBaseListAccountsReply(): ListAccountsReply {
  return { accounts: [] };
}

export const ListAccountsReply = {
  encode(message: ListAccountsReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.accounts) {
      Account.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAccountsReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAccountsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accounts.push(Account.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAccountsReply {
    return {
      accounts: globalThis.Array.isArray(object?.accounts) ? object.accounts.map((e: any) => Account.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListAccountsReply): unknown {
    const obj: any = {};
    if (message.accounts?.length) {
      obj.accounts = message.accounts.map((e) => Account.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAccountsReply>, I>>(base?: I): ListAccountsReply {
    return ListAccountsReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAccountsReply>, I>>(object: I): ListAccountsReply {
    const message = createBaseListAccountsReply();
    message.accounts = object.accounts?.map((e) => Account.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateAccountRequest(): UpdateAccountRequest {
  return {
    rollupId: "",
    accountId: 0,
    notifications: [],
    threshold: undefined,
    personInCharge: undefined,
    notificationInterval: undefined,
  };
}

export const UpdateAccountRequest = {
  encode(message: UpdateAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rollupId !== "") {
      writer.uint32(10).string(message.rollupId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).uint32(message.accountId);
    }
    for (const v of message.notifications) {
      writer.uint32(26).string(v!);
    }
    if (message.threshold !== undefined) {
      writer.uint32(34).string(message.threshold);
    }
    if (message.personInCharge !== undefined) {
      writer.uint32(42).string(message.personInCharge);
    }
    if (message.notificationInterval !== undefined) {
      Duration.encode(message.notificationInterval, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rollupId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.notifications.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.threshold = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.personInCharge = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.notificationInterval = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountRequest {
    return {
      rollupId: isSet(object.rollupId) ? globalThis.String(object.rollupId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      notifications: globalThis.Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => globalThis.String(e))
        : [],
      threshold: isSet(object.threshold) ? globalThis.String(object.threshold) : undefined,
      personInCharge: isSet(object.personInCharge) ? globalThis.String(object.personInCharge) : undefined,
      notificationInterval: isSet(object.notificationInterval)
        ? Duration.fromJSON(object.notificationInterval)
        : undefined,
    };
  },

  toJSON(message: UpdateAccountRequest): unknown {
    const obj: any = {};
    if (message.rollupId !== "") {
      obj.rollupId = message.rollupId;
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.notifications?.length) {
      obj.notifications = message.notifications;
    }
    if (message.threshold !== undefined) {
      obj.threshold = message.threshold;
    }
    if (message.personInCharge !== undefined) {
      obj.personInCharge = message.personInCharge;
    }
    if (message.notificationInterval !== undefined) {
      obj.notificationInterval = Duration.toJSON(message.notificationInterval);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountRequest>, I>>(base?: I): UpdateAccountRequest {
    return UpdateAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountRequest>, I>>(object: I): UpdateAccountRequest {
    const message = createBaseUpdateAccountRequest();
    message.rollupId = object.rollupId ?? "";
    message.accountId = object.accountId ?? 0;
    message.notifications = object.notifications?.map((e) => e) || [];
    message.threshold = object.threshold ?? undefined;
    message.personInCharge = object.personInCharge ?? undefined;
    message.notificationInterval = (object.notificationInterval !== undefined && object.notificationInterval !== null)
      ? Duration.fromPartial(object.notificationInterval)
      : undefined;
    return message;
  },
};

function createBaseUpdateAccountReply(): UpdateAccountReply {
  return { account: undefined };
}

export const UpdateAccountReply = {
  encode(message: UpdateAccountReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.account !== undefined) {
      Account.encode(message.account, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.account = Account.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountReply {
    return { account: isSet(object.account) ? Account.fromJSON(object.account) : undefined };
  },

  toJSON(message: UpdateAccountReply): unknown {
    const obj: any = {};
    if (message.account !== undefined) {
      obj.account = Account.toJSON(message.account);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountReply>, I>>(base?: I): UpdateAccountReply {
    return UpdateAccountReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountReply>, I>>(object: I): UpdateAccountReply {
    const message = createBaseUpdateAccountReply();
    message.account = (object.account !== undefined && object.account !== null)
      ? Account.fromPartial(object.account)
      : undefined;
    return message;
  },
};

function createBaseDeleteAccountRequest(): DeleteAccountRequest {
  return { rollupId: "" };
}

export const DeleteAccountRequest = {
  encode(message: DeleteAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rollupId !== "") {
      writer.uint32(10).string(message.rollupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rollupId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteAccountRequest {
    return { rollupId: isSet(object.rollupId) ? globalThis.String(object.rollupId) : "" };
  },

  toJSON(message: DeleteAccountRequest): unknown {
    const obj: any = {};
    if (message.rollupId !== "") {
      obj.rollupId = message.rollupId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteAccountRequest>, I>>(base?: I): DeleteAccountRequest {
    return DeleteAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteAccountRequest>, I>>(object: I): DeleteAccountRequest {
    const message = createBaseDeleteAccountRequest();
    message.rollupId = object.rollupId ?? "";
    return message;
  },
};

function createBaseDeleteAccountReply(): DeleteAccountReply {
  return {};
}

export const DeleteAccountReply = {
  encode(_: DeleteAccountReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAccountReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAccountReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteAccountReply {
    return {};
  },

  toJSON(_: DeleteAccountReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteAccountReply>, I>>(base?: I): DeleteAccountReply {
    return DeleteAccountReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteAccountReply>, I>>(_: I): DeleteAccountReply {
    const message = createBaseDeleteAccountReply();
    return message;
  },
};

export type RollupServiceDefinition = typeof RollupServiceDefinition;
export const RollupServiceDefinition = {
  name: "RollupService",
  fullName: "rollup.v1.RollupService",
  methods: {
    listRollups: {
      name: "ListRollups",
      requestType: ListRollupRequest,
      requestStream: false,
      responseType: ListRollupReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([17, 18, 15, 47, 118, 49, 47, 98, 102, 102, 47, 114, 111, 108, 108, 117, 112, 115]),
          ],
        },
      },
    },
    createOpstack: {
      name: "CreateOpstack",
      requestType: CreateOpstackRequest,
      requestStream: false,
      responseType: CreateOpstackReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([20, 34, 15, 47, 118, 49, 47, 98, 102, 102, 47, 111, 112, 115, 116, 97, 99, 107, 58, 1, 42]),
          ],
        },
      },
    },
    createOrbit: {
      name: "CreateOrbit",
      requestType: CreateOrbitRequest,
      requestStream: false,
      responseType: CreateOrbitReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([18, 34, 13, 47, 118, 49, 47, 98, 102, 102, 47, 111, 114, 98, 105, 116, 58, 1, 42]),
          ],
        },
      },
    },
    generateKey: {
      name: "GenerateKey",
      requestType: GenerateKeyRequest,
      requestStream: false,
      responseType: GenerateKeyReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              21,
              18,
              19,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              107,
              101,
              121,
              115,
              47,
              123,
              116,
              121,
              112,
              101,
              125,
            ]),
          ],
        },
      },
    },
    validatePromoCode: {
      name: "ValidatePromoCode",
      requestType: ValidatePromoCodeRequest,
      requestStream: false,
      responseType: ValidatePromoCodeReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([18, 34, 13, 47, 118, 49, 47, 98, 102, 102, 47, 112, 114, 111, 109, 111, 58, 1, 42]),
          ],
        },
      },
    },
    listPromoCodes: {
      name: "ListPromoCodes",
      requestType: ListPromoCodesRequest,
      requestStream: false,
      responseType: ListPromoCodesReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [new Uint8Array([15, 18, 13, 47, 118, 49, 47, 98, 102, 102, 47, 112, 114, 111, 109, 111])],
        },
      },
    },
    generatePromoCodes: {
      name: "GeneratePromoCodes",
      requestType: GeneratePromoCodesRequest,
      requestStream: false,
      responseType: GeneratePromoCodesReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([18, 26, 13, 47, 118, 49, 47, 98, 102, 102, 47, 112, 114, 111, 109, 111, 58, 1, 42]),
          ],
        },
      },
    },
    withdrawFeeRollupRegistered: {
      name: "WithdrawFeeRollupRegistered",
      requestType: WithdrawFeeRollupRegisteredRequest,
      requestStream: false,
      responseType: WithdrawFeeRollupRegisteredReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              40,
              18,
              38,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              111,
              112,
              115,
              116,
              97,
              99,
              107,
              47,
              119,
              105,
              116,
              104,
              100,
              114,
              97,
              119,
              102,
              101,
              101,
              47,
              114,
              101,
              103,
              105,
              115,
              116,
              101,
              114,
              101,
              100,
            ]),
          ],
        },
      },
    },
    registerWithdrawalFeeRollup: {
      name: "RegisterWithdrawalFeeRollup",
      requestType: RegisterWithdrawalFeeRollupRequest,
      requestStream: false,
      responseType: RegisterWithdrawalFeeRollupReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              41,
              34,
              36,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              111,
              112,
              115,
              116,
              97,
              99,
              107,
              47,
              119,
              105,
              116,
              104,
              100,
              114,
              97,
              119,
              102,
              101,
              101,
              47,
              114,
              101,
              103,
              105,
              115,
              116,
              101,
              114,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    triggerWithdrawFee: {
      name: "TriggerWithdrawFee",
      requestType: TriggerWithdrawFeeRequest,
      requestStream: false,
      responseType: TriggerWithdrawFeeReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              40,
              34,
              35,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              111,
              112,
              115,
              116,
              97,
              99,
              107,
              47,
              119,
              105,
              116,
              104,
              100,
              114,
              97,
              119,
              102,
              101,
              101,
              47,
              116,
              114,
              105,
              103,
              103,
              101,
              114,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    withdrawFeeStatus: {
      name: "WithdrawFeeStatus",
      requestType: WithdrawFeeStatusRequest,
      requestStream: false,
      responseType: WithdrawFeeStatusReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              36,
              18,
              34,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              111,
              112,
              115,
              116,
              97,
              99,
              107,
              47,
              119,
              105,
              116,
              104,
              100,
              114,
              97,
              119,
              102,
              101,
              101,
              47,
              115,
              116,
              97,
              116,
              117,
              115,
            ]),
          ],
        },
      },
    },
    withdrawHistory: {
      name: "WithdrawHistory",
      requestType: WithdrawHistoryRequest,
      requestStream: false,
      responseType: WithdrawHistoryReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              37,
              18,
              35,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              111,
              112,
              115,
              116,
              97,
              99,
              107,
              47,
              119,
              105,
              116,
              104,
              100,
              114,
              97,
              119,
              102,
              101,
              101,
              47,
              104,
              105,
              115,
              116,
              111,
              114,
              121,
            ]),
          ],
        },
      },
    },
    /** crud for balance-alert */
    createAccount: {
      name: "CreateAccount",
      requestType: CreateAccountRequest,
      requestStream: false,
      responseType: CreateAccountReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              28,
              34,
              23,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              99,
              99,
              111,
              117,
              110,
              116,
              115,
              47,
              99,
              114,
              101,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    listAccounts: {
      name: "ListAccounts",
      requestType: ListAccountsRequest,
      requestStream: false,
      responseType: ListAccountsReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              23,
              18,
              21,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              99,
              99,
              111,
              117,
              110,
              116,
              115,
              47,
              108,
              105,
              115,
              116,
            ]),
          ],
        },
      },
    },
    updateAccount: {
      name: "UpdateAccount",
      requestType: UpdateAccountRequest,
      requestStream: false,
      responseType: UpdateAccountReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              28,
              34,
              23,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              99,
              99,
              111,
              117,
              110,
              116,
              115,
              47,
              117,
              112,
              100,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    deleteAccount: {
      name: "DeleteAccount",
      requestType: DeleteAccountRequest,
      requestStream: false,
      responseType: DeleteAccountReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              28,
              34,
              23,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              99,
              99,
              111,
              117,
              110,
              116,
              115,
              47,
              100,
              101,
              108,
              101,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
