import {
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { FC, Fragment, MouseEvent, ReactNode, useMemo, useRef, useState } from 'react';
import { IoMdMore } from 'react-icons/io';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { AAAAppStatus, aAAAppStatusFromJSON, APP, OperateAction } from 'types/protoc-gen/bffaaa';

import useSubmitAaaRequest from './useSubmitAaaRequest';

function PublishButton({
  app,
  disabled,
  onCloseModal,
}: {
  app: APP;
  disabled?: boolean;
  onCloseModal: () => void;
}) {
  const { addAlert } = useAlerts();
  const { isSubmittingRequest, submitRequest } = useSubmitAaaRequest({
    onSuccess: () => {
      addAlert({
        title: 'Submitted publish request',
        desc: 'Your request has been submitted for review by our team. Please check back again later.',
        severity: ALERT_SEVERITY.SUCCESS,
      });
      setShowConfirmationDialog(false);
      onCloseModal?.();
    },
  });
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  return (
    <>
      <ConfirmationDialog
        handleClose={() => {
          setShowConfirmationDialog(false);
        }}
        isLoading={isSubmittingRequest}
        onClick={e => e?.stopPropagation()}
        onConfirm={() => {
          submitRequest({
            id: app?.appStoreID,
            OperateAction: OperateAction.OPERATE_ACTION_PUBLISH,
          });
        }}
        open={showConfirmationDialog}
      >
        This will make your app visible on the marketplace, and anyone will be able to create an
        agent from your template.
      </ConfirmationDialog>
      <ListItemButton
        disabled={disabled}
        onClick={() => {
          setShowConfirmationDialog(true);
        }}
      >
        <ListItemText primary="Make Public" />
      </ListItemButton>
    </>
  );
}

function DeleteButton({
  app,
  disabled,
  onCloseModal,
}: {
  app: APP;
  disabled?: boolean;
  onCloseModal: () => void;
}) {
  const { addAlert } = useAlerts();
  const { isSubmittingRequest, submitRequest } = useSubmitAaaRequest({
    onSuccess: () => {
      addAlert({
        title: 'Successfully deleted app',
        desc: 'Your app has been deleted.',
        severity: ALERT_SEVERITY.SUCCESS,
      });
      setShowConfirmationDialog(false);
      onCloseModal?.();
    },
  });
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  return (
    <>
      <ConfirmationDialog
        handleClose={() => {
          setShowConfirmationDialog(false);
        }}
        isLoading={isSubmittingRequest}
        onClick={e => e?.stopPropagation()}
        onConfirm={() => {
          submitRequest({
            id: app?.appStoreID,
            OperateAction: OperateAction.OPERATE_ACTION_DELETE,
          });
        }}
        open={showConfirmationDialog}
        title="Confirm App Deletion"
      />
      <ListItemButton
        disabled={disabled}
        onClick={() => {
          setShowConfirmationDialog(true);
        }}
      >
        <ListItemText primary="Delete" />
      </ListItemButton>
    </>
  );
}

const StoreCardActionsMenu: FC<
  {
    app: APP;
    renderMenuItems?: (
      defaultActions: { allowedStatuses?: AAAAppStatus[]; component: ReactNode }[],
    ) => ReactNode;
  } & IconButtonProps
> = ({ app, renderMenuItems, sx, ...rest }) => {
  const ref = useRef<any>(null);
  const [showActionMenu, setShowActionMenu] = useState(false);

  const toggleActionMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    setShowActionMenu(state => !state);
  };

  const closeActionMenu = (e: MouseEvent) => {
    e?.stopPropagation();
    setShowActionMenu(false);
  };

  const actions: { allowedStatuses?: AAAAppStatus[]; component: ReactNode }[] = useMemo(
    () => [
      {
        allowedStatuses: [AAAAppStatus.AAA_APP_STATUS_SUBMITTED],
        component: (
          <PublishButton
            app={app}
            onCloseModal={() => {
              setShowActionMenu(false);
            }}
          />
        ),
      },
      {
        allowedStatuses: [
          AAAAppStatus.AAA_APP_STATUS_SUBMITTED,
          AAAAppStatus.AAA_APP_STATUS_REJECTED,
          AAAAppStatus.AAA_APP_STATUS_PUBLISHED,
          AAAAppStatus.AAA_APP_STATUS_PENDING,
        ],
        component: (
          <DeleteButton
            app={app}
            onCloseModal={() => {
              setShowActionMenu(false);
            }}
          />
        ),
      },
    ],
    [app],
  );
  const actionsForCurrentStatus = actions.filter(cur =>
    cur?.allowedStatuses?.some(
      status => aAAAppStatusFromJSON(status) === aAAAppStatusFromJSON(app?.status),
    ),
  );

  return (
    <>
      <IconButton
        onClick={toggleActionMenu}
        ref={ref}
        sx={{ background: 'transparent', pointerEvents: 'all', ...sx }}
        {...rest}
      >
        <IoMdMore />
      </IconButton>
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={closeActionMenu}
        open={showActionMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List
          component="nav"
          onClick={e => {
            e?.stopPropagation();
          }}
          sx={{ p: 0 }}
        >
          {renderMenuItems ? (
            renderMenuItems?.(actionsForCurrentStatus)
          ) : actionsForCurrentStatus?.length > 0 ? (
            actionsForCurrentStatus.map((cur, index) => (
              <Fragment key={index}>{cur.component}</Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No Actions Available" />
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};

export default StoreCardActionsMenu;
