import { ArrowForwardSharp } from '@mui/icons-material';
import { BoxProps, Link as MuiLink, LinkProps, Stack, Typography, useTheme } from '@mui/material';
import AutonomeLogo from 'components/icons/AutonomeLogo';
import { IconAltLayer } from 'components/icons/IconAltLayer';
import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import IconLogo from '../icons/IconLogo';

const isAutonomeSubDomain = window.location.origin === process.env.REACT_APP_AUTONOME_ORIGIN;

const AppLogo = isAutonomeSubDomain ? AutonomeLogo : IconLogo;

const FooterLink = ({
  children,
  href,
  internal = false,
  ...props
}: PropsWithChildren<LinkProps & { internal?: boolean }>) => {
  return (
    <MuiLink
      component={internal ? Link : MuiLink}
      href={href}
      sx={{
        color: theme => theme.palette.text.primary,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
      }}
      to={href}
      {...props}
    >
      <Typography sx={{ display: { xs: 'inline', md: 'none' } }} variant="captionC">
        {children}
      </Typography>
      <Typography
        sx={{
          display: { xs: 'none', md: 'inline' },
          fontSize: { lg: '13px', md: '11px' },
        }}
        variant="bodySmallC"
      >
        {children}
      </Typography>
      {!internal && <ArrowForwardSharp sx={{ display: { xs: 'inline', md: 'none' } }} />}
    </MuiLink>
  );
};

const Footer: FC<BoxProps> = () => {
  const theme = useTheme();

  return (
    <Stack component={'footer'}>
      <Stack
        alignItems={{ md: 'center', xs: 'flex-start' }}
        flexDirection={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
        px={{ md: 5, xs: 0 }}
        sx={{
          background: theme => theme.colors.gradients.sheet,
          py: { xs: 0, md: 4 },
          pt: { xs: 2, md: 4 },
          rowGap: 2,
        }}
      >
        <AppLogo sx={{ height: { xs: '30px', md: '40px' }, width: 'auto', ml: { xs: 3, md: 0 } }} />
        <Stack
          alignItems="row"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent={{ md: 'space-around', xs: 'space-between' }}
          sx={{
            width: { xs: '100%', md: 'auto' },
            ml: { xs: 0, md: 5 },
            a: {
              color: theme.palette.text.primary,
              mr: { md: 5, sx: 0 },
              px: { xs: 3, md: 0 },
              py: { xs: 2, md: 0 },
            },
            'a:last-child': {
              mr: 0,
            },
            '& > a': {
              borderTop: { xs: '1px solid rgba(32, 39, 35, 0.03)', md: 'none' },
            },
          }}
        >
          <FooterLink href="https://altlayer.io" rel="noopener noreferrer" target="_blank">
            ALTLAYER
          </FooterLink>
          <FooterLink
            href="https://docs.altlayer.io/altlayer-documentation"
            rel="noopener noreferrer"
            target="_blank"
          >
            DOCS
          </FooterLink>
          <FooterLink
            href="https://www.altlayer.io/updates"
            rel="noopener noreferrer"
            target="_blank"
          >
            BLOG
          </FooterLink>
          <FooterLink href="https://careers.altlayer.io/" rel="noopener noreferrer" target="_blank">
            CAREERS
          </FooterLink>
          <FooterLink href="https://linktr.ee/altlayer" rel="noopener noreferrer" target="_blank">
            CONTACT
          </FooterLink>
          <FooterLink href="/privacy" internal>
            PRIVACY
          </FooterLink>
          <FooterLink href="/disclaimer" internal>
            DISCLAIMER
          </FooterLink>
        </Stack>
      </Stack>
      <Stack sx={{ background: theme => theme.colors.gradients.sheet }}>
        <Stack
          sx={{
            mx: { xs: 3, md: 5 },
            py: 2,
            borderTop: { md: '1px solid rgba(32, 39, 35, 0.03)', xs: 'none' },
          }}
        >
          <Typography alignItems="center" variant="caption">
            Autonome © 2024 | Powered by{' '}
            <strong>
              <IconAltLayer
                sx={{ width: 12, height: 12, path: { fill: 'black !important' }, mr: 0.25 }}
              />
              AltLayer
            </strong>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
