import 'react-day-picker/style.css';

import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { AutocompleteOption } from 'shared/components/CustomAutocomplete';
import { getDateString, getTimeString, isValidDate, timeZoneOptions } from 'utils/dates';

const DatePicker = styled(DayPicker)(({ theme }) => ({
  '.rdp-day.rdp-selected button, .rdp-day.rdp-selected:focus-visible button, .rdp-day.rdp-selected:hover button':
    {
      background: 'black',
      color: 'white',
    },
  '.rdp-day_button:hover:not([disabled]):not(.rdp-day.rdp-selected .rdp-day_button)': {
    background: theme.colors.gradients.metal,
  },
  '.rdp-day.rdp-today': {
    textDecoration: 'underline',
    color: 'black',
    fontWeight: 'bold',
  },
  '.rdp-selected .rdp-day_button': {
    border: 'none',
  },
  '.rdp-nav button>svg': {
    fill: 'black',
    color: 'black',
  },
}));

interface IExpiryDatePicker {
  selectedDateTime?: Date;
  setSelectedDateTime: Dispatch<SetStateAction<Date | undefined>>;
  selectedTimezone?: AutocompleteOption;
  setSelectedTimezone: Dispatch<SetStateAction<AutocompleteOption | undefined>>;
}

export const ExpiryDatePicker: FC<IExpiryDatePicker> = ({
  selectedDateTime,
  selectedTimezone,
  setSelectedDateTime,
  setSelectedTimezone,
}) => {
  const [dateInput, setDateInput] = useState('');

  const [monthToDisplay, setMonthToDisplay] = useState(new Date());
  const [useLocalTimezone, setUseLocalTimezone] = useState(true);
  const localTimezone = timeZoneOptions.find(
    (cur: AutocompleteOption) => cur.value === Intl.DateTimeFormat().resolvedOptions()?.timeZone,
  );

  const toggleLocalTimezone = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    setUseLocalTimezone(checked);

    if (checked) {
      setSelectedTimezone(localTimezone);
    }
  };

  const handleSelectDate = (dateClicked: Date) => {
    const selected = dateClicked ? new Date(dateClicked) : undefined;

    selected?.setHours(selectedDateTime?.getHours() || 0);
    selected?.setMinutes(selectedDateTime?.getMinutes() || 0);
    setSelectedDateTime(selected);
  };

  const onChangeDateInput = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const newDateTime = new Date(val);

    if (!isValidDate(val)) {
      setSelectedDateTime(undefined);
      setDateInput(val);

      return;
    }

    newDateTime.setHours(selectedDateTime?.getHours() || 0);
    newDateTime.setMinutes(selectedDateTime?.getMinutes() || 0);
    setSelectedDateTime(newDateTime);
    setDateInput(val);
    handleMonthChange(newDateTime);
  };

  const onChangeTimeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const [hours, mins] = val.split(':');
    const newEndDateTime = selectedDateTime ? new Date(selectedDateTime) : undefined;

    newEndDateTime?.setHours(parseInt(hours));
    newEndDateTime?.setMinutes(parseInt(mins));
    setSelectedDateTime(newEndDateTime);
  };

  const handleMonthChange = (newMonth: Date) => {
    setMonthToDisplay(newMonth);
  };

  return (
    <Grid container>
      <Grid container item justifyContent="center" xs={4}>
        <DatePicker
          disabled={{ before: new Date() }}
          mode="single"
          month={monthToDisplay}
          onMonthChange={handleMonthChange}
          onSelect={handleSelectDate}
          required
          selected={selectedDateTime || undefined}
          startMonth={new Date()}
        />
      </Grid>
      <Grid item px={2} xs={8}>
        <Stack>
          <Typography sx={{ whiteSpace: 'nowrap', my: 2 }} variant="bodySmallC">
            Timezone
          </Typography>
          <Autocomplete
            disabled={useLocalTimezone}
            getOptionLabel={(option: AutocompleteOption) => option?.label || ''}
            isOptionEqualToValue={(option, selected) => {
              const noOptionSelected = selected === null;

              return option?.value === selected?.value || noOptionSelected;
            }}
            onChange={(_, selectedOption?: AutocompleteOption | null) => {
              setSelectedTimezone(selectedOption || undefined);
            }}
            options={timeZoneOptions}
            renderInput={params => {
              return <TextField {...params} hiddenLabel placeholder={'Select a timezone'} />;
            }}
            renderOption={(props, option: any) => {
              return <li {...props}>{option.label}</li>;
            }}
            value={useLocalTimezone ? localTimezone : selectedTimezone}
          />
          <FormControlLabel
            control={<Checkbox checked={useLocalTimezone} onChange={toggleLocalTimezone} />}
            label="Use current timezone"
            labelPlacement="end"
          />
        </Stack>
        <Typography sx={{ whiteSpace: 'nowrap', mt: 4, mb: 2 }} variant="bodySmallC">
          Expiration Date and Time
        </Typography>
        <Stack alignItems="center" direction="row" spacing={2}>
          <TextField
            // error={Boolean(get(errors, [fieldName, 'from', 'message']))}
            fullWidth
            inputProps={{
              min: getDateString(new Date(0)),
            }}
            onChange={onChangeDateInput}
            sx={{
              input: {
                colorScheme: 'dark',
                '&::-webkit-calendar-picker-indicator': {
                  display: 'none',
                },
              },
            }}
            type="date"
            value={isValidDate(selectedDateTime) ? getDateString(selectedDateTime) : dateInput}
          />
          <TextField
            disabled={!selectedDateTime}
            // error={Boolean(get(errors, [fieldName, 'from', 'message']))}
            fullWidth
            onChange={onChangeTimeInput}
            sx={{
              input: {
                '&::-webkit-calendar-picker-indicator': { height: 25, width: 25 },
              },
            }}
            type="time"
            value={getTimeString(selectedDateTime)}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
