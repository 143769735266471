import { yupResolver } from '@hookform/resolvers/yup';
import { Editor } from '@monaco-editor/react';
import { Box, Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { PageBannerVariant } from 'components/Layout/PageBanner';
import { useCreateApp, useListAppTypes } from 'hooks/aaas';
import { useDisclosure } from 'hooks/ui';
import { AaasLayout } from 'layouts/AaasLayout';
import { EnvVarsDialog } from 'pages/aaas/NewAgentDeployment/EnvVarsDialog';
import { VerifyPromoCodeDialog } from 'pages/aaas/NewAgentDeployment/VerifyPromoCodeDialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATHS } from 'routes';
import { ErrorCaption } from 'shared/components/Error';
import { FormTextField } from 'shared/components/form';
import { Option as FormLabel } from 'shared/components/Option';
import { SectionTitle } from 'shared/components/SectionTitle';
import { BannerVariant, useBannerStore } from 'shared/stores/useBannerStore';
import { AAAType, aAATypeFromJSON, aAATypeToJSON } from 'types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'types/react-query';
import { encodeToBase64 } from 'utils/strings';
import * as yup from 'yup';

import { SummaryPanel } from './components';
import { SelectDeploymentType, SelectTemplate, SelectTemplateHandler } from './form-steps';
import { FormValues } from './types';
import { validationSchema } from './validation';

export const NewAgentDeployment = () => {
  const { setBanner } = useBannerStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const template = useMemo(() => searchParams?.get('template'), [searchParams]);

  useEffect(() => {
    setBanner(BannerVariant.AAA_WARNING, true);
  }, [setBanner]);

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      template: template || undefined,
      deploymentType: undefined,
      agentPrefixName: undefined,
    },
    resolver: yupResolver(validationSchema as yup.ObjectSchema<FormValues>),
  });
  const formValues = form.watch();

  const {
    onClose: onCloseVerifyPromoCodeDialog,
    onOpen: onOpenVerifyPromoCodeDialog,
    open: isOpenVerifyPromoCodeDialog,
  } = useDisclosure();
  const {
    onClose: onCloseEnvVarsDialog,
    onOpen: onOpenEnvVarsDialog,
    open: envVarsDialogOpened,
  } = useDisclosure();
  const [envVars, setEnvVars] = useState<Record<string, string>>({});
  const { data: getTemplatesResult } = useListAppTypes();
  const { isPending: deployAgentPending, mutateAsync: deployAgent } = useCreateApp({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_LIST_AAA] }),
  });
  const selectedTemplate = useMemo(
    () =>
      getTemplatesResult?.templates.find(template => String(template.name) === formValues.template),
    [formValues.template, getTemplatesResult?.templates],
  );

  const handleSelectTemplate: SelectTemplateHandler = useCallback(
    template => {
      switch (String(template?.name)) {
        case aAATypeToJSON(AAAType.AAATYPE_CUSTOM):
          form.resetField('config');
          form.reset({
            ...formValues,
            template: String(template?.name),
            deploymentType: formValues?.deploymentType,
            agentPrefixName: formValues?.agentPrefixName,
            imageId: undefined,
          });
          break;

        case aAATypeToJSON(AAAType.AAATYPE_ELIZA):
          form.resetField('imageId');
          form.reset({
            template: String(template?.name),
            deploymentType: formValues?.deploymentType,
            agentPrefixName: formValues?.agentPrefixName,
            config: undefined,
          });
          break;

        case '':
          form.resetField('imageId');
          form.resetField('config');
          form.reset({
            deploymentType: formValues?.deploymentType,
            agentPrefixName: formValues?.agentPrefixName,
          });
          break;

        default:
          form.resetField('imageId');
          form.resetField('config');
          form.reset({
            template: String(template?.name),
            deploymentType: formValues?.deploymentType,
            agentPrefixName: formValues?.agentPrefixName,
          });
          break;
      }
    },
    [form, formValues],
  );

  const handlePromoCodeVerified = useCallback(
    async (promoCode: string) => {
      const transformedEnvVars = Object.fromEntries(
        Object.entries(envVars).map(([key, value]) => [key, encodeToBase64(String(value))]),
      );

      const formValues = form.getValues();
      const selectedTemplate = [
        ...(getTemplatesResult?.selfOwned ?? []),
        ...(getTemplatesResult?.appStore ?? []),
        ...(getTemplatesResult?.templates ?? []),
      ].find(template => template.name === formValues.template);

      const res = await deployAgent({
        app: aAATypeFromJSON(selectedTemplate?.type),
        envList: transformedEnvVars,
        name: formValues.agentPrefixName ?? '',
        imageId: formValues?.imageId ?? '',
        config: formValues.config ?? '',
        appStoreID: selectedTemplate?.appStoreID ?? '',
        promoCode,
      });

      if (res?.aaa) navigate(PATHS.AAAS_DETAILS.replace(':appId', res?.aaa.id));
    },
    [deployAgent, envVars, form, getTemplatesResult, navigate],
  );

  const onValid: SubmitHandler<FormValues> = useCallback(
    formValues => {
      console.debug('formValues:', formValues);

      const envVarsListLength = Object.keys(selectedTemplate?.envList ?? []).length;

      if (envVarsListLength > 0) return onOpenEnvVarsDialog();

      return onOpenVerifyPromoCodeDialog();
    },
    [onOpenEnvVarsDialog, onOpenVerifyPromoCodeDialog, selectedTemplate?.envList],
  );

  return (
    <>
      <VerifyPromoCodeDialog
        onClose={onCloseVerifyPromoCodeDialog}
        onPromoCodeVerified={handlePromoCodeVerified}
        open={isOpenVerifyPromoCodeDialog}
      />
      <EnvVarsDialog
        onClose={onCloseEnvVarsDialog}
        onConfirmed={formValues => {
          setEnvVars(formValues);
          setTimeout(() => {
            onOpenVerifyPromoCodeDialog();
          }, 500);
        }}
        open={envVarsDialogOpened}
        template={selectedTemplate}
      />

      <AaasLayout
        pageBannerProps={{
          onClickBack: () => navigate(PATHS.AAAS_DEPLOYMENTS, { replace: true }),
          title: 'Deploy an agent',
          variant: PageBannerVariant.AAAS,
        }}
      >
        <Box bgcolor="#fafafa" pb={4} sx={{ position: 'relative' }}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onValid, err => console.error('err:', err))}>
              <Box margin="0 auto">
                <Grid container>
                  <Grid
                    item
                    md={8}
                    sx={{
                      '& > *:not(:first-child)': {
                        mr: {
                          xs: 3,
                          md: 0,
                        },
                      },
                    }}
                    xs={12}
                  >
                    <SectionTitle>Build your stack</SectionTitle>
                    <SelectDeploymentType />
                    <SelectTemplate onSelectTemplate={handleSelectTemplate} />
                    <FormTextField
                      fieldConfig={{
                        name: 'agentPrefixName',
                        label: 'Agent prefix name',
                        placeholder: 'agent',
                      }}
                      id="step_agentPrefixName"
                      tooltipContent="Maximum of 6 characters only for the prefix name."
                    />
                    {formValues.template === aAATypeToJSON(AAAType.AAATYPE_CUSTOM) && (
                      <FormTextField
                        fieldConfig={{
                          name: 'imageId',
                          label: 'Image ID',
                          placeholder: 'dockerhub_org_name/image_name:tag',
                        }}
                        id="step_imageId"
                      />
                    )}
                    {!!selectedTemplate?.configExample && (
                      <Controller
                        control={form.control}
                        name={'config'}
                        render={({ field, fieldState }) => {
                          return (
                            <FormLabel id="step_config" optionTitle="Config">
                              <Editor
                                {...field}
                                defaultLanguage="json"
                                height="100vh"
                                onChange={value => {
                                  field.onChange({ target: { value } });
                                }}
                                onMount={() => {
                                  form.setValue(
                                    'config',
                                    JSON.parse(
                                      JSON.stringify(selectedTemplate?.configExample, null, 2),
                                    ),
                                  );
                                }}
                                theme="vs-dark"
                                value={formValues.config}
                              />
                              <ErrorCaption
                                error={!!fieldState.error}
                                message={fieldState.error?.message}
                              />
                            </FormLabel>
                          );
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <SummaryPanel
                      isSubmitting={deployAgentPending}
                      sx={{
                        mt: {
                          xs: 10,
                        },
                        m: {
                          xs: 3,
                          md: 5,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </AaasLayout>
    </>
  );
};
