import { Menu, MenuItem, MenuProps } from '@mui/material';

export interface IMetricContainerMoreMenu extends MenuProps {
  onClickExpand?: React.MouseEventHandler<HTMLLIElement>;
  onClickRefetch?: React.MouseEventHandler<HTMLLIElement>;
}

export const MetricContainerMoreMenu = ({
  anchorEl,
  onClickExpand,
  onClickRefetch,
  onClose,
  open,
  ...props
}: IMetricContainerMoreMenu) => {
  return (
    <Menu anchorEl={anchorEl} onClose={onClose} open={open} {...props}>
      <MenuItem
        onClick={event => {
          onClickExpand?.(event);
          onClose?.(event, 'backdropClick');
        }}
      >
        Expand
      </MenuItem>
      {onClickRefetch && (
        <MenuItem
          onClick={event => {
            onClickRefetch?.(event);
            onClose?.(event, 'backdropClick');
          }}
        >
          Refetch
        </MenuItem>
      )}
    </Menu>
  );
};
