import numeral from 'numeral';
import { ethFormatter } from 'utils/recharts';
import { formatUnits, parseUnits } from 'viem';

import { countLeadingZeroes } from './number';

export function truncate(fullStr: string, separator = '...', frontChars = 8, backChars = 6) {
  const truncateIfExceedLen = frontChars + backChars;

  if (fullStr.length <= truncateIfExceedLen) return fullStr;

  return (
    fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars)
  );
}

function trimZero(v: string) {
  const regexp = /(?:\.0*|(\.\d+?)0+)$/;

  return v.replace(regexp, '$1');
}

export function isPositiveNumber(target: string): boolean {
  return /^\d+(\.{0,1}\d+){0,1}$/.test(target);
}

/**
 * Supports up to 1e20.
 *
 * @param amount - The number or string to be formatted.
 * @returns The formatted string with commas or a string representation
 *          of the number in scientific notation if it's extremely small.
 */
export function commify(amount: number | string) {
  // With usage of formatWithPrecision, there is a possibility of amount string being >= "1e21" or <= "1e-7" which will result in NaN
  const result = numeral(Number(amount))?.format('0,0.[000000000000000000]');

  if (result === 'NaN' && Number(amount) < 1e-6) {
    // All numbers smaller than this return NaN for numeral.js
    const expNotation = String(Number(amount));
    const [mantissa] = expNotation.split('e-');
    const mantissaString = mantissa?.replace('.', '');

    return '0.' + '0'?.repeat(countLeadingZeroes(Number(amount))) + mantissaString;
  }

  if (result === 'NaN' && Number(amount) > 1e21) {
    return '>1e21';
  }

  return result === 'NaN' ? '0' : result;
}

/**
 * @description Formats a number / bigint to 6 significant figures (default) and trims trailing zeros.
 * @description 0 dp for large integers
 * @returns string
 */
export function formatWithPrecision(value: number | bigint, _precision = 4, decimals = 18) {
  const stringVal = typeof value === 'number' ? value?.toString() : formatUnits(value, decimals);
  const integerLength = stringVal?.split('.')?.[0]?.length;
  const precision = integerLength > _precision ? integerLength : _precision;

  return trimZero(Number(stringVal)?.toPrecision(precision));
}

export function parseUnitsSafe(value: string, decimals: number): bigint {
  let result;

  try {
    result = parseUnits(value, decimals);
  } catch (error) {
    console.error('Parse units error: ', error);
    result = 0n;
  }

  return result;
}

export function formatAbbreviatedNumber(num: number): string {
  const isNegative = num < 0;
  const absNum = Math.abs(num);
  let formattedNumber;

  if (absNum >= 1e12) {
    // For trillions
    formattedNumber = (absNum / 1e12).toFixed(1) + 'T';
  } else if (absNum >= 1e9) {
    // For billions
    formattedNumber = (absNum / 1e9).toFixed(1) + 'B';
  } else if (absNum >= 1e6) {
    // For millions
    formattedNumber = (absNum / 1e6).toFixed(1) + 'M';
  } else if (absNum >= 1e3) {
    // For thousands
    formattedNumber = (absNum / 1e3).toFixed(1) + 'K';
  } else {
    // For numbers less than a thousand
    formattedNumber = absNum.toString();
  }

  return isNegative ? '-' + formattedNumber : formattedNumber;
}

export const roundDecimals = (value: number) => {
  const numValue = Number(value);

  if (numValue < 0.0001) {
    return numValue.toFixed(10);
  }

  if (numValue < 0.0000000001) {
    return numValue.toFixed(18);
  }

  return numValue.toFixed(5);
};

export const enforceLowercasedHyphenCasing = (value: string): string =>
  value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export const encodeIfNotBase64: (str: string) => string = str => {
  const sanitizedStr = str?.replaceAll('\\n', '\n'); // replace all literal \n with actual newline characterss

  try {
    return btoa(atob(sanitizedStr)) === sanitizedStr ? sanitizedStr : btoa(sanitizedStr);
  } catch (err) {
    return btoa(sanitizedStr);
  }
};

export const encodeToBase64: (str: string) => string = str => {
  const sanitizedStr = str?.replaceAll('\\n', '\n'); // replace all literal \n with actual newline characterss

  try {
    return btoa(sanitizedStr);
  } catch (err) {
    return sanitizedStr;
  }
};

export const formatTokenWithSymbol = (value: string, symbol: string = 'ETH', index?: number) =>
  [ethFormatter(value, index), symbol].join(' ');

export const isBase64: (str: string) => boolean = str => {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

/**
 * Converts a string representation of a regular expression into a RegExp object.
 * The input string should be in the format '/pattern/flags'.
 *
 * @param value - The string representation of the regular expression (e.g., '/abc/g')
 * @returns A RegExp object if the string is valid, undefined otherwise
 *
 * @example
 * ```typescript
 * toRegex('/abc/g') // returns /abc/g
 * toRegex('/[0-9]/i') // returns /[0-9]/i
 * toRegex('invalid') // returns undefined
 * ```
 */
export const toRegex = (value: string) => {
  try {
    const closingRegexIndex = value?.lastIndexOf('/');

    if (!closingRegexIndex) {
      throw Error("Missing closing '/'");
    }

    const pattern = value?.substring(1, closingRegexIndex);
    const flags = value?.substring(closingRegexIndex + 1);

    if (!pattern) {
      throw Error('Missing pattern');
    }

    return RegExp(pattern, flags);
  } catch (err) {
    return undefined;
  }
};
