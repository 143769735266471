import { Box } from '@mui/material';
import { useRaasDeployment } from 'hooks/raas/useRaasDeployment';
import { RollupType } from 'labels/raas';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Section } from 'shared/components/Section';

import { faqMarkdown } from './helpers';

export const FaqView = () => {
  const { data: rollup } = useRaasDeployment();

  return (
    <Section
      sx={{
        background: '#fff',
      }}
      title="FAQ"
    >
      <Box
        id="markdown-content"
        sx={theme => ({
          minHeight: '300px',
          borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
          px: '40px',
          '&#markdown-content hr': {
            borderColor: theme.colors.functional.subject.border,
          },
          '&#markdown-content a': {
            color: theme.colors.functional.text.link,
            textDecoration: 'underline',
            fontSize: '16px',
          },
          '&#markdown-content img': {
            maxHeight: '320px',
            width: 'auto',
            maxWidth: '100%',
            borderRadius: '4px',
            border: `1px solid ${theme.colors.functional.subject.border}`,
          },
          '&#markdown-content pre': {
            border: `1px solid ${theme.colors.functional.subject.border}`,
            whiteSpace: 'pre-wrap !important',
            background: '#f7f7f7',
            borderRadius: '4px',
            fontSize: '14px',
            width: '100%',
            p: '8px',
          },
          '&#markdown-content p > code': {
            border: `1px solid ${theme.colors.functional.subject.border}`,
            background: '#f7f7f7',
            borderRadius: '4px',
            fontSize: '14px',
            p: '2px',
          },
          '&#markdown-content table': {
            borderCollapse: 'collapse',
            width: '100%',
          },
          '&#markdown-content th, &#markdown-content td': {
            border: `1px solid ${theme.colors.functional.subject.border}`,
            padding: '8px',
          },
          '&#markdown-content thead': {
            backgroundColor: '#f7f7f7',
          },
          '&#markdown-content tbody tr:nth-of-type(odd)': {
            backgroundColor: '#fff',
          },
        })}
      >
        <Markdown remarkPlugins={[remarkGfm]}>{faqMarkdown[rollup?.type as RollupType]}</Markdown>
      </Box>
    </Section>
  );
};
