import { AreaChart } from 'shared/components/charts';
import { MetricContainer } from 'shared/components/metric/MetricContainer';

export const BridgeTVLMetric = () => {
  return (
    <MetricContainer title={'Bridge TVL'}>
      <AreaChart />
    </MetricContainer>
  );
};
