import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { RaasMetricsResult, Timeframe } from 'hooks/raas/types';
import { useParams } from 'react-router-dom';
import { chartDefaults } from 'shared/components/charts';
import { QUERY_KEYS } from 'types/react-query';

import { useBlockscoutAxios } from './useBlockscoutAxios';

export interface IUseBlockscoutMetrics
  extends Omit<UseQueryOptions<RaasMetricsResult>, 'queryFn' | 'queryKey'> {
  variant:
    | EoaAccountsVariant
    | TransactionsVariant
    | BlocksVariant
    | ContractsVariant
    | FeeTrackerVariant;
  l2?: boolean;
  from: string;
  to: string;
  timeframe?: Timeframe;
}

export const useBlockscoutMetrics = ({
  from,
  l2 = true,
  timeframe,
  to,
  variant,
  ...queryOptions
}: IUseBlockscoutMetrics) => {
  const { rollupId } = useParams();
  const blockscout = useBlockscoutAxios();
  const [waterPrimary] = chartDefaults.strokeColors;

  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_BLOCKSCOUT_STATS,
      {
        variant,
        rollupId,
        timeframe,
      },
    ],
    queryFn: async (): Promise<RaasMetricsResult> => {
      const res = await blockscout[l2 ? 'l2' : 'l1'].get<BlockscoutMetricsResponse>(
        `/lines/${variant}`,
        {
          params: {
            from,
            to,
          },
        },
      );

      const data: BlockscoutMetricsData[] = [];

      for (const v of res.data.chart) {
        data.push({
          date: v.date,
          [res.data.info.title]: +v.value,
        });
      }

      return {
        data,
        dataKeys: [
          {
            name: res.data.info.title,
            color: waterPrimary,
            fill: 'water',
          },
        ],
      };
    },
    enabled: !!blockscout?.rollup,
    ...queryOptions,
  });
};

export type EoaAccountsVariant = 'accountsGrowth' | 'activeAccounts' | 'newAccounts';
export type TransactionsVariant = 'txnsGrowth' | 'newTxns' | 'txnsSuccessRate';
export type BlocksVariant = 'averageBlockSize';
export type ContractsVariant = 'contractsGrowth' | 'newContracts';
export type FeeTrackerVariant = 'averageGasLimit' | 'averageGasPrice' | 'gasUsedGrowth';

export interface BlockscoutMetricsChart {
  date: string;
  date_to: string;
  value: string;
}

export interface BlockscoutMetricsInfo {
  id: string;
  description: string;
  resolutions: Array<'WEEK' | 'YEAR' | 'DAY' | 'MONTH'>;
  title: string;
  units: string | null;
}

export interface BlockscoutMetricsResponse {
  chart: BlockscoutMetricsChart[];
  info: BlockscoutMetricsInfo;
}

export interface BlockscoutMetricsData {
  [key: string]: number | string;
  date: string;
}
