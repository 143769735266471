import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { SubHeader } from 'components/Layout/SubHeader';
import dayjs from 'dayjs';

import { useRpmByMethods } from './hooks';

// TODO: remove when rpc usage monitoring finalized
export const RpmByMethodsTableMetric = () => {
  const { data } = useRpmByMethods();

  return (
    <>
      <SubHeader>Requests by method</SubHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {['Method Name', 'Requests/Min'].map(header => (
                <TableCell key={header}>
                  <Typography variant="captionC">{header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(
              data?.data.filter(
                v => dayjs(v.date).format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY'),
              )?.[0] ?? {},
            ).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{value.toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
