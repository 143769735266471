import { Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { FONT_FAMILY } from 'constants/font';
import {
  Area,
  AreaChart as RAreaChart,
  CartesianGrid,
  CartesianGridProps,
  Legend,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { CurveType } from 'recharts/types/shape/Curve';
import { largeNumberYAxisTickFormatter, monthDateTickFormatter } from 'utils/recharts';

import { chartDefaults } from './constants';
import { DataKey, Formatter } from './types';
import { useLinearGradients } from './useLinearGradients';

export interface IAreaChart extends Omit<CategoricalChartProps, 'data'> {
  linearGradientPrefix?: string;
  dataKeys?: DataKey[];
  data?: any[] | undefined;
  curveType?: CurveType;
  mock?: boolean;
  tooltipValueFormatter?: Formatter;
  tooltipKeyFormatter?: Formatter;
  xAxisProps?: XAxisProps;
  yAxisProps?: YAxisProps;
  cartesianGridProps?: CartesianGridProps;
  tooltipProps?: TooltipProps<ValueType, NameType>;
  hideLegend?: boolean;
  hideTooltipKeys?: boolean;
  isAnimationActive?: boolean;
}

export const AreaChart = ({
  cartesianGridProps,
  curveType,
  data,
  dataKeys,
  hideLegend,
  hideTooltipKeys,
  isAnimationActive,
  linearGradientPrefix,
  mock,
  tooltipKeyFormatter,
  tooltipProps,
  tooltipValueFormatter,
  xAxisProps,
  yAxisProps,
  ...props
}: IAreaChart) => {
  const theme = useTheme();
  const linearGradients = useLinearGradients(linearGradientPrefix);

  return (
    <RAreaChart data={!data || mock ? defaultData : data} margin={chartDefaults.margin} {...props}>
      <defs>
        {linearGradients.map(linearGradient => (
          <linearGradient
            id={linearGradient.id}
            key={linearGradient.id}
            x1="0"
            x2="0"
            y1="1"
            y2="0"
          >
            {linearGradient.stops.map(({ offset, stopColor, stopOpacity }) => (
              <stop
                key={stopColor}
                offset={offset}
                stopColor={stopColor}
                stopOpacity={stopOpacity}
              />
            ))}
          </linearGradient>
        ))}
      </defs>

      <CartesianGrid
        strokeDasharray="0 0"
        strokeOpacity={0.25}
        strokeWidth={'1px'}
        vertical={false}
        {...cartesianGridProps}
      />
      <XAxis
        fontFamily={FONT_FAMILY}
        fontSize={'13px'}
        fontWeight={400}
        stroke={'rgba(32, 39, 35, 0.25)'}
        strokeWidth={'1px'}
        style={{ lineHeight: '18px' }}
        type="category"
        {...xAxisProps}
        dataKey={xAxisProps?.dataKey ?? 'date'}
        tickFormatter={xAxisProps?.tickFormatter ?? monthDateTickFormatter}
      />
      <YAxis
        axisLine={false}
        fontFamily={FONT_FAMILY}
        fontSize={'13px'}
        fontWeight={400}
        stroke={'rgba(32, 39, 35, 0.25)'}
        strokeWidth={'1px'}
        style={{ lineHeight: '18px' }}
        tickLine={false}
        {...yAxisProps}
        tickFormatter={yAxisProps?.tickFormatter ?? largeNumberYAxisTickFormatter}
      />
      <Tooltip<ValueType, NameType>
        content={({ ...props }) => {
          return (
            <Stack
              spacing="4px"
              sx={_ => ({
                boxShadow: '0px 2px 4px 0px rgba(32, 39, 35, 0.16)',
                background: '#fff',
                px: '9px',
                py: '13px',
              })}
            >
              <Typography variant="caption">{props.label}</Typography>

              {props.payload?.map((payload, index) => (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  key={payload.name}
                  spacing="16px"
                >
                  {!hideTooltipKeys && (
                    <Stack alignItems="center" direction="row" spacing="4px">
                      <SvgIcon sx={{ height: '8px', width: '8px' }} viewBox="0 0 8 8">
                        <rect fill={payload.stroke} height="8" width="8" />
                      </SvgIcon>

                      <Typography variant="caption">
                        {tooltipKeyFormatter?.(payload.name, index) ?? payload.name}
                      </Typography>
                    </Stack>
                  )}
                  <Typography variant="captionM">
                    {tooltipValueFormatter?.(payload.value, index) ??
                      new Intl.NumberFormat().format(+String(payload.value ?? '0'))}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          );
        }}
        formatter={value => {
          if (typeof value === 'number') {
            return new Intl.NumberFormat().format(value);
          }

          return value;
        }}
        itemStyle={{
          minWidth: '172px',
          color: theme.colors.functional.text.primary,
          justifyContent: 'space-between',
        }}
        {...tooltipProps}
      />
      {!hideLegend && (
        <Legend
          color={theme.colors.functional.text.primary}
          content={({ payload }) => (
            <Stack direction="row" spacing="16px" sx={{ mt: '16px', ml: '40px' }}>
              {payload?.map((v, index) => (
                <Stack
                  alignItems="center"
                  direction="row"
                  key={v.dataKey?.toString() ?? index.toString()}
                  spacing="4px"
                >
                  <SvgIcon sx={{ height: '8px', width: '8px' }} viewBox="0 0 8 8">
                    <rect fill={v?.color} height="8" width="8" />
                  </SvgIcon>
                  <Typography variant="caption">{v.dataKey?.toString()}</Typography>
                </Stack>
              ))}
            </Stack>
          )}
          formatter={value => {
            if (typeof value === 'number') {
              return new Intl.NumberFormat().format(value);
            }

            return value;
          }}
          iconSize={8}
          iconType="square"
          margin={{ left: 40, top: 20, bottom: 0 }}
          verticalAlign="bottom"
        />
      )}
      {(!dataKeys && mock ? defaultDataKeys : dataKeys ?? []).map(({ color, fill, name }) => (
        <Area
          dataKey={name}
          dot={false}
          fill={`url(#${[linearGradientPrefix, fill].join('-')})`}
          fillOpacity={0.9}
          isAnimationActive={isAnimationActive}
          key={name}
          stroke={color}
          strokeWidth={'1.4px'}
          type={curveType ?? 'monotone'}
        />
      ))}
    </RAreaChart>
  );
};

enum DataKeyVal {
  INITIATED_TXNS = 'initiated txns',
  FINALIZED = 'finalized',
}

const defaultData = [
  {
    name: '0:00',
    [DataKeyVal.INITIATED_TXNS]: 0.2,
  },
  {
    name: '01:00',
    [DataKeyVal.INITIATED_TXNS]: 0.85,
  },
  {
    name: '02:00',
    [DataKeyVal.INITIATED_TXNS]: 1.92,
  },
  {
    name: '03:00',
    [DataKeyVal.INITIATED_TXNS]: 1.5,
  },
  {
    name: '04:00',
    [DataKeyVal.INITIATED_TXNS]: 2.2,
  },
  {
    name: '05:00',
    [DataKeyVal.INITIATED_TXNS]: 1.9,
  },
  {
    name: '06:00',
    [DataKeyVal.INITIATED_TXNS]: 3.56,
  },
  {
    name: '07:00',
    [DataKeyVal.INITIATED_TXNS]: 4,
  },
];

const defaultDataKeys = [
  {
    name: DataKeyVal.INITIATED_TXNS,
    color: '#5C8CE1',
    fill: 'water',
  },
];
