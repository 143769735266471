import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosRaas, raas } from 'api';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { useState } from 'react';
import { AutocompleteOption } from 'shared/components/CustomAutocomplete';
import { NumericStepper } from 'shared/components/NumericStepper';
import { QUERY_KEYS } from 'types/react-query';
import { getTimeZoneDiffFromLocal, timeZoneOptions } from 'utils/dates';

import EditCodeExpiryDialog from './EditCodeExpiryDialog';
import { ExpiryDatePicker } from './ExpiryDatePicker';
import PromoCodeList from './PromoCodeList';

TimeAgo.addDefaultLocale(en);

export enum PROMO_TYPE {
  RAAS_AAA = 'PROMO_TYPE_RAAS_AAA',
  AVS = 'PROMO_TYPE_AVS',
}

export interface PromoCode {
  code: string;
  // rollupId: string; TODO: uncomment this when the backend is ready
  email: string;
  expireAt: string;
  createdAt: string;
}

const promoTypeOptions = [
  {
    label: 'RAAS/Autonome',
    value: PROMO_TYPE.RAAS_AAA,
  },
  {
    label: 'AVS',
    value: PROMO_TYPE.AVS,
  },
];

const ManagePromoCodes = () => {
  const { addAlert } = useAlerts();

  const queryClient = useQueryClient();
  const {
    data: promoCodes,
    isPending,
    mutate: generatePromoCodes,
  } = useMutation({
    mutationFn: async ({
      count,
      expireAt,
      type,
    }: {
      count: number;
      expireAt: string;
      type: PROMO_TYPE;
    }) => {
      const res = await (type === PROMO_TYPE.AVS
        ? axiosRaas.post('avs/promos', { count, expireAt })
        : raas.put('bff/promo', { count, expireAt }));

      return res?.data?.codes as PromoCode[];
    },
    onSuccess: () => {
      addAlert({ severity: ALERT_SEVERITY.SUCCESS, title: 'Successfully generated' });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_BFF_PROMO_CODES] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_AVS_PROMO_CODES] });
    },
  });

  const [selectedDateTime, setSelectedDateTime] = useState<Date | undefined>(new Date());
  const [selectedPromoType, setSelectedPromoType] = useState<AutocompleteOption | undefined | null>(
    promoTypeOptions[0],
  );
  // TODO: uncomment when backend support for org whitelisting is added
  // const [selectedOrg, setSelectedOrg] = useState<AutocompleteOption | undefined | null>();
  const [promoCodeQty, setPromoCodeQty] = useState('1');
  const [qtyErrMsg, setQtyErrMsg] = useState('');

  const localTimezone = timeZoneOptions.find(
    (cur: AutocompleteOption) => cur.value === Intl.DateTimeFormat().resolvedOptions()?.timeZone,
  );
  const [selectedTimezone, setSelectedTimezone] = useState<AutocompleteOption | undefined>(
    localTimezone,
  );

  const setAndValidateQty = (val: string) => {
    setPromoCodeQty(val);

    if (!(Number(val) > 0) || String(parseInt(val)) !== val) {
      setQtyErrMsg('Please enter a positive integer');

      return;
    }

    setQtyErrMsg('');
  };

  const handleSubmit = () => {
    if (!selectedDateTime) {
      return;
    }

    const dateTime: Date = new Date(selectedDateTime);
    const timeDiffMins = getTimeZoneDiffFromLocal(selectedTimezone?.value as string);

    const localDateTime = new Date(dateTime.setMinutes(dateTime.getMinutes() - timeDiffMins));

    console.debug('dateTime ISO String: ', localDateTime?.toISOString());

    generatePromoCodes({
      count: Number(promoCodeQty),
      expireAt: localDateTime?.toISOString(),
      type: selectedPromoType?.value as PROMO_TYPE,
    });
  };

  const [isExpiryDialogOpen, setIsExpiryDialogOpen] = useState(false);
  const [selectedCode] = useState<PromoCode>();
  // TODO: Uncomment once edit is implemented by backend
  // const onClickEdit = (code: PromoCode) => {
  //   setSelectedCode(code);
  //   setIsExpiryDialogOpen(true);
  // };

  return (
    <Box sx={{ background: theme => theme.colors.gradients.sheet, p: 5 }}>
      <Container maxWidth="xl">
        {selectedCode && (
          <EditCodeExpiryDialog
            isOpen={isExpiryDialogOpen}
            selectedCode={selectedCode}
            setIsOpen={setIsExpiryDialogOpen}
          />
        )}

        <Box justifyContent="flex-start">
          <Grid container justifyContent={'flex-start'} spacing={2}>
            {selectedDateTime && (
              <ExpiryDatePicker
                selectedDateTime={selectedDateTime}
                selectedTimezone={selectedTimezone}
                setSelectedDateTime={setSelectedDateTime}
                setSelectedTimezone={setSelectedTimezone}
              />
            )}
            <Grid item md={4} xs={6}>
              <Typography variant="bodySmallC">Promo code type</Typography>
              <Autocomplete
                getOptionLabel={(option: AutocompleteOption) => option?.label || ''}
                isOptionEqualToValue={(option, selected) => {
                  const noOptionSelected = selected === null;

                  return option?.value === selected?.value || noOptionSelected;
                }}
                onChange={(_, selectedOption: AutocompleteOption | undefined | null) => {
                  setSelectedPromoType(selectedOption);
                }}
                options={promoTypeOptions}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      hiddenLabel
                      placeholder={'Select a Promo Code Tier'}
                    />
                  );
                }}
                renderOption={(props, option: any) => {
                  return <li {...props}>{option.label}</li>;
                }}
                value={selectedPromoType}
              />
            </Grid>
            {/* TODO: uncomment when backend support for org whitelisting is added */}
            {/* 
            <Grid item xs={4}>
            <Typography>Whitelist organization (optional)</Typography>
            <Autocomplete
              getOptionLabel={(option: AutocompleteOption) => option?.label || ''}
              isOptionEqualToValue={(option, selected) => {
                const noOptionSelected = selected === null;

                return option?.value === selected?.value || noOptionSelected;
              }}
              onChange={(_, selectedOption: AutocompleteOption | null) => {
                setSelectedOrg(selectedOption);
              }}
              options={organizationOptions}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    fullWidth
                    hiddenLabel
                    placeholder={'Select an organization to whitelist'}
                  />
                );
              }}
              renderOption={(props, option: any) => {
                return <li {...props}>{option.label}</li>;
              }}
              value={selectedOrg || null}
            />
            </Grid>
             */}
            <Grid item md={4} xs={6}>
              <Typography variant="bodySmallC">Number of promo codes to generate</Typography>
              <TextField
                InputProps={{
                  endAdornment: (
                    <NumericStepper setValue={setAndValidateQty} value={promoCodeQty} />
                  ),
                }}
                error={Boolean(qtyErrMsg)}
                fullWidth
                helperText={qtyErrMsg}
                onChange={e => {
                  const val = e?.target?.value;

                  setAndValidateQty(val);
                }}
                sx={{ '.MuiInputBase-root': { height: 54 } }}
                value={promoCodeQty}
              />
            </Grid>
            <Grid container mt={2}>
              <Grid item xs={4}>
                <LoadingButton
                  disabled={Boolean(qtyErrMsg)}
                  loading={isPending}
                  onClick={handleSubmit}
                  sx={{ mx: 2 }}
                  variant="contained"
                >
                  Generate
                </LoadingButton>
              </Grid>
              <Grid alignItems="center" container item xs={4}>
                {promoCodes?.length && (
                  <Box ml={2} width="100%">
                    <TextareaAutosize
                      readOnly
                      style={{ width: '100%' }}
                      value={promoCodes?.map(cur => cur?.code)?.join('\n')}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Typography sx={{ my: 5 }} variant="h3">
          All promo codes
        </Typography>
        <Box justifyContent="flex-start">
          <PromoCodeList />
        </Box>
      </Container>
    </Box>
  );
};

export default ManagePromoCodes;
