import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useListAppTypes } from 'hooks/aaas';
import { TemplateCard } from 'pages/aaas/NewAgentDeployment/TemplateCard';
import { FormValues } from 'pages/aaas/NewAgentDeployment/types';
import { FC, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorCaption } from 'shared/components/Error';
import { Option as FormLabel } from 'shared/components/Option';
import {
  AAAAppStatus,
  aAAAppStatusToJSON,
  AAAType,
  aAATypeToJSON,
  APP,
} from 'types/protoc-gen/bffaaa';

export type SelectTemplateHandler = (template: APP) => void;

export const SelectTemplate: FC<{ onSelectTemplate: SelectTemplateHandler }> = ({
  onSelectTemplate,
}) => {
  const { data, isPending } = useListAppTypes();

  const form = useFormContext<FormValues>();

  const selected = form.watch('template');
  const error = form.formState.errors.template;

  const [defaultTemplates, appStoreTemplates, selfOwnedTemplates, customTemplate] = useMemo(
    () => [
      data?.templates
        .filter(
          template =>
            ![
              aAATypeToJSON(AAAType.AAATYPE_CUSTOM),
              aAATypeToJSON(AAAType.AAATYPE_AGENTKIT_ROLLUP),
            ]?.includes(String(template?.type)),
        )
        .sort((a, b) => String(a.type).localeCompare(String(b.type))),
      getFilteredTemplates(data?.appStore),
      getFilteredTemplates(data?.selfOwned),
      data?.templates.find(
        template => String(template.type) === aAATypeToJSON(AAAType.AAATYPE_CUSTOM),
      ),
    ],
    [data?.appStore, data?.selfOwned, data?.templates],
  );
  const [hasAppStoreTemplates, hasSelfOwnedTemplates] = useMemo(
    () => [
      appStoreTemplates && appStoreTemplates.length > 0,
      selfOwnedTemplates && selfOwnedTemplates.length > 0,
    ],
    [appStoreTemplates, selfOwnedTemplates],
  );

  const renderTemplate = useCallback(
    (template: APP, selected: boolean) => (
      <Grid
        item
        key={template?.name}
        md={6}
        sx={theme => ({
          ...(selected
            ? {
                border: `1px solid ${theme.colors.functional.text.link}`,
              }
            : {
                borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
                borderRight: `1px solid ${theme.colors.functional.subject.border}`,
              }),
        })}
        xs={12}
      >
        <TemplateCard
          onClick={() => {
            if (selected) {
              return form.setValue('template', undefined);
            }

            form.setValue('template', String(template?.name));
            onSelectTemplate(template);
          }}
          selected={selected}
          template={template}
        />
      </Grid>
    ),
    [form, onSelectTemplate],
  );
  const renderTemplatesList = useCallback(
    (templates: APP[] | undefined) =>
      templates?.map(template => renderTemplate(template, selected === template?.name)),
    [renderTemplate, selected],
  );

  return (
    <FormLabel id="step_template" optionTitle="Select a template">
      <Stack
        spacing={3}
        sx={theme => ({
          ...(error && {
            border: `1px solid ${theme.colors.schema.error}`,
          }),
          '& > .MuiStack-root': {
            gap: 2,
          },
          '& > * > .MuiGrid-root': {
            borderTop: `1px solid ${theme.colors.functional.subject.border}`,
            borderLeft: `1px solid ${theme.colors.functional.subject.border}`,
          },
        })}
      >
        <Stack>
          {hasAppStoreTemplates && hasSelfOwnedTemplates && (
            <Typography variant="bodySmallM">Built-in templates</Typography>
          )}

          <Grid container>
            {isPending ? (
              Array.from({ length: 6 }).map((_, idx) => (
                <Grid item key={idx.toString()} xs={6}>
                  <Skeleton sx={{ minHeight: '150px' }} variant="rectangular" />
                </Grid>
              ))
            ) : (
              <>
                {renderTemplatesList(defaultTemplates)}
                {customTemplate &&
                  renderTemplate(customTemplate, selected === String(customTemplate?.type))}
              </>
            )}
          </Grid>
        </Stack>

        {hasAppStoreTemplates && (
          <Stack>
            <Typography variant="bodySmallM">Community templates</Typography>
            <Grid container>{renderTemplatesList(appStoreTemplates)}</Grid>
          </Stack>
        )}

        {hasSelfOwnedTemplates && (
          <Stack>
            <Typography variant="bodySmallM">Your templates</Typography>
            <Grid container>{renderTemplatesList(selfOwnedTemplates)}</Grid>
          </Stack>
        )}
      </Stack>
      <ErrorCaption error={!!error} message={error?.message} />
    </FormLabel>
  );
};

const getFilteredTemplates = (templates: APP[] | undefined) =>
  templates
    ?.filter(template => String(template?.type) !== aAATypeToJSON(AAAType.AAATYPE_CUSTOM))
    .filter(
      template =>
        String(template.status) === aAAAppStatusToJSON(AAAAppStatus.AAA_APP_STATUS_PUBLISHED),
    )
    .sort((a, b) => String(a.type).localeCompare(String(b.type)));
