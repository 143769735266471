import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { IconChevronLeft } from 'components/icons/IconChevronLeft';
import { useRaasDeployment } from 'hooks';
import { CurrentPlanSection } from 'pages/raas/DeploymentDetails/views/OverviewView/CurrentPlanSection';
import { AssetsSection } from 'pages/raas/DeploymentDetails/views/RollupInfoView/AssetsSection';
import { DaDetailsSection } from 'pages/raas/DeploymentDetails/views/RollupInfoView/DaDetailsSection';
import { GeneralSection } from 'pages/raas/DeploymentDetails/views/RollupInfoView/GeneralSection';
import { MetadataSection } from 'pages/raas/DeploymentDetails/views/RollupInfoView/MetadataSection';
import { VersionSection } from 'pages/raas/DeploymentDetails/views/RollupInfoView/VersionSection';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PATHS } from 'routes';

export const RaasAdminDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data: rollup, isPending } = useRaasDeployment({ rollupId: params?.rollupId });

  const onBack = useCallback(() => navigate(PATHS.ADMIN_RAAS), [navigate]);

  return (
    <Box sx={{ background: '#fafafa', mb: '148px' }}>
      <Grid
        container
        sx={{
          px: '252px',
          py: '40px',
        }}
      >
        <Grid item xs={12}>
          <GeneralSection loading={isPending} rollup={rollup} />
        </Grid>
        <Grid item xs={12}>
          <AssetsSection loading={isPending} rollup={rollup} />
        </Grid>
        <Grid item xs={12}>
          <MetadataSection loading={isPending} rollup={rollup} />
        </Grid>
        <Grid item xs={12}>
          <VersionSection loading={isPending} rollup={rollup} />
        </Grid>
        <Grid item xs={12}>
          <DaDetailsSection loading={isPending} rollup={rollup} />
        </Grid>
        <Grid
          item
          sx={{
            borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
          }}
          xs={12}
        >
          <CurrentPlanSection />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="end">
            <Button
              onClick={onBack}
              sx={{
                background: '#fff',
                my: '40px',
                border: '1px solid #20272314',
                minHeight: '68px',
                color: theme => theme.colors.functional.text.primary,
                '&:hover': {
                  background: '#fff',
                  color: theme => theme.colors.functional.text.primary,
                },
              }}
            >
              <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
                <IconChevronLeft sx={{ color: 'transparent' }} />
                <Typography>Return</Typography>
              </Stack>
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
