import { useRaasDeployment } from 'hooks';
import { SectionButtonTimeFilter } from 'pages/raas/DeploymentDetails/components/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { L2FinalizedMetric } from './L2FinalizedMetric';
import { L2SafeMetric } from './L2SafeMetric';
import { L2UnsafeMetric } from './L2UnsafeMetric';

export const L2Section = () => {
  const { isPending } = useRaasDeployment();

  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <L2UnsafeMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <L2SafeMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <L2FinalizedMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <></>
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter loading={isPending} variant={TimeFilterVariant.L2_NODES_SECTION} />
      }
      sectionContentSx={{ p: 0 }}
      title="Nodes"
    />
  );
};
