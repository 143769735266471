import { AdminGuard } from 'guards';
import { Outlet, RouteObject } from 'react-router-dom';

import { routes as adminRaasRoutes } from './RaasAdmin/routes';
import ManageAgentRequests from './ManageAgentRequests';
import ManagePromoCodes from './ManagePromoCodes';
import { RaasAdmin } from './RaasAdmin';

export enum ADMIN_PATHS {
  ADMIN_RAAS = '/admin/raas',
  MANAGE_PROMO_CODES = '/admin/promo-codes',
  MANAGE_AGENT_REQUESTS = '/admin/publish-agent-requests',
}

export const routes: RouteObject[] = [
  {
    path: '/admin',
    element: (
      <AdminGuard>
        <Outlet />
      </AdminGuard>
    ),
    children: [
      {
        path: 'raas',
        element: <RaasAdmin />,
        children: [...adminRaasRoutes],
      },
      {
        element: <ManagePromoCodes />,
        path: 'promo-codes',
      },
      {
        element: <ManageAgentRequests />,
        path: 'publish-agent-requests',
      },
    ],
  },
];
