import { Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { FONT_FAMILY } from 'constants/font';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as RLineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { DataKey } from 'shared/components/charts';
import { useLinearGradients } from 'shared/components/charts/useLinearGradients';

export interface IBiAxisLineChart extends Omit<CategoricalChartProps, 'data'> {
  dataKeys?: DataKey[];
  data?: any[] | undefined;
  mock?: boolean;
}

export const BiAxisLineChart = ({ data, dataKeys, mock, ...props }: IBiAxisLineChart) => {
  const theme = useTheme();
  const linearGradients = useLinearGradients();

  return (
    <RLineChart
      data={!data || mock ? defaultData : data}
      margin={{ top: 24, left: 0, right: 24 }}
      {...props}
    >
      <defs>
        {linearGradients.map(linearGradient => (
          <linearGradient
            id={linearGradient.id}
            key={linearGradient.id}
            x1="0"
            x2="1"
            y1="0"
            y2="0"
          >
            {linearGradient.stops.map(({ offset, stopColor, stopOpacity }) => (
              <stop
                key={stopColor}
                offset={offset}
                stopColor={stopColor}
                stopOpacity={stopOpacity}
              />
            ))}
          </linearGradient>
        ))}
      </defs>

      <CartesianGrid
        strokeDasharray="0 0"
        strokeOpacity={0.25}
        strokeWidth={'1px'}
        vertical={false}
      />
      <XAxis
        dataKey="name"
        fontFamily={FONT_FAMILY}
        fontSize={'13px'}
        fontWeight={400}
        stroke={'rgba(32, 39, 35, 0.25)'}
        strokeWidth={'1px'}
        style={{ lineHeight: '18px' }}
        type="category"
      />

      <YAxis
        axisLine={false}
        fontFamily={FONT_FAMILY}
        fontSize={'13px'}
        fontWeight={400}
        orientation="left"
        stroke={'rgba(32, 39, 35, 0.25)'}
        strokeWidth={'1px'}
        style={{ lineHeight: '18px' }}
        tickFormatter={value => {
          switch (typeof value) {
            case 'number':
              if (Number.isFinite(value) && !Number.isInteger(value)) {
                return `${(value * 100).toFixed()}%`;
              }

              return new Intl.NumberFormat().format(value);
            default:
              return value;
          }
        }}
        yAxisId={'left'}
      />
      <YAxis
        axisLine={false}
        fontFamily={FONT_FAMILY}
        fontSize={'13px'}
        fontWeight={400}
        orientation="right"
        stroke={'rgba(32, 39, 35, 0.25)'}
        strokeWidth={'1px'}
        style={{ lineHeight: '18px' }}
        tickFormatter={value =>
          typeof value === 'number' ? new Intl.NumberFormat().format(value) : value
        }
        yAxisId={'right'}
      />

      <Tooltip
        content={({ ...props }) => {
          return (
            <Stack
              spacing="4px"
              sx={_ => ({
                boxShadow: '0px 2px 4px 0px rgba(32, 39, 35, 0.16)',
                background: '#fff',
                minWidth: '172px',
                minHeight: '78px',
                px: '9px',
                py: '13px',
              })}
            >
              <Typography variant="caption">{props.label}</Typography>

              {props.payload?.map(payload => (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  key={payload.name}
                  spacing="16px"
                >
                  <Stack alignItems="center" direction="row" spacing="4px">
                    <SvgIcon sx={{ height: '8px', width: '8px' }} viewBox="0 0 8 8">
                      <rect fill={payload.stroke} height="8" width="8" />
                    </SvgIcon>

                    <Typography variant="caption">{payload.name}</Typography>
                  </Stack>
                  <Typography variant="captionM">
                    {new Intl.NumberFormat().format(+String(payload.value ?? '0'))}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          );
        }}
        formatter={value => {
          if (typeof value === 'number') {
            return new Intl.NumberFormat().format(value);
          }

          return value;
        }}
        itemStyle={{
          minWidth: '172px',
          color: theme.colors.functional.text.primary,
          justifyContent: 'space-between',
        }}
      />
      <Legend
        color={theme.colors.functional.text.primary}
        content={({ payload }) => (
          <Stack direction="row" spacing="16px" sx={{ mt: '16px', ml: '40px' }}>
            {payload?.map((v, index) => (
              <Stack
                alignItems="center"
                direction="row"
                key={v.dataKey?.toString() ?? index.toString()}
                spacing="4px"
              >
                <SvgIcon sx={{ height: '8px', width: '8px' }} viewBox="0 0 8 8">
                  <rect fill={v?.color} height="8" width="8" />
                </SvgIcon>
                <Typography variant="caption">{v.dataKey?.toString()}</Typography>
              </Stack>
            ))}
          </Stack>
        )}
        formatter={value => {
          if (typeof value === 'number') {
            return new Intl.NumberFormat().format(value);
          }

          return value;
        }}
        iconSize={8}
        iconType="square"
        margin={{ left: 40, top: 20, bottom: 0 }}
        verticalAlign="bottom"
      />
      {(!dataKeys && mock ? defaultDataKeys : dataKeys ?? []).map(({ color, name, yAxisId }) => (
        <Line
          dataKey={name}
          dot={false}
          key={name}
          stroke={color}
          strokeWidth={'1.4px'}
          type="monotone"
          yAxisId={yAxisId}
        />
      ))}
    </RLineChart>
  );
};

enum DataLabel {
  SYSTEM = 'system',
  IO_WAIT = 'iowait',
  GETH = 'geth',
  GOROUTINES = 'goroutines',
  THREADS = 'threads',
}

const defaultData = [
  {
    name: '0:00',
    [DataLabel.SYSTEM]: 0.15,
    [DataLabel.IO_WAIT]: 30,
    [DataLabel.GETH]: 101,
    [DataLabel.GOROUTINES]: 20,
    [DataLabel.THREADS]: 151,
  },
  {
    name: '01:00',
    [DataLabel.SYSTEM]: 0.2,
    [DataLabel.IO_WAIT]: 35,
    [DataLabel.GETH]: 101,
    [DataLabel.GOROUTINES]: 20,
    [DataLabel.THREADS]: 152,
  },
  {
    name: '02:00',
    [DataLabel.SYSTEM]: 0.25,
    [DataLabel.IO_WAIT]: 50,
    [DataLabel.GETH]: 101,
    [DataLabel.GOROUTINES]: 20,
    [DataLabel.THREADS]: 155,
  },
  {
    name: '03:00',
    [DataLabel.SYSTEM]: 0.3,
    [DataLabel.IO_WAIT]: 45,
    [DataLabel.GETH]: 101,
    [DataLabel.GOROUTINES]: 20,
    [DataLabel.THREADS]: 175,
  },
  {
    name: '04:00',
    [DataLabel.SYSTEM]: 0.3,
    [DataLabel.IO_WAIT]: 55,
    [DataLabel.GETH]: 101,
    [DataLabel.GOROUTINES]: 20,
    [DataLabel.THREADS]: 160,
  },
  {
    name: '05:00',
    [DataLabel.SYSTEM]: 0.32,
    [DataLabel.IO_WAIT]: 58,
    [DataLabel.GETH]: 101,
    [DataLabel.GOROUTINES]: 20,
    [DataLabel.THREADS]: 165,
  },
  {
    name: '06:00',
    [DataLabel.SYSTEM]: 0.35,
    [DataLabel.IO_WAIT]: 70,
    [DataLabel.GETH]: 101,
    [DataLabel.GOROUTINES]: 20,
    [DataLabel.THREADS]: 144,
  },
  {
    name: '07:00',
    [DataLabel.SYSTEM]: 0.5,
    [DataLabel.IO_WAIT]: 80,
    [DataLabel.GETH]: 101,
    [DataLabel.GOROUTINES]: 20,
    [DataLabel.THREADS]: 130,
  },
];

const defaultDataKeys = [
  {
    name: DataLabel.SYSTEM,
    color: '#5C8CE1',
    yAxisId: 'left',
  },
  {
    name: DataLabel.IO_WAIT,
    color: '#66B489',
    yAxisId: 'right',
  },
  {
    name: DataLabel.GETH,
    color: '#F8D97D',
    yAxisId: 'right',
  },
  {
    name: DataLabel.GOROUTINES,
    color: '#A281FF',
    yAxisId: 'right',
  },
  {
    name: DataLabel.THREADS,
    color: '#F1605F',
    yAxisId: 'right',
  },
];
