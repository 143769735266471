import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { bff } from 'api';
import {
  WithdrawFeeRollupRegisteredReply,
  WithdrawFeeRollupRegisteredRequest,
} from 'types/protoc-gen/raas-rollup';
import { QUERY_KEYS } from 'types/react-query';

interface IUseWFRegistered
  extends Omit<UseQueryOptions<boolean, unknown, boolean>, 'queryKey' | 'queryFn'> {
  params: WithdrawFeeRollupRegisteredRequest;
}

export const useWFRegistered = (opts?: IUseWFRegistered) =>
  useQuery<boolean, unknown, boolean>({
    queryKey: [QUERY_KEYS.RAAS_WITHDRAW_FEE_REGISTERED],
    queryFn: async () => {
      const response = await bff.get<WithdrawFeeRollupRegisteredReply>(
        '/bff/opstack/withdrawfee/registered',
        {
          params: opts?.params,
        },
      );

      return response.data.registered;
    },
    enabled: !!opts?.params.opstackId,
    ...opts,
  });
