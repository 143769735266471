import { useRaasMetrics } from 'hooks';
import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'types/protoc-gen/raas-metrics';
import { formatAbbreviatedNumber } from 'utils/strings';

export interface ITxPoolMetric {
  timeFilterVariant?: TimeFilterVariant;
}

export const TxPoolMetric = ({ timeFilterVariant }: ITxPoolMetric) => {
  const { rollupId } = useParams();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step, timeframe } = useTimeFilterOptions(
    selectedTimeFilter[timeFilterVariant ?? TimeFilterVariant.OVERVIEW_METRICS_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    rollupId,
    panel: Panel.PANEL_OPSTACK_TX_POOL,
    timeframe,
    start,
    end,
    step,
  });

  return (
    <Metric
      chartType="line"
      data={result}
      description={
        'Transaction pool statistics, including the number of local, pending, and queued transactions over time'
      }
      hideLegend={false}
      hideTooltipKeys
      id={'tx-pool'}
      legendSpacing={'8px'}
      loading={isPending}
      title={'Tx pool'}
      yAxisProps={{
        tickFormatter: value => formatAbbreviatedNumber(value),
      }}
    />
  );
};
