import { Grid, Stack } from '@mui/material';
import { hideFeature } from 'helpers/visibility';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { RpmByMethodsMetric, RpmByMethodsTableMetric, RpmCountMetric, RpmMetric } from './metrics';

export const RpcUsageMonitoringSection = () => {
  return (
    <Section title="RPC Usage Monitoring">
      <Stack spacing="24px" sx={{ width: '100%' }}>
        <Grid container>
          {
            // TODO: remove hideFeature when backend is ready for integration
            !hideFeature && (
              <Grid xs={6}>
                <RpmCountMetric />
              </Grid>
            )
          }

          <MetricGridItem item xs={6}>
            <RpmMetric />
          </MetricGridItem>

          <MetricGridItem item xs={6}>
            <RpmByMethodsMetric />
          </MetricGridItem>

          {
            // TODO: remove hideFeature when backend is ready for integration
            !hideFeature && (
              <Grid item xs={12}>
                <RpmByMethodsTableMetric />
              </Grid>
            )
          }
        </Grid>
      </Stack>
    </Section>
  );
};
