import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { NumericStepper, NumericStepperProps } from 'shared/components/NumericStepper';

import { FormTextField, IFormTextField } from './FormTextField';

interface IFormNumericTextField extends IFormTextField {
  numericStepperProps?: Partial<NumericStepperProps>;
  endAdornment?: React.ReactNode;
}

export const FormNumericTextField = ({
  endAdornment,
  fieldConfig,
  numericStepperProps,
  ...textFieldProps
}: IFormNumericTextField) => {
  const { control, setValue } = useFormContext();
  const fieldValue = useWatch({ control, name: fieldConfig.name });
  const setFieldValue = (newValue: string) =>
    setValue(fieldConfig.name, newValue, { shouldTouch: true, shouldDirty: true });

  return (
    <FormTextField
      InputProps={{
        type: 'text',
        endAdornment: (
          <>
            {endAdornment}
            <NumericStepper setValue={setFieldValue} value={fieldValue} {...numericStepperProps} />
          </>
        ),
      }}
      fieldConfig={fieldConfig}
      type="number"
      {...textFieldProps}
    />
  );
};
