import { PERMISSIONS, useAuthContext } from 'contexts/AuthContext';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { PATHS } from 'routes';

export const AdminGuard = ({ children }: PropsWithChildren) => {
  const { user } = useAuthContext();
  const isAdmin = user?.permissions?.includes(PERMISSIONS.WRITE_PROMO_CODE);

  if (!isAdmin) {
    return <Navigate to={PATHS.DEFAULT} />;
  }

  return <>{children}</>;
};
