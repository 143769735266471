import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { PageBannerVariant } from 'components/Layout/PageBanner';
import { AaasLayout } from 'layouts/AaasLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { FormTextField } from 'shared/components/form';
import { SectionTitle } from 'shared/components/SectionTitle';
import * as yup from 'yup';

import EnvVarsFieldArray from './EnvVarsFieldArray';
import { PublishAgentSummaryPanel } from './PublishAgentSummaryPanel';
import { FormValues } from './types';
import usePublishAgent from './usePublishAgent';
import { validationSchema } from './validation';

export const PublishCommunityAgent = () => {
  const navigate = useNavigate();
  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      imageName: '',
      imageDesc: '',
      imageId: '',
      agentLogo: '',
      livenessProbePath: '',
      githubUrl: '',
    },
    resolver: yupResolver(validationSchema as yup.ObjectSchema<FormValues>),
  });

  const { isPending: isPendingPublish, publish } = usePublishAgent();

  return (
    <>
      <AaasLayout
        pageBannerProps={{
          onClickBack: () => navigate(PATHS.AAAS_DEPLOYMENTS, { replace: true }),
          title: 'List your agent',
          variant: PageBannerVariant.AAAS,
        }}
      >
        <Box bgcolor="#fafafa" pb={4} sx={{ position: 'relative' }}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(publish, err => console.error('err:', err))}>
              <Box margin="0 auto">
                <Stack flexDirection="row">
                  <Box flex={1}>
                    <SectionTitle>Agent image metadata</SectionTitle>
                    <FormTextField
                      fieldConfig={{
                        name: 'imageName',
                        label: 'Name',
                        placeholder: 'agent-ottie-007',
                      }}
                      id="step_imageName"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'imageDesc',
                        label: 'Description',
                        placeholder:
                          'A sophisticated furry chat agent specializing in handling mission-critical conversations.',
                      }}
                      id="step_imageDesc"
                      maxRows={10}
                      minRows={3}
                      multiline
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'imageId',
                        label: 'Docker Image ID',
                        placeholder: 'dockerhub_org_name/image_name:tag',
                      }}
                      id="step_imageId"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'agentLogo',
                        label: 'Agent logo',
                        placeholder: 'https://example.com/ottie007.png',
                      }}
                      id="step_agentLogo"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'livenessProbePath',
                        label: 'Liveness probe path',
                        placeholder: '/poke',
                      }}
                      id="step_livenessProbePath"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'githubUrl',
                        label: 'Github URL (Optional)',
                        placeholder: 'https://github.com/alt-research',
                      }}
                      id="step_githubUrl"
                    />
                    <EnvVarsFieldArray />
                  </Box>
                  <Box width="30%">
                    <PublishAgentSummaryPanel isSubmitting={isPendingPublish} />
                  </Box>
                </Stack>
              </Box>
              <DevTool control={form.control} />
            </form>
          </FormProvider>
        </Box>
      </AaasLayout>
    </>
  );
};
