import { envVarHelpMarkdown } from 'pages/aaas/NewAgentDeployment/constants';
import { ReactNode } from 'react';
import { FormLabelMdTooltip } from 'shared/components/form';

export const envVarLabelMap: Record<string, ReactNode> = {
  OPENAIKEY: (
    <FormLabelMdTooltip title="OPENAI API KEY" tooltipMarkdown={envVarHelpMarkdown.OPENAI} />
  ),
  OPENAI_API_KEY: (
    <FormLabelMdTooltip title="OPENAI API KEY" tooltipMarkdown={envVarHelpMarkdown.OPENAI} />
  ),
  CDP_API_KEY_NAME: (
    <FormLabelMdTooltip title="CDP API KEY NAME" tooltipMarkdown={envVarHelpMarkdown.CDP} />
  ),
  CDP_API_KEY_PRIVATE_KEY: (
    <FormLabelMdTooltip title="CDP API KEY PRIVATE KEY" tooltipMarkdown={envVarHelpMarkdown.CDP} />
  ),
  CDP_PRIVATE_KEY: (
    <FormLabelMdTooltip title="CDP PRIVATE KEY" tooltipMarkdown={envVarHelpMarkdown.CDP} />
  ),
  POST_INTERVAL_MIN: 'MIN POST INTERVAL (MINUTES)',
  POST_INTERVAL_MAX: 'MAX POST INTERVAL (MINUTES)',
  TWITTER_PASSWORD: (
    <FormLabelMdTooltip
      title="TWITTER/X PASSWORD"
      tooltipMarkdown="2FA should be disabled for login to be success"
    />
  ),
  TWITTER_EMAIL: 'TWITTER/X EMAIL',
  TWITTER_USERNAME: 'TWITTER/X USERNAME',
};
