import {
  Button,
  Dialog,
  DialogProps,
  Link,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import IconLink from 'components/icons/IconLink';
import Web3LoadingButton from 'components/Web3/Web3LoadingButton';
import RegistryCoordinatorAbi from 'contracts/RegistryCoordinatorAbi';
import { useAvsRoles } from 'hooks/useAvsRoles';
// import StakeRegistryAbi from 'contracts/StakeRegistryAbi';
import { useWriteTx } from 'hooks/useWriteTx';
import { avsStrategyLabels } from 'labels/deployments';
import { ReactNode, useMemo, useState } from 'react';
import CustomStepIcon from 'shared/components/CustomStepIcon';
import { AVS_QUORUMS, strategiesByQuorums } from 'types/avs';
import { AVSDeployment } from 'types/protoc-gen/avs';
import { truncate } from 'utils/strings';
import { Address, parseEther } from 'viem';
import { useAccount } from 'wagmi';

const multiplier = parseEther('1');

interface WizardStep {
  title: ReactNode;
  description?: ReactNode;
  content?: ReactNode;
  actionBar: ReactNode;
}

export default function EditStrategiesWizard({
  avs,
  onClose,
  quorumChangeset,
  ...props
}: DialogProps & {
  avs: AVSDeployment;
  quorumChangeset: { addedQuorums: AVS_QUORUMS[]; removedQuorums: AVS_QUORUMS[] };
}) {
  const { chain } = useAccount();
  const { svcMngrOwner } = useAvsRoles(avs);
  const [activeStep, setActiveStep] = useState(0);
  const [txHashByQuorum, setTxHashByQuorum] = useState({} as Record<AVS_QUORUMS, `0x${string}`>);
  const activeStepQuorumMap = [
    quorumChangeset?.addedQuorums,
    quorumChangeset?.removedQuorums,
  ]?.flat();
  const activeStepQuorum = activeStepQuorumMap[activeStep];

  console.debug('quorumChangeset: ', quorumChangeset);

  const strategyParamsByQuorum = useMemo(() => {
    return quorumChangeset?.addedQuorums?.reduce(
      (acc, quorum) => ({
        ...acc,
        [quorum]: strategiesByQuorums[quorum]?.map(token => ({ strategy: token, multiplier })),
      }),
      {} as Record<AVS_QUORUMS, { strategy: Address; multiplier: bigint }[]>,
    );
  }, [quorumChangeset]);

  const { isPending: isAddingStrategies, write: addStrategies } = useWriteTx({
    contractAddress: avs?.registryCoordinatorProxy as Address,
    contractAbi: RegistryCoordinatorAbi,
    functionName: 'createQuorum',
    functionArgs: [
      {
        maxOperatorCount: 50,
        kickBIPsOfOperatorStake: 11000,
        kickBIPsOfTotalStake: 1001,
      },
      0n,
      strategyParamsByQuorum?.[activeStepQuorum],
    ],
    txKey: `addStrategies_${avs?.stakeRegistryProxy}`,
    onTxConfirmed: txHash => {
      setTxHashByQuorum(prev => ({ ...prev, [activeStepQuorum]: txHash }));
      setActiveStep(activeStep + 1);
    },
  });

  const steps: WizardStep[] = useMemo(() => {
    const _steps: WizardStep[] = [] as any;
    const stepAddStrategiesForQuorum = (quorum: AVS_QUORUMS) => ({
      title: <Typography>Add {avsStrategyLabels?.[quorum]} strategies</Typography>,
      description: txHashByQuorum?.[quorum] ? (
        <Link
          href={`${chain?.blockExplorers?.default?.url}/tx/${txHashByQuorum?.[quorum]}`}
          rel="noopener noreferrer"
          sx={{ textDecoration: 'underline', display: 'flex', alignItems: 'center' }}
          target="_blank"
        >
          Txn hash: {truncate(txHashByQuorum?.[quorum], undefined, 5, 3)}
          <IconLink sx={{ ml: 1, width: '14px', height: '14px' }} />
        </Link>
      ) : (
        ''
      ),
      actionBar: (
        <Web3LoadingButton
          loading={isAddingStrategies}
          onClick={addStrategies}
          requiredSender={svcMngrOwner}
          variant="contained"
        >
          Add
        </Web3LoadingButton>
      ),
    });
    // const stepRemoveStrategies = {
    //   title: <Typography>Remove strategies</Typography>,
    //   description: removeStratTxHash ? (
    //     <Link
    //       href={`${chain?.blockExplorers?.default?.url}/tx/${removeStratTxHash}`}
    //       rel="noopener noreferrer"
    //       sx={{ textDecoration: 'underline', display: 'flex', alignItems: 'center' }}
    //       target="_blank"
    //     >
    //       Txn hash: {truncate(removeStratTxHash, undefined, 5, 3)}
    //       <IconLink sx={{ ml: 1, width: '14px', height: '14px' }} />
    //     </Link>
    //   ) : (
    //     ''
    //   ),
    //   actionBar: (
    //     <Web3LoadingButton
    //       loading={isRemovingStrategies}
    //       onClick={removeStrategies}
    //       variant="contained"
    //     >
    //       Remove
    //     </Web3LoadingButton>
    //   ),
    // };

    quorumChangeset?.addedQuorums?.forEach(quorum => {
      _steps?.push(stepAddStrategiesForQuorum(quorum));
    });

    // if (quorumChangeset?.removedQuorums?.length > 0) {
    //   steps?.push(stepRemoveStrategies);
    // }

    return _steps;
  }, [
    addStrategies,
    chain?.blockExplorers?.default?.url,
    isAddingStrategies,
    quorumChangeset?.addedQuorums,
    svcMngrOwner,
    txHashByQuorum,
  ]);

  return (
    <Dialog disablePortal onClose={onClose} sx={{ '& .MuiPaper-root': { p: 5 } }} {...props}>
      <Typography variant="h5">Add Selected Strategies</Typography>
      <Stepper activeStep={activeStep} connector={null} orientation="vertical">
        {steps.map((step, index) => (
          <Step
            key={index}
            sx={
              index !== steps?.length - 1
                ? {
                    position: 'relative',
                    '& .MuiStepLabel-iconContainer:after': {
                      content: "' '",
                      height: 'calc(100% - 24px)',
                      borderRight: '1px solid black',
                      position: 'absolute',
                      left: '11px',
                      top: '32px',
                      zIndex: 0,
                    },
                  }
                : {}
            }
          >
            <StepLabel StepIconComponent={CustomStepIcon} sx={{ alignItems: 'flex-start' }}>
              <Typography mt={0.25}>{step.title}</Typography> {step.description}
            </StepLabel>
            <StepContent>
              <Typography>{step.content}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Stack alignItems="center" justifyContent="center" mt={2} width="100%">
        {activeStep >= steps?.length ? (
          <Stack gap={2}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#66B489',
                fontWeight: 500,
              }}
            >
              🎉&nbsp;Successfully updated strategies
            </Typography>
            <Button
              onClick={() => {
                onClose?.({}, 'escapeKeyDown');
              }}
              variant="contained"
            >
              Close
            </Button>
          </Stack>
        ) : (
          steps?.[activeStep]?.actionBar
        )}
      </Stack>
    </Dialog>
  );
}
