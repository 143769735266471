import { Box, CardMedia, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

export default function CarouselItem({
  index,
  url,
}: PropsWithChildren<{
  url: string;
  index: number;
}>) {
  return (
    <Box
      className={`carousel-item`}
      data-index={index}
      sx={{
        flexShrink: 0,
        width: '100%',
        scrollSnapAlign: 'center',
      }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
        <CardMedia
          alt={`carousel image ${index}`}
          component="img"
          image={url}
          sx={{
            width: '100%',
            aspectRatio: { sm: '8 / 3', xs: '36 / 50' },
            maxWidth: 'unset',
          }}
        />
      </Stack>
    </Box>
  );
}
