import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useBlockscoutAxios } from 'hooks/raas/useBlockscoutAxios';
import { useParams } from 'react-router-dom';
import { QUERY_KEYS } from 'types/react-query';

export interface IUseBlockscoutStats
  extends Omit<UseQueryOptions<BlockscoutStatsResponse>, 'queryFn' | 'queryKey'> {}

export const useBlockscoutStats = (queryOptions?: IUseBlockscoutStats) => {
  const { rollupId } = useParams();
  const blockscout = useBlockscoutAxios({ v2: true });

  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_BLOCKSCOUT_STATS,
      {
        variant: 'stats',
        rollupId,
      },
    ],
    queryFn: async (): Promise<BlockscoutStatsResponse> => {
      const res = await blockscout.l2.get<BlockscoutStatsResponse>(`/stats`);

      return res.data;
    },
    enabled: !!blockscout?.rollup,
    gcTime: 60 * 30 * 1000,
    ...queryOptions,
  });
};

export interface BlockscoutStatsResponse {
  average_block_time: number;
  coin_image: string;
  coin_price: string;
  coin_price_change_percentage: number;
  gas_price_updated_at: string;
  gas_prices: {
    slow: number;
    average: number;
    fast: number;
  };
  gas_prices_update_in: number;
  gas_used_today: string;
  last_output_root_size: string;
  market_cap: string;
  network_utilization_percentage: number;
  secondary_coin_image: string | null;
  secondary_coin_price: string | null;
  static_gas_price: string | null;
  total_addresses: string;
  total_blocks: string;
  total_gas_used: string;
  total_transactions: string;
  transactions_today: string;
  tvl: string | null;
}
