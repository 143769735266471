import { useQuery } from '@tanstack/react-query';
import { raas } from 'api';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import useSubmitAaaRequest from 'pages/aaas/AaasDeployments/components/UserAgentStore/useSubmitAaaRequest';
import { useMemo } from 'react';
import { ListAAAAppTypeReply } from 'types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'types/react-query';

export default function useManageAgentRequests({
  onReviewSuccess,
}: {
  onReviewSuccess?: () => void;
}) {
  const { addAlert } = useAlerts();

  const { data: pendingRequests, isPending: isLoadingRequests } = useQuery({
    queryKey: [QUERY_KEYS.AAAS_PENDING_AGENT_REQUESTS],
    queryFn: async () => {
      const res = await raas.get<ListAAAAppTypeReply>('/bff/apps?isPending=true');

      return res?.data?.appStore;
    },
  });

  const { isSubmittingRequest: isSubmittingReview, submitRequest: reviewRequest } =
    useSubmitAaaRequest({
      onSuccess: () => {
        addAlert({
          severity: ALERT_SEVERITY.SUCCESS,
          title: 'Request has been updated.',
        });
        onReviewSuccess?.();
      },
    });

  return useMemo(
    () => ({
      pendingRequests,
      isLoadingRequests,
      reviewRequest,
      isSubmittingReview,
    }),
    [pendingRequests, isLoadingRequests, reviewRequest, isSubmittingReview],
  );
}
