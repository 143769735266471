import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogProps, Stack, SxProps, Typography } from '@mui/material';
import IconInfo from 'components/icons/IconInfo';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { ReactNode, useCallback, useEffect } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import {
  FormNumericTextField,
  FormPasswordTextField,
  FormTextField,
  IFormTextField,
} from 'shared/components/form';
import { AAAType, aAATypeToJSON, APP } from 'types/protoc-gen/bffaaa';
import * as yup from 'yup';

import {
  ENV_VARS_FORM_MULTILINE_FIELDS,
  ENV_VARS_FORM_NON_SENSITIVE_FIELDS,
  ENV_VARS_FORM_NUMERIC_FIELDS,
} from './constants';
import { envVarLabelMap } from './labels';
import { EnvVarsFormValues } from './types';
import { getEnvVarValidationSchema } from './validation';

const bodyProps: SxProps = { maxHeight: '60vh', overflow: 'auto' };

type ITextFieldComponent = (props: IFormTextField) => ReactNode;

interface IEnvVarsDialog extends DialogProps {
  template: APP | undefined;
  onConfirmed: (envVars: EnvVarsFormValues) => void;
}

export const EnvVarsDialog: React.FC<IEnvVarsDialog> = ({
  onClose,
  onConfirmed,
  open,
  template,
  ...props
}) => {
  const { addAlert } = useAlerts();

  const form = useForm<EnvVarsFormValues>({
    mode: 'onChange',
    resolver: yupResolver(
      getEnvVarValidationSchema(template?.envList ?? []) as yup.ObjectSchema<EnvVarsFormValues>,
    ),
  });

  const handleClose = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

  const handleConfirmed = useCallback(
    (formValues: EnvVarsFormValues) => {
      onConfirmed(formValues);
      form.reset();
      addAlert({
        severity: ALERT_SEVERITY.INFO,
        title: 'Env variables set',
        desc: 'Environment variables are set. Ready for deployment!',
      });
      handleClose();
    },
    [addAlert, form, handleClose, onConfirmed],
  );

  const onSubmit: SubmitHandler<EnvVarsFormValues> = useCallback(
    formValues => {
      handleConfirmed(formValues);
    },
    [handleConfirmed],
  );

  const onSubmitError: SubmitErrorHandler<EnvVarsFormValues> = useCallback(
    err => {
      console.error(err);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to set env variables',
        desc: 'All fields are required. Please fill them out.',
      });
    },
    [addAlert],
  );

  const renderTextField = useCallback(
    (envVar: string) => {
      let TextFieldComponent: ITextFieldComponent;

      const isNonSensitive = ENV_VARS_FORM_NON_SENSITIVE_FIELDS.includes(envVar);
      const isMultiline = ENV_VARS_FORM_MULTILINE_FIELDS.includes(envVar);
      const isNumeric = ENV_VARS_FORM_NUMERIC_FIELDS.includes(envVar);
      const isError = !!form.formState.errors?.[envVar];

      if (isNumeric) {
        TextFieldComponent = FormNumericTextField;
      } else if (isMultiline) {
        TextFieldComponent = FormTextField;
      } else if (isNonSensitive) {
        TextFieldComponent = FormTextField;
      } else {
        TextFieldComponent = FormPasswordTextField;
      }

      return (
        <Stack spacing={2}>
          {envVarLabelMap[envVar] ? (
            typeof envVarLabelMap[envVar] === 'string' ? (
              <Typography variant="captionC">{envVarLabelMap[envVar]}</Typography>
            ) : (
              envVarLabelMap[envVar]
            )
          ) : (
            <Typography variant="captionC">{envVar}</Typography>
          )}

          <TextFieldComponent
            {...(form.formState.errors?.[envVar]?.message?.includes('top up') && {
              FormHelperTextProps: { sx: { '&&': { color: '#FFA756' } } },
            })}
            error={isError}
            fieldConfig={{
              name: envVar,
              placeholder: envVar.replaceAll('_', ' ').replace('TWITTER', 'X'),
            }}
            fullWidth
            helperText={isError ? form.formState.errors?.[envVar]?.message : undefined}
            hideLabel
            key={envVar}
            minRows={isMultiline ? 4 : undefined}
            multiline={isMultiline}
          />
        </Stack>
      );
    },
    [form],
  );

  useEffect(() => {
    form.reset();
  }, [template, form]);

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open} {...props}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, onSubmitError)}>
          <DialogContainer sx={{ minWidth: '750px' }}>
            <DialogHeader onClose={handleClose} title="Setup environmental variables" />

            <Stack spacing={3} sx={bodyProps}>
              {String(template?.type) === aAATypeToJSON(AAAType.AAATYPE_ELIZA) && (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    background: theme => theme.colors.gradients.cream,
                    border: theme => `1px solid ${theme.colors.functional.subject.border}`,
                    p: 3,
                  }}
                >
                  <IconInfo />

                  <Typography>For Eliza to work your Twitter/X 2FA has to be disabled.</Typography>
                </Stack>
              )}

              {template?.envList?.map(envVar => renderTextField(envVar))}
            </Stack>
            <Stack direction="row" spacing="24px">
              <Button fullWidth type="submit">
                <Typography>Confirm</Typography>
              </Button>
            </Stack>
          </DialogContainer>
        </form>
      </FormProvider>
    </Dialog>
  );
};
