import { useRaasMetrics } from 'hooks';
import { useParams } from 'react-router-dom';
import { Panel } from 'types/protoc-gen/raas-metrics';

import { getTimeFilterOpts } from './helpers';

export const useRpmByMethods = () => {
  const { rollupId } = useParams();
  const { end, start, step, template } = getTimeFilterOpts();

  return useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_RPM_BY_METHOD,
    refetchInterval: 10_000,
    timeframe: 'hour',
    rollupId,
    step,
    start,
    end,
    template,
  });
};
