// [Days, hours, minutes, seconds] format
export const msToDaysHrsMinsSeconds = (ms: number) => {
  const diffInSeconds = Math.floor(ms / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  const seconds = diffInSeconds % 60;
  const minutes = diffInMinutes % 60;
  const hours = diffInHours % 24;

  if (diffInDays < 0 || hours < 0 || minutes < 0 || seconds < 0) {
    return [0, 0, 0, 0];
  }

  return [diffInDays, hours, minutes, seconds];
};

export const monthDateFormat = (value: string | number) => {
  const date = new Date(value);

  return `${date.getMonth() + 1}/${date.getDate()}`;
};

/**
 * @description Change timezone from local to other time zones for functions accepting a Date object (Ignore timezone info subsequently)
 * @example if timezone is 3hrs ahead, 2022-12-21T00:00:00+08:00 -> 2022-12-21T03:00:00+08:00
 */
export const changeTimeZone = (date: string | Date, timeZone: string): Date => {
  let dateInNewTimeZone;

  if (typeof date === 'string') {
    dateInNewTimeZone = new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    );
  } else {
    dateInNewTimeZone = new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  return dateInNewTimeZone;
};

export const getTimeZoneDiffFromLocal = (timeZone: string): number => {
  const foreignTimeNow = changeTimeZone(new Date(), timeZone);
  const timeDiffMs = foreignTimeNow.getTime() - Date.now();
  const timeDiffMins = timeDiffMs / 1000 / 60;

  return Math.round(timeDiffMins);
};

/**
 * @param offsetMins number
 * @example offsetMins == -91 --> UTC -1:30
 * Rounds to nearest 15mins (There are 30min and 45min offset timezones)
 */
const getTimeZoneString = (offsetMins: number) => {
  const hours = new Intl.NumberFormat('en-US', {
    signDisplay: 'never',
  }).format(Math.round(offsetMins / 60));

  const minutes = new Intl.NumberFormat('en-US', {
    signDisplay: 'never',
  })
    .format((Math.round((offsetMins % 60) / 15) * 15) % 60)
    .padEnd(2, '0');

  return `${offsetMins < 0 ? '-' : '+'}${hours}:${minutes}`;
};

const timeZones: { offsetHrs: string; timeZone: string }[] = (() => {
  const supportedTimeZones = (Intl as any).supportedValuesOf?.('timeZone');

  console.log('supportedTimeZones: ', supportedTimeZones);
  const timeZones = supportedTimeZones.map((cur: string) => {
    const timeZoneDiffFromLocal = getTimeZoneDiffFromLocal(cur);
    const timeZoneUtcOffsetMins = -new Date().getTimezoneOffset() + timeZoneDiffFromLocal;

    return {
      timeZone: cur,
      offsetHrs: getTimeZoneString(timeZoneUtcOffsetMins),
    };
  });

  return timeZones;
})();

export const timeZoneOptions = timeZones
  .sort((a, b) => (a.timeZone > b.timeZone ? -1 : 1))
  .sort((a, b) => (parseInt(a.offsetHrs) > parseInt(b.offsetHrs) ? 1 : -1))
  .map(cur => ({
    label: `[GMT${cur.offsetHrs}] ${cur.timeZone}`,
    value: cur.timeZone,
  }));

// HH:mm
export const getTimeString = (date?: Date) => {
  if (!isValidDate(date)) {
    return '';
  }

  return `${String(date?.getHours()).padStart(2, '0')}:${String(date?.getMinutes()).padStart(
    2,
    '0',
  )}`;
};

// YYYY-MM-DD
export const getDateString = (date?: Date) =>
  date
    ? `${String(date?.getFullYear()).padStart(2, '0')}-${String(date?.getMonth() + 1).padStart(
        2,
        '0',
      )}-${String(date?.getDate()).padStart(2, '0')}`
    : '';

// Check for YYYY-MM-DD format
export const isValidDateFormat = (dateStr: string) => {
  if (!/\d{4}-\d{2}-\d{2}/.test(dateStr)) return false;

  return true;
};

// Check for invalid date object. E.g. new Date("asd") --> Invalid Date Object.
// This DOES NOT CHECK FOR INVALID DATES! E.g. new Date("0001-01-01") returns true
export const isValidDateObject = (date?: Date) => date && !isNaN(date?.getTime());

export const isValidDate = (dateInput?: Date | string) => {
  if (!dateInput) {
    return false;
  }

  let date: Date;

  if (typeof dateInput === 'string' && !isValidDateFormat(dateInput)) {
    return false;
  } else if (typeof dateInput === 'string') {
    date = new Date(dateInput);
  } else {
    date = dateInput;
  }

  if (!isValidDateObject(date)) {
    return false;
  }

  if (date?.getTime() < 0 || date?.getFullYear() > 3000) {
    // after 1 Jan 1970 UTC and be a reasonable date
    return false;
  }

  return true;
};
