import { Button, Stack, TextField, Typography } from '@mui/material';
import * as bip39 from '@scure/bip39';
import { wordlist } from '@scure/bip39/wordlists/english';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { axiosAuth } from '../../api';
import { PATHS } from '../../App';
import { useAuthContext, User } from '../../contexts/AuthContext';
import { authUtil } from '../../utils/auth';
import useValidateOrgName from './useValidateOrgName';

export function CreateOrganization() {
  const { setOrganization, setUser, user } = useAuthContext();
  const [orgNameInput, setOrgNameInput] = useState('');
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: async (orgName: string) => {
      return await axiosAuth.post<{ accessToken: string; user: User }>('/org', { orgName });
    },
    onSuccess: res => {
      setUser(res?.data?.user);
      authUtil.setAccessToken(res?.data?.accessToken);
      navigate(PATHS.DEFAULT);
    },
  });

  const { error, reset: resetValidationState, validateOrgName } = useValidateOrgName();

  return user?.org_id ? (
    <Navigate to={PATHS.DEFAULT} />
  ) : (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ p: 15, bgcolor: '#fafafa' }}
      width="100%"
    >
      <Stack alignItems="center" gap={2} justifyContent="center" maxWidth={500}>
        <Typography variant="body2">CREATE AN ORGANIZATION</Typography>
        <Typography textAlign="center">
          Organizations are shared environments where teams can launch, monitor, and maintain their
          deployments.
        </Typography>
        <Stack direction="row" gap={2} height={68} width="100%">
          <TextField
            error={!!error}
            fullWidth
            helperText={error?.response?.data?.message}
            onChange={e => {
              const val = e.target?.value;

              setOrgNameInput(val);

              if (val) {
                validateOrgName(val);
              } else {
                resetValidationState();
              }
            }}
            value={orgNameInput}
            variant="outlined"
          />
          {orgNameInput ? (
            <Button
              disabled={!!error}
              onClick={() => {
                setOrganization(orgNameInput);
                mutate(orgNameInput);
              }}
              sx={{ height: '100%' }}
            >
              Create
            </Button>
          ) : (
            <Button
              onClick={() => {
                const mnemonic = bip39.generateMnemonic(wordlist);
                const randomWord = mnemonic?.toString()?.split(' ')?.[0];
                const orgName = `user:${user?.email}:${randomWord}`;

                setOrganization(orgName);
                mutate(orgName);
              }}
              sx={{ height: '100%' }}
              variant="outlined"
            >
              Skip
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
