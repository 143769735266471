import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { IconClose } from 'components/icons/IconClose';
import IconInfo from 'components/icons/IconInfo';
import { useVerifyPromoCode } from 'pages/aaas/NewAgentDeployment/useVerifyPromoCode';
import { useCallback, useState } from 'react';
import { PasswordTextField } from 'shared/components/TextField/PasswordTextField';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';

export interface IVerifyPromoCodeDialog extends DialogProps {
  onPromoCodeVerified?: (promoCode: string) => void;
}

export const VerifyPromoCodeDialog: React.FC<IVerifyPromoCodeDialog> = ({
  onClose,
  onPromoCodeVerified,
  ...props
}) => {
  const [promoCode, setPromoCode] = useState<string>();
  const { isPending: verifyPromoCodePending, mutate: verifyPromoCode } = useVerifyPromoCode({
    onSuccess: valid => {
      if (valid && promoCode) onPromoCodeVerified?.(promoCode);

      setPromoCode(undefined);
      onClose?.({}, 'backdropClick');
    },
  });

  const handleSkip = useCallback(() => {
    onPromoCodeVerified?.('FREE_TRIAL');
    onClose?.({}, 'backdropClick');
  }, [onClose, onPromoCodeVerified]);

  return (
    <Dialog {...props}>
      <Stack
        component="form"
        onSubmit={e => {
          e?.preventDefault();
          verifyPromoCode(promoCode);
        }}
        spacing="40px"
        sx={{
          width: '504px',
          p: '40px',
        }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h5">Verify promo code</Typography>

          <IconButton onClick={() => onClose?.({}, 'backdropClick')} size="small">
            <IconClose />
          </IconButton>
        </Stack>

        <PasswordTextField onChange={e => setPromoCode(e.target.value)} placeholder="PROMO123" />

        <Stack direction="row" spacing="16px" sx={{ '& > *': { width: '100%' } }}>
          <Button
            onClick={handleSkip}
            sx={theme => ({
              border: `1px solid ${theme.colors.functional.subject.border}`,
              '&, &:hover': {
                background: theme.colors.gradients.metal,
                color: theme.colors.functional.text.primary,
              },
            })}
          >
            <Stack direction="row" spacing={1}>
              <ConditionalTooltip
                enabled
                title="For a free trial, instances will expire in 1 hour."
                wrapped
              >
                <IconInfo />
              </ConditionalTooltip>

              <Typography variant="body1">Free Trial</Typography>
            </Stack>
          </Button>

          <LoadingButton
            disabled={!promoCode}
            loading={verifyPromoCodePending}
            type="submit"
            variant="contained"
          >
            <Typography variant="body1">Verify Promo Code</Typography>
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
