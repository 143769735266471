import { Stack, StackProps, Typography } from '@mui/material';
import { IPageBanner, PageBanner, PageBannerVariant } from 'components/Layout/PageBanner';
import { useDisclosure } from 'hooks/ui';
import { Banner } from 'shared/components/Banner';
import { BannerVariant, useBannerStore } from 'shared/stores/useBannerStore';

export interface IAaasLayout extends StackProps {
  bannerDefaultOpen?: boolean;
  pageBannerProps?: IPageBanner;
}

export const AaasLayout: React.FC<IAaasLayout> = props => {
  const { banners, setBanner } = useBannerStore();
  const { onClose: onCloseWarningBanner } = useDisclosure({
    defaultOpen: props.bannerDefaultOpen ?? banners[BannerVariant.AAA_WARNING],
    onClose: () => setBanner(BannerVariant.AAA_WARNING, false),
  });

  return (
    <Stack {...props} sx={{ mb: '164px', ...props.sx }}>
      <PageBanner variant={PageBannerVariant.AAAS} {...props?.pageBannerProps} />
      <Banner in={banners[BannerVariant.AAA_WARNING]} onClose={onCloseWarningBanner}>
        <Typography>
          Your agent may be terminated after expiration. Please remember to back up all your API and
          private keys safely to avoid any loss of keys or funds.
        </Typography>
      </Banner>

      {props.children}
    </Stack>
  );
};
