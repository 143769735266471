import { ProductionEnvGuard } from 'guards';
import { Outlet, RouteObject } from 'react-router-dom';

import {
  AutoWithdrawalView,
  BalanceAlertView,
  BatcherView,
  BlockchainStatisticsView,
  BridgeMetricsView,
  ContactUsView,
  CustomizationView,
  FaqView,
  FeeTrackerView,
  L2View,
  OverviewView,
  ProposerView,
  RollupInfoView,
  RollupStatisticsView,
  RpcUsageMonitoringView,
  StartFullnodeView,
} from './views';

export const routes: RouteObject[] = [
  {
    path: '',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <OverviewView />,
      },
      {
        path: 'info',
        element: <RollupInfoView />,
      },
      {
        path: 'start-fullnode',
        element: (
          <ProductionEnvGuard>
            <StartFullnodeView />
          </ProductionEnvGuard>
        ),
      },
      {
        path: 'customization',
        element: (
          <ProductionEnvGuard>
            <CustomizationView />
          </ProductionEnvGuard>
        ),
      },
      {
        path: 'stats',
        element: <RollupStatisticsView />,
      },
      {
        path: 'bridge',
        element: <BridgeMetricsView />,
      },
      {
        path: 'fee-tracker',
        element: <FeeTrackerView />,
      },
      {
        path: 'blockchain-stats',
        element: <BlockchainStatisticsView />,
      },
      {
        path: 'proposer',
        element: <ProposerView />,
      },
      {
        path: 'batcher',
        element: <BatcherView />,
      },
      {
        path: 'rollup-statistics',
        element: <L2View />,
      },
      {
        path: 'withdrawal-fee',
        element: <AutoWithdrawalView />,
      },
      {
        path: 'balance',
        element: <BalanceAlertView />,
      },
      {
        path: 'rpc',
        element: <RpcUsageMonitoringView />,
      },
      {
        path: 'faq',
        element: <FaqView />,
      },
      {
        path: 'contact-us',
        element: <ContactUsView />,
      },
    ],
  },
];
