import { Box } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopWrapperProps {
  children: ReactNode;
}

export const ScrollToTopWrapper: React.FC<ScrollToTopWrapperProps> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const element = document.querySelector('#main-raas-content > :nth-child(2)');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname]);

  return (
    <Box component={'main'} id="main-raas-content">
      {children}
    </Box>
  );
};
