import { Grid, Stack, Typography } from '@mui/material';
import { SubHeader } from 'components/Layout/SubHeader';
import { useListAppTypes } from 'hooks/aaas';

import AgentStoreCard from './AgentStoreCard';

function UserAgentStore() {
  const { data, isPending } = useListAppTypes();
  const apps = data?.selfOwned || [];

  return (
    <>
      <SubHeader isLoading={isPending}>Your agent store</SubHeader>
      {!apps?.length ? (
        <Stack
          direction="row"
          justifyContent="center"
          py={2}
          sx={{ background: '#fafafa' }}
          width="100%"
        >
          <Typography>You do not have any listed agents</Typography>
        </Stack>
      ) : (
        <Grid
          container
          sx={{
            background: '#fafafa',
            pb: '50px',
            '& > *': theme => ({
              borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
              borderRight: `1px solid ${theme.colors.functional.subject.border}`,
            }),
          }}
        >
          {apps.map(app => (
            <AgentStoreCard app={app} key={app.appStoreID} />
          ))}
        </Grid>
      )}
    </>
  );
}

export default UserAgentStore;
