import { useBlockscoutBalanceMetrics, useRaasDeployment } from 'hooks';
import { RollupType } from 'labels/raas';
import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/useTimeFilterOptions';
import {
  TimeFilterVariant,
  useTimeFilterStore,
} from 'pages/raas/DeploymentDetails/useTimeFilterStore';
import { getL1TokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import { Metric } from 'shared/components/metric';
import { addressFormatter } from 'utils/recharts';
import { formatTokenWithSymbol } from 'utils/strings';

export const ProposerBalanceHistoryMetric = () => {
  const { data: rollup } = useRaasDeployment();
  const { selectedTimeFilter } = useTimeFilterStore();
  const {
    end: to,
    start: from,
    timeframe,
  } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.PROPOSER_BALANCE_BALANCE_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutBalanceMetrics({
    account: rollup?.accounts?.[rollup?.type === RollupType.OPSTACK ? 'proposer' : 'staker'],
    timeframe,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={"Proposer's balance over time"}
      id={'proposer-balance-history'}
      loading={isPending}
      title={'Balance history'}
      tooltipKeyFormatter={addressFormatter}
      tooltipValueFormatter={value => formatTokenWithSymbol(value, getL1TokenSymbol(rollup))}
      yAxisProps={{
        tickFormatter: value => formatTokenWithSymbol(value, rollup?.tokenMetadata?.symbol),
      }}
    />
  );
};
