import { Button, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchDeployments } from 'api/deployments';
import { rollupTypeIcons, rollupTypeLabels } from 'labels/deployments';
import { useFormContext } from 'react-hook-form';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CustomAutocomplete } from 'shared/components/CustomAutocomplete';
import { FormFieldArray } from 'shared/components/form';
import { Option } from 'shared/components/Option';
import { WithInfo } from 'shared/components/WithInfo';
import { InitParams } from 'types/deployments';
import { Deployment, RollupType } from 'types/protoc-gen/rollup';
import { QUERY_KEYS } from 'types/react-query';

import { AVSFormValues } from '..';

const rollupTypeOptions = (
  Object.values(RollupType)?.filter(cur => Number(cur) >= 0) as RollupType[]
)?.map((rollupType: RollupType) => ({
  icon: rollupTypeIcons?.[rollupType],
  label: rollupTypeLabels?.[rollupType],
  value: rollupType,
}));

export default function SelectChainAndStack() {
  const { formState, register, setValue, trigger: validate, watch } = useFormContext();
  const { data: deployments } = useQuery<Deployment[]>({
    queryKey: [QUERY_KEYS.GET_DEPLOYMENTS],
    queryFn: fetchDeployments,
  });
  const chainIdOptions =
    deployments?.map(cur => {
      const { chainId, chainName }: InitParams = JSON.parse(cur?.initParam || '{}');

      return {
        label: `${chainId} (${chainName})`,
        value: chainId,
        deploymentInfo: { chainId, chainName, id: cur.deploymentId },
      };
    }) || [];

  return (
    <Option
      optionTitle={
        <WithInfo
          info="Select from a list of deployed rollups, or enter a custom chain ID."
          text={<Typography variant="bodySmallC">ENTER YOUR ROLLUP STACK AND CHAIN ID</Typography>}
        />
      }
    >
      <Stack mt={3}>
        <FormFieldArray<AVSFormValues>
          addRowText="Add a chain ID"
          fieldArrayConfig={{ name: 'chains', label: 'Add Rollup stacks and chain IDs' }}
          inputRef={register('chains').ref}
          renderField={({ field, fieldArray, fieldArrayConfig, formState, index }) => {
            return (
              <>
                <Grid alignItems="center" container>
                  <Grid item xs={6}>
                    <CustomAutocomplete
                      afterChange={selectedOption => {
                        setValue(
                          `${field.name}.chainId`,
                          selectedOption
                            ? {
                                ...selectedOption,
                                value: Number(selectedOption?.value),
                              }
                            : null,
                          {
                            shouldTouch: true,
                            shouldValidate: true,
                          },
                        );

                        const deployment = deployments?.find(cur => {
                          const { chainId } = JSON.parse(cur?.initParam || '{}');

                          return chainId === selectedOption?.value;
                        });
                        const matchedRollupTypeOption = rollupTypeOptions?.find(
                          cur => cur.value === deployment?.rollupType,
                        );

                        if (matchedRollupTypeOption) {
                          setValue(`${field.name}.rollupType`, matchedRollupTypeOption || null, {
                            shouldValidate: true,
                            shouldTouch: true,
                          });
                        }
                      }}
                      creatable
                      getCreateOptionText={input => `Use chain ID: ${input}`}
                      noOptionsText="Start typing to add a chain ID"
                      options={chainIdOptions}
                      placeholder="Select or enter a chain ID"
                      sx={{ background: theme => theme.colors.gradients.sheet }}
                      value={watch(`${field.name}.chainId`) || ''}
                    />
                  </Grid>
                  <Grid container item justifyContent="center" xs={index === 0 ? 6 : 5}>
                    <CustomAutocomplete
                      afterChange={selectedOption => {
                        setValue(`${field.name}.rollupType`, selectedOption || null, {
                          shouldTouch: true,
                          shouldValidate: true,
                        });
                      }}
                      getCreateOptionText={input => `Use chain ID: ${input}`}
                      options={rollupTypeOptions}
                      placeholder="Select a rollup stack"
                      sx={{ width: '100%' }}
                      value={watch(`${field.name}.rollupType`)}
                    />
                  </Grid>
                  <Grid
                    component={Button}
                    item
                    onClick={() => {
                      if (fieldArray.fields.map(cur => !!cur).length > 1) {
                        fieldArray.remove(index);
                      } else {
                        fieldArray.replace([]);
                      }

                      validate(fieldArrayConfig.name);
                    }}
                    sx={{
                      '&&': {
                        display: index === 0 ? 'none' : 'inline-flex',
                        minWidth: 'unset',
                        height: '3.4rem',
                        background: theme => theme.colors.gradients.sheet,
                        border: '1px solid rgba(32, 39, 35, 0.08)',
                      },
                      '&:hover': { background: theme => theme.colors.gradients.metal },
                    }}
                    variant="text"
                    xs={1}
                  >
                    <RiDeleteBin6Line color="#202723" size={20} />
                  </Grid>
                </Grid>
                {formState.errors?.chains?.[index] && (
                  <FormHelperText error>
                    {formState.errors?.chains?.[index]?.chainId?.message ||
                      formState.errors?.chains?.[index]?.chainId?.value?.message ||
                      formState.errors?.chains?.[index]?.rollupType?.message ||
                      formState.errors?.chains?.[index]?.rollupType?.value?.message}
                  </FormHelperText>
                )}
              </>
            );
          }}
        />
        {formState.errors?.chains?.root?.message && (
          <FormHelperText error>{String(formState?.errors?.chains?.root?.message)}</FormHelperText>
        )}
      </Stack>
    </Option>
  );
}
