import { Button, CircularProgress, Stack, SvgIcon, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { PageBanner, PageBannerVariant } from 'components/Layout/PageBanner';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useWagmi } from 'contexts/WagmiContext';
import { useRaasDeployment } from 'hooks/raas/useRaasDeployment';
import { useBalAlerts } from 'pages/raas/DeploymentDetails/views/BalanceAlertView/useBalAlerts';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { PATHS } from 'routes';
import { MUTATION_KEYS } from 'types/react-query';
import { numberToHex } from 'viem';
import { http, useSwitchChain } from 'wagmi';

import { DeploymentDetailsLayout } from './layout';
import { SideNavigation } from './SideNavigation';

export const DeploymentDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { addAlert } = useAlerts();
  const { getAccountsPending } = useBalAlerts();
  const { data: rollup, isPending: getRollupPending } = useRaasDeployment({
    rollupId: params?.rollupId,
    enabled: !!params?.rollupId,
  });
  const { handleSetChains, handleSetTransports } = useWagmi();
  const { switchChainAsync } = useSwitchChain();

  const { isPending: addNetworkPending, mutate: addNetwork } = useMutation({
    mutationKey: [MUTATION_KEYS.ADD_NETWORK_TO_WALLET, rollup?.id],
    mutationFn: async () => {
      if (!window.ethereum) return;
      if (!rollup?.chainId)
        return addAlert({
          title: 'Chain ID not found',
          desc: 'Please try again',
          severity: ALERT_SEVERITY.WARNING,
        });

      try {
        handleSetTransports({
          [rollup?.chainId]: http(rollup.rpcEndpoint),
        });
        handleSetChains({
          id: rollup?.chainId,
          name: rollup?.name,
          rpcUrls: {
            default: {
              http: [rollup?.rpcEndpoint],
              webSocket: [rollup?.rpcWsEndpoint],
            },
          },
          blockExplorers: {
            default: {
              name: rollup?.name,
              url: rollup?.explorer,
            },
          },
          nativeCurrency: {
            name: rollup?.tokenMetadata?.symbol,
            symbol: rollup?.tokenMetadata?.symbol,
            decimals: rollup?.tokenMetadata?.decimals ?? 18,
          },
        });

        await switchChainAsync({
          chainId: rollup?.chainId,
        });

        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: numberToHex(rollup?.chainId),
              chainName: rollup?.name,
              rpcUrls: [rollup?.rpcEndpoint ?? ''],
              blockExplorerUrls: [rollup?.explorer ?? ''],
              nativeCurrency: {
                name: rollup?.tokenMetadata?.symbol,
                symbol: rollup?.tokenMetadata?.symbol,
                decimals: rollup?.tokenMetadata?.decimals ?? 18,
              },
            },
          ],
        });
      } catch (error) {
        console.error(`Failed to add network to wallet:\n\n ${JSON.stringify(error, null, 2)}`);

        throw error;
      }
    },
    onSuccess: () =>
      addAlert({
        title: 'Network added to wallet',
        desc: 'You can now switch to this network in your wallet',
        severity: ALERT_SEVERITY.SUCCESS,
      }),
    onError: () =>
      addAlert({
        title: 'Failed to add network to wallet',
        desc: 'Please try again',
        severity: ALERT_SEVERITY.ERROR,
      }),
  });

  return (
    <>
      <PageBanner
        loading={getAccountsPending || getRollupPending}
        onClickBack={() => navigate(PATHS.RAAS_DEPLOYMENTS)}
        renderSubTitleContent={
          rollup?.chainId && (
            <Button
              onClick={() => addNetwork()}
              sx={{
                ml: '75px',
                mt: '12px',
                background: 'transparent',
                '&:hover': { background: 'transparent' },
              }}
            >
              {addNetworkPending ? (
                <CircularProgress
                  size="20px"
                  sx={theme => ({ color: theme.colors.functional.text.primary })}
                />
              ) : (
                <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
                  <SvgIcon sx={{ height: '30px', width: '30px' }} viewBox="0 0 30 30">
                    <circle cx="15" cy="14.46" fill="#202723" r="12" />
                    <path
                      clipRule="evenodd"
                      d="M14.5 15.5V21H15.5V15.5H21V14.5H15.5V9H14.5V14.5H9V15.5H14.5Z"
                      fill="#ffffff"
                      fillRule="evenodd"
                    />
                  </SvgIcon>
                  <Typography
                    sx={theme => ({
                      color: theme.colors.functional.text.primary,
                      fontWeight: 500,
                      fontSize: '20px',
                      lineHeight: '20px',
                      letterSpacing: '0.06em',
                      textAlign: 'left',
                    })}
                  >
                    {'ADD TO WALLET'}
                  </Typography>
                </Stack>
              )}
            </Button>
          )
        }
        title={rollup?.name}
        variant={PageBannerVariant.RAAS}
      />

      <Stack flexDirection="row" sx={{ position: 'relative', mb: '164px', background: '#fafafa' }}>
        <SideNavigation
          sx={{
            position: 'sticky',
            top: '0',
            alignSelf: 'flex-start',
            zIndex: 100,
            overflow: 'auto',
            maxHeight: '100vh',
          }}
        />

        <DeploymentDetailsLayout>
          <Outlet />
        </DeploymentDetailsLayout>
      </Stack>
    </>
  );
};
