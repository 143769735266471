import { Box, Button, CircularProgress, Skeleton, Stack, Typography } from '@mui/material';
import { IconDelete } from 'components/icons/IconDelete';
import { IconEdit } from 'components/icons/IconEdit';
import { IconExternalLink } from 'components/icons/IconExternalLink';
import dayjs from 'dayjs';
import { useDisclosure } from 'hooks/ui';
import { aaaType } from 'labels/aaas';
import { useApp } from 'pages/aaas/AaasDetails/useApp';
import { ReactNode } from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { Section } from 'shared/components/Section';
import { StatusLabel } from 'shared/components/StatusLabel';
import { AAAHostType, aAAHostTypeFromJSON, aAATypeFromJSON } from 'types/protoc-gen/bffaaa';

import { DeleteAppDialog } from './DeleteAppDialog';
import { useStatus } from './useStatus';

export const DetailsView = () => {
  const navigate = useNavigate();
  const { data: app, isPending } = useApp();
  const { deployed, failureCount, isPending: healthzPending } = useStatus(app);
  const {
    onClose: onCloseDeleteDialog,
    onOpen: onOpenDeleteDialog,
    open: isOpenDeleteDialog,
  } = useDisclosure();

  return (
    <>
      <Section
        renderSubHeaderEndContent={
          isPending ? (
            <Skeleton height="24px" variant="rectangular" width="68px" />
          ) : (
            <Stack direction="row" justifyContent="end" spacing="16px" sx={{ minWidth: '400px' }}>
              {deployed ? (
                <StatusLabel sx={{ background: theme => theme.colors.schema.leafPrimary }}>
                  Live
                </StatusLabel>
              ) : (
                <StatusLabel sx={{ background: '#F8D97D' }}>Deploying</StatusLabel>
              )}
            </Stack>
          )
        }
        renderSubHeaderStartContent={
          isPending ? (
            <Skeleton variant="text" width="80px" />
          ) : (
            <Typography variant={'bodySmallC'}>{aaaType[aAATypeFromJSON(app?.app)]}</Typography>
          )
        }
        sx={{ mb: '164px' }}
      >
        <Stack>
          <Field label={'Project name'} loading={isPending}>
            {app?.name}
          </Field>
          {app?.endpoints?.host && (
            <Field label={'App URL'} loading={isPending}>
              {app?.endpoints?.host}
            </Field>
          )}
          <Field label={'Server size'} loading={isPending}>
            {'Autoscaling'}
          </Field>
          {app?.createdAt && (
            <Field label={'Expiration'} loading={isPending}>
              <Typography variant="bodySmallM">
                <Countdown date={dayjs(app?.createdAt).add(24, 'hour').toDate()} daysInHours />
              </Typography>
            </Field>
          )}

          <Stack
            sx={theme => ({
              background: theme.colors.gradients.sheet,
              borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
            })}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="end"
              sx={theme => ({
                '& > .MuiButton-root:first-of-type': {
                  borderLeft: `1px solid ${theme.colors.functional.subject.border}`,
                },
                '& > .MuiButton-root': {
                  color: theme.colors.functional.text.primary,
                  '&, &:hover': { background: theme.colors.gradients.metal },
                  borderRight: `1px solid ${theme.colors.functional.subject.border}`,
                },
              })}
            >
              {[
                ...(aAAHostTypeFromJSON(app?.hostType) === AAAHostType.AAAHOSTTYPE_CHATBOX
                  ? [
                      {
                        id: 'chat-button',
                        label: (
                          <Stack>
                            <Typography>Chat with Agent</Typography>
                            {(!deployed || healthzPending) && (
                              <Typography
                                fontSize={8}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: 1,
                                }}
                                variant="bodySmall"
                              >
                                <CircularProgress size={10} />{' '}
                                {failureCount < 1 ? 'Initializing...' : 'Deploying...'}
                              </Typography>
                            )}
                          </Stack>
                        ),
                        onClick: () =>
                          app && navigate(PATHS.AAAS_DETAILS_CHAT.replace(':appId', app.id)),
                        icon: <IconExternalLink sx={{ height: '20px', width: '20px' }} />,
                        disabled: !deployed || healthzPending,
                      },
                    ]
                  : []),
                ...[
                  {
                    id: 'delete-button',
                    label: 'Delete App',
                    onClick: onOpenDeleteDialog,
                    icon: <IconDelete sx={{ height: '20px', width: '20px' }} />,
                  },
                ],
              ].map(({ disabled, icon, id, label, onClick }) => (
                <Button disabled={disabled || isPending} key={id} onClick={onClick}>
                  <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
                    {icon}
                    <Typography variant="bodySmallC">{label}</Typography>
                  </Stack>
                </Button>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Section>

      <DeleteAppDialog app={app} onClose={onCloseDeleteDialog} open={isOpenDeleteDialog} />
    </>
  );
};

const Field: React.FC<{
  loading?: boolean;
  label: ReactNode;
  children: string | ReactNode;
  onClickEdit?: () => void;
}> = ({ children, label, loading, onClickEdit }) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    spacing="24px"
    sx={{
      borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
      '&:hover': { background: '#fafafa' },
      background: '#fff',
      minHeight: '68px',
      py: '24px',
      px: '40px',
    }}
  >
    <Stack alignItems="center" direction="row" width="100%">
      {loading ? (
        <Box width="357px">
          <Skeleton variant="text" width="55%" />
        </Box>
      ) : (
        <Typography variant="bodySmallM" width="357px">
          {label}:
        </Typography>
      )}

      {loading ? (
        <Skeleton variant="text" width="15%" />
      ) : typeof children === 'string' ? (
        <Typography variant="bodySmallM">{children}</Typography>
      ) : (
        children
      )}
    </Stack>

    {onClickEdit && (
      <Button
        disabled={loading}
        onClick={onClickEdit}
        size="small"
        sx={{
          '&, &:hover': { background: 'transparent' },
          color: theme => theme.colors.functional.text.primary,
        }}
      >
        <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
          <IconEdit sx={{ height: '18px', width: '18px' }} />

          <Typography variant="bodySmallC">EDIT</Typography>
        </Stack>
      </Button>
    )}
  </Stack>
);
