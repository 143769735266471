import { Box, Button, ButtonProps, Radio, Stack, Typography, useTheme } from '@mui/material';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';
import { APP } from 'types/protoc-gen/bffaaa';

export interface ITemplateCard extends ButtonProps {
  template: APP | undefined;
  selected?: boolean;
  onClick: () => void;
}

export const TemplateCard: React.FC<ITemplateCard> = ({
  onClick,
  selected,
  sx,
  template,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      sx={{
        background: theme.colors.gradients.sheet,
        color: theme.palette.text.primary,
        '&:hover': {
          background: theme.colors.schema.metalPrimary,
        },
        minHeight: '150px',
        width: '100%',
        cursor: 'pointer',
        p: 3,
        ...sx,
      }}
      {...props}
    >
      <Stack spacing={3} sx={{ height: '100%', width: '100%' }}>
        <Stack
          alignItems="start"
          direction="row"
          justifyContent="space-between"
          spacing={5}
          sx={{ height: '100%' }}
        >
          <Stack alignItems="start" justifyContent="start" spacing={2} sx={{ height: '100%' }}>
            <Stack direction="row" spacing={2}>
              {template?.iconLink && <Box component="img" src={template?.iconLink} />}
              <Typography variant="bodySmallM">{template?.name}</Typography>
            </Stack>
            <ConditionalTooltip enabled title={template?.description}>
              <Typography
                sx={{
                  mr: 3,
                  textAlign: 'justify',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  cursor: 'text',
                }}
                variant="caption"
              >
                {template?.description}
              </Typography>
            </ConditionalTooltip>
          </Stack>
          <Radio
            checked={selected}
            onClick={onClick}
            sx={theme => ({
              color: theme.palette.text.primary,
              '&.Mui-checked': {
                color: theme.colors.functional.text.link,
              },
            })}
          />
        </Stack>
      </Stack>
    </Button>
  );
};
