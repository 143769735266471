import { Margin } from 'recharts/types/util/types';

const linearGradientIds = ['water', 'cream', 'pistachio', 'leaf', 'metal'];

const strokeColors = [
  '#5C8CE1',
  '#66B489',
  '#F1605F',
  '#A6C3FA',
  '#F1905F',
  '#4AD5D0',
  '#F8D97D',
  '#A281FF',
  '#D5E9BB',
  '#BAC6AB',
  '#FD81FF',
  '#B78F49',
];

const margin: Margin = { top: 16, right: 40, left: 16, bottom: 0 };

export const chartDefaults = {
  linearGradientIds,
  strokeColors,
  margin,
};
