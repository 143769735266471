import '@rainbow-me/rainbowkit/styles.css';

import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { PageBanner } from 'components/Layout/PageBanner';
import { useGlobalPublicAvs } from 'hooks/api/useGlobalPublicAvs';
import { useCallback, useEffect, useState } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { FormNumericTextField, FormTextField } from 'shared/components/form';
import { SectionTitle } from 'shared/components/SectionTitle';
import { UserGuideButton } from 'shared/components/UserGuideButton';
import { useNewAvsStore } from 'shared/stores';
import { AVS_QUORUMS, AVS_TYPES } from 'types/avs';
import { RollupType } from 'types/protoc-gen/rollup';
import * as yup from 'yup';

import SelectAVSType, { avsTypeOptions } from './form-steps/SelectAVSType';
import SelectChainAndStack from './form-steps/SelectChainAndStack';
import SelectStrategies from './form-steps/SelectStrategies';
import AVSSummaryPanel from './AVSSummaryPanel';
import CodePanel from './CodePanel';
import { DeployAvsPopup } from './DeployAvsPopup';
import { getValidationSchema } from './validationSchema';

export interface AVSFormValues {
  avsName?: string;
  avsType: AVS_TYPES;
  strategies: AVS_QUORUMS[];
  ecdsaWeight?: bigint;
  chains?: {
    chainId: { value: number };
    rollupType: { value: RollupType };
  }[];
  compiledOutput?: {
    abi: any[];
    bytecode: string;
    contractName?: string;
    version: string;
  };
  initArgs?: any[];
  constructorArgs?: any[];
  aggregatorHandlerName?: { label: string; value: string };
}

export function NewAVS() {
  const queryClient = useQueryClient();
  const { reset: resetNewAvsState } = useNewAvsStore();
  const [visited, setVisited] = useState<boolean>(false);
  const [showDeployPopup, setShowDeployPopup] = useState(false);
  const { data: globalAvsDeployments, refetch: getGlobalPublicAvs } = useGlobalPublicAvs({
    enabled: true,
  });

  const form = useForm<AVSFormValues>({
    mode: 'all',
    resolver: yupResolver(getValidationSchema(queryClient) as yup.ObjectSchema<AVSFormValues>),
  });
  const avsType = useWatch({ name: 'avsType', control: form.control });

  const onSubmit: SubmitHandler<AVSFormValues> = data => {
    console.log(data);
    setShowDeployPopup(true);
    // create(data);
  };

  const onError: SubmitErrorHandler<AVSFormValues> = formErrors => {
    console.error(formErrors);
  };

  useEffect(() => {
    resetNewAvsState();
  }, [resetNewAvsState]);

  useEffect(() => {
    form.setValue('avsType', AVS_TYPES.GENERIC, { shouldTouch: true });
    form.setValue('strategies', [AVS_QUORUMS.ETH_LST], { shouldTouch: true });
  }, [form]);

  useEffect(() => {
    if (globalAvsDeployments?.avsDeployment.length === 0) getGlobalPublicAvs();
  }, [getGlobalPublicAvs, globalAvsDeployments]);

  const handleClickUserGuideButton = useCallback(() => {
    window.open('https://docs.altlayer.io/altlayer-documentation/wizard/create-avs', '_blank');
    localStorage.setItem('avs.new.user-guide', 'true');
  }, []);

  useEffect(() => {
    const visited = localStorage.getItem('avs.new.user-guide');

    if (visited === 'true') {
      setVisited(true);
    } else {
      setVisited(false);
    }
  }, []);

  return (
    <>
      <PageBanner title="Deploy an AVS" />
      <Box bgcolor="#fafafa" pb={4}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            {showDeployPopup && (
              <DeployAvsPopup onClose={() => setShowDeployPopup(false)} open={true} />
            )}
            <Box margin="0 auto">
              <Stack flexDirection="row">
                <Box flex={1}>
                  <SectionTitle alignItems="center" direction="row" justifyContent="space-between">
                    Build your stack
                    {!visited && <UserGuideButton onClick={handleClickUserGuideButton} />}
                  </SectionTitle>
                  <Grid container mt={4}>
                    <Grid item lg={8} md={10} xl={6} xs={12}>
                      <FormTextField
                        fieldConfig={{
                          name: 'avsName',
                          label: 'AVS Name',
                          placeholder: 'abc-net',
                        }}
                        id="step_avsName"
                      />
                    </Grid>
                  </Grid>
                  <SelectAVSType id="step_avsType" />
                  <CodePanel avsType={avsType} />
                  {avsType === AVS_TYPES.MACH && <SelectChainAndStack />}
                  <SelectStrategies />
                  {avsTypeOptions?.find(cur => cur?.value === avsType)?.sig === 'ecdsa' && (
                    <FormNumericTextField
                      fieldConfig={{
                        name: 'ecdsaWeight',
                        label: 'Threshold Weight',
                        placeholder: 'Enter threshold weight',
                      }}
                      id="step_ecdsaWeight"
                      tooltipContent="The threshold weight required to validate a message. This is the cumulative weight that must be met or exceeded by the sum of the stakes of the signatories for a message to be deemed valid."
                      type="number"
                    />
                  )}
                </Box>
                <Box width="30%">
                  <AVSSummaryPanel />
                </Box>
              </Stack>
            </Box>
          </form>
          <DevTool control={form.control} />
        </FormProvider>
      </Box>
    </>
  );
}
