import {
  IconButton,
  IconButtonProps,
  Stack,
  StackProps,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { ReactNode } from 'react';

import IconInfo from '../../components/icons/IconInfo';

export function WithInfo({
  info,
  text,
  textProps,
  ...props
}: { text: ReactNode; info?: ReactNode; textProps?: TypographyProps } & StackProps) {
  return (
    <Stack alignItems="center" flexDirection="row" justifyContent="space-between" {...props}>
      {typeof text === 'string' ? (
        <Typography variant="body1" {...textProps}>
          {text}
        </Typography>
      ) : (
        text
      )}
      {info && <Info info={info} sx={{ p: 0, ml: 1 }} />}
    </Stack>
  );
}

export function Info({ info, sx, ...props }: { info: ReactNode } & IconButtonProps) {
  return (
    <Tooltip placement="right" title={info}>
      <IconButton sx={{ p: 0, ...sx }} {...props}>
        <IconInfo />
      </IconButton>
    </Tooltip>
  );
}
