import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { RaasMetricsData, RaasMetricsResult, Timeframe } from 'hooks/raas/types';
import { useParams } from 'react-router-dom';
import { chartDefaults } from 'shared/components/charts';
import { QUERY_KEYS } from 'types/react-query';
import { Address } from 'viem';

import { useBlockscoutAxios } from './useBlockscoutAxios';

export interface IUseBlockscoutBalanceMetrics
  extends Omit<UseQueryOptions<RaasMetricsResult>, 'queryFn' | 'queryKey'> {
  account: Address | undefined;
  l2?: boolean;
  from?: string;
  to?: string;
  timeframe?: Timeframe;
}

export const useBlockscoutBalanceMetrics = ({
  account,
  from,
  l2,
  timeframe,
  to,
  ...queryOptions
}: IUseBlockscoutBalanceMetrics) => {
  const { rollupId } = useParams();
  const blockscout = useBlockscoutAxios({ v2: true });
  const [waterPrimary] = chartDefaults.strokeColors;

  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_BLOCKSCOUT_STATS,
      {
        variant: ['balance-history', account].join('-'),
        rollupId,
        timeframe,
      },
    ],
    queryFn: async (): Promise<RaasMetricsResult> => {
      if (!account) throw new Error('account is undefined');

      const res = await blockscout?.[l2 ? 'l2' : 'l1'].get<BlockscoutBalanceResponse>(
        `/addresses/${account}/coin-balance-history-by-day`,
        {
          params: {
            from,
            to,
          },
        },
      );

      const data: RaasMetricsData[] = [];

      for (const v of res.data.items) {
        data.push({
          date: v.date,
          [account]: +v.value,
        });
      }

      return {
        data,
        dataKeys: [
          {
            name: account,
            color: waterPrimary,
            fill: 'water',
          },
        ],
      };
    },
    enabled: !!blockscout?.rollup && !!account,
    ...queryOptions,
  });
};

export interface BlockscoutBalanceItem {
  date: string;
  value: string;
}

export interface BlockscoutBalanceNextPageParams {
  block_number: number;
  items_count: number;
}

export interface BlockscoutBalanceResponse {
  items: BlockscoutBalanceItem[];
  next_page_params: BlockscoutBalanceNextPageParams;
}
