import { Icon0g } from 'components/icons/Icon0g';
import { IconAltLayer } from 'components/icons/IconAltLayer';
import IconAnyTrustDa from 'components/icons/IconAnyTrustDa';
import IconArb from 'components/icons/IconArb';
import { IconArbOrbit } from 'components/icons/IconArbOrbit';
import IconAvail from 'components/icons/IconAvail';
import { IconBinance } from 'components/icons/IconBinance';
import IconCelestia from 'components/icons/IconCelestia';
import IconEigenDa from 'components/icons/IconEigenDa';
import IconEth from 'components/icons/IconEth';
import IconEthBlobs from 'components/icons/IconEthBlobs';
import { IconNear } from 'components/icons/IconNear';
import IconOP from 'components/icons/IconOP';
import IconPolygon from 'components/icons/IconPolygon';
import { IconSepoliaEth } from 'components/icons/IconSepoliaEth';
import IconZKSync from 'components/icons/IconZKSync';
import { ReactNode } from 'react';
import { StatusLabel } from 'shared/components/StatusLabel';
import { Chain } from 'viem';
import { arbitrum, arbitrumSepolia, bsc, bscTestnet, mainnet, sepolia } from 'viem/chains';

const iconSize = 20;

export enum SettlementLayer {
  ARBITRUM_ONE = 'Arbitrum One',
  ARBITRUM_SEPOLIA = 'Arbitrum Sepolia',
  BINANCE_MAINNET = 'BNB Smart Chain',
  BINANCE_TESTNET = 'BNB Smart Chain Testnet',
  ETHEREUM = 'Ethereum',
  SEPOLIA = 'Sepolia',
  UNRECOGNIZED = 'Unrecognized',
}

export enum RollupType {
  OPSTACK = 'OP Stack',
  ORBIT = 'Arbitrum Orbit',
  CDK = 'Polygon CDK',
  ZKSTACK = 'ZK Stack',
  UNRECOGNIZED = 'Unrecognized',
}

export enum RollupStatus {
  ACTIVE = 'Active',
  DEPLOYING = 'Deploying',
  FAILED = 'Failed',
  TERMINATED = 'Terminated',
  UNRECOGNIZED = 'Unrecognized',
}

export enum DataAvailabilityLayer {
  CALLDATA = 'Calldata',
  BLOBS = 'Blobs',
  BLOBS_BNB = 'Blobs (BNB)',
  EIGEN_DA = 'Alt-DA (EigenDA)',
  CELESTIA = 'Alt-DA (Celestia)',
  AVAIL = 'Alt-DA (Avail)',
  OG = 'Alt-DA (0G)',
  NEAR = 'Alt-DA (Near)',
  ALT_DA_DA_SERVER = 'Alt-DA (DA Server)',
  ANYTRUST = 'AnyTrust',
  ALT_DA = 'Alt-DA',
  UNRECOGNIZED = 'Unrecognized',
}

const rollupTypeIcons: Record<string | RollupType, ReactNode> = {
  [RollupType.OPSTACK]: <IconOP sx={{ width: iconSize, height: iconSize }} />,
  [RollupType.ORBIT]: <IconArbOrbit sx={{ width: iconSize, height: iconSize }} />,
  [RollupType.CDK]: <IconPolygon sx={{ width: iconSize, height: iconSize }} />,
  [RollupType.ZKSTACK]: <IconZKSync sx={{ width: iconSize, height: iconSize }} />,
  [RollupType.UNRECOGNIZED]: undefined,
};

const daTypeIcons: Record<string | DataAvailabilityLayer, ReactNode> = {
  [DataAvailabilityLayer.EIGEN_DA]: <IconEigenDa sx={{ width: iconSize, height: iconSize }} />,
  [DataAvailabilityLayer.CELESTIA]: <IconCelestia sx={{ width: iconSize, height: iconSize }} />,
  [DataAvailabilityLayer.AVAIL]: <IconAvail sx={{ width: iconSize, height: iconSize }} />,
  [DataAvailabilityLayer.NEAR]: <IconNear sx={{ width: iconSize, height: iconSize }} />,
  [DataAvailabilityLayer.OG]: <Icon0g sx={{ width: iconSize, height: iconSize }} />,
  [DataAvailabilityLayer.ANYTRUST]: <IconAnyTrustDa sx={{ width: iconSize, height: iconSize }} />,
  [DataAvailabilityLayer.BLOBS]: <IconEthBlobs sx={{ width: iconSize, height: iconSize }} />,
  [DataAvailabilityLayer.CALLDATA]: undefined,
  [DataAvailabilityLayer.ALT_DA]: <IconAltLayer sx={{ width: iconSize, height: iconSize }} />,
  [DataAvailabilityLayer.ALT_DA_DA_SERVER]: (
    <IconAltLayer sx={{ width: iconSize, height: iconSize }} />
  ),
  [DataAvailabilityLayer.UNRECOGNIZED]: undefined,
};

const rollupStatusLabelMap: Record<string | RollupStatus, ReactNode> = {
  [RollupStatus.ACTIVE]: <StatusLabel sx={{ background: '#66B489' }}>Live</StatusLabel>,
  [RollupStatus.TERMINATED]: <StatusLabel sx={{ backgorund: '#F1605F' }}>Terminated</StatusLabel>,
  [RollupStatus.DEPLOYING]: <StatusLabel sx={{ background: '#F8D97D' }}>Deploying</StatusLabel>,
  [RollupStatus.FAILED]: <StatusLabel sx={{ background: '#F1605F' }}>Failed</StatusLabel>,
  [RollupStatus.UNRECOGNIZED]: undefined,
};

const rollupSettlementLayerIconMap: Record<string | SettlementLayer, ReactNode> = {
  [SettlementLayer.ARBITRUM_ONE]: <IconArb sx={{ width: iconSize, height: iconSize }} />,
  [SettlementLayer.ARBITRUM_SEPOLIA]: <IconArb sx={{ width: iconSize, height: iconSize }} />,
  [SettlementLayer.BINANCE_MAINNET]: <IconBinance sx={{ width: iconSize, height: iconSize }} />,
  [SettlementLayer.BINANCE_TESTNET]: <IconBinance sx={{ width: iconSize, height: iconSize }} />,
  [SettlementLayer.ETHEREUM]: <IconEth sx={{ width: iconSize, height: iconSize }} />,
  [SettlementLayer.SEPOLIA]: <IconSepoliaEth sx={{ width: iconSize, height: iconSize }} />,
  [SettlementLayer.UNRECOGNIZED]: undefined,
};

const rollupSettlementLayerToChain: Record<SettlementLayer, Chain | undefined> = {
  [SettlementLayer.ARBITRUM_ONE]: arbitrum,
  [SettlementLayer.ARBITRUM_SEPOLIA]: arbitrumSepolia,
  [SettlementLayer.BINANCE_MAINNET]: bsc,
  [SettlementLayer.BINANCE_TESTNET]: bscTestnet,
  [SettlementLayer.ETHEREUM]: mainnet,
  [SettlementLayer.SEPOLIA]: sepolia,
  [SettlementLayer.UNRECOGNIZED]: undefined,
};

export const renderRollupTypeIcon = (rollupType: string | RollupType): ReactNode =>
  rollupTypeIcons[rollupType];

export const renderDaTypeIcon = (daType: string | DataAvailabilityLayer): ReactNode =>
  daTypeIcons[daType];

export const renderRollupStatusLabel = (RollupStatus: string | RollupStatus): ReactNode =>
  rollupStatusLabelMap[RollupStatus];

export const renderRollupSettlementLayerIcon = (
  settlementLayer: string | SettlementLayer,
): ReactNode => rollupSettlementLayerIconMap[settlementLayer];

export const getRollupSettlementLayerChain = (
  settlementLayer: SettlementLayer,
): Chain | undefined => rollupSettlementLayerToChain[settlementLayer];
