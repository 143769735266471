import { IconButton, Skeleton, Stack } from '@mui/material';
import { IconDocument } from 'components/icons/IconDocument';
import { IconDownload } from 'components/icons/IconDownload';
import { IconMagnifyingGlass } from 'components/icons/IconMagnifyingGlass';
import { usePreviewDialogFns } from 'hooks/ui/usePreviewDialogFns';
import { SectionContentCompactRow } from 'pages/raas/DeploymentDetails/components/SectionContentCompactRow';
import { ReactNode, useCallback } from 'react';
import { Section } from 'shared/components/Section';

import { PreviewDialog } from './PreviewDialog';
import { IRollupInfoSection } from './types';

export const MetadataSection = ({ loading, rollup }: IRollupInfoSection) => {
  const {
    handleDownloadJSON,
    handlePreviewMetadata,
    jsonOrUrl,
    onClosePreviewDialog,
    openedPreviewDialog,
    title,
  } = usePreviewDialogFns();

  // TODO: uncomment or remove function when design decisions are finalized for raas-m2
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDownloadContractAddresses = useCallback(() => {
    const fileName = 'contract-addresses.json';
    const json = JSON.stringify(rollup?.contractAddresses, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  }, [rollup?.contractAddresses]);

  return (
    <>
      <Section
        renderGridContent={
          <Stack spacing="24px" sx={{ width: '100%', mb: '24px' }}>
            {loading ? (
              Array.from({ length: 3 }).map((_, idx) => (
                <Skeleton key={idx.toString()} variant="text" width="35%" />
              ))
            ) : (
              <>
                {Object.entries({
                  ...rollup?.metadata,
                }).map(([key, value]) => {
                  let renderEnd: ReactNode;
                  let label: string = `${key}.json`;

                  if (typeof value === 'string' && value.includes('.json')) {
                    renderEnd = (
                      <IconButton onClick={() => handleDownloadJSON(key, value)} size="small">
                        <IconDownload sx={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    );
                  } else if (typeof value === 'object') {
                    label = key;
                    renderEnd = (
                      <IconButton
                        onClick={() => handlePreviewMetadata(key, JSON.stringify(value, null, 2))}
                        size="small"
                      >
                        <IconMagnifyingGlass sx={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    );
                  } else {
                    label = key;
                    renderEnd = <>{value}</>;
                  }

                  return (
                    <SectionContentCompactRow
                      key={['rollup-info', key].join('-')}
                      label={label ?? key}
                      renderEnd={renderEnd}
                      renderStart={<IconDocument sx={{ height: '20px', width: '20px' }} />}
                    />
                  );
                })}
                {rollup?.contractAddresses && (
                  <SectionContentCompactRow
                    label="contract address.json"
                    renderEnd={
                      <IconButton
                        onClick={() =>
                          handlePreviewMetadata(
                            'Contract Addresses',
                            JSON.stringify(rollup?.contractAddresses, null, 2),
                          )
                        }
                        size="small"
                      >
                        <IconMagnifyingGlass sx={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    }
                    renderStart={<IconDocument sx={{ height: '20px', width: '20px' }} />}
                  />
                )}
              </>
            )}
          </Stack>
        }
        sx={{
          borderRight: '1px solid rgba(32, 39, 35, 0.08)',
        }}
        title="Metadata"
      />

      <PreviewDialog
        jsonOrUrl={jsonOrUrl}
        onClose={onClosePreviewDialog}
        open={openedPreviewDialog}
        title={title}
      />
    </>
  );
};
