import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { avsTypeLabels } from 'labels/deployments';
import { IoArrowForwardSharp } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import TRow from 'shared/components/Table/TRow';
import { AVS_TYPES } from 'types/avs';
import { AVSDeployment } from 'types/protoc-gen/avs';
import { ColumnConfig } from 'types/table';

const columnConfig: ColumnConfig<AVSDeployment>[] = [
  {
    id: 'avsName',
    Header: 'Name',
    Cell: row => (
      <Typography alignItems="center" display="flex" gap={1}>
        {/* {row?.iconUrl && <Box component="img" src={row?.iconUrl} sx={{ height: 20, width: 20 }} />} */}
        {row?.avsName}
      </Typography>
    ),
  },
  {
    id: 'avsType',
    Header: 'Type',
    Cell: row => <Typography>{avsTypeLabels?.[row?.avsType as AVS_TYPES]}</Typography>,
  },
  {
    id: 'actionCol',
    Header: '',
    Cell: row => (
      <Box component={Link} sx={{ color: 'rgb(32, 39, 35)' }} to={`/avs/${row?.deploymentId}`}>
        <Box component={IoArrowForwardSharp} size={24} />
      </Box>
    ),
    width: 25,
  },
];

export function GlobalAvsDeployments({
  data,
  isLoading,
}: {
  data: AVSDeployment[];
  isLoading: boolean;
}) {
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        sx={{ '&>*': { minHeight: '12rem', boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)' } }}
      >
        {isLoading ? (
          <Grid alignItems="center" container item justifyContent="center" xs={12}>
            <CircularProgress size={40} />
          </Grid>
        ) : (
          <TableContainer>
            <Table
              sx={{
                background: '#FFF',
                '&& th:first-of-type,&& td:first-of-type': { pl: 5 },
                '&& th:last-of-type,&& td:last-of-type': { pr: 5 },
              }}
            >
              <TableHead sx={{ background: 'rgba(0, 0, 0, 0.16)' }}>
                <TableRow>
                  {columnConfig?.map(col => (
                    <TableCell align={col.align} key={col.id} sx={{ width: col.width }}>
                      {col.Header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map(avs => (
                  <TRow<AVSDeployment>
                    columnConfig={columnConfig}
                    key={avs?.deploymentId}
                    onClick={() => {
                      navigate(`/avs/${avs?.deploymentId}`);
                    }}
                    rowData={avs}
                    sx={{ cursor: 'pointer' }}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </>
  );
}
