import { Close as CloseIcon } from '@mui/icons-material';
import {
  darken,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { IoAddSharp } from 'react-icons/io5';
import { MdPublish } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';

interface AgentCreationModeDialogProps {
  open: boolean;
  onClose: () => void;
}

const AgentCreationModeDialog: React.FC<AgentCreationModeDialogProps> = ({ onClose, open }) => {
  const navigate = useNavigate();

  return (
    <Dialog
      disablePortal
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiPaper-root': { p: 3, background: '#FFFFFF', maxWidth: '90vw', minWidth: '30vw' },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          spacing={3}
          sx={{
            '& > div': {
              bgcolor: '#F6F6F6',
              p: 5,
              cursor: 'pointer',
            },
            '& > div:hover': {
              bgcolor: darken('#F6F6F6', 0.05),
            },
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            onClick={() => navigate(PATHS.AAAS_NEW_DEPLOYMENT)}
            spacing={2}
          >
            <IoAddSharp size={24} />
            <Typography variant="bodySmallC">Deploy your agent</Typography>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            onClick={() => navigate(PATHS.AAAS_PUBLISH_AGENT)}
            spacing={2}
          >
            <MdPublish size={24} />
            <Typography variant="bodySmallC">List your agent</Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AgentCreationModeDialog;
